import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Check, X } from 'lucide-react';
import useSubscription from '../../hooks/useSubscription';

const PlanComparison = ({ plans }) => {
  const { currentTier } = useSubscription();

  // --- Begin: Special Launch Pricing States ---
  // You can hardcode this if you want, or fetch it from props/context
  const [launchPricingEnabled] = useState(true);
  const [discountPercentage] = useState(25);

  // Helper to apply discount and format price
  const getDiscountedPrice = (price) => {
    if (!price || price <= 0) return price; 
    const discounted = price - price * (discountPercentage / 100);
    return discounted.toFixed(2);
  };
  // --- End: Special Launch Pricing States ---

  // Existing helper to format various feature values
  const formatValue = (value) => {
    if (value === 999) return 'Unlimited';
    if (typeof value === 'boolean') {
      return value 
        ? <Check className="h-4 w-4 text-primary mx-auto" /> 
        : <X className="h-4 w-4 text-muted-foreground mx-auto" />;
    }
    return value;
  };

  // We override the default format for 'price_monthly' to display 
  // both the original price (struck through) and the discounted price.
  const features = [
    { 
      key: 'price_monthly', 
      label: 'Monthly Price', 
      format: (val) => {
        if (launchPricingEnabled && val > 0) {
          return (
            <div className="flex flex-col items-center">
              <span className="line-through text-gray-400">${val}</span>
              <span className="text-red-500 font-bold">${getDiscountedPrice(val)}</span>
            </div>
          );
        } else {
          // default (no discount or zero price)
          return `$${val}`;
        }
      }
    },
    { key: 'activeFlows', label: 'Active Flows' },
    { key: 'flowSteps', label: 'Steps per Flow' },
    { key: 'chatCredits', label: 'Monthly Chat Credits' },
    { key: 'executionCredits', label: 'Monthly Execution Credits' },
    { key: 'imageCredits', label: 'Monthly Image Credits' },
    { key: 'customAssistants', label: 'Custom Assistants' },
    { key: 'seats', label: 'Available Team Seats' },
    { key: 'isAdvancedTemplates', label: 'Advanced Templates' }
  ];

  return (
    <div className="rounded-lg border bg-card">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Feature</TableHead>
            {plans.map((plan) => (
              <TableHead 
                key={plan._id} 
                className="text-center"
              >
                {plan.name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.key}>
              <TableCell className="font-medium">{feature.label}</TableCell>
              {plans.map((plan) => (
                <TableCell key={plan._id} className="text-center">
                  {feature.format 
                    ? feature.format(plan[feature.key])
                    : formatValue(plan[feature.key])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PlanComparison;