import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Coins, MessageSquare, Image, Play } from "lucide-react";

const CreditsCard = ({ chatCredits = 0, imageCredits = 0, executionCredits = 0, periodDates = null }) => {
  return (
    <Card className="flex flex-col border-none shadow-lg">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-base font-semibold">Available Credits</CardTitle>
        <Coins className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4">
          <div className="flex items-center space-x-4 bg-gray-100 border-none p-4 rounded-lg">
            <div className="p-2 bg-black rounded-full">
              <MessageSquare className="h-5 w-5 text-gray-100" />
            </div>
            <div>
              <p className="text-sm font-medium text-muted-foreground">Chat</p>
              <p className="text-2xl font-bold">{chatCredits}</p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-gray-100 border-none p-4 rounded-lg">
            <div className="p-2 bg-black rounded-full">
              <Image className="h-5 w-5 text-gray-100" />
            </div>
            <div>
              <p className="text-sm font-medium text-muted-foreground">Image</p>
              <p className="text-2xl font-bold">{imageCredits}</p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-gray-100 border-none p-4 rounded-lg">
            <div className="p-2 bg-black rounded-full">
              <Play className="h-5 w-5 text-gray-100" />
            </div>
            <div>
              <p className="text-sm font-medium text-muted-foreground">Execution</p>
              <p className="text-2xl font-bold">{executionCredits}</p>
            </div>
          </div>

          {periodDates && (
            <div className="text-sm text-muted-foreground">
              <p>
                Credits will refill: {periodDates.end}.
              </p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default CreditsCard;