import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFlow, fetchGlobalFlows, fetchFlowById } from '../slices/flowsSlice';
import { openFlowStackModal } from "../slices/flowStackModalSlice";
import { useNavigate } from 'react-router-dom';
import useSubscription from "../hooks/useSubscription";
import { useToast } from "../components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { ScrollArea } from "../components/ui/scroll-area";
import { PlusCircle, FileJson, Search, ChevronUp, ChevronDown, ChevronLeft, InfoIcon, Folder, ArrowLeft, Menu, Clock, Play, Hash, Layers, Tag, X, Cog, ChevronRight, Sparkles  } from 'lucide-react';
import FlowForm from './FlowForm';
import JsonInputForm from './FlowFormComponents/JsonInputForm';
import { Alert, AlertDescription } from "../components/ui/alert";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";
import { Badge } from "../components/ui/badge";
import PreMadeFlows from './FlowFormComponents/PreMadeFlows';
import { fetchFlows } from "../slices/flowsSlice";
import TeamSelector from '../components/TeamSelector';

const FlowCreate = () => {
  const dispatch = useDispatch();
  const { credits, limits } = useSubscription();
  const { flows, status, error, totalItems, currentPage, itemsPerPage } =
      useSelector((state) => state.flows);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPreMadeFlow, setSelectedPreMadeFlow] = useState(null);
  const [isLoadingPreMadeFlow, setIsLoadingPreMadeFlow] = useState(false);
  const [preMadeFlowError, setPreMadeFlowError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isTemplateSelectionExpanded, setIsTemplateSelectionExpanded] = useState(true);
  const [activeTab, setActiveTab] = useState('pre-made');
  const [isNavExpanded, setIsNavExpanded] = useState(true);
  const globalFlows = useSelector(state => state.flows.globalFlows.data);
  //console.log('globalFlows', globalFlows);
  const globalFlowsStatus = useSelector(state => state.flows.globalFlowsStatus);
  const { selectedTeam, teams } = useSelector((state) => state.teams);
  const [isSaving, setIsSaving] = useState(false);

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const InfoBlurb = ({ children }) => (
    <div className="flex items-start space-x-2 text-sm text-muted-foreground mt-4">
      <span>{children}</span>
    </div>
  );

  const NavButton = ({ icon: Icon, label, tabName }) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant={activeTab === tabName ? 'default' : 'ghost'}
            className={`w-full justify-start ${activeTab === tabName ? 'bg-black' : ''} ${!isNavExpanded ? 'px-2' : ''}`}
            onClick={() => setActiveTab(tabName)}
          >
            <Icon className="w-4 h-4 mr-2" />
            {isNavExpanded && <span>{label}</span>}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right" align="start">
          {label}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  const getInfoBlurb = () => {
    switch (activeTab) {
      case 'standard':
        return "The manual step-by-step builder gives you complete control over your flow creation process. This intuitive interface allows you to add individual actions and requests, customize each step with specific parameters and conditions, and visualize the flow as you build it.";
      case 'pre-made':
        return "Our library of pre-made templates offers a quick start for common business processes. You can choose from a variety of industry-standard workflow templates and customize them to fit your specific needs.";
      case 'json':
        return "For advanced users or those with pre-existing flow definitions, we offer the ability to input raw JSON. This method allows you to paste or upload your flow definition in JSON format, enabling quick import of complex flows with multiple steps and conditions.";
      default:
        return "";
    }
  };

  

  const selectedTeamInfo = useMemo(() => {
    if (selectedTeam === 'all') {
      return { name: 'Personal', id: 'all' };
    } else if (selectedTeam === 'personal') {
      return { name: 'Personal', id: 'personal' };
    } else {
      const teamRecord = teams.find(team => team._id === selectedTeam);
      return teamRecord ? { name: teamRecord.name, id: teamRecord._id } : null;
    }
  }, [teams, selectedTeam]);

  useEffect(() => {
    if (globalFlowsStatus === 'idle') {
      dispatch(fetchGlobalFlows());
    }
  }, [globalFlowsStatus, dispatch]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(fetchFlows({ page: 1 })).unwrap(),
          ]);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };
      if (!totalItems) {
        fetchData();
      }
    }, [dispatch]);

  const handleSubmit = async (flowData) => {
    if (totalItems >= limits.activeFlows) {
      toast({
        title: "Error",
        description: "You have reached the limit of active flows for your subscription. Please upgrade your plan to create more flows.",
        variant: "destructive",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      let flowDataWithTeam = { ...flowData };
      if (selectedTeamInfo.id !== 'all' && selectedTeamInfo.id !== 'personal') {
        flowDataWithTeam.team = selectedTeamInfo.id;
      }
      await dispatch(createFlow(flowDataWithTeam)).unwrap();
      toast({
        title: "Success",
        description: "Flow created successfully",
        variant: "default",
      });
      navigate('/manage-flows');
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to create flow",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleJsonSubmit = async (jsonData) => {
    setIsSubmitting(true);
    try {
      let jsonDataWithTeam = { ...jsonData };
      if (selectedTeamInfo.id !== 'all' && selectedTeamInfo.id !== 'personal') {
        jsonDataWithTeam.team = selectedTeamInfo.id;
      }
      await dispatch(createFlow(jsonDataWithTeam)).unwrap();
      toast({
        title: "Success",
        description: "Flow created successfully from JSON",
        variant: "default",
      });
      navigate('/manage-flows');
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to create flow from JSON",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  /*const handlePreMadeFlowSelect = async (flowId) => {
    setIsLoadingPreMadeFlow(true);
    setPreMadeFlowError(null);
    try {
      const fullFlow = await dispatch(fetchFlowById(flowId)).unwrap();
      setSelectedPreMadeFlow({
        ...fullFlow,
        name: `${fullFlow.name} (Template)`,
        team: selectedTeamInfo.id !== 'all' && selectedTeamInfo.id !== 'personal' ? selectedTeamInfo.id : null,
      });
      setIsTemplateSelectionExpanded(false);
    } catch (error) {
      setPreMadeFlowError("Failed to load the selected flow. Please try again.");
      toast({
        title: "Error",
        description: "Failed to load the selected flow",
        variant: "destructive",
      });
    } finally {
      setIsLoadingPreMadeFlow(false);
    }
  };*/

  /*const filteredFlows = globalFlows
  .filter(flow => 
    flow.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    flow.description.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => a.name.localeCompare(b.name));

  const toggleTemplateSelection = () => {
    setIsTemplateSelectionExpanded(!isTemplateSelectionExpanded);
  };*/

  if (!credits || !limits.activeFlows || !globalFlows) {
    return <div className="text-center">Loading subscription details...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-2rem)] lg:h-[calc(100vh-0rem)] -mx-6 -mt-8 lg:mx-0 lg:mt-0">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center h-8 px-4 border-b sticky mt-4 bg-background z-[60]">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsNavExpanded(!isNavExpanded)}
          className="mr-2"
        >
          <Cog className="h-5 w-5" />
        </Button>
        <div className="flex-1 flex items-center justify-between">
          <h2 className="text-base font-medium truncate">
            {activeTab === 'standard' ? 'Standard Creation' : 
             activeTab === 'pre-made' ? 'Pre-made Flows' : 'JSON Input'}
          </h2>
        </div>
      </div>
  
      {/* Navigation Sidebar */}
      <div 
  className={`
    w-full lg:w-1/4 border-r bg-background
    fixed lg:relative inset-y-0 left-0 z-[70]
    transform lg:transform-none transition-transform duration-200 ease-in-out
    h-[100vh] lg:h-auto pt-0
    ${isNavExpanded ? 'translate-x-0' : '-translate-x-full'}
  `}
>
        <ScrollArea className="h-full">
          <div className="p-4 py-0 pt-4 md:pt-1">
            <div className="flex justify-between items-center mb-4">
              {isNavExpanded && <h1 className="text-2xl font-bold text-inter">Create a Flow</h1>}
              <span className={`text-sm ${limits.activeFlows <= totalItems ? 'text-red-500 font-bold' : 'text-muted-foreground'} `}>Active Flows: {totalItems}/{limits.activeFlows}</span>
              <Button 
                variant="ghost" 
                size="icon" 
                onClick={() => {
                  toggleNav();
                  if (window.innerWidth < 1024) { // lg breakpoint
                    setIsNavExpanded(false);
                  }
                }}
                className="hidden"
              >
                {isNavExpanded ? <ChevronLeft className="w-4 h-4" /> : <Menu className="lg:hidden w-4 h-4" />}
              </Button>
              <Button
                variant="ghost"
                size="sm"
                className="lg:hidden"
                onClick={() => setIsNavExpanded(false)}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            {isNavExpanded && selectedTeamInfo && (
              <div className="flex flex-col items-start text-sm space-x-2 mb-4">
                Creating flow for:
                <TeamSelector />
              </div>
            )}
            {isNavExpanded && (<><div className="space-y-2" onClick={(e) => {
                if (window.innerWidth < 1024) { // Only collapse nav on mobile
                  setIsNavExpanded(false);
                }
              }}>
              {limits.activeFlows > totalItems && (
                <NavButton icon={PlusCircle} label="Standard Creation" tabName="standard" />
            )}
              <NavButton icon={Layers} label="Pre-made Flows" tabName="pre-made" />
              {limits.activeFlows > totalItems && (
              <NavButton icon={FileJson} label="JSON Input" tabName="json" />
            )}
            </div>
            <InfoBlurb>{getInfoBlurb()}</InfoBlurb></>)}
            {limits.activeFlows <= totalItems && (
              <Alert className="mt-4 border border-red-300 bg-red-100">
                <AlertDescription>
                  You have reached the limit of active flows for your subscription. You can browse the pre-made flows, but you will not be able to create a new flow.<br></br><br></br><span className='cursor-pointer underline' onClick={()=>navigate("/subscription")}>Please upgrade your plan to create more flows</span>.
                </AlertDescription>
              </Alert>
              )}
              <Button className="magical-gradient mt-10" onClick={() => dispatch(openFlowStackModal())}>
                <Sparkles className="h-4 w-4 text-white mr-2" />
                Magically Create a Flow with Flow Agent
              </Button>
              <div className="mt-1 text-sm text-muted-foreground">
                Answer a few quick questions about your goal or task, and Flow Agent will create a flow for you.
              </div>
          </div>
        </ScrollArea>
      </div>
  
      {/* Main Content Area */}
      <div className="flex-1 lg:w-3/4 overflow-y-auto mt-2 lg:mt-1">
        <ScrollArea className="h-full">
          {activeTab === 'standard' && (
            <div className="p-4 py-0">
              <h2 className="text-2xl font-bold mb-4 hidden lg:block">Standard Creation</h2>
              <div className='mt-8 lg:-mt-2'>
                <FlowForm 
                  onSubmit={handleSubmit}
        submitButtonText="Create Flow"
        saveButtonText="Save"
        isSubmitting={isSubmitting}
                      submittingText="Creating Flow..."
                  isEditMode={false}
                />
              </div>
            </div>
          )}
          {activeTab === 'pre-made' && (
            <PreMadeFlows
            globalFlows={globalFlows}
            selectedTeamInfo={selectedTeamInfo}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            toast={toast}
            limits={limits}
            totalItems={totalItems}
          />
          )}
          {activeTab === 'json' && (
            <Card className="m-4 mt-0">
              <CardHeader>
                <CardTitle>JSON Input</CardTitle>
              </CardHeader>
              <CardContent>
                <JsonInputForm
                  onSubmit={handleJsonSubmit}
                  isSubmitting={isSubmitting}
                />
                <a 
                  href="/JSON_Flow_Example.txt" 
                  download 
                  className="text-blue-600 hover:text-blue-800 underline mt-2 inline-block"
                >
                  Download JSON example
                </a>
              </CardContent>
            </Card>
          )}
        </ScrollArea>
      </div>
  
      {/* Mobile Menu Overlay */}
      {isNavExpanded && (
        <div 
          className="fixed inset-0 bg-black/50 z-[65] lg:hidden"
          onClick={() => setIsNavExpanded(false)}
        />
      )}
    </div>
  );
};

export default FlowCreate;