import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
  const { isAuthenticated, user, loading } = useSelector((state) => state.auth);

  if (loading) {
    // You can customize this spinner or message as needed
    return (
      <div className="flex flex-col items-center justify-center min-h-[200px]">
        <div>Loading...</div>
      </div>
    );
  }

  // If not authenticated or the user role is not 'admin', redirect them
  if (!isAuthenticated || user?.role !== 'admin') {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AdminRoute;
