import React, { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import {
  AudioLines,
  ChevronDown,
  ChevronUp,
  CircleDot,
  Command,
  Dot,
  FlaskConical,
  Mic,
  MicOff,
  Speech,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const VoiceCommands = ({
  onSubmitMessage,
  onImprovePrompt,
  onTranscriptChange,
}) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [showNoSupportAlert, setShowNoSupportAlert] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Use a ref to ensure that a command is triggered only once per transcript change.
  const commandTriggeredRef = useRef(false);
  // New ref to track if an execution is in progress.
  const executionInProgressRef = useRef(false);

  useEffect(() => {
    // Update the parent component with the current transcript.
    if (typeof onTranscriptChange === "function") {
      onTranscriptChange(transcript);
    }

    // When transcript is cleared, reset our command trigger flag.
    if (transcript.trim() === "") {
      commandTriggeredRef.current = false;
      return;
    }

    // If a command has already been triggered or an execution is in progress, do nothing.
    if (commandTriggeredRef.current || executionInProgressRef.current) {
      return;
    }

    const submitPhrases = ["flow submit"];

    const improvementPhrases = [
      "flow improve",
      "flow approve",
      "flow and prove",
      "flow and proof",
      "flow prove",
      "flow improv",
    ];

    const lowerTranscript = transcript.toLowerCase();
    console.log("Transcript:", lowerTranscript);

    if (submitPhrases.some((phrase) => lowerTranscript.includes(phrase))) {
      commandTriggeredRef.current = true;
      executionInProgressRef.current = true;
      SpeechRecognition.stopListening();
      console.log("Message Submitted (via voice)");
      if (typeof onSubmitMessage === "function") {
        const result = onSubmitMessage({ truncate: true });
        // If the command returns a promise, wait for it to complete.
        if (result && typeof result.then === "function") {
          result.finally(() => {
            executionInProgressRef.current = false;
            resetTranscript();
          });
        } else {
          executionInProgressRef.current = false;
          resetTranscript();
        }
      }
    } else if (
      improvementPhrases.some((phrase) => lowerTranscript.includes(phrase))
    ) {
      commandTriggeredRef.current = true;
      executionInProgressRef.current = true;
      SpeechRecognition.stopListening();
      console.log("Prompt Improvement triggered (via voice)");
      resetTranscript();
      if (typeof onImprovePrompt === "function") {
        const result = onImprovePrompt({ truncate: true });
        if (result && typeof result.then === "function") {
          result.finally(() => {
            executionInProgressRef.current = false;
            resetTranscript();
          });
        } else {
          executionInProgressRef.current = false;
          resetTranscript();
        }
      }
    }
  }, [
    transcript,
    resetTranscript,
    onTranscriptChange,
  ]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!listening && e.ctrlKey && e.code === "Space") {
        e.preventDefault();
        handleStartListening();
      }
      // Space bar triggers stop listening only if we're currently listening
      if (listening && e.code === "Space") {
        e.preventDefault();
        handleStopListening();
      }
      // Enter key triggers stop listening and submit when listening
      if (listening && (e.code === "Enter" || e.code === "NumpadEnter")) {
        e.preventDefault();
        SpeechRecognition.stopListening();
        resetTranscript();
        console.log("Message Submitted (via Enter key)");
        if (typeof onSubmitMessage === "function") {
          const result = onSubmitMessage({ truncate: false });
        // If the command returns a promise, wait for it to complete.
        if (result && typeof result.then === "function") {
          result.finally(() => {
            executionInProgressRef.current = false;
            resetTranscript();
          });
        } else {
          executionInProgressRef.current = false;
          resetTranscript();
        }
        }
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [listening, onSubmitMessage]);

  if (!browserSupportsSpeechRecognition) {
    return (
      <>
        {showNoSupportAlert ? (
          <div className="w-full p-4 bg-gray-100 rounded shadow-md">
            <div className="relative p-4 border border-red-400 rounded bg-red-100 text-red-800">
              <p>
                Your browser does not support speech recognition. For best
                results, please use Chrome.
              </p>
              <button
                onClick={() => setShowNoSupportAlert(false)}
                className="absolute top-0 right-0 m-2 text-xl font-bold leading-none text-red-800"
                aria-label="Close alert"
              >
                &times;
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    console.log("Listening manually stopped.");
  };

  // Determine the start button styling based on the listening state.
  const startButtonClasses = `px-4 py-2 flex items-center justify-center text-white rounded hover:text-white ${
    listening
      ? "bg-green-500 hover:bg-green-600"
      : "bg-black hover:bg-green-600"
  }`;

  return (
    <div className="w-full bg-white rounded shadow-md">
      {/* Collapsible header */}
      <div
        className={`p-4 py-3 pr-8 cursor-pointer flex items-center justify-between ${
          isCollapsed && listening ? "bg-green-600" : ""
        }`}
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <div className={`flex items-center`}>
          <Mic
            className={`w-5 h-5 mr-1 ${
              isCollapsed && listening ? "text-white" : "text-green-500"
            }`}
          />
          <span
            className={`font-medium ${
              isCollapsed && listening ? "text-white" : "text-black"
            }`}
          >
            Chat With Your Mic
          </span>
          {listening && (
            <CircleDot className={`h-5 w-5 ml-1 text-red-600 blink`} />
          )}
          <div className="ml-2 text-xs text-gray-500 flex items-center text-indigo-400">
            <FlaskConical className="h-3 w-3" />
            Experimental Feature
          </div>
        </div>
        <div className="text-gray-500">
          {isCollapsed ? (
            <ChevronDown className="h-4 w-4" />
          ) : (
            <ChevronUp className="h-4 w-4" />
          )}
        </div>
      </div>

      {/* Collapsible content with slide animation */}
      <AnimatePresence>
        {!isCollapsed && (
          <motion.div
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <div className="p-4 pt-0 space-y-3">
  <div className={`flex flex-col gap-2`}>
    
    <div className="text-sm leading-tight mb-4">
      <div>Voice Chat Controls:</div>
      <div className="mt-2">
        • Press <kbd className="inline-flex px-1 py-1 text-xs font-semibold text-black bg-gray-100 border border-gray-500 rounded shadow-md">Ctrl</kbd> + <kbd className="inline-flex px-1 py-1 text-xs font-semibold text-black bg-gray-100 border border-gray-500 rounded shadow-md">Space</kbd> or <kbd className="inline-flex px-1 py-1 text-xs font-semibold text-green-500 bg-gray-100 border border-green-500 rounded shadow-md">Start</kbd> to start recording
      </div>
    </div>

    <div className="text-sm font-medium mb-2">While Recording:</div>
    
    {/* Voice Commands Section */}
    <div className={`flex items-center ${listening ? "opacity-100" : "opacity-30"}`}>
      <AudioLines className={`min-w-4 w-4 h-4 min-h-4 mr-1 ${listening ? "text-green-500" : "text-black"}`} />
      <div className="text-sm">
        Say "<strong>FLOW SUBMIT</strong>" to send or "<strong>FLOW IMPROVE</strong>" to enhance
      </div>
    </div>

    {/* Keyboard Controls Section */}
    <div className={`flex items-center ${listening ? "opacity-100" : "opacity-30"}`}>
      <Command className={`min-w-4 w-4 h-4 min-h-4 mr-1 ${listening ? "text-green-500" : "text-black"}`} />
      <div className="text-sm">
        Press <kbd className="inline-flex px-1 py-1 text-xs font-semibold text-black bg-gray-100 border border-gray-500 rounded shadow-md">Space</kbd> or <kbd className="inline-flex px-1 py-1 text-xs font-semibold text-red-500 bg-gray-100 border border-red-500 rounded shadow-md">Stop</kbd> to pause (review before sending)
      </div>
    </div>
    <div className={`flex items-center ${listening ? "opacity-100" : "opacity-30"}`}>
      <Command className={`min-w-4 w-4 h-4 min-h-4 mr-1 ${listening ? "text-green-500" : "text-black"}`} />
      <div className="text-sm">
        Press <kbd className="inline-flex px-1 py-1 text-xs font-semibold text-black bg-gray-100 border border-gray-500 rounded shadow-md">Enter</kbd> to stop and send
      </div>
    </div>
  </div>

  {/* Buttons remain the same */}
  <div className="w-full flex justify-between items-center gap-4 py-3">
    <Button
      type="button"
      onClick={handleStartListening}
      disabled={!browserSupportsSpeechRecognition}
      className={startButtonClasses}
    >
      <Mic className="w-6 h-6 mr-2" />
      {listening ? "Recording..." : "Start"}
    </Button>
    <Button
      type="button"
      variant="destructive"
      onClick={handleStopListening}
      disabled={!listening || !browserSupportsSpeechRecognition}
      className="px-4 py-2 flex items-center justify-center"
    >
      <MicOff className="w-6 h-6 mr-2" />
      Stop
    </Button>
  </div>

  {/* Stop message simplified 
  <AnimatePresence>
    <motion.div
      key="listeningMessage"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      className="flex overflow-hidden"
    >
      <MicOff className={`min-w-4 w-4 h-4 min-h-4 mr-1 ${listening ? "opacity-100 text-red-500" : "opacity-40"}`} />
      <div className={`text-sm leading-tight ${listening ? "opacity-100" : "opacity-40"}`}>
        Click <strong>Stop</strong> to pause without sending. Your message will be saved for review.
      </div>
    </motion.div>
  </AnimatePresence>*/}
</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VoiceCommands;
