import React from "react";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { 
  ArchiveIcon, 
  CircleIcon, 
  Trash2Icon, 
  CheckCircleIcon,
  MailIcon,
  MailOpenIcon,
  TrashIcon
} from "lucide-react";
import AssetDetail from "./AssetDetail";
import EmptyState from "./EmptyState";

const NotificationsPanel = ({
  notifications,
  selectedNotificationId,
  onSelectNotification,
  onMarkAsRead,
  onMarkAsUnread,
  onDelete,
  onMarkAllAsRead,
  onDeleteAll,
  searchTerm,
  viewMode,
  status
}) => {
  // Filter notifications based on search term
  const filteredNotifications = notifications.filter(notification => {
    if (!searchTerm) return true;
    
    const searchFields = [
      notification.title,
      notification.message,
      notification.type
    ];
    
    return searchFields.some(field => 
      field && field.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Get the selected notification
  const selectedNotification = notifications.find(n => n._id === selectedNotificationId);

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // If the date is today, only show time
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    
    // If the date is within the last week, show day and time
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    if (date > lastWeek) {
      return date.toLocaleDateString(undefined, {
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    
    // Otherwise show full date
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="flex h-full">
      <div className={`flex-1 ${selectedNotificationId ? 'lg:w-2/3' : 'w-full'} h-full overflow-hidden flex flex-col`}>
        <div className="p-4 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-bold">Notifications</h2>
            <p className="text-sm text-gray-500 mt-1">
              {filteredNotifications.length} notifications
              {filteredNotifications.filter(n => !n.isRead).length > 0 && 
                ` (${filteredNotifications.filter(n => !n.isRead).length} unread)`}
            </p>
          </div>
          
          <div className="flex space-x-2">
            <Button 
              variant="outline" 
              size="sm"
              onClick={onMarkAllAsRead}
              disabled={filteredNotifications.filter(n => !n.isRead).length === 0}
              className="border-none shadow-md"
            >
              <MailOpenIcon className="h-4 w-4 mr-2" />
              Mark All Read
            </Button>
            
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="text-red-500 hover:text-red-600 hover:bg-red-50 border-none shadow-md"
                  disabled={filteredNotifications.length === 0}
                >
                  <TrashIcon className="h-4 w-4 mr-2" />
                  Delete All
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Delete All Notifications</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to delete all notifications? This action cannot be undone.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={onDeleteAll}>
                    Delete All
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>
        
        <div className="flex-1 overflow-auto">
          {status === "loading" ? (
            <div className="flex justify-center items-center h-64">
              <div className="loader"></div>
            </div>
          ) : filteredNotifications.length === 0 ? (
            <EmptyState 
              assetType="notifications"
              searchTerm={searchTerm}
            />
          ) : (
            <div className="p-4 space-y-2">
              {filteredNotifications.map((notification) => (
                <Card
                  key={notification._id}
                  className={`cursor-pointer transition-colors ${
                    selectedNotificationId === notification._id
                      ? "bg-primary/5 border-primary"
                      : !notification.isRead
                      ? "bg-blue-50 dark:bg-blue-900/20 border-blue-100 dark:border-blue-900"
                      : "bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
                  }`}
                >
                  <CardContent className="p-4 flex justify-between items-start">
                    <div 
                      className="flex-grow"
                      onClick={() => onSelectNotification(notification._id)}
                    >
                      <div className="flex items-center">
                        {!notification.isRead ? (
                          <CircleIcon className="h-3 w-3 text-blue-500 fill-current mr-2 flex-shrink-0" />
                        ) : (
                          <CheckCircleIcon className="h-3 w-3 text-gray-400 mr-2 flex-shrink-0" />
                        )}
                        <h3 className="font-semibold text-base">
                          {notification.title || `${notification.type ? notification.type.charAt(0).toUpperCase() + notification.type.slice(1) : 'Notification'}`}
                        </h3>
                        <span className="ml-auto text-xs text-gray-500">
                          {formatDate(notification.date)}
                        </span>
                      </div>
                      <p className="mt-1 text-sm text-gray-600 dark:text-gray-300 line-clamp-2 ml-5">
                        {notification.message || "No message content"}
                      </p>
                    </div>
                    
                    <div className="flex items-center ml-4 flex-shrink-0">
                      <Button
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          notification.isRead ? onMarkAsUnread(notification._id) : onMarkAsRead(notification._id);
                        }}
                        title={notification.isRead ? "Mark as unread" : "Mark as read"}
                      >
                        {notification.isRead ? (
                          <MailIcon className="h-4 w-4 text-gray-400" />
                        ) : (
                          <MailOpenIcon className="h-4 w-4 text-blue-500" />
                        )}
                      </Button>
                      
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            className="h-8 w-8 p-0 text-red-500"
                            title="Delete notification"
                          >
                            <Trash2Icon className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Delete Notification</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to delete this notification? This action cannot be undone.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => onDelete(notification._id)}>
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </div>
      </div>
      
      {selectedNotificationId && (
        <div className="hidden lg:block lg:w-1/3 border-l h-full overflow-auto">
          <AssetDetail
            asset={selectedNotification}
            assetType="notifications"
            onDeleteAsset={(id) => onDelete(id)}
          />
        </div>
      )}

      {/* Mobile notification detail view */}
      {selectedNotificationId && (
        <div className="lg:hidden fixed inset-0 bg-white dark:bg-gray-800 z-50 overflow-auto">
          <div className="sticky top-0 flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-700">
            <Button 
              variant="ghost" 
              onClick={() => onSelectNotification(null)}
            >
              Back
            </Button>
            <h2 className="text-lg font-bold">Notification Details</h2>
            <div className="w-10"></div> {/* Spacer for centering */}
          </div>
          <div className="p-4">
            <AssetDetail
              asset={selectedNotification}
              assetType="notifications"
              onDeleteAsset={(id) => onDelete(id)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsPanel;