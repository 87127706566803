// src/components/surveyData/FinalScreenerResponseScreen.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../ui/use-toast";
import { motion } from "framer-motion";
import {
  setCurrentChat,
  addMessage,
} from "../../slices/chatSlice";
import { executeStack, editPromptStack } from "../../slices/promptStackSlice";
import { updateCredits } from "../../slices/authSlice";
import { updateFlow } from "../../slices/flowsSlice";
import { startExecution } from "../../slices/executionsSlice";
import {
  ArrowDown,
  ArrowRight,
  Highlighter,
  Image,
  Play,
  Sparkles,
  WandSparkles,
  Waypoints,
  X,
  Edit3,
  Dot,
  Save,
} from "lucide-react";
import { ScrollArea } from "../ui/scroll-area";

const FinalScreenerResponseScreen = ({
  selectedTeam,
  credits,
  answers,
  response,
  onClose,
  restartQuestions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [stackResponding, setStackResponding] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
useEffect(() => {
  setHasMounted(true);
}, []);

  // Parse response if it's a string
  let data = response;
  if (typeof response === "string") {
    try {
      data = JSON.parse(response);
    } catch (error) {
      console.error("Error parsing response:", error);
      data = {};
    }
  }

  // Destructure the workflow response fields
  const [localData, setLocalData] = useState(data);
  const { title, description, prompts, tags, category, steps, name, _id } = localData;

  // For prompt stack, create state for editing prompt messages
  const [editedPrompts, setEditedPrompts] = useState(
    prompts ? prompts.map((p) => p.message) : []
  );
  const [editedSteps, setEditedSteps] = useState(
    steps ? steps.map((s) => s.prompt || s.description) : []
  );
  // Track which prompt(s) are in edit mode. Keys are indices.
  const [editing, setEditing] = useState({});

  // When localData.prompts changes (for example, after a save) update the editedPrompts state.
  useEffect(() => {
    if (localData.prompts) {
      setEditedPrompts(localData.prompts.map((p) => p.message));
    }
  }, [localData.prompts]);

  
  if (!response) return null;

  // Determine if any prompt has unsaved changes.
  const unsavedChangesStack =
    answers?.assetType === "promptStack" &&
    prompts &&
    editedPrompts.some((edited, idx) => edited !== prompts[idx].message);

  // Determine if any step has unsaved changes.
  const unsavedChangesFlow = answers?.assetType === "flow" && steps && editedSteps.some((edited, idx) => edited !== steps[idx].prompt);

  // Framer Motion animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  // Placeholder action for the primary button
  const handleRunAction = () => {
    if (answers?.assetType === "promptStack") {
      navigate("/chat");
    } else {
      navigate("/manage-flows");
    }
    onClose();
  };

  const handleSaveFlow = async () => {
    try {
      // Create an updated steps array using the editedSteps state.
      const updatedSteps = localData.steps.map((step, idx) =>
        step.prompt !== undefined
          ? { ...step, prompt: editedSteps[idx] }
          : { ...step, description: editedSteps[idx] }
      );
  
      // Merge the updated steps into the flow data.
      const updatedFlow = { ...localData, steps: updatedSteps };
      console.log("Updated flow data:", updatedFlow);
  
      // Dispatch updateFlow with the necessary fields.
      await dispatch(
        updateFlow({
          id: localData._id,
          flowData: updatedFlow,
        })
      ).unwrap();
  
      setLocalData(updatedFlow);
      setEditing({});
      toast({
        title: "Success",
        description: "Flow saved successfully",
        variant: "default",
      });
    } catch (err) {
      console.error("Error in handleSave:", err);
      toast({
        title: "Error",
        description: "Failed to save flow",
        variant: "destructive",
      });
    }
  };  

    const handleExecuteFlow = async () => {
      // If there are unsaved prompt edits, save them first.
    if (unsavedChangesFlow) {
      await handleSaveFlow();
    }
        
        try {
          const result = await dispatch(
            startExecution({
              flowId: localData._id,
              teamId: selectedTeam || null,
            })
          ).unwrap();
          onClose();
              dispatch(updateCredits({ type: "execution", amount: 1, subtract: true }));
          
          navigate(`/execution/${result.executionId}`, {
            state: { isNewExecution: true },
          });
        } catch (error) {
          dispatch(updateCredits({ type: "execution", amount: 1, subtract: false }));
          toast({
            title: "Error",
            description: "Failed to start execution: " + error.message,
            variant: "destructive",
          });
        }
      };

  const handleSavePromptStack = async () => {
    try {
      if (localData._id) {
        // Create updated prompts by replacing each prompt's message with the edited version.
        const updatedPrompts = localData.prompts.map((prompt, idx) => ({
          ...prompt,
          message: editedPrompts[idx],
        }));
        const updatedStackData = { title: localData.title, prompts: updatedPrompts };
        await dispatch(
          editPromptStack({ id: localData._id, stackData: updatedStackData })
        ).unwrap();
        setLocalData(updatedStackData);
        setEditing({});
        toast({
          title: "Success",
          description: "Prompt stack updated successfully",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to save prompt stack",
        variant: "destructive",
      });
    }
  };

  const handleExecuteStack = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // If there are unsaved prompt edits, save them first.
    if (unsavedChangesStack) {
      await handleSavePromptStack();
    }

    onClose();
    navigate("/loading");
    const stack = { title, description, prompts, tags, category, _id };
    if (stack.prompts.length > credits.chat) {
      toast({
        title: "Oops! Insufficient Credits",
        description: "You need more chat credits to execute this stack.",
        variant: "destructive",
        duration: 3000,
      });
      return;
    }
    try {
      dispatch(
        updateCredits({ type: "chat", amount: stack.prompts.length, subtract: true })
      );
      setStackResponding(true);

      const stackTitle = {
        role: "user",
        content: stack.title,
      };
      dispatch(addMessage(stackTitle));

      const response = await dispatch(
        executeStack({
          chatId: "new",
          stackId: stack._id,
          promptCount: stack.prompts.length,
          teamId: selectedTeam || null,
        })
      ).unwrap();

      dispatch(setCurrentChat(response));
      navigate(`/chat/${response._id}`);
      setStackResponding(false);
    } catch (error) {
      setStackResponding(false);
      dispatch(
        updateCredits({ type: "chat", amount: stack.prompts.length, subtract: false })
      );
      toast({
        title: "Oops! Something went wrong.",
        description: error || "Failed to execute prompt stack.",
        variant: "destructive",
        duration: 3000,
      });
    }
  };


  return (
    <motion.div
    className="flex flex-col h-screen bg-gray-100 lg:bg-white absolute inset-0 z-[60]" 
    initial={hasMounted ? false : { opacity: 0 }}
    animate={{ opacity: 1 }}
  >
      <div className="flex-shrink-0 pt-1 lg:pt-2 pb-0 flex items-center justify-center gap-2 text-md lg:text-xl font-medium text-indigo-600">
        ...and here is your shiny new {answers?.assetType === "promptStack" ? "prompt stack" : "flow"}! <WandSparkles className="h-5 w-5 lg:h-6 w-6" />
      </div>
      <div className="flex-shrink-0 p-0 flex justify-center mb-2 lg:mb-4">
        <span className="inline-flex items-center px-10 lg:px-2 rounded text-xs font-medium text-gray-800 italic leading-none lg:leading-tight">
          We've already saved this for you. You can close this or create another
          tool without losing this one.
        </span>
      </div>
      {/* Header */}
      <header className="px-6 lg:px-16 py-2 lg:py-4 rounded magical-gradient lg:max-w-8xl mx-4 lg:mx-auto text-gray-100 mb-1 text-center">
        <h2 className="text-lg lg:text-3xl font-bold flex items-center gap-2 justify-center leading-none"><Waypoints className="h-5 w-5 lg:h-8 w-8 text-gray-100" />{title || name}</h2>
        <p className="mt-1 lg:mt-0 text-sm lg:text-lg leading-none lg:leading-tight">{description}</p>
      </header>

      {/* Main Content */}
      <ScrollArea className="flex-grow bg-gray-100 lg:bg-white p-2 pt-0 lg:pt-2 px-4 lg:px-8 pb-4 lg:pb-1 shadow-lg">
        <motion.div
          className="flex flex-col max-w-4xl mx-auto space-y-2 lg:space-y-4 lg:max-w-full lg:flex-row lg:gap-10 lg:justify-center"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {answers && (<>
            <div className="hidden lg:flex flex-col flex-wrap lg:gap-1 lg:w-1/6 text-left leading-tight lg:mt-16">
              <h2 className="font-bold lg:text-xl flex items-center gap-2">
                <Sparkles className="h-5 w-5 lg:h-6 w-6 text-indigo-600" />
                Your Request Summary:
              </h2>
              <div className="leading-tight">
              → You want to create a{" "}<span className="text-indigo-600 font-bold uppercase">
                  {answers?.assetType === "promptStack"
                    ? "Prompt Stack"
                    : "Flow"}
                </span>{" "}
                with{" "}
                <span className="text-indigo-600 font-bold uppercase">
                  {answers?.complexity}
                </span>{" "}
                complexity.
              </div>
              <div className="leading-tight">
              → You want to use{" "}
                <span className="text-indigo-600 font-bold uppercase">
                  {answers?.standardOnly
                    ? "only standard assistants."
                    : "all of your available assistants."}
                </span>
              </div>
              <div className="leading-tight mt-2 lg:mt-4">
              → You want to create this {answers?.assetType === "promptStack" ? "prompt stack" : "flow"} for the following reason:
              </div>
              <div className="leading-tight text-indigo-600 font-bold">
                {answers?.flowDescription}
              </div>
            </div>
            <div>
              <ArrowRight className="hidden lg:block h-16 w-16 text-indigo-600 mt-20 scroll-chevron-glow" />
              {/*<ArrowDown className="lg:hidden h-8 w-8 text-indigo-600 mx-auto scroll-chevron-glow" />*/}
            </div>
          </>)}

          <div className="flex flex-col gap-4 lg:w-1/2">
            <div className="text-center text-sm text-gray-500 leading-tight">
              Click the edit icon <Edit3 className="h-4 w-4 inline" /> to change any of the prompts below. After editing, click "<Save className="h-4 w-4 mb-1 inline" /> Save Changes" to update your {answers?.assetType === "promptStack" ? "prompt stack" : "flow"}. Click "<Play className="h-4 w-4 mb-1 inline" /> Run This {answers?.assetType === "promptStack" ? "Stack" : "Flow"}" to execute it. Any changes you make will be saved automatically before executing.
            </div>
            {prompts &&
              prompts.map((prompt, idx) => (
                <motion.div
                  key={idx}
                  variants={itemVariants}
                  className="bg-white shadow-md rounded-lg p-4 lg:p-6 py-2 lg:py-4 flex flex-col md:flex-row items-start"
                >
                  <div className="flex-shrink-0 w-10 h-10 flex items-center justify-center magical-gradient shadow-md text-white rounded-full mx-auto mb-2 lg:mb-0 lg:mr-4">
                    <span className="text-sm lg:text-2xl font-bold">
                      {idx + 1}
                    </span>
                  </div>
                  <div className="w-full lg:flex-grow">
                  <div className="flex items-center justify-center">
                      {editing[idx] ? (
                        <textarea
                          value={editedPrompts[idx]}
                          onChange={(e) => {
                            const newEdits = [...editedPrompts];
                            newEdits[idx] = e.target.value;
                            setEditedPrompts(newEdits);
                          }}
                          className="w-full border border-indigo-600 bg-gray-100 rounded p-2 focus:outline-none focus:ring-2 focus:ring-indigo-600 leading-none lg:leading-tight"
                        />
                      ) : (
                        <p className="text-gray-900 text-base leading-none lg:leading-tight">
                          {editedPrompts[idx]}
                        </p>
                      )}
                      {/* Only show the edit icon if this is a prompt stack */}
                      {answers?.assetType === "promptStack" && (
                        <button
                          onClick={() =>
                            setEditing({ ...editing, [idx]: !editing[idx] })
                          }
                          className="ml-2"
                          title="Edit prompt"
                        >
                          <Edit3 className="h-5 w-5 text-gray-500" />
                        </button>
                      )}
                    </div>
                    <div className="mt-2 flex items-center gap-2 justify-center">
                      <img src={prompt.assistantId?.avatarUrl} alt="Assistant" className="w-8 h-8 rounded-full" />
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-black text-gray-100 shadow-md">
                        Assistant: {prompt.assistantId?.name}
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))}
            {steps &&
              steps.map((step, idx) => (
                <motion.div
                  key={idx}
                  variants={itemVariants}
                  className="bg-white shadow-md rounded-lg p-4 lg:p-6 py-2 lg:py-4 flex flex-col md:flex-row items-start"
                >
                  <div className="flex-shrink-0 w-8 h-8 lg:w-10 h-10 flex items-center justify-center magical-gradient shadow-md text-white rounded-full mx-auto mb-2 lg:mb-0 lg:mr-4">
                    <span className="text-sm lg:text-2xl font-bold">
                      {idx + 1}
                    </span>
                  </div>
                  <div className="w-full lg:flex-grow">

                    <div className="flex items-center justify-center">
                      {editing[idx] ? (
                        <textarea
                          value={editedSteps[idx]}
                          onChange={(e) => {
                            const newEdits = [...editedSteps];
                            newEdits[idx] = e.target.value;
                            setEditedSteps(newEdits);
                          }}
                          className="w-full border border-indigo-600 bg-gray-100 rounded p-2 focus:outline-none focus:ring-2 focus:ring-indigo-600 leading-none lg:leading-tight"
                        />
                      ) : (
                        <p className="text-gray-900 text-base leading-none lg:leading-tight">
                          {editedSteps[idx]}
                        </p>
                      )}
                      {/* Only show the edit icon if this is a prompt stack */}
                      {answers?.assetType === "flow" && (
                        <button
                          onClick={() =>
                            setEditing({ ...editing, [idx]: !editing[idx] })
                          }
                          className="ml-2"
                          title="Edit prompt"
                        >
                          <Edit3 className="h-5 w-5 text-gray-500" />
                        </button>
                      )}
                    </div>

                    {step.assistantId && (<div className="mt-2 flex items-center gap-2 justify-center">
                      <img src={step.assistantId?.avatarUrl} alt="Assistant" className="w-8 h-8 rounded-full" />
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-black text-gray-100 shadow-md">
                        Assistant: {step.assistantId?.name}
                      </span>
                    </div>)}
                    {!step.assistantId && step.type === 'generateAIImage' && (<div className="mt-2 flex items-center gap-2 justify-center">
                      <Image className="w-7 h-7" />
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-black text-gray-100 shadow-md">
                        Assistant: AI Image Generator
                      </span>
                    </div>)}
                    {!step.assistantId && step.type === 'addContext' && (<div className="mt-2 flex items-center gap-2 justify-center">
                      <Highlighter className="w-6 h-6" />
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-white border border-indigo-600 text-indigo-600 shadow-md">
                        Add Your Own Details
                      </span>
                    </div>)}
                  </div>
                </motion.div>
              ))}
          </div>
        </motion.div>
      </ScrollArea>

      {/* Footer */}
      <footer className="flex-shrink-0 p-4 flex flex-col lg:flex-row justify-center space-y-1 lg:space-y-0 lg:space-x-4 bg-gray-100">
        {unsavedChangesStack && (
          <motion.button
            onClick={handleSavePromptStack}
            className="px-5 py-2 bg-green-600 text-white font-medium rounded hover:bg-green-700 transition-colors duration-200 flex items-center justify-center gap-2"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Save className="h-6 w-6" />
            Save Changes
          </motion.button>
        )}
        {unsavedChangesFlow && (
          <motion.button
            onClick={handleSaveFlow}
            className="px-5 py-2 bg-green-600 text-white font-medium rounded hover:bg-green-700 transition-colors duration-200 flex items-center justify-center gap-2"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Save className="h-6 w-6" />
            Save Changes
          </motion.button>
        )}
        <motion.button
          onClick={restartQuestions}
          className="px-5 py-2 bg-indigo-600 text-white font-medium rounded hover:bg-indigo-700 transition-colors duration-200 flex items-center justify-center gap-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Waypoints className="h-6 w-6" />
          Create Another Tool
        </motion.button>
        <motion.button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            answers?.assetType === "promptStack" ? handleExecuteStack(e) : handleExecuteFlow(e);
          }}
          disabled={stackResponding}
          className="px-5 py-2 bg-indigo-600 text-white font-medium rounded hover:bg-indigo-700 transition-colors duration-200 flex items-center justify-center gap-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Play className="h-6 w-6" />
          Run This {answers?.assetType === "promptStack"
                    ? "Stack"
                    : "Flow"}
        </motion.button>
        <motion.button
          onClick={onClose}
          className="px-5 py-2 bg-gray-200 text-gray-700 font-medium rounded hover:bg-gray-300 transition-colors duration-200 flex items-center justify-center gap-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <X className="h-6 w-6" />
          Close
        </motion.button>
      </footer>
    </motion.div>
  );
};

export default FinalScreenerResponseScreen;
