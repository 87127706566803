// src/pages/Chatbots.js
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMarketplaceChatbots,
  fetchUserAddedChatbots,
  selectMarketplaceChatbots,      // Now expects an object { data, total }
  selectMarketplaceLoading,
  selectUserChatbots,
  selectUserChatbotsLoading,
  selectMarketplaceFilters,
} from '../slices/chatbotsSlice';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { ScrollArea } from '../components/ui/scroll-area';
import { ArrowRight, BotMessageSquare, Grid, PackageSearch, User } from 'lucide-react';
import MarketplaceView from './ChatbotComponents/MarketplaceView';
import UserChatbotsView from './ChatbotComponents/UserChatbotsView';
import ChatbotDetails from './ChatbotComponents/ChatbotDetails';
import MarketplaceFilters from './ChatbotComponents/MarketplaceFilters';
import { Pagination } from '../components/ui/pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import AssistantCategories from './ChatbotComponents/AssistantCategories';

const Chatbots = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.activeTab || 'marketplace');
  const [selectedChatbotId, setSelectedChatbotId] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 21; // adjust as needed

  const marketplaceFilters = useSelector(selectMarketplaceFilters);

  const marketplaceChatbotsData = useSelector(selectMarketplaceChatbots);
  const marketplaceChatbots = marketplaceChatbotsData.data || [];
  const totalChatbots = marketplaceChatbotsData.total || 0;
  
  const userChatbots = useSelector(selectUserChatbots);
  const marketplaceLoading = useSelector(selectMarketplaceLoading);
  const userChatbotsLoading = useSelector(selectUserChatbotsLoading);
  const ownedChatbotIds = useMemo(() =>
    new Set(userChatbots.map(bot => bot._id)),
    [userChatbots]
  );

  // Handle window resize events
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Debounce searchQuery changes (500ms delay)
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [marketplaceFilters]);

  // Fetch data whenever debounced search query or currentPage changes
  useEffect(() => {
    dispatch(
      fetchMarketplaceChatbots({
        search: debouncedSearchQuery,
        filters: marketplaceFilters,
        page: currentPage,
        limit: pageSize
      })
    );
    dispatch(fetchUserAddedChatbots());
  }, [dispatch, debouncedSearchQuery, currentPage, pageSize, marketplaceFilters]);

  const handleTabChange = (value) => {
    setActiveTab(value);
    setSelectedChatbotId(null);
  };

  const detailsRef = useRef(null);

  const handleChatbotSelect = (chatbotId) => {
    setSelectedChatbotId(chatbotId);
    if (!isMobileView && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Update search state and reset page to 1 when search changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  // Handler for pagination changes (assumes your Pagination component calls paginate)
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="h-[calc(100vh-4rem)] lg:h-screen container mx-auto p-2 lg:p-4 -mt-4 lg:-mt-0">
      <Tabs
        defaultValue="marketplace"
        value={activeTab}
        onValueChange={handleTabChange}
        className="w-full"
      >
        <div className="flex flex-col lg:flex-row gap-4 justify-between items-center mb-0 lg:mb-4">
          <TabsList className="magical-gradient text-white">
            <TabsTrigger value="marketplace" className="flex items-center gap-2">
              <PackageSearch className="h-4 w-4" />
              Marketplace
            </TabsTrigger>
            <TabsTrigger value="my-chatbots" className="flex items-center gap-2">
              <User className="h-4 w-4" />
              My Added Assistants
            </TabsTrigger>
          </TabsList>
          <Button type="button" className="bg-white text-black flex items-center gap-2 py-10 shadow-md w-full lg:w-fit hover:text-white" onClick={() => navigate('/custom-assistants')}>
            <BotMessageSquare className="h-8 w-8" />
            <div className='flex flex-col px-2 leading-none'>
              <span className='text-lg'>Custom Assistants</span>
              <span className='text-xs'>Create your own AI assistant</span>
              <span className='text-xs'>Or manage ones you already have</span>
            </div>
            <ArrowRight className="h-4 w-4" />
          </Button>
        </div>

        <div className="mb-2 py-2 bg-muted/50 rounded-lg">
          <h2 className="text-lg font-semibold mb-1">Welcome to the AI Assistant Marketplace</h2>
          <p className="text-sm text-muted-foreground lg:max-w-4xl leading-tight">
            Browse and add AI assistants, tools, teams, writing styles, and more to enhance your workspace.
            Discover free and premium assistants specialized in various tasks, or manage your existing collection in the My Assistants tab.
            Once you add a new AI assistant, it will be available in your chat space, as well as within your flows and prompt stacks.
          </p>
          <div className='hidden lg:block'>
          <AssistantCategories />
          </div>
          {/* Search input
          <input 
            type="text" 
            placeholder="Search chatbots..." 
            value={searchQuery} 
            onChange={handleSearchChange} 
            className="mt-2 p-2 border rounded w-full" 
          /> */}
        </div>

        <div className="flex flex-col lg:flex-row gap-4 h-[calc(100vh-8rem)] lg:h-full">
          <div className={`w-full ${selectedChatbotId && !isMobileView ? 'lg:w-2/3' : 'lg:w-full'}`} ref={detailsRef}>
            <TabsContent value="marketplace" className="m-0 pb-8">
              <div className="flex flex-col h-full">
                <MarketplaceFilters 
                  className="mb-4" 
                  chatbots={marketplaceChatbots}
                />
                <ScrollArea className="flex-grow mb-4">
                  <MarketplaceView
                    chatbots={marketplaceChatbots}
                    loading={marketplaceLoading}
                    onChatbotSelect={handleChatbotSelect}
                    selectedChatbotId={selectedChatbotId}
                    isMobileView={isMobileView}
                    ownedChatbotIds={ownedChatbotIds}
                  />
                </ScrollArea>
                {/* Pagination component */}
                <Pagination 
                  currentPage={currentPage} 
                  totalItems={totalChatbots} 
                  itemsPerPage={pageSize} 
                  paginate={handlePageChange} 
                />
              </div>
            </TabsContent>

            <TabsContent value="my-chatbots" className="m-0">
              <ScrollArea className="h-full">
                <UserChatbotsView
                  chatbots={userChatbots}
                  loading={userChatbotsLoading}
                  onChatbotSelect={handleChatbotSelect}
                  selectedChatbotId={selectedChatbotId}
                  isMobileView={isMobileView}
                />
              </ScrollArea>
            </TabsContent>
          </div>

          {selectedChatbotId && !isMobileView && (
            <div className="hidden lg:block w-1/3 h-full">
              <ScrollArea className="h-full">
                <ChatbotDetails
                  chatbotId={selectedChatbotId}
                  onClose={() => setSelectedChatbotId(null)}
                />
              </ScrollArea>
            </div>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default Chatbots;