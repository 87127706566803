import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { openFlowStackModal } from "../slices/flowStackModalSlice";
import { Button } from '../components/ui/button';
import { ArrowRight, Plus, ListTodo, Play, Info, Sparkles } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Separator } from '../components/ui/separator';
import FlowInfoContent from './FlowFormComponents/FlowInfoContent';
import FlowCards from './FlowFormComponents/FlowCards';

const Flows = () => {
  return (
    <div className="container mx-auto py-6 space-y-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Flows</h1>
          <p className="text-muted-foreground mt-1">
            Create, manage, and monitor your automated workflows
          </p>
        </div>
        <Button asChild>
          <Link to="/create-flow" className="flex items-center gap-2">
            <Plus size={16} />
            Create New Flow
          </Link>
        </Button>
      </div>

      <Tabs defaultValue="overview" className="w-full">
        <TabsList className="magical-gradient text-white">
          <TabsTrigger value="overview">Overview</TabsTrigger>
          <TabsTrigger value="info">Learn</TabsTrigger>
          {/*<TabsTrigger value="recent">Recent</TabsTrigger>
          <TabsTrigger value="favorites">Favorites</TabsTrigger>*/}
        </TabsList>
        
        <TabsContent value="overview" className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div className="bg-muted/50 rounded-lg p-6">
            <div className="flex items-center gap-2 mb-4">
              <Info size={20} className="text-primary" />
              <h2 className="text-xl font-semibold">What are Flows?</h2>
            </div>
              <p className='mb-4'>
                Flows are smart sequences that help you automate tasks with ease. Think of them as a cool series of steps that work together, 
                making it super simple to create and share awesome content. 
                With Flows, you can save time, keep your brand on point, and generate high-quality stuff without the hassle!
              </p>
            <Button variant="outline" asChild>
              <Link to="#info" onClick={() => document.querySelector('[data-value="info"]').click()}>
                Learn More About Flows
                <ArrowRight size={16} className="ml-2" />
              </Link>
            </Button>
            </div>
            <NavCardSpecial 
              title="Magically Create a Flow with Flow Agent" 
              description="Magically build a perfect flow or prompt stack with just a few words and clicks."
              icon={<Sparkles size={24} />}
              path="/create-flow"
              ctaText="Create with Flow Agent"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            <NavCard 
              title="Create a Flow" 
              description="Design and implement new automated workflows. Get started with a blank canvas or a pre-made template."
              icon={<Plus size={24} />}
              path="/create-flow"
              ctaText="Start Creating"
            />
            <NavCard 
              title="Manage Flows" 
              description="View, edit, and organize your existing workflows. Clone, edit, execute, and delete flows with ease."
              icon={<ListTodo size={24} />}
              path="/manage-flows"
              ctaText="View Flows"
            />
            <NavCard 
              title="Executions" 
              description="Monitor and analyze the performance of your workflows. View the complete results of your flow executions."
              icon={<Play size={24} />}
              path="/execution"
              ctaText="View Executions"
            />
          </div>
          
        </TabsContent>
        
        <TabsContent value="info">
          <FlowInfoContent />
        </TabsContent>
        
        <TabsContent value="recent">
          <FlowCards type="recent" />
        </TabsContent>
        
        <TabsContent value="favorites">
          <FlowCards type="favorites" />
        </TabsContent>
      </Tabs>
    </div>
  );
};

// Navigation Card component
const NavCard = ({ title, description, icon, path, ctaText }) => {
  return (
    <Card className="flex flex-col h-full">
      <CardHeader>
        <Link to={path} className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center text-primary mb-2">
          {icon}
        </Link>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow">
        {/* Card content can be added here if needed */}
      </CardContent>
      <CardFooter>
        <Button asChild className="w-full">
          <Link to={path} className="flex items-center justify-center gap-2">
            {ctaText}
            <ArrowRight size={16} />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};

const NavCardSpecial = ({ title, description, icon, path, ctaText }) => {
    const dispatch = useDispatch();
    return (
      <Card className="flex flex-col h-full">
        <CardHeader>
          <div className="h-12 w-12 rounded-full magical-gradient flex items-center justify-center text-white mb-2">
            {icon}
          </div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent className="flex-grow">
          {/* Card content can be added here if needed */}
        </CardContent>
        <CardFooter>
          <Button onClick={() => dispatch(openFlowStackModal())} className="w-full magical-gradient text-white">
              {ctaText}
              <ArrowRight size={16} />
          </Button>
        </CardFooter>
      </Card>
    );
  };

export default Flows;