// src/pages/AdminDashboard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnalyticsDashboard from './AdminComponents/AnalyticsDashboard';
import ManagementDashboard from './AdminComponents/ManagementDashboard';
import UsersDashboard from './AdminComponents/UsersDashboard';

// Lucide icons
import { BarChart, Settings, User } from 'lucide-react';
import { Button } from '../components/ui/button';

const tabs = [
  {
    key: 'analytics',
    label: 'Analytics',
    icon: <BarChart className="w-5 h-5" />,
    component: <AnalyticsDashboard />
  },
  {
    key: 'management',
    label: 'Management',
    icon: <Settings className="w-5 h-5" />,
    component: <ManagementDashboard />
  },
  {
    key: 'users',
    label: 'Users',
    icon: <User className="w-5 h-5" />,
    component: <UsersDashboard />
  }
];

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('analytics');

  const renderActiveTab = () => {
    const tab = tabs.find((t) => t.key === activeTab);
    return tab ? tab.component : null;
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className='flex items-center gap-4'>
        <Button
          onClick={() => navigate('/dashnew')}
          className="flex items-center border-none shadow-md gap-2 bg-gray-900 hover:bg-gray-100 hover:text-gray-900 text-white"
        >
          Dashboard Agent
        </Button>
        <Button
          onClick={() => navigate('/flows')}
          className="flex items-center border-none shadow-md gap-2 bg-gray-900 hover:bg-gray-100 hover:text-gray-900 text-white"
        >
          Flows
        </Button>
      </div>
      {/* Tab Navigation */}
      <div className="mb-4 border-b border-gray-200">
        <nav className="-mb-px flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              className={`inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm focus:outline-none transition-colors duration-200
                ${activeTab === tab.key ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              title={tab.label}
            >
              {tab.icon}
              <span className="ml-2">{tab.label}</span>
            </button>
          ))}
        </nav>
      </div>

      {/* Tab Content */}
      <div>
        {renderActiveTab()}
      </div>
    </div>
  );
};

export default AdminDashboard;