// src/pages/ResetPasswordPage.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../slices/authSlice';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [localError, setLocalError] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);

  // Redirect after 5 seconds if reset was successful
  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [success, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLocalError('');
    if (password !== confirmPassword) {
      setLocalError('Passwords do not match');
      return;
    }
    try {
      await dispatch(resetPassword({ token, password })).unwrap();
      setSuccess(true);
    } catch (err) {
      // The error is handled via the redux slice state
    }
  };

  if (success) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
        <div className="max-w-md w-full bg-white p-8 shadow rounded text-center">
        <div className="flex items-center space-x-2 justify-center mb-4">
                  <img
                    src="/FlowOrchestraIcon5.png"
                    alt="Flow Orchestra Icon"
                    className="w-8 h-8 bg-white rounded-md"
                  />
                  <span className="text-xl font-semibold">Flow Orchestra</span>
                </div>
          <h2 className="text-2xl font-bold mb-6">Password Reset Successful</h2>
          <p className="mb-4">
            Your password has been updated. You will be redirected to the login page in 5 seconds.
          </p>
          <Button onClick={() => navigate('/login')} className="w-full">
            Go to Login
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white p-8 shadow rounded">
      <div className="flex items-center space-x-2 justify-center mb-4">
                  <img
                    src="/FlowOrchestraIcon5.png"
                    alt="Flow Orchestra Icon"
                    className="w-8 h-8 bg-white rounded-md"
                  />
                  <span className="text-xl font-semibold">Flow Orchestra</span>
                </div>
        <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>
        {(localError || error) && (
          <div className="text-red-500 mb-4">
            {localError || error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button type="submit" disabled={loading} className="w-full">
            {loading ? 'Resetting...' : 'Reset Password'}
          </Button>
        </form>
        <div className="mt-4 text-center">
          <Button
            variant="link"
            onClick={() => navigate('/login')}
            className="text-blue-500 hover:underline"
          >
            Back to Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
