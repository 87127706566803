import React, { useState } from "react";
import { Button } from "../../../components/ui/button";
import { Skeleton } from "../../../components/ui/skeleton";
import { 
  BotMessageSquare, 
  ArrowRight, 
  BrainCircuit, 
  MessageCirclePlus, 
  Info,
  ChevronDown,
  ChevronUp 
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const AssistantSuggestion = ({ fetchedSuggestions, onChatNow }) => {
  const navigate = useNavigate();
  const [isProTipsExpanded, setIsProTipsExpanded] = useState(false);
  const [expandedAssistants, setExpandedAssistants] = useState({});

  const toggleProTips = () => {
    setIsProTipsExpanded(!isProTipsExpanded);
  };

  const toggleAssistantExpand = (assistantId) => {
    setExpandedAssistants(prevState => ({
      ...prevState,
      [assistantId]: !prevState[assistantId]
    }));
  };

  return (
    <div className="bg-white border border-gray-200 rounded shadow-md p-2 lg:p-6">
      <div className="text-xl font-semibold mb-4 flex items-center text-gray-900" title="Need quick answers? Chat with our AI assistants for personalized guidance! They're equipped to help you tackle tasks and enhance your production in a familiar conversational way, making your experience smoother.">
        <BotMessageSquare className="h-7 w-7 mr-2 magical-gradient text-white p-1 rounded-full" />
        <h3 className="text-base lg:text-xl font-semibold">
          Suggestion: Chat with an Assistant
        </h3>
      </div>
      <div className="text-muted-foreground text-sm mb-2" title="When you click to chat with an assistant, you will be redirected to the chat page to start a new chat with this assistant.">
        <Info className="inline h-4 w-4 mr-1 text-black" />Click on an assistant to go to the chat page and chat with your chosen assistant.
      </div>
      {fetchedSuggestions?.data?.suggestions?.assistants?.assistants?.length > 0 ? (
        <div className="space-y-4">
          {fetchedSuggestions.data.suggestions.assistants.assistants.map((assistant) => (
            <div 
              key={assistant.assistantId}
              className="border border-gray-200 rounded-md shadow-md overflow-hidden"
            >
              {/* Collapsed Assistant Header */}
              <div 
                className="flex items-center justify-between p-2 bg-gray-100 cursor-pointer"
                onClick={() => toggleAssistantExpand(assistant.assistantId)}
              >
                <div className="flex items-center gap-2">
                  <img 
                    src={assistant.avatarUrl} 
                    alt={assistant.name} 
                    className="w-8 h-8 rounded-md flex-shrink-0 shadow-sm" 
                  />
                  <h4 className="font-semibold text-gray-900">{assistant.name}</h4>
                </div>
                {expandedAssistants[assistant.assistantId] ? (
                  <ChevronUp className="h-4 w-4 text-gray-600" />
                ) : (
                  <ChevronDown className="h-4 w-4 text-gray-600" />
                )}
              </div>
              
              {/* Expanded Assistant Content */}
              {expandedAssistants[assistant.assistantId] && (
                <Button
                  onClick={(e) => onChatNow(e, assistant.assistantId)}
                  type="button"
                  variant="outline"
                  className="flex items-center border-none gap-2 group bg-gray-100 hover:bg-gray-900 hover:text-gray-100 text-black p-2 lg:p-3 h-fit w-full justify-between cursor-default"
                >
                  <img src={assistant.avatarUrl} alt={assistant.name} className="w-10 h-10 lg:w-20 lg:h-20 rounded-md flex-shrink-0 shadow-lg" />
                  <div className="flex flex-col items-start flex-1 min-w-0">
                    <h4 className="font-semibold text-lg truncate w-full">{assistant.name}</h4>
                    <p className="text-sm text-gray-700 group-hover:text-white w-full whitespace-normal break-words">{assistant.description}</p>
                    <div className="mt-1 mx-auto flex flex-row items-center justify-center bg-white text-gray-900 hover:text-gray-100 gap-2 hover:bg-gray-600 py-1 px-3 rounded cursor-pointer">
                      <MessageCirclePlus className="h-6 w-6 p-1 bg-black text-white rounded-full" />
                      Chat with {assistant.name || "this Assistant"}
                    </div>
                  </div>
                </Button>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col space-y-3">
          <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
          <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
          <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
        </div>
      )}
      <div className="mt-4">
        <Button
          variant="outline"
          className="flex items-center border-none shadow-md gap-2 bg-gray-900 hover:bg-gray-100 hover:text-gray-900 text-white"
          onClick={() => navigate("/chat")}
        >
          Go to the Chat Page
          <ArrowRight className="h-4 w-4 magical-gradient text-white rounded-full" />
        </Button>
      </div>
      <div className="mt-4 text-sm text-black mt-6 lg:mt-8">
        <Button
          variant="ghost"
          className="w-full p-0 flex items-center justify-between hover:bg-transparent"
          onClick={toggleProTips}
        >
          <h4 className="font-semibold text-md flex items-center gap-2">
            <BrainCircuit className="h-4 w-4" />
            Pro Follow Up Tips:
          </h4>
          {isProTipsExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
        <div 
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isProTipsExpanded ? "max-h-40 opacity-100 mt-2" : "max-h-0 opacity-0"
          }`}
        >
          <div className="space-y-1">
            <div>
              <span className="font-semibold">Tip:</span> Prepare specific questions or tasks you want help with to maximize your chat experience.
            </div>
            <div>
              <span className="font-semibold">Tip:</span> Save valuable insights from your chat for future reference or to inform your content strategy.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantSuggestion;