export const flowStackData = [
  {
      id: 1,
      questionText: "Describe the content workflow you would like to magically create",
      questionDescription: "This is where you should describe the goals or deliverables of the workflow you want to create. Example: 'I want to create a workflow that generates a list of 10 blog post ideas based on a given topic, and then write a 500-word blog post on the most promising idea.'",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-LaptopDoodle.png",
      inputType: "textarea",
      fieldName: "flowDescription",
      dropdownOptions: null,
      buttons: null,
  },
  {
      id: 2,
      questionText: "Would you like to create a Flow or a Prompt Stack?",
      questionDescription: "Flows can be more complex and can be scheduled. Prompt Stacks are simpler and available while chatting.",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-ThinkingDoodle.png",
      inputType: "none",
      fieldName: "assetType",
      dropdownOptions: null,
      buttons: [
          { label: "I want to create a Flow", value: "flow" },
          { label: "I want to create a Prompt Stack", value: "promptStack" },
      ],
  },
  {
    id: 3,
    questionText: "How detailed should we make this for you?",
    imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-ComplexityDoodle.png",
    inputType: "none",
    fieldName: "complexity",
    dropdownOptions: null,
    buttons: [
        { label: "Basic (5 steps or fewer)", value: "basic" },
        { label: "Advanced (6 steps or more)", value: "advanced" },
    ],
},
  {
      id: 4,
      questionText: "Should we use all of your available assistants or just the standard assistants?",
      questionDescription: "If you plan to share the resulting flow or prompt stack with teammates, using only standard assistants can come in handy.",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-AssistantDoodle.png",
      inputType: "none",
      fieldName: "standardOnly",
      dropdownOptions: null,
      buttons: [
          { label: "Use all of my available assistants", value: false },
          { label: "Use standard assistants only, please", value: true },
      ],
  },
  {
      id: 5,
      questionText: "Ready for Flow Orchestra to create your masterpiece?",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-RocketDoodle.png",
      inputType: "none",
      fieldName: "showResults",
      dropdownOptions: null,
      buttons: [
          { label: "Of course I am", value: true },
          { label: "I changed my mind", value: false },
      ],
  },
];