import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { ScrollArea } from "../../components/ui/scroll-area";
import RealTimeFlowExecution from '../../components/RealTimeFlowExecution';
import { CheckCircle, XCircle, Clock, AlertTriangle, Search, Copy, ChevronDown, ChevronUp, Ban } from 'lucide-react';
import { format, formatDistanceToNow } from 'date-fns';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import AIImageDisplay from '../../pages/FlowFormComponents/AIImageDisplay';
import { useToast } from "../../components/ui/use-toast";
import TakeToChatButton from '../../components/TakeToChatButton';
import { useDispatch } from 'react-redux';
import { cancelExecution } from '../../slices/executionsSlice';

const ExecutionDetails = ({ execution, isPolling, setIsPolling  }) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const containerRef = useRef(null);

    const [preservedSteps, setPreservedSteps] = useState([]);
    const [expandedSteps, setExpandedSteps] = useState({});
    const [isCanceled, setIsCanceled] = useState(false);
  
    useEffect(() => {
      if (execution && execution._id) {
        // Ensure preservedSteps is updated based on the actual execution, not just the flow
        setPreservedSteps(execution.flow.steps || []);
        console.log('ExecutionDetails execution:', execution);
      }
    }, [execution]);
  
    if (!execution) {
      return <div>No execution data available.</div>;
    }

    const handleCancel = () => {
      if (execution && execution._id) {
      dispatch(cancelExecution(execution._id));
      dispatch(cancelExecution(execution._id))
          .unwrap()
          .then(() => {
            // On successful cancellation
            toast({
              title: "Execution Canceled",
              description: "The execution has been successfully canceled.",
              duration: 3000,
            });
            setIsCanceled(true);
          })
          .catch(() => {
            // Handle error if needed
          });
      }
      };
  
    const getStatusIcon = (status) => {
      switch (status) {
        case 'completed':
          return <CheckCircle className="text-green-500" />;
        case 'failed':
          return <XCircle className="text-red-500" />;
        case 'running':
          return <Clock className="text-blue-500" />;
        case 'skipped':
          return <Ban className="text-gray-500" />;
        default:
          return <AlertTriangle className="text-yellow-500" />;
      }
    };
  
    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? 'Invalid Date' : format(date, 'PPpp');
    };
  
    const formatDuration = (duration) => {
      if (!duration) return 'N/A';
      const seconds = Math.floor(duration / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
    };

    const copyToClipboard = (text) => {
      const stringifiedText = stringifyContent(text);
      navigator.clipboard.writeText(stringifiedText).then(() => {
        toast({
          title: "Copied to Clipboard",
          description: "The output has been copied to your clipboard.",
          duration: 2000,
        });
      }).catch((err) => {
        toast({
          title: "Copy Failed",
          description: "Failed to copy text: " + err,
          variant: "destructive",
          duration: 3000,
        });
      });
    };

    const stringifyContent = (content) => {
      if (typeof content === 'string') {
        return content;
      }
      if (typeof content === 'object') {
        return JSON.stringify(content, null, 2);
      }
      return String(content);
    };
  
    const renderStepOutput = (step, stepType) => {
      if (stepType === 'generateAIImage' && typeof step.output === 'string' && step.output.startsWith('http')) {
        return <AIImageDisplay imageUrl={step.output} />;
      }

      
  
      return (
        <div className="mt-2 relative w-full overflow-hidden">
          <div className="bg-gray-100 p-4 rounded mt-1 overflow-x-auto">
            <ReactMarkdown
              className='markdown-content'
              remarkPlugins={[remarkGfm]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <SyntaxHighlighter
                      style={vscDarkPlus}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    >
                      {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {typeof step.output === 'string' ? step.output : JSON.stringify(step.output, null, 2)}
            </ReactMarkdown>
          </div>
          <Button
            variant="outline"
            size="sm"
            className="absolute bottom-2 right-2 z-10"
            onClick={() => copyToClipboard(step.output)}
          >
            <Copy className="h-4 w-4" />
            
          </Button>
        </div>
      );
    };

    const toggleStep = (stepIndex) => {
      setExpandedSteps(prev => ({
        ...prev,
        [stepIndex]: !prev[stepIndex]
      }));
    };

    const scrollToTop = () => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    };
  
    return (
      <div className="w-full px-4 py-0" ref={containerRef}>
        <div id="execution-top"></div>
        <h1 className="text-2xl font-bold mb-4">Execution Details</h1>
  <Card className="mb-4">
    <CardHeader className="py-4">
      <div className="flex flex-col space-y-4">
        <h2 className="text-md font-semibold break-words lg:text-xl">{execution.flow?.name || 'N/A'}</h2>
        <div className="flex items-center gap-2">
          {getStatusIcon(execution.status)}
          <span className="capitalize">{execution.status}</span>
        </div>
      </div>
    </CardHeader>
    <CardContent>
      <div className="space-y-3">
        {/*<div>
          <p className="text-sm text-muted-foreground">Execution ID:</p>
          <p className="text-sm font-mono break-all">{execution._id}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Flow ID:</p>
          <p className="text-sm font-mono break-all">{execution.flow?._id || 'N/A'}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Start Time:</p>
          <p className="text-sm">{formatDate(execution.startTime)}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">End Time:</p>
          <p className="text-sm">{formatDate(execution.endTime)}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Duration:</p>
          <p className="text-sm">{formatDuration(execution.duration)}</p>
        </div>*/}
        {!isCanceled && execution.status === 'running' && (
          <Button variant="destructive" size="sm" onClick={handleCancel}>
            Cancel Execution
          </Button>
        )}
      </div>
    </CardContent>
        </Card>
        {execution && (
  <RealTimeFlowExecution 
    execution={execution}
    isPolling={isPolling}
    setIsPolling={setIsPolling}
  />
)}
        <h2 className="text-2xl font-semibold mb-4">Step Results {execution.status === 'cancelled' && (<span>(Cancelled)</span>)}</h2>
        
        {Array.isArray(execution.stepResults) && execution.stepResults.length > 0 ? (
          execution.stepResults.map((step, index) => {
            const preservedStep = preservedSteps[index] || {};
            return (
              <Card key={step._id || index} className={`mb-2 ${step.status === 'skipped' ? 'bg-gray-300' : ''}`}>
  <CardHeader className="cursor-pointer py-3 px-4" onClick={() => toggleStep(index)}>
  <div className="flex justify-between items-center">
    <div className="flex items-center gap-2 min-w-0">
      {getStatusIcon(step.status)}
      <h3 className="text-sm font-medium truncate">
        Step {index + 1}: {preservedStep.name || preservedStep.type || 'Unknown Step'}
      </h3>
      <span className="text-sm text-muted-foreground capitalize whitespace-nowrap">
        {step.status}
      </span>
    </div>
    {expandedSteps[index] ? (
      <ChevronUp className="h-4 w-4 text-muted-foreground flex-shrink-0 ml-2" />
    ) : (
      <ChevronDown className="h-4 w-4 text-muted-foreground flex-shrink-0 ml-2" />
    )}
  </div>
</CardHeader>
  {expandedSteps[index] && (
    <CardContent className="pt-0">
      <p className='mb-2'><strong>Instructions:</strong> {preservedStep.prompt || preservedStep.description || 'No instructions available'}</p>
      <p><strong>Duration:</strong> {formatDuration(step.duration)}</p>
      {step.output && renderStepOutput(step, preservedStep.type)}
      {step.error && (
        <div className="mt-2 p-4 bg-red-100 text-red-700 rounded">
          <strong>Error:</strong> {step.error}
        </div>
      )}
    </CardContent>
  )}
</Card>
            );
          })
        ) : (
          <p>No step results available.</p>
        )}
  
  
  
        {isPolling ? (
          <div className="mt-4">
            <p className="text-blue-500">Polling for updates...</p>
          </div>
        ) : (<>
          <div className='hidden lg:block absolute right-4 bottom-4'>
            <TakeToChatButton executionId={execution._id} />
          </div>
          {/* Back to Top Button */}
      <div className="mt-8 mb-6 lg:text-left text-center">
        <Button 
          variant="outline"
          onClick={scrollToTop}
          className="gap-2 border-none bg-gray-100 hover:bg-gray-200 shadow-md"
        >
          <ChevronUp className="h-4 w-4" />
          Back to Top
        </Button>
      </div>
        </>)}
      </div>
    );
  };

export default ExecutionDetails;