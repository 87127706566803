// components/dashboard/EditBlogPostModal.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import { 
  Form, 
  FormControl, 
  FormDescription, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '../../components/ui/select';
import { Plus, X, Loader2, LayoutGrid, NotebookPen, AudioLines, PencilRuler, BookOpenText, Pencil } from 'lucide-react';
import { Badge } from '../../components/ui/badge';
import { useForm } from 'react-hook-form';
import { updateContentRequest } from '../../slices/contentSlice';

const toneOptions = [
  { value: 'professional', label: 'Professional' },
  { value: 'conversational', label: 'Conversational' },
  { value: 'friendly', label: 'Friendly' },
  { value: 'formal', label: 'Formal' },
  { value: 'informative', label: 'Informative' },
  { value: 'authoritative', label: 'Authoritative' },
];

const lengthOptions = [
  { value: 'short', label: 'Short (300-500 words)' },
  { value: 'medium', label: 'Medium (600-1000 words)' },
  { value: 'long', label: 'Long (1000-1500 words)' },
  { value: 'comprehensive', label: 'Comprehensive (1500+ words)' },
];

const contentTypeOptions = [
  { value: 'article', label: 'Article' },
  { value: 'tutorial', label: 'Tutorial' },
  { value: 'howTo', label: 'How-to Guide' },
  { value: 'listicle', label: 'Listicle' },
  { value: 'review', label: 'Review' },
  { value: 'story', label: 'Story' },
  { value: 'interview', label: 'Interview' },
  { value: 'caseStudy', label: 'Case Study' },
  { value: 'newsUpdate', label: 'News Update' },
];

const EditBlogPostModal = ({ isOpen, onClose, blogPost }) => {
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  const [currentTopic, setCurrentTopic] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const form = useForm({
    defaultValues: {
      tone: blogPost?.tone || '',
      length: blogPost?.length || '',
      blogContentType: blogPost?.blogContentType || '',
      notes: blogPost?.notes || ''
    }
  });

  useEffect(() => {
    if (blogPost?.topics && blogPost.topics.length > 0) {
      setTopics(blogPost.topics.map(t => t.topic));
    }
  }, [blogPost]);

  const handleAddTopic = () => {
    if (currentTopic.trim() !== '' && !topics.includes(currentTopic.trim())) {
      setTopics([...topics, currentTopic.trim()]);
      setCurrentTopic('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(topics.filter(topic => topic !== topicToRemove));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTopic();
    }
  };

  const onSubmit = async (data) => {
    if (topics.length === 0) {
      form.setError("topics", {
        type: "manual",
        message: "At least one topic is required",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await dispatch(updateContentRequest({
        id: blogPost._id,
        contentType: 'BlogPost',
        data: {
          ...data,
          topics
        }
      })).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to update blog post:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] z-[60] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
        <DialogTitle className='flex flex-row gap-2 items-center'><Pencil className='h-5 w-5' />Edit Blog Post</DialogTitle>
          <DialogDescription>
            Update the details for your blog post.
          </DialogDescription>
        </DialogHeader>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="space-y-2">
              <FormLabel className='text-lg flex flex-row items-center gap-1'><LayoutGrid className='h-4 w-4 text-indigo-500' />Topics</FormLabel>
              <div className="flex items-center gap-2">
                <Input
                  value={currentTopic}
                  onChange={(e) => setCurrentTopic(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter a topic and press Enter"
                  className="flex-1"
                />
                <Button 
                  type="button" 
                  onClick={handleAddTopic} 
                  variant="outline"
                  disabled={!currentTopic.trim()}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
              <FormDescription>
                Add one or more topics for your blog post. Press Enter or click the + button to add.
              </FormDescription>
              {form.formState.errors.topics && (
                <p className="text-sm font-medium text-destructive">
                  {form.formState.errors.topics.message}
                </p>
              )}
              <div className="flex flex-wrap gap-1 mt-2">
                {topics.map((topic, index) => (
                  <Badge key={index} variant="secondary" className="gap-1">
                    {topic}
                    <button
                      type="button"
                      onClick={() => handleRemoveTopic(topic)}
                      className="ml-1 rounded-full h-4 w-4 inline-flex items-center justify-center"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </Badge>
                ))}
              </div>
            </div>

            <FormField
              control={form.control}
              name="tone"
              rules={{ required: 'Tone is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><AudioLines className='h-4 w-4 text-indigo-500' />Tone</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className='shadow-md rounded bg-gray-100'>
                        <SelectValue placeholder="Select a tone" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className='z-[60]'>
                      {toneOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Choose the tone for your blog post.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="length"
              rules={{ required: 'Length is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><PencilRuler className='h-4 w-4 text-indigo-500' />Length</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className='shadow-md rounded bg-gray-100'>
                        <SelectValue placeholder="Select a length" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className='z-[60]'>
                      {lengthOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Choose how long you want your blog post to be.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="blogContentType"
              rules={{ required: 'Content type is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><BookOpenText className='h-4 w-4 text-indigo-500' />Content Type</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className='shadow-md rounded bg-gray-100'>
                        <SelectValue placeholder="Select a content type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className='z-[60]'>
                      {contentTypeOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Choose what type of content you want to create.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><NotebookPen className='h-4 w-4 text-indigo-500' />Additional Notes (Optional)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Add any specific instructions or notes here..."
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Provide any additional instructions or requirements for your blog post.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="button" variant="outline" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Save Changes
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditBlogPostModal;