import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { X, HelpCircle, Check, Copy, Bell, FileText, Eye, Trash2, LayoutDashboard } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Badge } from "../../components/ui/badge";

const DocumentationPanel = ({ onClose }) => {
    const navigate = useNavigate();
  return (
    <div className="bg-white rounded-lg h-full overflow-auto">
      <div className="flex justify-between items-center p-4 sticky top-0 bg-white dark:bg-gray-800 z-10">
        <div className="flex items-center">
          <HelpCircle className="h-5 w-5 mr-2 text-primary" />
          <h2 className="text-xl font-bold">Content Dashboard Documentation</h2>
        </div>
        <Button variant="ghost" onClick={onClose} className="h-8 w-8 p-0" aria-label="Close">
          <X className="h-5 w-5" />
        </Button>
      </div>

      <div className="p-6">
        <Tabs defaultValue="overview">
          <TabsList className="mb-4 magical-gradient text-white">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="features">Features</TabsTrigger>
            <TabsTrigger value="tips">Tips & Tricks</TabsTrigger>
            <TabsTrigger value="faq">FAQ</TabsTrigger>
          </TabsList>

          <TabsContent value="overview">
            <div className="prose dark:prose-invert max-w-none">
              <h3 className="text-xl font-semibold mb-4">Welcome to Your Content Dashboard</h3>
              
              <p className="mb-4">
                The Content Dashboard provides a centralized place to view, manage, and organize all the content we create for you. This includes blog posts, product descriptions, social media content, courses, images, and more.
              </p>

              <h4 className="text-lg font-semibold mt-6 mb-2">Dashboard Sections</h4>
              
              <ul className="space-y-3 mb-6">
                <li className="flex items-center">
                  <div className="bg-gray-100 p-2 rounded mr-3 mt-1">
                    <LayoutDashboard className="h-4 w-4 text-black" />
                  </div>
                  <div>
                    <strong>Overview</strong> - Get a bird's eye view of all your assets with counts and quick-access cards for each category.
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="bg-gray-100 p-2 rounded mr-3 mt-1">
                    <Bell className="h-4 w-4 text-black" />
                  </div>
                  <div>
                    <strong>Notifications</strong> - View system messages, alerts, and updates from our team related to your content.
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="bg-gray-100 p-2 rounded mr-3 mt-1">
                    <FileText className="h-4 w-4 text-black" />
                  </div>
                  <div>
                    <strong>Asset Categories</strong> - Access specific types of content through dedicated sections like Blog Posts, Products, Social Posts, etc.
                  </div>
                </li>
              </ul>

              <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg mb-6">
                <h4 className="font-medium mb-2">Asset Types Available</h4>
                <div className="flex flex-wrap gap-2">
                  <Badge variant="secondary">Blog Posts</Badge>
                  <Badge variant="secondary">Products</Badge>
                  <Badge variant="secondary">Social Posts</Badge>
                  <Badge variant="secondary">General Content</Badge>
                  <Badge variant="secondary">Images</Badge>
                </div>
              </div>
            </div>
          </TabsContent>

          <TabsContent value="features">
            <div className="prose dark:prose-invert max-w-none">
              <h3 className="text-xl font-semibold mb-4">Key Features</h3>

              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger className="text-lg font-medium">Asset Management</AccordionTrigger>
                  <AccordionContent className="space-y-3">
                    <p>The dashboard provides comprehensive tools to manage all types of content assets:</p>
                    
                    <div className="flex items-start mb-2">
                      <Eye className="h-4 w-4 mr-2 mt-1 text-gray-500" />
                      <div>
                        <strong>View Assets</strong> - See both a list of your assets and detailed views with all metadata.
                      </div>
                    </div>
                    
                    <div className="flex items-start mb-2">
                      <Trash2 className="h-4 w-4 mr-2 mt-1 text-red-500" />
                      <div>
                        <strong>Delete Assets</strong> - Remove content you no longer need.
                      </div>
                    </div>
                    
                    <div className="flex items-start mb-2">
                      <Copy className="h-4 w-4 mr-2 mt-1 text-blue-500" />
                      <div>
                        <strong>Copy Content</strong> - Copy asset content to clipboard for easy use elsewhere.
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Check className="h-4 w-4 mr-2 mt-1 text-green-500" />
                      <div>
                        <strong>Status Management</strong> - Toggle assets between "Draft" and "Published" states.
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-2">
                  <AccordionTrigger className="text-lg font-medium">View Options</AccordionTrigger>
                  <AccordionContent className="space-y-3">
                    <p>
                      The dashboard offers flexible viewing options to fit your workflow:
                    </p>
                    
                    <div className="flex items-start mb-2">
                      <div className="bg-gray-200 dark:bg-gray-700 rounded p-1 mr-2">
                        <div className="grid grid-cols-2 gap-0.5">
                          <div className="h-2 w-2 bg-gray-500 rounded-sm"></div>
                          <div className="h-2 w-2 bg-gray-500 rounded-sm"></div>
                          <div className="h-2 w-2 bg-gray-500 rounded-sm"></div>
                          <div className="h-2 w-2 bg-gray-500 rounded-sm"></div>
                        </div>
                      </div>
                      <div>
                        <strong>Card View</strong> - Visual card-based layout perfect for scanning content.
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="bg-gray-200 dark:bg-gray-700 rounded p-1 mr-2">
                        <div className="flex flex-col gap-0.5">
                          <div className="h-1 w-5 bg-gray-500 rounded-sm"></div>
                          <div className="h-1 w-5 bg-gray-500 rounded-sm"></div>
                          <div className="h-1 w-5 bg-gray-500 rounded-sm"></div>
                        </div>
                      </div>
                      <div>
                        <strong>Table View</strong> - Compact, data-dense layout for efficient browsing and management.
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-3">
                  <AccordionTrigger className="text-lg font-medium">Notification Management</AccordionTrigger>
                  <AccordionContent className="space-y-3">
                    <p>
                      Effectively manage all notifications from our platform:
                    </p>
                    
                    <div className="flex items-start mb-2">
                      <Bell className="h-4 w-4 mr-2 mt-1 text-blue-500" />
                      <div>
                        <strong>Read/Unread Status</strong> - Keep track of which notifications you've seen.
                      </div>
                    </div>
                    
                    <div className="flex items-start mb-2">
                      <Check className="h-4 w-4 mr-2 mt-1 text-green-500" />
                      <div>
                        <strong>Mark All as Read</strong> - Quickly clear your unread notification count.
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Trash2 className="h-4 w-4 mr-2 mt-1 text-red-500" />
                      <div>
                        <strong>Delete All</strong> - Clear out your notification inbox with one click.
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-4">
                  <AccordionTrigger className="text-lg font-medium">Search Capabilities</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      Quickly find the exact content you're looking for with our powerful search:
                    </p>
                    <ul className="mt-2 space-y-2">
                      <li>Search across all content types or within specific categories</li>
                      <li>Search by title, content text, tags, categories, and more</li>
                      <li>Instant filtering as you type</li>
                    </ul>
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-5">
                  <AccordionTrigger className="text-lg font-medium">Sharing Options</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      Share your content with team members or via email:
                    </p>
                    <ul className="mt-2 space-y-2">
                      <li>Share assets with team members for collaboration</li>
                      <li>Send content via email to clients or stakeholders</li>
                      <li>Control exactly who has access to your assets</li>
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </TabsContent>

          <TabsContent value="tips">
            <div className="prose dark:prose-invert max-w-none">
              <h3 className="text-xl font-semibold mb-4">Tips & Tricks</h3>
              
              <div className="space-y-6">
                <div className="bg-gray-100 border border-blue-100 dark:border-blue-800 rounded-lg p-4">
                  <h4 className="font-medium mb-2">Quick Navigation</h4>
                  <p className="">
                    Use the Overview tab to quickly see all asset counts and navigate to specific categories with a single click.
                  </p>
                </div>
                
                <div className="bg-gray-100 border border-green-100 dark:border-green-800 rounded-lg p-4">
                  <h4 className="font-medium mb-2">Content Management</h4>
                  <p className="">
                    Use the status badge to quickly toggle between "Draft" and "Published" states to organize your workflow stages.
                  </p>
                </div>
                
                <div className="bg-gray-100 border border-purple-100 dark:border-purple-800 rounded-lg p-4">
                  <h4 className="font-medium mb-2">Efficiency Booster</h4>
                  <p className="">
                    Switch between card view and table view based on your task: cards for browsing, table for data-heavy operations.
                  </p>
                </div>
                
                <div className="bg-gray-100 border border-orange-100 dark:border-orange-800 rounded-lg p-4">
                  <h4 className="font-medium mb-2">Staying Updated</h4>
                  <p className="">
                    Check the Notifications tab regularly to stay informed about content delivery, updates, and other important announcements.
                  </p>
                </div>
              </div>
            </div>
          </TabsContent>

          <TabsContent value="faq">
            <div className="prose dark:prose-invert max-w-none">
              <h3 className="text-xl font-semibold mb-4">Frequently Asked Questions</h3>
              
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="faq-1">
                  <AccordionTrigger>How often is new content added to my dashboard?</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      New content is added based on your subscription plan and content creation schedule. You'll receive a notification whenever new content is available in your dashboard.
                    </p>
                  </AccordionContent>
                </AccordionItem>
                
                <AccordionItem value="faq-2">
                  <AccordionTrigger>Can I edit the content directly in the dashboard?</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      Currently, the dashboard is primarily for viewing, organizing, and managing content. For editing, you can copy the content and use it in your preferred editor or platform.
                    </p>
                  </AccordionContent>
                </AccordionItem>
                
                <AccordionItem value="faq-3">
                  <AccordionTrigger>What's the difference between "Draft" and "Published" status?</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      The status indicator helps you organize your content workflow:
                    </p>
                    <ul className="mt-2">
                      <li><strong>Draft</strong> - Content that's still in progress or needs review before use</li>
                      <li><strong>Published</strong> - Content that's finalized and ready to use in your marketing channels</li>
                    </ul>
                    <p className="mt-2">
                      This status is for your organization only and doesn't affect whether content is publicly visible elsewhere.
                    </p>
                  </AccordionContent>
                </AccordionItem>
                
                <AccordionItem value="faq-4">
                  <AccordionTrigger>How do I share content with my team?</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      You can share content with team members using the TeamShare button available in the asset details view. This allows you to give specific team members access to particular assets.
                    </p>
                    <p className="mt-2">
                      For sharing outside your team, use the Share via Email option to quickly send content to clients or other stakeholders.
                    </p>
                  </AccordionContent>
                </AccordionItem>
                
                <AccordionItem value="faq-5">
                  <AccordionTrigger>What should I do if I accidentally delete content?</AccordionTrigger>
                  <AccordionContent>
                    <p>
                      Currently, deletion is permanent. We recommend confirming carefully before deleting any assets.
                    </p>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </TabsContent>
        </Tabs>

        <div className="mt-8 bg-gray-100 dark:bg-gray-700 rounded-lg p-4">
          <h3 className="text-lg font-medium mb-3">Need More Help?</h3>
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            If you have additional questions or need assistance with your dashboard, our support team is ready to help.
          </p>
          <div className="flex flex-col sm:flex-row gap-2">
            <Button variant="outline" className="border-none shadow-md" onClick={()=>navigate('/support')}>Contact Support</Button>
            {/*<Button variant="outline">View Video Tutorial</Button>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentationPanel;