import React from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/ui/accordion';
import { Card, CardContent } from '../../components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import { Badge } from '../../components/ui/badge';
import { ArrowRight, Info, Lightbulb, Zap, Clock, Repeat, Calendar, Check, Highlighter, Bot, Image, Save, BookA } from 'lucide-react';

const FlowInfoContent = () => {
  return (
    <div className="space-y-8 pb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardContent className="pt-6">
            <div className="mb-4">
              <h2 className="text-2xl font-bold mb-2">What is a Flow?</h2>
              <p className="text-muted-foreground">
                A flow is an automated sequence of actions that execute in response to triggers 
                or on a scheduled basis. Flows connect different services, process data, and automate 
                tasks without requiring complex programming.
              </p>
            </div>
            
            <div className="grid grid-cols-2 gap-4 mt-6">
              <FeatureCard 
                icon={<Highlighter size={20} />} 
                title="Add Context Steps" 
                description="Add your specific details to be used throughout the flow. This can be business details, topics, or any other information you want to use in the flow." 
              />
              <FeatureCard 
                icon={<Repeat size={20} />} 
                title="AI Check and Improve Steps" 
                description="Add this step after an AI request step to check and improve the response before returning a better 'final draft.'" 
              />
              <FeatureCard 
                icon={<Bot size={20} />} 
                title="AI Request Steps" 
                description="These steps contain the prompts (questions, instructions, etc.) that will be sent to the AI assistant." 
              />
              <FeatureCard 
                icon={<Image size={20} />} 
                title="Generate AI Image Steps" 
                description="Add a description or prompt to generate an image using the AI image generator. Image credits are required to use this feature." 
              />
              <FeatureCard 
                icon={<Save size={20} />} 
                title="Action: Save Asset" 
                description="These steps allow you to save parts of your flow responses as one item that will be available in your saved assets for later use." 
              />
              <FeatureCard 
                icon={<BookA size={20} />} 
                title="Action: Generate PDF/Image File" 
                description="Create an image or PDF file from the content of your flow. This can be used to generate images or PDFs from the results of your other steps." 
              />
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardContent className="pt-6">
            <h2 className="text-2xl font-bold mb-4">Common Flow Use Cases</h2>
            <ul className="space-y-3">
              <UseCaseItem 
                title="Blog Content Pipeline" 
                description="Generate themed article outlines, draft content, and create matching featured images in one workflow" 
              />
              <UseCaseItem 
                title="Social Media Campaign Bundle" 
                description="Create coordinated posts across platforms with matching visuals and copy tailored to each network" 
              />
              <UseCaseItem 
                title="Product Description Generator" 
                description="Transform basic specifications into compelling descriptions with matching lifestyle imagery" 
              />
              <UseCaseItem 
                title="Email Newsletter Assembly" 
                description="Generate newsletter content, segment-specific CTAs, and header graphics in a single workflow" 
              />
              <UseCaseItem 
                title="Video Script & Thumbnail Creator" 
                description="Develop video scripts with accompanying storyboards and eye-catching thumbnail images" 
              />
              <UseCaseItem 
                title="Podcast Content Ecosystem" 
                description="Generate episode outlines, show notes, promotional quotes, and cover art variations" 
              />
              <UseCaseItem 
                title="Course Material Developer" 
                description="Create lesson plans, student worksheets, and instructional graphics for educational content" 
              />
              <UseCaseItem 
                title="Real Estate Listing Enhancer" 
                description="Transform property details into compelling listings with staged virtual room visualizations" 
              />
              <UseCaseItem 
                title="Product Launch Campaign" 
                description="Generate announcement copy, technical specifications, and product imagery concepts" 
              />
              <UseCaseItem 
                title="Seasonal Promotion Planner" 
                description="Create holiday-specific marketing content and themed promotional imagery" 
              />
            </ul>
          </CardContent>
        </Card>
      </div>
      
      <Alert className="bg-primary/10 border-primary">
        <Info className="h-4 w-4 text-primary" />
        <AlertTitle>Pro Tip</AlertTitle>
        <AlertDescription>
          Start with a simple flow and gradually add complexity. Test each step thoroughly before adding the next one.
        </AlertDescription>
      </Alert>
      
      <h2 className="text-2xl font-bold mt-8 mb-4">Flow Fundamentals</h2>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex items-center gap-2">
              <Calendar size={18} />
              Creating Your First Flow
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <p className="mb-3">
              To create your first flow, follow these steps:
            </p>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Navigate to the "Create a Flow" page</li>
              <li>Choose a pre-made template that matches your goal or create a flow from scratch</li>
              <li>If creating from scratch, add steps to run in order that will accomplish your goal. Be sure to include "Add Context" steps to personalize your results.</li>
              <li>If creating from a pre-made template, be sure to update any/all "Add Context" steps</li>
              <li>Look over your full flow to make sure each step has all the necessary information (selected assistant, your personal details, etc)</li>
              <li>Save your flow and execute it when you are ready</li>
            </ol>
          </AccordionContent>
        </AccordionItem>
        
        <AccordionItem value="item-2">
          <AccordionTrigger>
            <div className="flex items-center gap-2">
              <Check size={18} />
              Best Practices
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <ul className="space-y-3">
              <li className="flex gap-2">
                <Badge variant="outline">Tip</Badge>
                <span>Name your flows clearly based on their purpose</span>
              </li>
              <li className="flex gap-2">
                <Badge variant="outline">Tip</Badge>
                <span>Tweak prompts/steps within your flows to get different results</span>
              </li>
              <li className="flex gap-2">
                <Badge variant="outline">Tip</Badge>
                <span>Monitor flow executions regularly to identify issues</span>
              </li>
              <li className="flex gap-2">
                <Badge variant="outline">Tip</Badge>
                <span>Document your flow's purpose and expected behavior</span>
              </li>
              <li className="flex gap-2">
                <Badge variant="outline">Tip</Badge>
                <span>Break complex processes into smaller, manageable steps</span>
              </li>
            </ul>
          </AccordionContent>
        </AccordionItem>
        
        {/*<AccordionItem value="item-3">
          <AccordionTrigger>
            <div className="flex items-center gap-2">
              <Lightbulb size={18} />
              Advanced Concepts
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="space-y-3">
              <p>
                As you become more comfortable with flows, consider these advanced techniques:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Using conditional logic to create branching workflows</li>
                <li>Implementing error handling and retry mechanisms</li>
                <li>Creating modular flows that can be reused</li>
                <li>Using variables and expressions for dynamic values</li>
                <li>Implementing approval steps for human oversight</li>
                <li>Setting up monitoring and alerting for flow failures</li>
              </ul>
            </div>
          </AccordionContent>
        </AccordionItem>*/}
      </Accordion>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col p-4 border rounded-lg">
      <div className="text-primary mb-2">{icon}</div>
      <h3 className="font-medium mb-1">{title}</h3>
      <p className="text-sm text-muted-foreground">{description}</p>
    </div>
  );
};

const UseCaseItem = ({ title, description }) => {
  return (
    <li className="flex items-start gap-3">
      <div className="h-6 w-6 rounded-full bg-primary/10 flex items-center justify-center text-primary mt-0.5">
        <Check size={14} />
      </div>
      <div>
        <p className="font-medium">{title}</p>
        <p className="text-sm text-muted-foreground">{description}</p>
      </div>
    </li>
  );
};

export default FlowInfoContent;