import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { CirclePlus } from "lucide-react";

const NavigationSection = () => {
  const navigate = useNavigate();
  
  const navigationButtons = [
    {
      label: 'Create a New Flow',
      path: '/create-flow'
    },
    {
      label: 'Generate an Image with AI',
      path: '/image-generation'
    },
    {
      label: 'Start a Chat Conversation',
      path: '/chat'
    },
    {
      label: 'Build a Custom Assistant',
      path: '/custom-assistants'
    },
    {
      label: 'View Help & Guides',
      path: '/documentation'
    }
  ];

  return (
    <div className="w-full py-6 border-t border-gray-200">
  <div className="flex flex-col lg:flex-row items-center justify-center lg:space-x-4 space-y-2 lg:space-y-0 flex-wrap">
    <div className='flex items-center justify-center gap-2'>
      <CirclePlus className="h-7 w-7 magical-gradient text-white p-1 rounded-full" />
      <h2 className="text-xl font-semibold text-gray-900">Quick Actions:</h2>
    </div>
    {navigationButtons.map((button, index) => (
      <Button
        key={index}
        variant="outline"
        onClick={() => navigate(button.path)}
        className="border border-gray-400 lg:border-none bg-gray-100 rounded text-black hover:bg-gray-900 hover:text-gray-100 mb-2 lg:mb-0"
      >
        <h2 className="flex items-center justify-center gap-2">
          <CirclePlus className="h-4 w-4" />
          {button.label}
        </h2>
      </Button>
    ))}
  </div>
</div>
  );
};

export default NavigationSection;