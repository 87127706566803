// src/pages/ForgotPasswordPage.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../slices/authSlice';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      await dispatch(forgotPassword({ email })).unwrap();
      setMessage('A reset link has been sent to your email address.');
    } catch (err) {
      // error is already in the slice state; you can also set local error state if needed.
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 px-4">
      <div className="max-w-md w-full bg-white p-8 shadow rounded">
      <div className="flex items-center space-x-2 justify-center mb-4">
                  <img
                    src="/FlowOrchestraIcon5.png"
                    alt="Flow Orchestra Icon"
                    className="w-8 h-8 bg-white rounded-md"
                  />
                  <span className="text-xl font-semibold">Flow Orchestra</span>
                </div>
        <h2 className="text-2xl font-bold mb-6 text-center">Forgot Password</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {message && <div className="text-green-500 mb-4">{message}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" disabled={loading} className="w-full">
            {loading ? 'Sending...' : 'Send Reset Link'}
          </Button>
        </form>
        <div className="mt-4 text-center">
          <button
            onClick={() => navigate('/login')}
            className="text-blue-500 hover:underline"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;