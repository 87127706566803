import { 
    Folder,
    FileText, 
    ShoppingCart, 
    Share2, 
    TrendingUp,
    Boxes,
    DollarSign,
    Users,
    Calculator,
    MoreHorizontal,
    Search,
    GraduationCap,
    Store,
    BarChart2,
    Heart,
    Briefcase,
    Building2,
    Film,
    Stethoscope,
    Headset
  } from 'lucide-react';
  
  const CategoryIcon = ({ category, className }) => {
    const getIcon = () => {
      switch (category) {
        case "Content Creation":
          return <FileText className={className} />;
        case "Product Creation":
        case "Product Development":
          return <Boxes className={className} />;
        case "Social Media Marketing":
          return <Share2 className={className} />;
        case "Digital Marketing":
          return <TrendingUp className={className} />;
        case "Customer Support":
          return <Headset className={className} />;
        case "Sales":
          return <DollarSign className={className} />;
        case "HR":
          return <Users className={className} />;
        case "Finance":
          return <Calculator className={className} />;
        case "Research":
          return <Search className={className} />;
        case "Education":
          return <GraduationCap className={className} />;
        case "E-commerce":
          return <ShoppingCart className={className} />;
        case "Analytics and Data":
          return <BarChart2 className={className} />;
        case "Lifestyle":
          return <Heart className={className} />;
        case "Career":
          return <Briefcase className={className} />;
        case "Business":
          return <Building2 className={className} />;
        case "Entertainment":
          return <Film className={className} />;
        case "Health":
          return <Stethoscope className={className} />;
        case "Other":
          return <MoreHorizontal className={className} />;
        case "Uncategorized":
        default:
          return <Folder className={className} />;
      }
    };
  
    return getIcon();
  };
  
  export default CategoryIcon;