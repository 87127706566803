// src/pages/AdminComponents/ManagementDashboard.js
import React from 'react';

const ManagementDashboard = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Management</h2>
      <p className="text-gray-600">This section is under construction. Check back soon for management tools and insights.</p>
    </div>
  );
};

export default ManagementDashboard;
