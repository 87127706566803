// src/components/surveyData/FinalScreenerResponseScreen.js
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  StarIcon,
  Target,
  Sparkles,
  Timer,
  Waypoints,
  KeySquare,
  BotMessageSquare,
  BadgeCheck,
  MousePointerClick,
  Clock7,
  Pointer,
  Rocket,
  X,
  AudioWaveform,
  TreePalm,
  TentTree,
  BicepsFlexed,
  LampDesk,
} from "lucide-react";
import { Card } from "../../components/ui/card";
import { ScrollArea } from "../../components/ui/scroll-area";
import AuthForm from "../../components/AuthForm"; // NEW: import the AuthForm component

const FinalScreenerResponseScreen = ({ response, onClose }) => {
  // NEW: State to toggle between final summary and the AuthForm
  const [showAuthForm, setShowAuthForm] = useState(false);
  if (!response) return null;

  let data = response;
  if (typeof response === "string") {
    try {
      data = JSON.parse(response);
    } catch (error) {
      console.error("Error parsing response:", error);
      data = {};
    }
  }

  const { recommendations, benefits, useCases } = data;
  const timeSavings =
    data.timeSavings || (recommendations && recommendations.timeSavings);

  // Subtle slide-up animation for content
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="flex flex-col h-[calc(100vh-1rem)] bg-white -mt-10 leading-tight"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {/* Header */}
      <header className="flex-shrink-0 px-8 py-4 text-center">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex items-center justify-center gap-4 mb-3">
            <img
              src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/FlowOrchestraAIChatAvatar.jpg"
              alt="AI Avatar"
              className="w-10 h-10 rounded-full"
            />
            <h2 className="text-2xl font-semibold text-gray-900">
              Your Growth Gameplan
            </h2>
          </div>
          <p className="text-gray-600 leading-tight">
            Custom-crafted shortcuts to your next content breakthrough
          </p>
        </motion.div>
      </header>

      {/* Main Content */}
      <ScrollArea className="flex-grow pt-6 px-6 lg:px-8 bg-gray-100 rounded shadow-xl">
        <AnimatePresence mode="wait">
          {showAuthForm ? (
            // NEW: Slide in the AuthForm when showAuthForm is true.
            <motion.div
              key="authForm"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.5 }}
              className="w-full flex items-center justify-center lg:pt-12"
            >
              <AuthForm onSuccess={onClose} isLoginDefault={false} />
            </motion.div>
          ) : (
            // Existing summary content remains unchanged.
            <motion.div
              key="summaryContent"
              variants={containerVariants}
              initial="hidden"
              animate="show"
              className="max-w-4xl mx-auto space-y-12 pb-8"
            >
              {/* Recommendations Section */}
              {recommendations && (
                <motion.section variants={itemVariants} className="space-y-6">
                  <div className="flex items-center justify-center gap-3">
                    <Rocket
                      className="h-5 w-5 text-indigo-500"
                      strokeWidth={1.5}
                    />
                    <h3 className="text-xl font-bold text-gray-900">
                      Unleash Your Unfair Advantage
                    </h3>
                  </div>
                  {recommendations.flows && (
                    <div className="space-y-2">
                      <h4 className="text-lg text-gray-700">
                        Recommended Flows
                      </h4>
                      <div className="text-gray-600 text-sm leading-tight">
                        Click-ready content recipes that make your competition
                        wonder how you do it all
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {recommendations.flows.map((flow, idx) => (
                          <div
                            key={`flow-${idx}`}
                            className="bg-black text-gray-100 backdrop-blur-sm rounded-2xl p-6 transition-all duration-300 cursor-pointer" onClick={() => setShowAuthForm(true)}
                          >
                            <h5 className="font-medium text-gray-100 mb-2 flex items-center justify-center">
                              <Waypoints className="h-4 w-4 mr-1" />
                              {flow.name}
                            </h5>
                            <p className="text-gray-200 text-sm mb-3 leading-tight">
                              {flow.description}
                            </p>
                            {flow.relevance && (
                              <p className="text-indigo-400 text-md lg:text-lg leading-tight lg:leading-none">
                                {flow.relevance}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {recommendations.assistants && (
                    <div className="space-y-4">
                      <h4 className="text-lg text-gray-700">AI Assistants</h4>
                      <span className="text-gray-600 text-sm">
                        Your secret weapons: AI experts that multiply your
                        output (while you take the credit)
                      </span>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {recommendations.assistants.map((assistant, idx) => (
                          <div
                            key={`assistant-${idx}`}
                            className="bg-black text-gray-100 backdrop-blur-sm rounded-2xl p-6 transition-all duration-300 cursor-pointer" onClick={() => setShowAuthForm(true)}
                          >
                            <h5 className="font-medium text-gray-100 mb-2 flex items-center justify-center">
                              <BotMessageSquare className="h-4 w-4 mr-2" />
                              {assistant.name}
                            </h5>
                            <p className="text-gray-200 text-sm mb-3 leading-tight">
                              {assistant.description}
                            </p>
                            <p className="text-indigo-400 text-md lg:text-lg leading-tight lg:leading-none">
                              {assistant.relevance}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {recommendations.features && (
                    <div className="space-y-4">
                      <h4 className="text-lg text-gray-700">Key Features</h4>
                      <span className="text-gray-600 text-sm">
                        Like having a content genius in your pocket. Without the
                        attitude.
                      </span>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {recommendations.features.map((feature, idx) => (
                          <div
                            key={`feature-${idx}`}
                            className="bg-black text-gray-100 backdrop-blur-sm rounded-2xl p-6 transition-all duration-300 cursor-pointer" onClick={() => setShowAuthForm(true)}
                          >
                            <h5 className="font-medium text-gray-100 mb-2 flex items-center justify-center">
                              <KeySquare className="h-4 w-4 mr-2" />
                              {feature.name}
                            </h5>
                            <p className="text-gray-200 text-sm mb-3 leading-tight">
                              {feature.description}
                            </p>
                            <p className="text-indigo-400 text-md lg:text-lg leading-tight lg:leading-none">
                              {feature.relevance}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </motion.section>
              )}
              {/* Benefits Section */}
              {benefits && benefits.length > 0 && (
                <motion.section variants={itemVariants} className="space-y-6">
                  <div className="flex items-center gap-3">
                    <Sparkles
                      className="h-7 w-7 text-indigo-500"
                      strokeWidth={1.5}
                    />
                    <h3 className="text-xl font-medium text-gray-900">
                      Business Benefits
                    </h3>
                    <span className="text-gray-600 text-sm leading-tight">
                      Less stress, more success. That's literally it.
                    </span>
                  </div>
                  <div className="grid grid-cols-1 gap-4">
                    {benefits.map((benefit, idx) => (
                      <div
                        key={`benefit-${idx}`}
                        className="bg-black text-gray-100 backdrop-blur-sm rounded-2xl p-6 transition-all duration-300 cursor-pointer" onClick={() => setShowAuthForm(true)}
                      >
                        <h5 className="font-medium text-gray-100 mb-2 flex items-center justify-center">
                          <BadgeCheck className="h-4 w-4 mr-2" />
                          {benefit.title}
                        </h5>
                        <p className="text-gray-200 text-sm mb-3 leading-tight">
                          {benefit.description}
                        </p>
                        <p className="text-indigo-400 text-md lg:text-lg lg:px-14 leading-tight lg:leading-none">
                          {benefit.impact}
                        </p>
                      </div>
                    ))}
                  </div>
                </motion.section>
              )}
              {/* Use Cases Section */}
              {useCases && useCases.length > 0 && (
                <motion.section variants={itemVariants} className="space-y-6">
                  <div className="flex items-center gap-3">
                    <LampDesk
                      className="h-7 w-7 text-indigo-500"
                      strokeWidth={1.5}
                    />
                    <h3 className="text-xl font-medium text-gray-900">
                      Practical Applications
                    </h3>
                    <span className="text-gray-600 text-sm leading-tight">
                      Real tools for real creators. No fluff included.
                    </span>
                  </div>
                  <div className="space-y-4">
                    {useCases.map((useCase, idx) => (
                      <div
                        key={`useCase-${idx}`}
                        className="bg-black text-gray-100 backdrop-blur-sm rounded-2xl p-6 transition-all duration-300 cursor-pointer" onClick={() => setShowAuthForm(true)}
                      >
                        <h5 className="font-medium text-gray-100 mb-2 flex items-center justify-center">
                          <MousePointerClick className="h-4 w-4 mr-2" />
                          {useCase.scenario}
                        </h5>
                        <p className="text-gray-200 text-sm mb-3 leading-tight">
                          {useCase.implementation}
                        </p>
                        <p className="text-indigo-400 text-md lg:text-lg lg:px-14 leading-tight lg:leading-none">
                          {useCase.outcome}
                        </p>
                      </div>
                    ))}
                  </div>
                </motion.section>
              )}
              {/* Time Savings Section */}
              {timeSavings && (
                <motion.section variants={itemVariants} className="space-y-6">
                  <div className="flex items-center gap-3">
                    <BicepsFlexed
                      className="h-7 w-7 text-indigo-500"
                      strokeWidth={1.5}
                    />
                    <h3 className="text-xl font-medium text-gray-900">
                      Efficiency Gains
                    </h3>
                    <span className="text-gray-600 text-sm leading-tight">
                      Because you've got better things to do than grind.
                    </span>
                  </div>
                  <div className="bg-black text-gray-100 backdrop-blur-sm rounded-2xl p-6 transition-all duration-300 cursor-pointer" onClick={() => setShowAuthForm(true)}>
                    <div className="flex items-center justify-center gap-6 mb-2">
                      <h4 className="font-bold text-gray-100">
                        Weekly Time Saved
                      </h4>
                      <span className="text-2xl font-semibold text-indigo-400 flex items-center gap-2">
                        <Clock7 className="h-7 w-7 text-white" />
                        {timeSavings.hoursPerWeek}h
                      </span>
                    </div>
                    <motion.div
                      variants={itemVariants}
                      className="flex flex-col items-center justify-center gap-0 mb-4"
                    >
                      <div className="flex items-center gap-2">
                      <TentTree className="h-7 w-7" />
                        <span className="text-2xl font-semibold text-indigo-400">
                          {(timeSavings.hoursPerWeek / 2).toFixed(1)}
                        </span>
                        <h4 className="text-gray-100 text-md font-bold">
                          work days per month saved
                        </h4>
                        
                      </div>
                      
                      <div className="flex items-center gap-2">
                        
                        <div className="text-gray-200 text-sm">
                            That's like gaining an unexpected mini-holiday every month!
                        </div>
                      </div>
                      <div className="flex items-center gap-2 mt-4">
                      <TreePalm className="h-7 w-7" />
                        <span className="text-2xl font-semibold text-indigo-400">
                          {((timeSavings.hoursPerWeek * 52) / 40).toFixed(1)}
                        </span>
                        <h4 className="text-gray-100 text-md font-bold">
                          weeks per year off work
                        </h4>
                      </div>
                      <div className="flex items-center gap-2">
                        
                        <div className="text-gray-200 text-sm">
                            That's a whole lot of time to kick back and recharge—talk about work-life balance!
                        </div>
                      </div>
                    </motion.div>
                    <p className="text-indigo-400 text-md lg:text-lg lg:px-14 leading-tight lg:leading-none">
                      {timeSavings.explanation}
                    </p>
                  </div>
                </motion.section>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </ScrollArea>

      {/* Footer with Two Buttons */}
      <footer className="flex-shrink-0 p-4 lg:p-6 px-2 flex justify-center space-x-4">
        {!showAuthForm ? (
          <motion.button
            onClick={() => setShowAuthForm(true)}
            className="px-5 py-1 bg-black text-gray-100 font-medium rounded hover:bg-indigo-700 transition-colors duration-200 flex items-center gap-2"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <AudioWaveform className="h-6 w-6" />
            Start Flowing For Free
          </motion.button>
        ) : (
          <div
            onClick={() => setShowAuthForm(true)}
            className="px-5 py-1 text-indigo-700 font-medium rounded hover:bg-indigo-700 hover:text-gray-100 transition-colors duration-200 flex items-center gap-2"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <AudioWaveform className="h-6 w-6" />
            Start Flowing For Free
          </div>
        )}

        <motion.button
          onClick={onClose}
          className="px-2 py-1 bg-gray-100 text-gray-700 font-medium rounded hover:bg-gray-300 transition-colors duration-200 text-center flex flex-col items-center gap-0"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <X className="h-5 w-5" />
          Close
        </motion.button>
      </footer>
    </motion.div>
  );
};

export default FinalScreenerResponseScreen;
