// src/slices/adminAnalyticsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsersAnalytics, getRecordsAnalytics } from '../services/api';

// Async thunk for fetching users analytics
export const fetchUsersAnalytics = createAsyncThunk(
  'adminAnalytics/fetchUsersAnalytics',
  async ({ startDate, endDate } = {}, { rejectWithValue }) => {
    try {
      const response = await getUsersAnalytics(startDate, endDate);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async thunk for fetching records analytics
export const fetchRecordsAnalytics = createAsyncThunk(
  'adminAnalytics/fetchRecordsAnalytics',
  async ({ startDate, endDate } = {}, { rejectWithValue }) => {
    try {
      const response = await getRecordsAnalytics(startDate, endDate);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const adminAnalyticsSlice = createSlice({
  name: 'adminAnalytics',
  initialState: {
    usersAnalytics: {
      data: null,
      loading: false,
      error: null,
    },
    recordsAnalytics: {
      data: null,
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // Users Analytics
    builder.addCase(fetchUsersAnalytics.pending, (state) => {
      state.usersAnalytics.loading = true;
      state.usersAnalytics.error = null;
    });
    builder.addCase(fetchUsersAnalytics.fulfilled, (state, action) => {
      state.usersAnalytics.loading = false;
      state.usersAnalytics.data = action.payload;
    });
    builder.addCase(fetchUsersAnalytics.rejected, (state, action) => {
      state.usersAnalytics.loading = false;
      state.usersAnalytics.error = action.payload;
    });

    // Records Analytics
    builder.addCase(fetchRecordsAnalytics.pending, (state) => {
      state.recordsAnalytics.loading = true;
      state.recordsAnalytics.error = null;
    });
    builder.addCase(fetchRecordsAnalytics.fulfilled, (state, action) => {
      state.recordsAnalytics.loading = false;
      state.recordsAnalytics.data = action.payload;
    });
    builder.addCase(fetchRecordsAnalytics.rejected, (state, action) => {
      state.recordsAnalytics.loading = false;
      state.recordsAnalytics.error = action.payload;
    });
  },
});

export default adminAnalyticsSlice.reducer;
