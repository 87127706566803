// pages/ChatComponents/ChatInputComponents/conversationOptionsData.js
export const conversationOptions = [
    {
      text: "Let's keep it casual",
      description: "A relaxed, friendly conversation—like chatting with a buddy."
    },
    {
      text: "Give it to me straight",
      description: "Concise, no-nonsense answers with just the facts."
    },
    {
      text: "Explain like I’m five",
      description: "Break it down in the simplest way possible."
    },
    {
      text: "Take me to expert mode",
      description: "Deep, detailed insights—assume I know the basics."
    },
    {
      text: "Make it fun and engaging",
      description: "Use humor, analogies, and a storytelling approach."
    },
    {
      text: "Help me think outside the box",
      description: "Creative brainstorming and idea generation."
    },
    {
      text: "I need a structured plan",
      description: "Step-by-step guidance and actionable advice."
    },
    {
      text: "Challenge my perspective",
      description: "Play devil’s advocate and introduce new viewpoints."
    },
    {
      text: "Summarize it for me",
      description: "A quick and clear recap of key points."
    },
    {
      text: "Give me the pros and cons",
      description: "A balanced breakdown of any decision or topic."
    },
    {
      text: "Turn it into a list",
      description: "Organize ideas into easy-to-digest bullet points."
    },
    {
      text: "Help me refine and improve",
      description: "Polish an idea, concept, or piece of writing."
    },
    {
      text: "Let's role-play a scenario",
      description: "Simulate a conversation, pitch, or real-world situation."
    },
    {
      text: "Help me learn by doing",
      description: "Interactive explanations with exercises or examples."
    },
    {
      text: "What would an expert say?",
      description: "Get an answer in the style of a field specialist."
    }
];
