import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFlows,
  deleteFlow,
  setCurrentPage,
  setItemsPerPage,
  createFlow,
  cloneFlow,
  fetchFlowById,
  updateFlow,
} from "../slices/flowsSlice";
import { updateCredits } from "../slices/authSlice";
import { startExecution } from "../slices/executionsSlice";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Pagination } from "../components/ui/pagination";
import { Input } from "../components/ui/input";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "../components/ui/collapsible";
import {
  ChevronDown,
  ChevronUp,
  Edit,
  Trash2,
  Play,
  Copy,
  Plus,
  Clock,
  Tag,
  Eye,
  EyeOff,
  BarChart,
  Maximize2,
  Minimize2,
  Menu,
  X,
  Cog,
} from "lucide-react";
import { debounce } from "lodash";
import { useToast } from "../components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { ScrollArea } from "../components/ui/scroll-area";
import { Badge } from "../components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import useSubscription from "../hooks/useSubscription";
import TeamShareButton from "../components/TeamShare/TeamShareButton";
import { openVideoModal } from "../slices/videoModalSlice";
import AddContextModal from "./FlowFormComponents/AddContextModal";

const FlowListItem = ({ flow, isSelected, onSelect, onDelete }) => (
  <Card
    className={`cursor-pointer transition-colors bg-gray-100 shadow-md ${
      flow.privacy === "global"
        ? isSelected
          ? "bg-amber-700 text-white hover:bg-amber-800"
          : "bg-amber-100 hover:bg-amber-200"
        : isSelected
        ? "bg-black text-white"
        : "hover:bg-gray-200"
    } mb-2`}
    onClick={() => onSelect(flow)}
  >
    <CardHeader className="px-4 py-1 lg:py-2">
      <div className="flex flex-col xl:flex-row justify-between items-center">
        <h3 className="text-sm font-semibold">{flow.name}</h3>
        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Badge
                  variant="outline"
                  className={isSelected ? "text-white" : ""}
                >
                  <Clock className="w-3 h-3 mr-1" />
                  {flow.averageExecutionTime.toFixed(1)}s
                </Badge>
              </TooltipTrigger>
              <TooltipContent>Average execution time</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Badge
                  variant="outline"
                  className={isSelected ? "text-white" : ""}
                >
                  <BarChart className="w-3 h-3 mr-1" />
                  {flow.executionCount}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>Execution count</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={(e) => e.stopPropagation()}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="z-[80]">
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the
                  flow.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(flow._id);
                  }}
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <p
        className={`text-xs ${
          isSelected ? "text-gray-100" : "text-muted-foreground"
        }`}
      >
        {flow.description}
      </p>
      <div className="flex flex-wrap gap-1 mt-2">
        {flow.tags.map((tag, index) => (
          <Badge key={index} variant="secondary" className="text-xs">
            {tag}
          </Badge>
        ))}
      </div>
    </CardHeader>
  </Card>
);

const FlowList = ({ flows, onSelectFlow, selectedFlowId, onDeleteFlow }) => (
  <ScrollArea className="h-full">
    <div className="space-y-2 p-4">
      {flows.map((flow) => (
        <FlowListItem
          key={flow._id}
          flow={flow}
          isSelected={selectedFlowId === flow._id}
          onSelect={onSelectFlow}
          onDelete={onDeleteFlow}
        />
      ))}
    </div>
  </ScrollArea>
);

const StepDetails = ({ step, index }) => (
  <Collapsible className="border-none shadow-md bg-gray-100 rounded-md p-3 px-6 mb-2 hover:bg-gray-200 group data-[state=open]:bg-white data-[state=open]:hover:bg-white">
    <CollapsibleTrigger className="flex justify-between items-center w-full">
      <span className="font-semibold">
        {index + 1}. {step.name}
      </span>
      <ChevronDown className="h-4 w-4" />
    </CollapsibleTrigger>
    <CollapsibleContent className="space-y-2">
      <p>
        <strong>Type:</strong> {step.type}
      </p>
      <p className="text-sm text-gray-700 mb-2">{step.description}</p>
      <p>
        <strong>Instructions:</strong> {step.prompt || step.description}
      </p>
      <p>
        <strong>Output Name (used to reference this response in later steps):</strong> {step.outputKey}
      </p>
      {step.assetType && (
        <p>
          <strong>Asset Type:</strong> {step.assetType}
        </p>
      )}
      {step.assetData && (
        <p>
          <strong>Asset Data:</strong> {step.assetData}
        </p>
      )}
    </CollapsibleContent>
  </Collapsible>
);

const FlowDetails = ({
  flow,
  onEdit,
  onDelete,
  onExecute,
  onClone,
  limits,
  totalItems,
  credits,
  user,
}) => (
  <ScrollArea className="h-full">
    <div className="p-6 pt-0 space-y-2">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4">
        <h2 className="text-md lg:text-2xl font-bold">{flow.name}</h2>
        <div className="flex flex-wrap lg:flex-nowrap gap-2">
          <Button variant="outline" size="sm" onClick={() => onEdit(flow._id)}
            className="border-none bg-[#fafafa] hover:bg-gray-200 shadow-md">
            <Edit className="w-4 h-4 mr-1" /> Edit
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={credits.execution <= 0}
            onClick={() => onExecute(flow._id)}
            className="border-none bg-[#fafafa] hover:bg-gray-200 shadow-md"
          >
            <Play className="w-4 h-4 mr-1" /> Execute
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={limits?.activeFlows <= totalItems}
            onClick={() => onClone(flow._id)}
            className="border-none bg-[#fafafa] hover:bg-gray-200 shadow-md"
          >
            <Copy className="w-4 h-4 mr-1" /> Clone
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="destructive" size="sm"
            className="border-none shadow-md">
                <Trash2 className="w-4 h-4 mr-1" /> Delete
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="z-[80]">
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the
                  flow.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={() => onDelete(flow._id)}>
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      <p className="text-muted-foreground leading-none">{flow.description}</p>
      <div className="flex flex-wrap gap-0">
        {flow.tags.map((tag, index) => (
          <Badge key={index} variant="secondary">
            <Tag className="w-3 h-3 mr-1" />
            {tag}
          </Badge>
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 border-none shadow-md rounded p-4 bg-white">
        <div>
          <h3 className="text-lg font-semibold mb-2">Details</h3>
          <p className="capitalize">
            <strong>Privacy:</strong> {flow.privacy}
          </p>
          <p>
            <strong>Active:</strong> {flow.isActive ? "Yes" : "No"}
          </p>
          <p>
            <strong>Execution Count:</strong> {flow.executionCount}
          </p>
          <p>
            <strong>Avg. Execution Time:</strong>{" "}
            {flow.averageExecutionTime.toFixed(1)}s
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Dates</h3>
          <p>
            <strong>Created:</strong>{" "}
            {new Date(flow.createdAt).toLocaleString()}
          </p>
          <p className="mb-2">
            <strong>Updated:</strong>{" "}
            {new Date(flow.updatedAt).toLocaleString()}
          </p>
          {(user?._id === flow.creator || user?.role === 'admin') && (
            <TeamShareButton
            assetId={flow._id}
            modelName="Flow" // or "Flow", "PromptStack", etc.
            currentTeams={flow.team} // Array of team IDs currently having access
            onShareSuccess={() => {
              // Optional callback for when sharing is successful
              // e.g., refetch the asset data
            }}
          />
          )}
          
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Steps</h3>
        {flow.steps.map((step, index) => (
          <StepDetails key={step._id} step={step} index={index} />
        ))}
      </div>
    </div>
  </ScrollArea>
);

const FlowManagement = () => {
  const { credits, limits } = useSubscription();
  const [jsonInput, setJsonInput] = useState("");
  const [jsonError, setJsonError] = useState(null);
  const [isJsonExpanded, setIsJsonExpanded] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { flows, status, error, totalItems, currentPage, itemsPerPage } =
    useSelector((state) => state.flows);
  const { user } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedFlows, setExpandedFlows] = useState({});
  const { teams } = useSelector((state) => state.teams);
  const { selectedTeam } = useSelector((state) => state.teams);
  const [isCardMinimized, setIsCardMinimized] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const [showContextModal, setShowContextModal] = useState(false);
const [contextSteps, setContextSteps] = useState([]);
const [executingFlowId, setExecutingFlowId] = useState(null);

  const toggleCardMinimized = () => {
    setIsCardMinimized(!isCardMinimized);
  };

  const handleJsonSubmit = () => {
    if (limits.activeFlows <= totalItems) {
      toast({
        title: "Error",
        description:
          "You have reached the maximum number of active flows allowed by your subscription plan. Please upgrade your subscription to create more flows.",
        variant: "destructive",
      });
      return;
    }
    setJsonError(null);
    try {
      const parsedJson = JSON.parse(jsonInput);
      dispatch(createFlow({ ...parsedJson, team: selectedTeam || null }))
        .unwrap()
        .then(() => {
          setJsonInput("");
          setIsJsonExpanded(false);
          toast({
            title: "Success",
            description: "Flow created successfully from JSON",
            variant: "default",
          });
          debouncedFetchFlows(
            currentPage,
            itemsPerPage,
            searchTerm,
            selectedTeam
          );
        })
        .catch((error) => {
          setJsonError(error.message);
          toast({
            title: "Error",
            description: "Failed to create flow from JSON",
            variant: "destructive",
          });
        });
    } catch (error) {
      setJsonError("Invalid JSON format");
    }
  };

  const toggleJsonSection = () => {
    setIsJsonExpanded(!isJsonExpanded);
  };

  const debouncedFetchFlows = debounce((page, limit, search, teamId) => {
    dispatch(fetchFlows({ page, limit, search, teamId }));
  }, 300);

  useEffect(() => {
    debouncedFetchFlows(currentPage, itemsPerPage, searchTerm, selectedTeam);
  }, [currentPage, itemsPerPage, searchTerm, selectedTeam, dispatch]);

  const handleEdit = (id) => {
    navigate(`/edit-flow/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteFlow(id))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Flow deleted successfully",
          variant: "default",
        });
        setSelectedFlow(null);
        debouncedFetchFlows(
          currentPage,
          itemsPerPage,
          searchTerm,
          selectedTeam
        );
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to delete flow: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleSave = async (flowData) => {
    try {
      await dispatch(updateFlow({ 
        id: flowData._id, // Use the flow's ID from flowData
        flowData 
      })).unwrap();
      
      await dispatch(fetchFlowById(flowData._id)).unwrap();
      
      toast({
        title: "Success",
        description: "Flow saved successfully",
        variant: "default",
      });
    } catch (err) {
      console.error('Error in handleSave:', err);
      toast({
        title: "Error",
        description: "Failed to save flow",
        variant: "destructive",
      });
    }
  };

  const handleClone = (id) => {
    if (limits.activeFlows <= totalItems) {
      toast({
        title: "Error",
        description:
          "You have reached the maximum number of active flows allowed by your subscription plan. Please upgrade your subscription to create more flows.",
        variant: "destructive",
      });
      return;
    }
    dispatch(cloneFlow(id))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Flow cloned successfully",
          variant: "default",
        });
        debouncedFetchFlows(
          currentPage,
          itemsPerPage,
          searchTerm,
          selectedTeam
        );
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to clone flow: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    dispatch(setCurrentPage(1));
  };

  const handleItemsPerPageChange = (value) => {
    dispatch(setItemsPerPage(Number(value)));
    dispatch(setCurrentPage(1));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const toggleFlowExpansion = (id) => {
    setExpandedFlows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleOpenSingleVideo = () => {
    dispatch(
      openVideoModal({
        url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Flow%20form%20Scratch%20%20-%20Explainer%20Vid.mp4",
        title: "How to Build a Flow from Scratch",
      })
    );
  };

  const handleExecute = async (id) => {
    if (credits.execution <= 0) {
      toast({
        title: "Out of Execution Credits",
        description: "You need execution credits to start a flow execution. Please upgrade your subscription for more credits.",
        variant: "destructive",
      });
      return;
    }
  
    // Find any addContext steps
    const flow = flows.find(f => f._id === id);
    const addContextSteps = flow.steps.filter(step => step.type === 'addContext');
  
    if (addContextSteps.length > 0) {
      setContextSteps(addContextSteps);
      setExecutingFlowId(id);
      setShowContextModal(true);
      return;
    }
  
    // If no addContext steps, proceed with normal execution
    proceedWithExecution(id);
  };
  
  // Add this new function to handle the normal execution flow
  const proceedWithExecution = async (id) => {
    dispatch(updateCredits({ type: "execution", amount: 1, subtract: true }));
    
    try {
      const result = await dispatch(
        startExecution({
          flowId: id,
          teamId: selectedTeam || null,
        })
      ).unwrap();
      
      navigate(`/execution/${result.executionId}`, {
        state: { isNewExecution: true },
      });
    } catch (error) {
      dispatch(updateCredits({ type: "execution", amount: 1, subtract: false }));
      toast({
        title: "Error",
        description: "Failed to start execution: " + error.message,
        variant: "destructive",
      });
    }
  };
  
  // Add this new function to handle modal submission
  const handleContextSubmit = async (updatedPrompts) => {
    if (!executingFlowId) return;
  
    const flow = flows.find(f => f._id === executingFlowId);
    const updatedSteps = flow.steps.map(step => {
      if (step.type === 'addContext' && updatedPrompts[step._id] !== undefined) {
        return { ...step, prompt: updatedPrompts[step._id] };
      }
      return step;
    });
  
    try {
      await handleSave({ ...flow, steps: updatedSteps });
      setShowContextModal(false);
      proceedWithExecution(executingFlowId);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update flow: " + error.message,
        variant: "destructive",
      });
    }
  };

  if (flows.length === 0) {
    return (
      <div className="flex flex-col gap-2 mt-10 mx-10 border rounded p-8">
        You have not created any flows yet. Need some help?
        <Button
          variant="outline"
          size="sm"
          className="group text-white hover:text-gray-800 transition-colors duration-100 w-full lg:w-fit bg-black"
          onClick={handleOpenSingleVideo}
        >
          <div className="flex items-center">
            <Play className="h-4 w-4 mr-2 text-white group-hover:text-gray-800" />
            How to Build a Flow from Scratch
          </div>
        </Button>
      </div>
    );
  }

  if (!credits || !limits) {
    return <div className="text-center">Loading subscription details...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-4rem)] lg:h-screen">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center px-4 border-b bg-background z-[60] shadow-sm -mx-6 -mt-4">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="mr-2"
        >
          <Cog className="h-5 w-5" />
        </Button>
        <h2 className="text-base font-medium truncate">
          {!isMobileMenuOpen && (selectedFlow ? selectedFlow.name : "Flows")}
        </h2>
      </div>

      {/* Flows List Panel */}
      <div
        className={`
    w-full lg:w-1/3 border-r bg-background
    fixed lg:relative inset-y-0 left-0 z-[60]
    transform transition-transform duration-200 ease-in-out
    lg:transform-none h-screen pt-10 lg:pt-0 lg:mr-4
    ${isMobileMenuOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}
  `}
      >
        <div className="flex flex-col h-full">
          <div className="p-4 border-b">
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col items-start">
                <h1 className="text-xl font-semibold">
                  Flows ({totalItems} of {limits.activeFlows})
                </h1>
                <span
                  className={`text-sm ${
                    credits.execution <= 25
                      ? "text-red-500 font-bold"
                      : "text-muted-foreground"
                  } `}
                >
                  Execution Credits: {credits.execution}
                </span>
              </div>
              <Button
                variant="ghost"
                size="sm"
                className="lg:hidden"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <Input
                  type="text"
                  placeholder="Search flows..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="flex-1 mr-2"
                />
                <Button
                  onClick={() => navigate("/create-flow")}
                  disabled={limits.activeFlows <= totalItems}
                  className="bg-black text-white hover:bg-gray-500"
                >
                  <Plus className="w-4 h-4 mr-1" /> New
                </Button>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            <FlowList
              flows={flows}
              onSelectFlow={(flow) => {
                setSelectedFlow(flow);
                setIsMobileMenuOpen(false);
              }}
              selectedFlowId={selectedFlow?._id}
              onDeleteFlow={handleDelete}
            />
          </div>

          <div className="p-4 border-t mt-auto">
            <div className="flex lg:flex-row gap-2 items-center justify-between">
              <Select
                value={itemsPerPage.toString()}
                onValueChange={handleItemsPerPageChange}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Items per page" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="10">10 per page</SelectItem>
                  <SelectItem value="20">20 per page</SelectItem>
                  <SelectItem value="50">50 per page</SelectItem>
                </SelectContent>
              </Select>
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                paginate={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Flow Details Panel */}
      <div className="flex-1 lg:w-2/3 overflow-y-auto pt-0 -mx-6 lg:pt-4">
        {selectedFlow ? (
          <div className="py-4">
            <FlowDetails
              flow={selectedFlow}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onExecute={handleExecute}
              onClone={handleClone}
              limits={limits}
              totalItems={totalItems}
              credits={credits}
              user={user}
            />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <p className="text-muted-foreground">
              Select a flow to view details
            </p>
          </div>
        )}
      </div>

      {/* Mobile Menu Overlay 
      {!isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-[60] lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}*/}

      {/* Quick Create Flow Card */}
      {isCardMinimized ? (
        <Button
          className="hidden lg:block fixed bottom-4 right-4 p-2 z-50"
          onClick={toggleCardMinimized}
          variant="outline"
        >
          <Plus className="h-6 w-6" />
        </Button>
      ) : (
        <Card className="hidden lg:block fixed bottom-4 right-4 w-96 max-w-[90vw] z-50">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <h3 className="font-bold text-lg">Quick Create Flow</h3>
            <Button variant="ghost" size="icon" onClick={toggleCardMinimized}>
              <Minimize2 className="h-4 w-4" />
            </Button>
          </CardHeader>
          <CardContent>
            <Collapsible open={isJsonExpanded} onOpenChange={toggleJsonSection}>
              <CollapsibleTrigger asChild>
                <Button
                  variant="outline"
                  className="w-full flex justify-between items-center"
                >
                  Create Flow from JSON
                  {isJsonExpanded ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )}
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent className="mt-4">
                <Textarea
                  value={jsonInput}
                  onChange={(e) => setJsonInput(e.target.value)}
                  placeholder="Paste your flow JSON here"
                  className="w-full h-40 mb-2"
                />
                <Button onClick={handleJsonSubmit} className="w-full">
                  Create Flow
                </Button>
                {jsonError && (
                  <Alert variant="destructive" className="mt-2">
                    <AlertTitle>JSON Error</AlertTitle>
                    <AlertDescription>{jsonError}</AlertDescription>
                  </Alert>
                )}
                <a
                  href="/JSON_Flow_Example.txt"
                  download
                  className="text-blue-600 hover:text-blue-800 underline ml-2 text-sm mt-2"
                >
                  Download JSON example
                </a>
              </CollapsibleContent>
            </Collapsible>
          </CardContent>
        </Card>
      )}
      <AddContextModal
  isOpen={showContextModal}
  onClose={() => {
    setShowContextModal(false);
    setExecutingFlowId(null);
    setContextSteps([]);
  }}
  contextSteps={contextSteps}
  onSubmit={handleContextSubmit}
  isSubmitting={false}
/>
    </div>
  );
};

export default FlowManagement;
