// components/dashboard/ContentDashboard.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Button } from '../components/ui/button';
import { Loader2, Plus } from 'lucide-react';
import { fetchAllContentRequests, fetchUserPackageInfo } from '../slices/contentSlice';
import UsageStats from './ContentDashboardComponents/UsageStats';
import CreateContentModal from './ContentDashboardComponents/CreateContentModal';
import BlogPostCard from './ContentDashboardComponents/BlogPostCard';
import SocialPostCard from './ContentDashboardComponents/SocialPostCard';
import AIImageCard from './ContentDashboardComponents/AIImageCard';

// This completely redesigned component avoids any potential infinite loops
const ContentDashboard = () => {
    // Component state
    const [activeTab, setActiveTab] = useState('blogPosts');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [createContentType, setCreateContentType] = useState(null);
    
    // Use a ref to track if initial data is already fetched
    const dataFetchedRef = useRef(false);
    
    // Redux
    const dispatch = useDispatch();
    const { 
      blogPosts, 
      socialPosts, 
      aiImages, 
      packageInfo,
      isLoading 
    } = useSelector(state => state.content);
  
    // Fetch data only once when component mounts
    useEffect(() => {
      // Only fetch data if we haven't already
      if (dataFetchedRef.current === false) {
        dataFetchedRef.current = true;
        
        // Fetch package info first, then content
        dispatch(fetchUserPackageInfo())
          .then(() => dispatch(fetchAllContentRequests()))
          .catch(err => console.error("Error loading dashboard data:", err));
      }
    }, []);
  
    const handleCreateContent = (contentType) => {
      setCreateContentType(contentType);
      setIsCreateModalOpen(true);
    };
  
    // Loading state
    if (isLoading && !blogPosts.length && !socialPosts.length && !aiImages.length) {
      return (
        <div className="container mx-auto py-6">
          <div className="flex justify-center items-center h-64">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        </div>
      );
    }
  
    return (
      <div className="mx-auto p-1 lg:p-4 min-h-[80vh]">
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-xl font-bold">Content Settings</h1>
        </div>
  
        {packageInfo && <UsageStats packageInfo={packageInfo} />}
  
        <Tabs value={activeTab} onValueChange={setActiveTab} className="mt-6">
          <div className="flex flex-col lg:flex-row gap-4 justify-between items-center mb-4">
            <TabsList className="magical-gradient text-white">
              <TabsTrigger value="blogPosts">Blog Posts</TabsTrigger>
              <TabsTrigger value="socialPosts">Social Posts</TabsTrigger>
              <TabsTrigger value="aiImages">AI Images</TabsTrigger>
            </TabsList>
  
            {activeTab === 'blogPosts' && (
              <Button onClick={() => handleCreateContent('BlogPost')} disabled={!packageInfo?.usage?.blogPosts?.remaining}>
                <Plus className="h-4 w-4 mr-2" />
                New Blog Post
              </Button>
            )}
  
            {activeTab === 'socialPosts' && (
              <Button onClick={() => handleCreateContent('SocialPost')} disabled={!packageInfo?.usage?.socialPosts?.remaining}>
                <Plus className="h-4 w-4 mr-2" />
                New Social Post
              </Button>
            )}
  
            {activeTab === 'aiImages' && (
              <Button onClick={() => handleCreateContent('AIImage')} disabled={!packageInfo?.usage?.aiImages?.remaining}>
                <Plus className="h-4 w-4 mr-2" />
                New AI Image
              </Button>
            )}
          </div>
  
          <TabsContent value="blogPosts">
            {blogPosts.length > 0 ? (
              <div className="grid gap-4">
                {blogPosts.map(post => (
                  <BlogPostCard key={post._id} post={post} />
                ))}
              </div>
            ) : (
              <div className="py-8 text-center">
                <p className="text-muted-foreground">No blog posts found. Create your first blog post!</p>
              </div>
            )}
          </TabsContent>
  
          <TabsContent value="socialPosts">
            {socialPosts.length > 0 ? (
              <div className="grid gap-4">
                {socialPosts.map(post => (
                  <SocialPostCard key={post._id} post={post} />
                ))}
              </div>
            ) : (
              <div className="py-8 text-center">
                <p className="text-muted-foreground">No social posts found. Create your first social post!</p>
              </div>
            )}
          </TabsContent>
  
          <TabsContent value="aiImages">
            {aiImages.length > 0 ? (
              <div className="grid gap-4">
                {aiImages.map(image => (
                  <AIImageCard key={image._id} image={image} />
                ))}
              </div>
            ) : (
              <div className="py-8 text-center">
                <p className="text-muted-foreground">No AI images found. Create your first AI image!</p>
              </div>
            )}
          </TabsContent>
        </Tabs>
  
        {isCreateModalOpen && (
          <CreateContentModal
            isOpen={isCreateModalOpen}
            onClose={() => setIsCreateModalOpen(false)}
            contentType={createContentType}
          />
        )}
      </div>
    );
  };
  
  export default ContentDashboard;