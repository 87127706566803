import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { 
  Brain, 
  Copy, 
  BrainCircuit, 
  ClipboardList, 
  ChevronUp, 
  ArrowRight, 
  Info,
  ChevronDown 
} from "lucide-react";
import { useToast } from "../../../components/ui/use-toast";

const ImagePromptsSuggestion = ({
  imagePrompts,
  setAdditionalImageDetails,
  additionalImageDetails,
  setShowAdditionalImagePromptDetails,
  showAdditionalImagePromptDetails,
  onImagePromptClick,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [activePromptIndex, setActivePromptIndex] = useState(null);
  const [isProTipsExpanded, setIsProTipsExpanded] = useState(false);
  const [expandedPrompts, setExpandedPrompts] = useState({});

  const toggleProTips = () => {
    setIsProTipsExpanded(!isProTipsExpanded);
  };

  const togglePromptExpand = (index) => {
    setExpandedPrompts(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  // Function to truncate the prompt for collapsed view
  const truncatePrompt = (prompt, maxLength = 60) => {
    if (prompt.length <= maxLength) return prompt;
    return prompt.substring(0, maxLength) + "...";
  };

  if (!imagePrompts || imagePrompts.length === 0) return null;

  return (
    <div className="bg-white border border-gray-200 rounded shadow-md p-2 lg:p-6">
      <div className="text-base lg:text-xl font-semibold mb-4 flex items-center text-gray-900" title="Stuck on how to describe the image you envision? Our curated collection of image prompts is here to inspire your creativity and help you articulate your ideas effectively. Use these prompts to generate eye-catching visuals that align perfectly with your content goals and brand aesthetics!">
        <Brain className="h-7 w-7 mr-2 magical-gradient text-white p-1 rounded-full" />
        Perfect Image Prompts For You
      </div>
      <div className="text-sm text-muted-foreground mb-2" title="Click on a prompt to generate an image with it. You can also add any additional details or ideas in the text area that will display. Or, copy the prompt to your clipboard and use it in your favorite image generation tool by clicking on the copy icon.">
        <Info className="inline h-4 w-4 mr-1 text-black" />Click on a prompt to generate an image with it, or copy the prompt to your clipboard and use it in your favorite image generation tool.
      </div>
      <div className="space-y-2 lg:space-y-3">
        {imagePrompts.map((prompt, index) => (
          <React.Fragment key={index}>
            {activePromptIndex === index && showAdditionalImagePromptDetails && (
              <div className="mt-2 lg:max-w-4xl pb-2">
                <div className="flex items-end justify-between mb-2">
                  <div className="flex items-center gap-2 font-semibold text-gray-900 mb-2">
                    <ClipboardList className="h-5 w-5" />
                    Enter any additional details for your image prompt:
                  </div>
                  <Button
                    onClick={() => {
                      setShowAdditionalImagePromptDetails(false);
                      setActivePromptIndex(null);
                    }}
                    className="px-6 w-fit flex items-center justify-center ml-2 rounded text-gray-900 bg-gray-100 hover:bg-gray-200 border-none shadow-md"
                  >
                    <ChevronUp className="h-4 w-4" />
                  </Button>
                </div>
                <textarea
                  value={additionalImageDetails}
                  onChange={(e) => setAdditionalImageDetails(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      onImagePromptClick(e, prompt);
                      setShowAdditionalImagePromptDetails(false);
                      setActivePromptIndex(null);
                    }
                  }}
                  className="w-full h-28 p-4 border rounded-lg bg-white backdrop-blur-sm shadow-md
                        border-none focus:border-gray-900 focus:ring-2 
                        focus:ring-gray-900 focus:ring-opacity-50 
                        transition-all duration-200 ease-in-out
                        resize-none text-lg"
                  placeholder="Add any additional details, ideas, or notes here..."
                />
                <div className="mt-2 w-full flex items-center justify-end">
                  <Button
                    onClick={(e) => {
                      onImagePromptClick(e, prompt);
                      setShowAdditionalImagePromptDetails(false);
                      setActivePromptIndex(null);
                    }}
                    className="px-6 w-fit flex items-center justify-center ml-2 rounded text-white magical-gradient"
                  >
                    Submit Prompt
                    <ArrowRight className="h-4 w-4 ml-2" />
                  </Button>
                </div>
              </div>
            )}
            <div className="border border-gray-200 rounded-md shadow-md overflow-hidden">
              {/* Collapsed Prompt Header */}
              <div 
                className="flex items-center justify-between p-2 bg-gray-100 cursor-pointer"
                onClick={() => togglePromptExpand(index)}
              >
                <div className="flex-1 text-sm text-gray-900 truncate">
                  {truncatePrompt(prompt)}
                </div>
                <div className="flex items-center">
                  <Copy
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(prompt);
                      toast({
                        description: "Prompt copied to clipboard",
                        duration: 2000,
                      });
                    }}
                    className="h-4 w-4 opacity-50 hover:opacity-100 mx-2 hover:bg-gray-300 hover:text-black p-1 rounded"
                  />
                  {expandedPrompts[index] ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </div>
              </div>
              
              {/* Expanded Prompt Content */}
              {expandedPrompts[index] && (
                <div
                  onClick={() => {
                    setActivePromptIndex(index);
                    setShowAdditionalImagePromptDetails(true);
                  }}
                  className={`p-2 lg:p-3 cursor-pointer transition-colors duration-200 border-none flex justify-between items-center group text-sm ${
                    activePromptIndex === index
                      ? "bg-gray-900 text-gray-100"
                      : "bg-gray-100 text-black hover:bg-gray-900 hover:text-gray-100"
                  }`}
                >
                  <div className="w-11/12">{prompt}</div>
                  <div className="w-1/12">
                    <Copy
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(prompt);
                        toast({
                          description: "Prompt copied to clipboard",
                          duration: 2000,
                        });
                      }}
                      className="h-4 w-4 opacity-50 group-hover:opacity-100 mx-auto hover:bg-gray-600 hover:text-white"
                    />
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="mt-4">
        <Button
          variant="outline"
          className="flex items-center border-none shadow-md gap-2 bg-gray-900 hover:bg-gray-100 hover:text-gray-900 text-white"
          onClick={() => navigate("/generate-image")}
        >
          Go to the Image Generator
          <ArrowRight className="h-4 w-4 magical-gradient text-white rounded-full" />
        </Button>
      </div>
      <div className="mt-4 text-sm text-black mt-6 lg:mt-8">
        <Button
          variant="ghost"
          className="w-full p-0 flex items-center justify-between hover:bg-transparent"
          onClick={toggleProTips}
        >
          <h4 className="font-semibold text-md flex items-center gap-2">
            <BrainCircuit className="h-4 w-4" />
            Pro Follow Up Tips:
          </h4>
          {isProTipsExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
        <div 
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isProTipsExpanded ? "max-h-40 opacity-100 mt-2" : "max-h-0 opacity-0"
          }`}
        >
          <div className="space-y-1">
            <div>
              <span className="font-semibold">Tip:</span> Tailor the prompts to reflect your specific themes or messages, ensuring the generated images resonate with your audience.
            </div>
            <div>
              <span className="font-semibold">Tip:</span> Mix and match elements from different prompts to create unique and personalized visuals that stand out in your marketing efforts.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePromptsSuggestion;