import React, { useState, useRef, useEffect } from 'react';
import { Maximize, Minimize } from "lucide-react";
import { Dialog, DialogContent } from "./ui/dialog";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import { ChevronLeft, ChevronRight, X } from "lucide-react";

// IframeDisplay component handles the iframe display
const IframeDisplay = ({ 
  currentIframe, 
  onNext, 
  onPrevious, 
  hasNext, 
  hasPrevious 
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  // Update fullscreen state when exiting via Escape key
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative w-full max-w-4xl mx-auto">
      <div className="aspect-video w-full">
        <iframe
          src={currentIframe.url}
          className="w-full h-full rounded-lg border-0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      {/* Navigation Controls */}
      <div className="absolute top-4 right-4">
        <Button
          variant="secondary"
          size="icon"
          onClick={toggleFullscreen}
          className="opacity-80 hover:opacity-100"
        >
          {isFullscreen ? <Minimize size={20} /> : <Maximize size={20} />}
        </Button>
      </div>

      <div className="absolute top-1/2 -translate-y-1/2 w-full flex justify-between px-4 pointer-events-none">
        {hasPrevious && (
          <Button
            variant="secondary"
            size="icon"
            onClick={onPrevious}
            className="pointer-events-auto opacity-80 hover:opacity-100"
          >
            <ChevronLeft size={24} />
          </Button>
        )}
        
        {hasNext && (
          <Button
            variant="secondary"
            size="icon"
            onClick={onNext}
            className="pointer-events-auto opacity-80 hover:opacity-100"
          >
            <ChevronRight size={24} />
          </Button>
        )}
      </div>
    </div>
  );
};

// Main IframeModal component
const IframeModal = ({ isOpen, onClose, iframes }) => {
  const [currentIframeIndex, setCurrentIframeIndex] = useState(0);
  
  // Add safety checks for iframes prop
  const iframeArray = React.useMemo(() => {
    if (!iframes) return [];
    return Array.isArray(iframes) ? iframes : [iframes];
  }, [iframes]);

  // If there are no iframes, don't render the modal
  if (!iframeArray.length) {
    return null;
  }

  const handleNext = () => {
    if (currentIframeIndex < iframeArray.length - 1) {
      setCurrentIframeIndex(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIframeIndex > 0) {
      setCurrentIframeIndex(prev => prev - 1);
    }
  };

  // Ensure currentIframeIndex is within bounds
  const safeCurrentIndex = Math.min(currentIframeIndex, iframeArray.length - 1);
  const currentIframe = iframeArray[safeCurrentIndex];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-4xl z-[60]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {currentIframe.title}
          </h2>
          <Button
            variant="ghost"
            size="icon"
            onClick={onClose}
            className="hover:bg-red-100 hover:text-red-500"
          >
          </Button>
        </div>

        <IframeDisplay
          currentIframe={currentIframe}
          onNext={handleNext}
          onPrevious={handlePrevious}
          hasNext={safeCurrentIndex < iframeArray.length - 1}
          hasPrevious={safeCurrentIndex > 0}
        />

        {iframeArray.length > 1 && (
          <ScrollArea className="mt-4 h-32">
            <div className="grid grid-cols-1 gap-2">
              {iframeArray.map((iframe, index) => (
                <Button
                  key={index}
                  variant={index === safeCurrentIndex ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  onClick={() => setCurrentIframeIndex(index)}
                >
                  {iframe.title}
                </Button>
              ))}
            </div>
          </ScrollArea>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default IframeModal;