// src/components/AuthForm.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, register } from "../slices/authSlice";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import GoogleAuthButton from "./GoogleAuthButton";

const AuthForm = ({ onSuccess, isLoginDefault }) => {
  // State to toggle between login and registration modes.
  const [isLogin, setIsLogin] = useState(isLoginDefault);
  const [error, setError] = useState("");

  // Login form state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Registration form state
  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handler for login submission.
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await dispatch(login({ email, password })).unwrap();
      // If an onSuccess callback is passed (e.g., to close a modal), call it.
      if (onSuccess) {
        onSuccess();
      } else {
        navigate("/dashboard");
      }
    } catch (err) {
      alert("Invalid email or password");
      setError("Invalid email or password");
      setPassword("");
    }
  };

  // Handler for registration submission.
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (registrationData.password !== registrationData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await dispatch(register(registrationData)).unwrap();
      if (onSuccess) {
        onSuccess();
      } else {
        navigate("/dashboard");
      }
    } catch (err) {
      setError(err.message || "Registration failed");
    }
  };

  return (
    <div className="w-full max-w-sm">
      {error && (
        <div className="mb-4 p-3 text-sm text-red-500 bg-red-50 border border-red-200 rounded-md">
          {error}
        </div>
      )}
      {isLogin ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold">Sign in</h2>
            <button
              onClick={() => {
                setIsLogin(false);
                setError("");
              }}
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              Register
            </button>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            Enter your credentials below to access your account
          </p>
          <form onSubmit={handleLoginSubmit} className="mt-8 space-y-6">
            <div className="space-y-4">
              <Input
                id="login-email"
                name="email"
                type="email"
                placeholder="Email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                id="login-password"
                name="password"
                type="password"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                className="w-full bg-black text-white hover:bg-gray-800"
              >
                Sign In
              </Button>
              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>
              <GoogleAuthButton
                onClick={() => {
                  window.location.href = `${
                    process.env.REACT_APP_SOCKET_URL || "http://localhost:5000"
                  }/auth/google`;
                }}
                text="Sign in with Google"
              />
              <div className="text-right mt-2">
                <a
                  href="/forgot-password"
                  className="text-blue-500 hover:underline text-sm"
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          </form>
          <p className="mt-6 text-center text-sm text-gray-500">
            By signing in, you agree to our{" "}
            <a
              href="/terms-of-service"
              className="font-medium text-gray-900 hover:underline"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              className="font-medium text-gray-900 hover:underline"
            >
              Privacy Policy
            </a>
            .
          </p>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold">Register</h2>
            <button
              onClick={() => {
                setIsLogin(true);
                setError("");
              }}
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              Sign in
            </button>
          </div>
          <form onSubmit={handleRegisterSubmit} className="mt-8 space-y-6">
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <Input
                  type="text"
                  placeholder="First Name"
                  value={registrationData.firstName}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                  required
                />
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={registrationData.lastName}
                  onChange={(e) =>
                    setRegistrationData((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <Input
                type="email"
                placeholder="Email address"
                value={registrationData.email}
                onChange={(e) =>
                  setRegistrationData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                required
              />
              {registrationData.password &&
                registrationData.password.length < 8 && (
                  <div className="text-sm text-red-500">
                    Passwords must contain at least 8 characters
                  </div>
                )}
              <Input
                type="password"
                placeholder="Password"
                value={registrationData.password}
                onChange={(e) =>
                  setRegistrationData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                required
                className={
                  registrationData.password &&
                  registrationData.password.length < 8
                    ? "border-red-500 focus:border-red-500"
                    : ""
                }
              />
              <Input
                type="password"
                placeholder="Confirm Password"
                value={registrationData.confirmPassword}
                onChange={(e) =>
                  setRegistrationData((prev) => ({
                    ...prev,
                    confirmPassword: e.target.value,
                  }))
                }
                required
              />
              {registrationData.password &&
                registrationData.confirmPassword &&
                registrationData.password !== registrationData.confirmPassword && (
                  <div className="text-sm text-red-500">
                    Passwords do not match
                  </div>
                )}
              <Button
                type="submit"
                className="w-full bg-black text-white hover:bg-gray-800"
                disabled={
                  !registrationData.password ||
                  registrationData.password.length < 8 ||
                  !registrationData.confirmPassword ||
                  registrationData.password !== registrationData.confirmPassword
                }
              >
                Register
              </Button>
              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>
              <GoogleAuthButton
                onClick={() => {
                  window.location.href = `${
                    process.env.REACT_APP_SOCKET_URL || "http://localhost:5000"
                  }/auth/google`;
                }}
                text="Sign up with Google"
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default AuthForm;