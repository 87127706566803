import React, { useState } from "react";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Copy, Check, Trash2, Clock, Calendar, Tag, FileText, ArrowRight } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import AIImageDisplay from "../FlowFormComponents/AIImageDisplay";
import TeamShareButton from "../../components/TeamShare/TeamShareButton";
import ShareViaEmailButton from "../../components/ShareViaEmailButton";

// Utility function to stringify content
const stringifyContent = (content) => {
  if (typeof content === "string") {
    return content;
  }
  if (typeof content === "object") {
    return JSON.stringify(content, null, 2);
  }
  return String(content);
};

// Markdown content component
const MarkdownContent = ({ content }) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    components={{
      code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        return !inline && match ? (
          <SyntaxHighlighter
            style={vscDarkPlus}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, "")}
          </SyntaxHighlighter>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
    }}
    className="markdown-content"
  >
    {stringifyContent(content)}
  </ReactMarkdown>
);

const AssetDetail = ({
  asset,
  assetType,
  onDeleteAsset,
  onUpdateStatus,
  onClose
}) => {
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");

  if (!asset) {
    return (
      <div className="flex h-full items-center justify-center">
        <p className="text-gray-500">Select an asset to view details</p>
      </div>
    );
  }

  const handleCopy = async () => {
    const contentToCopy = asset.content || asset.text || asset.message || "";
    try {
      await navigator.clipboard.writeText(contentToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Determine the correct status badge color
  const getStatusBadge = () => {
    const status = asset.status || "draft";
    return (
      <Badge
        variant={status === "published" ? "default" : "outline"}
        className="ml-2 cursor-pointer"
        onClick={() => onUpdateStatus(status === "published" ? "draft" : "published")}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Badge>
    );
  };

  // Render asset metadata based on asset type
  const renderAssetMetadata = () => {
    return (
      <div className="grid grid-cols-2 gap-4 mb-4">
        {/* Created Date */}
        <div className="flex items-center text-sm">
          <Calendar className="h-4 w-4 mr-2 text-gray-500" />
          <div>
            <p className="font-medium">Created</p>
            <p className="text-gray-500">{formatDate(asset.date)}</p>
          </div>
        </div>
        
        {/* Last Updated */}
        <div className="flex items-center text-sm">
          <Clock className="h-4 w-4 mr-2 text-gray-500" />
          <div>
            <p className="font-medium">Last Updated</p>
            <p className="text-gray-500">{formatDate(asset.statusChangeDate)}</p>
          </div>
        </div>
        
        {/* Categories (if applicable) */}
        {(asset.categories || (asset.course && asset.course.course_categories)) && (
          <div className="flex items-start text-sm col-span-2">
            <Tag className="h-4 w-4 mr-2 mt-0.5 text-gray-500" />
            <div>
              <p className="font-medium">Categories</p>
              <p className="text-gray-500">
                {asset.categories?.join(", ") || 
                 asset.course?.course_categories?.join(", ") || 
                 "None"}
              </p>
            </div>
          </div>
        )}
        
        {/* Tags (if applicable) */}
        {(asset.tags || (asset.course && asset.course.course_tags) || asset.hashtags) && (
          <div className="flex items-start text-sm col-span-2">
            <Tag className="h-4 w-4 mr-2 mt-0.5 text-gray-500" />
            <div>
              <p className="font-medium">Tags</p>
              <p className="text-gray-500">
                {asset.tags?.join(", ") || 
                 asset.hashtags?.join(", ") || 
                 asset.course?.course_tags?.join(", ") || 
                 "None"}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Render asset-specific content
  const renderAssetSpecificContent = () => {
    switch (assetType) {
      case "blog-posts":
        return (
          <>
            {asset.excerpt && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Excerpt</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.excerpt}
                </p>
              </div>
            )}
            
            {/* Share functionality */}
            <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <TeamShareButton 
                assetId={asset._id}
                modelName="SavedBlogPost"
                currentTeams={asset?.team ?? []}
              />
              <ShareViaEmailButton 
                content={asset.content || asset.text || asset.message}
                title="Share This Content"
                iconOnly={false}
              />
            </div>
          </>
        );
      
      case "products":
        return (
          <>
            {asset.short_description && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Short Description</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.short_description}
                </p>
              </div>
            )}
            
            {asset.images && asset.images.length > 0 && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-2">Product Images</h3>
                <div className="grid lg:grid-cols-2 gap-4">
                  {asset.images.map((url, index) => (
                    <AIImageDisplay key={index} imageUrl={url} />
                  ))}
                </div>
              </div>
            )}
            
            {/* Share functionality */}
            <div className="mt-4">
              <TeamShareButton 
                assetId={asset._id}
                modelName="SavedGeneralText"
                currentTeams={asset?.team ?? []}
              />
            </div>
          </>
        );
      
      case "social-posts":
        return (
          <>
            {asset.platform && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Platform</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.platform}
                </p>
              </div>
            )}
            
            {asset.mentions && asset.mentions.length > 0 && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Mentions</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.mentions.join(", ")}
                </p>
              </div>
            )}
            
            {asset.scheduledDate && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Scheduled Date</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {formatDate(asset.scheduledDate)}
                </p>
              </div>
            )}
            
            {asset.mediaUrls && asset.mediaUrls.length > 0 && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-2">Media</h3>
                <div className="grid lg:grid-cols-2 gap-4">
                  {asset.mediaUrls.map((url, index) => (
                    <AIImageDisplay key={index} imageUrl={url} />
                  ))}
                </div>
              </div>
            )}
            
            {/* Share functionality */}
            <div className="mt-4">
              <TeamShareButton 
                assetId={asset._id}
                modelName="SavedGeneralText"
                currentTeams={asset?.team ?? []}
              />
            </div>
          </>
        );
      
      case "general-images":
        return (
          <>
            <div className="mb-4">
              <AIImageDisplay imageUrl={asset.imageUrl} />
            </div>
            
            {/* Share functionality */}
            <div className="mt-4">
              <TeamShareButton 
                assetId={asset._id}
                modelName="SavedGeneralText"
                currentTeams={asset?.team ?? []}
              />
            </div>
          </>
        );
      
      case "courses":
        return (
          <>
            {asset.course && asset.course.course_level && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Course Level</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.course.course_level}
                </p>
              </div>
            )}
            
            {asset.topics && asset.topics.length > 0 && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Topics</h3>
                <ul className="list-disc list-inside text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.topics.map((topic, index) => (
                    <li key={index}>{topic.topic_title}</li>
                  ))}
                </ul>
              </div>
            )}
          </>
        );
      
      case "notifications":
        return (
          <>
            {asset.type && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Notification Type</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md capitalize">
                  {asset.type}
                </p>
              </div>
            )}
            
            {asset.relatedTo && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Related To</h3>
                <p className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  {asset.relatedTo} (ID: {asset.relatedId})
                </p>
              </div>
            )}
          </>
        );
      
      case "general-assets":
      default:
        return (
          <>
            {/* Note (if applicable) */}
            {asset.note && (
              <div className="mb-4">
                <h3 className="text-sm font-medium mb-1">Note</h3>
                <div className="text-sm bg-gray-50 dark:bg-gray-800 p-3 rounded-md">
                  <MarkdownContent content={asset.note} />
                </div>
              </div>
            )}
            
            {/* Share functionality */}
            <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center gap-2">
              <TeamShareButton 
                assetId={asset._id}
                modelName="SavedGeneralText"
                currentTeams={asset?.team ?? []}
              />
              <ShareViaEmailButton 
                content={asset.content || asset.text || asset.message}
                title="Share This Content"
                iconOnly={false}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className="h-full flex flex-col bg-gray-100 p-4">
        <div className="w-full flex justify-end lg:px-4">
        <div
            className="border-none flex items-center gap-2 bg-gray-100 text-gray-900 hover:text-gray-100 hover:bg-gray-900 cursor-pointer py-1 px-3 rounded-lg"
            onClick={onClose}
            >
            hide details<ArrowRight className="h-4 w-4" />
            </div>
        </div>
      <div className="p-0 pt-1 lg:p-4 lg:pt-0 flex flex-col lg:flex-row justify-between items-center gap-4">
        
        <div className="flex items-center">
          <h2 className="text-md lg:text-xl leading-tight font-bold flex-wrap max-w-xs" title={asset.title || asset.name || (asset.course && asset.course.post_title) || "Untitled"}>
            {asset.title || asset.name || (asset.course && asset.course.post_title) || "Untitled"}
          </h2>
          {getStatusBadge()}
        </div>
        
        <div className="flex items-center">
          {(asset.content || asset.text || asset.message) && (
            <Button
              onClick={handleCopy}
              variant="outline"
              size="sm"
              className="border-none shadow-md mr-2 h-9"
              title="Copy content"
            >
              {copied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
              <span className="ml-2 hidden sm:inline">
                {copied ? "Copied" : "Copy"}
              </span>
            </Button>
          )}
          
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="outline" size="sm" className="border-none shadow-md h-9 text-red-500 hover:text-red-600">
                <Trash2 className="h-4 w-4 lg:mr-2" />
                <span className="hidden sm:inline">Delete</span>
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete Asset</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete this asset? This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={() => onDeleteAsset(asset._id)}>
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
      
      <div className="p-4 overflow-y-auto flex-1">
        <Tabs defaultValue="overview" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-4 magical-gradient text-white w-full lg:w-fit">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="content">Content</TabsTrigger>
            {asset.note && <TabsTrigger value="notes">Notes</TabsTrigger>}
          </TabsList>
          
          <TabsContent value="overview" className="space-y-4">
            {renderAssetMetadata()}
            {renderAssetSpecificContent()}
          </TabsContent>
          
          <TabsContent value="content">
            <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
              {assetType === "general-images" ? (
                <div className="flex justify-center">
                  <AIImageDisplay imageUrl={asset.imageUrl} />
                </div>
              ) : (
                <MarkdownContent 
                  content={
                    asset.content || 
                    asset.text || 
                    asset.message || 
                    asset.description ||
                    (asset.course && asset.course.post_content) ||
                    "No content available"
                  } 
                />
              )}
            </div>
          </TabsContent>
          
          {asset.note && (
            <TabsContent value="notes">
              <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
                <MarkdownContent content={asset.note} />
              </div>
            </TabsContent>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default AssetDetail;