import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchSavedAssets = createAsyncThunk(
  'savedAssets/fetchSavedAssets',
  async (assetType, { rejectWithValue }) => {
    try {
      const response = await api.fetchSavedAssets(assetType);
     
      // Sort the data by updatedAt, date_created, or date in descending order
      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.updatedAt || a.date_created || a.date || 0);
        const dateB = new Date(b.updatedAt || b.date_created || b.date || 0);
        return dateB - dateA; // Descending order
      });

      // Add assetType to each asset for easier filtering
      const dataWithType = sortedData.map(asset => ({
        ...asset,
        assetType
      }));
     
      return dataWithType;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteSavedAsset = createAsyncThunk(
  'savedAssets/deleteSavedAsset',
  async ({ assetType, id }, { rejectWithValue }) => {
    try {
      await api.deleteSavedAsset(assetType, id);
      return { id, assetType };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// New async thunk for updating asset status
export const updateAssetStatus = createAsyncThunk(
  'savedAssets/updateAssetStatus',
  async ({ assetType, id, status }, { rejectWithValue }) => {
    try {
      const response = await api.updateAssetStatus(assetType, id, status);
      return {
        id,
        assetType,
        status,
        statusChangeDate: new Date().toISOString()
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// New async thunk for marking an asset as read
export const markAssetAsRead = createAsyncThunk(
  'savedAssets/markAssetAsRead',
  async ({ assetType, id }, { rejectWithValue }) => {
    try {
      const response = await api.markAssetAsRead(assetType, id);
      return { id, assetType };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const savedAssetsSlice = createSlice({
  name: 'savedAssets',
  initialState: {
    assets: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // Add a reducer for local-only updates if needed
    markAsReadLocally: (state, action) => {
      const { id } = action.payload;
      const assetIndex = state.assets.findIndex(asset => asset._id === id);
      if (assetIndex !== -1) {
        state.assets[assetIndex].isRead = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedAssets.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSavedAssets.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Merge new assets with existing ones, replacing duplicates
        const existingIds = new Set(state.assets.map(asset => asset._id));
        const newAssets = action.payload.filter(asset => !existingIds.has(asset._id));
        state.assets = [...state.assets, ...newAssets];
      })
      .addCase(fetchSavedAssets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteSavedAsset.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteSavedAsset.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assets = state.assets.filter(asset => asset._id !== action.payload.id);
      })
      .addCase(deleteSavedAsset.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handle updateAssetStatus
      .addCase(updateAssetStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateAssetStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { id, status, statusChangeDate } = action.payload;
        const assetIndex = state.assets.findIndex(asset => asset._id === id);
        if (assetIndex !== -1) {
          state.assets[assetIndex].status = status;
          state.assets[assetIndex].statusChangeDate = statusChangeDate;
        }
      })
      .addCase(updateAssetStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Handle markAssetAsRead
      .addCase(markAssetAsRead.pending, (state) => {
        // No need to change global loading state for read status updates
      })
      .addCase(markAssetAsRead.fulfilled, (state, action) => {
        const { id } = action.payload;
        const assetIndex = state.assets.findIndex(asset => asset._id === id);
        if (assetIndex !== -1) {
          state.assets[assetIndex].isRead = true;
        }
      })
      .addCase(markAssetAsRead.rejected, (state, action) => {
        // Silently fail or handle specific error cases
        console.error("Failed to mark asset as read:", action.payload);
      });
  },
});

export const { markAsReadLocally } = savedAssetsSlice.actions;
export default savedAssetsSlice.reducer;