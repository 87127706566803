import React, { useEffect, useState } from 'react';

const NavigationDots = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const sectionIndex = parseInt(entry.target.getAttribute('data-section-index'));
          setActiveSection(sectionIndex);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((_, index) => {
      const element = document.querySelector(`[data-section-index="${index}"]`);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [sections]);

  const scrollToSection = (index) => {
    const element = document.querySelector(`[data-section-index="${index}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Array of section names for labels
  const sectionNames = ['Login', 'Pricing', 'Benefits', 'FAQ', 'Connect'];

  return (
    <div className="fixed right-6 top-1/2 transform -translate-y-1/2 z-50 hidden lg:block">
      <div className="flex flex-col space-y-6">
        {sections.map((section, index) => (
          <div key={index} className="flex items-center justify-end gap-2 group cursor-pointer"
               onClick={() => scrollToSection(index)}>
            {/* Label - appears on hover */}
            <span className="text-sm text-gray-500 opacity-50 group-hover:opacity-100 transition-opacity duration-200">
              {sectionNames[index]}
            </span>
            
            {/* Dot */}
            <div
              className={`w-3 h-3 rounded-full transition-all duration-300 ${
                activeSection === index
                  ? 'bg-black scale-125'
                  : 'bg-gray-300 hover:bg-gray-400'
              }`}
              aria-label={`Scroll to ${sectionNames[index]}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavigationDots;