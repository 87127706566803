import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { ArrowRight } from "lucide-react";

const AssetTypeCard = ({ assetType, count, unreadCount, onClick }) => {
  return (
    <Card 
      className="cursor-pointer shadow-md hover:bg-gray-100 transition-all" 
      onClick={onClick}
    >
      <CardContent className="p-6 flex justify-between items-center">
        <div className="flex items-center">
          <div className="p-3 rounded-full bg-gray-900 text-gray-100">
            {assetType.icon}
          </div>
          <div className="ml-4">
            <h3 className="text-lg font-semibold">{assetType.name}</h3>
            <div className="flex items-center">
              <p className="text-sm text-gray-500">
                {count} {count === 1 ? "item" : "items"}
              </p>
              {unreadCount > 0 && (
                <span className="ml-2 magical-gradient shadow-md text-white text-xs font-medium px-2 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  {unreadCount} unread
                </span>
              )}
            </div>
          </div>
        </div>
        <ArrowRight className="text-gray-400 h-5 w-5" />
      </CardContent>
    </Card>
  );
};

export default AssetTypeCard;