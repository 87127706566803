import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  iframes: null
};

const iframeModalSlice = createSlice({
  name: 'iframeModal',
  initialState,
  reducers: {
    openIframeModal: (state, action) => {
      state.isOpen = true;
      state.iframes = action.payload;
    },
    closeIframeModal: (state) => {
      state.isOpen = false;
      state.iframes = null;
    }
  }
});

export const { openIframeModal, closeIframeModal } = iframeModalSlice.actions;
export default iframeModalSlice.reducer;