// redux/slices/contentSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  fetchAllContentRequests as fetchAllContentRequestsAPI,
  fetchContentRequestsByType as fetchContentRequestsByTypeAPI,
  fetchUserPackageInfo as fetchUserPackageInfoAPI,
  createBlogPostRequest as createBlogPostRequestAPI,
  createSocialPostRequest as createSocialPostRequestAPI,
  createAIImageRequest as createAIImageRequestAPI,
  updateContentRequest as updateContentRequestAPI,
  deleteContentRequest as deleteContentRequestAPI
} from '../services/api';

// Request status tracking
let isContentRequestsLoading = false;
let isUserPackageInfoLoading = false;

// Async thunks
export const fetchAllContentRequests = createAsyncThunk(
  'content/fetchAllContentRequests',
  async (_, { rejectWithValue }) => {
    // Prevent multiple simultaneous requests
    if (isContentRequestsLoading) {
      return rejectWithValue('Request already in progress');
    }
    
    try {
      isContentRequestsLoading = true;
      const data = await fetchAllContentRequestsAPI();
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    } finally {
      isContentRequestsLoading = false;
    }
  }
);

export const fetchContentRequestsByType = createAsyncThunk(
  'content/fetchContentRequestsByType',
  async (contentType, { rejectWithValue, getState }) => {
    // Skip if already loading or if we already have content of this type
    const state = getState();
    const hasContent = contentType === 'BlogPost' ? state.content.blogPosts.length > 0 :
                       contentType === 'SocialPost' ? state.content.socialPosts.length > 0 :
                       contentType === 'AIImage' ? state.content.aiImages.length > 0 : false;
    
    if (isContentRequestsLoading || hasContent) {
      return rejectWithValue('Request skipped - already loaded or in progress');
    }
    
    try {
      isContentRequestsLoading = true;
      const data = await fetchContentRequestsByTypeAPI(contentType);
      return { contentType, data };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    } finally {
      isContentRequestsLoading = false;
    }
  }
);

export const fetchUserPackageInfo = createAsyncThunk(
  'content/fetchUserPackageInfo',
  async (_, { rejectWithValue }) => {
    // Prevent multiple simultaneous requests
    if (isUserPackageInfoLoading) {
      return rejectWithValue('Request already in progress');
    }
    
    try {
      isUserPackageInfoLoading = true;
      const data = await fetchUserPackageInfoAPI();
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    } finally {
      isUserPackageInfoLoading = false;
    }
  }
);

export const createBlogPostRequest = createAsyncThunk(
  'content/createBlogPostRequest',
  async (blogData, { rejectWithValue }) => {
    try {
      const data = await createBlogPostRequestAPI(blogData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createSocialPostRequest = createAsyncThunk(
  'content/createSocialPostRequest',
  async (socialData, { rejectWithValue }) => {
    try {
      const data = await createSocialPostRequestAPI(socialData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createAIImageRequest = createAsyncThunk(
  'content/createAIImageRequest',
  async (imageData, { rejectWithValue }) => {
    try {
      const data = await createAIImageRequestAPI(imageData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateContentRequest = createAsyncThunk(
  'content/updateContentRequest',
  async ({ id, contentType, data }, { rejectWithValue }) => {
    try {
      const responseData = await updateContentRequestAPI(id, data);
      return { contentType, data: responseData };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteContentRequest = createAsyncThunk(
  'content/deleteContentRequest',
  async ({ id, contentType }, { rejectWithValue }) => {
    try {
      await deleteContentRequestAPI(id);
      return { id, contentType };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Initial state
const initialState = {
  allContentRequests: [],
  blogPosts: [],
  socialPosts: [],
  aiImages: [],
  packageInfo: null,
  isLoading: false,
  error: null,
  lastFetchTime: null
};

// Create the slice
const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    resetLoadingState: (state) => {
      state.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch all content requests
      .addCase(fetchAllContentRequests.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllContentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allContentRequests = action.payload;
        state.lastFetchTime = Date.now();
        
        // Categorize content by type
        state.blogPosts = action.payload.filter(item => item.contentType === 'BlogPost');
        state.socialPosts = action.payload.filter(item => item.contentType === 'SocialPost');
        state.aiImages = action.payload.filter(item => item.contentType === 'AIImage');
      })
      .addCase(fetchAllContentRequests.rejected, (state, action) => {
        // Only set loading to false if this is not a deliberate skip
        if (action.payload !== 'Request already in progress') {
          state.isLoading = false;
          state.error = action.payload || 'Failed to fetch content requests';
        }
      })
      
      // Fetch content requests by type
      .addCase(fetchContentRequestsByType.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchContentRequestsByType.fulfilled, (state, action) => {
        state.isLoading = false;
        
        // Update the appropriate content array based on type
        switch (action.payload.contentType) {
          case 'BlogPost':
            state.blogPosts = action.payload.data;
            break;
          case 'SocialPost':
            state.socialPosts = action.payload.data;
            break;
          case 'AIImage':
            state.aiImages = action.payload.data;
            break;
          default:
            break;
        }
      })
      .addCase(fetchContentRequestsByType.rejected, (state, action) => {
        // Only set loading to false if this is not a deliberate skip
        if (action.payload !== 'Request skipped - already loaded or in progress' && 
            action.payload !== 'Request already in progress') {
          state.isLoading = false;
          state.error = action.payload || 'Failed to fetch content requests';
        }
      })
      
      // Fetch user package info
      .addCase(fetchUserPackageInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserPackageInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.packageInfo = action.payload;
      })
      .addCase(fetchUserPackageInfo.rejected, (state, action) => {
        // Only set loading to false if this is not a deliberate skip
        if (action.payload !== 'Request already in progress') {
          state.isLoading = false;
          state.error = action.payload || 'Failed to fetch user package info';
        }
      })
      
      // Create blog post request
      .addCase(createBlogPostRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createBlogPostRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.blogPosts.unshift(action.payload);
        state.allContentRequests.unshift(action.payload);
        
        // Update package info
        if (state.packageInfo) {
          state.packageInfo.usage.blogPosts.used += 1;
          state.packageInfo.usage.blogPosts.remaining -= 1;
        }
      })
      .addCase(createBlogPostRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to create blog post request';
      })
      
      // Create social post request
      .addCase(createSocialPostRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSocialPostRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.socialPosts.unshift(action.payload);
        state.allContentRequests.unshift(action.payload);
        
        // Update package info
        if (state.packageInfo) {
          state.packageInfo.usage.socialPosts.used += 1;
          state.packageInfo.usage.socialPosts.remaining -= 1;
        }
      })
      .addCase(createSocialPostRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to create social post request';
      })
      
      // Create AI image request
      .addCase(createAIImageRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAIImageRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aiImages.unshift(action.payload);
        state.allContentRequests.unshift(action.payload);
        
        // Update package info
        if (state.packageInfo) {
          state.packageInfo.usage.aiImages.used += 1;
          state.packageInfo.usage.aiImages.remaining -= 1;
        }
      })
      .addCase(createAIImageRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to create AI image request';
      })
      
      // Update content request
      .addCase(updateContentRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateContentRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        const { contentType, data } = action.payload;
        
        // Update the specific content array
        switch (contentType) {
          case 'BlogPost':
            state.blogPosts = state.blogPosts.map(post => 
              post._id === data._id ? data : post
            );
            break;
          case 'SocialPost':
            state.socialPosts = state.socialPosts.map(post => 
              post._id === data._id ? data : post
            );
            break;
          case 'AIImage':
            state.aiImages = state.aiImages.map(image => 
              image._id === data._id ? data : image
            );
            break;
          default:
            break;
        }
        
        // Also update in the all content requests array
        state.allContentRequests = state.allContentRequests.map(content => 
          content._id === data._id ? data : content
        );
      })
      .addCase(updateContentRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to update content request';
      })
      
      // Delete content request
      .addCase(deleteContentRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteContentRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        const { id, contentType } = action.payload;
        
        // Remove from the specific content array
        switch (contentType) {
          case 'BlogPost':
            state.blogPosts = state.blogPosts.filter(post => post._id !== id);
            
            // Update package info
            if (state.packageInfo) {
              state.packageInfo.usage.blogPosts.used -= 1;
              state.packageInfo.usage.blogPosts.remaining += 1;
            }
            break;
          case 'SocialPost':
            state.socialPosts = state.socialPosts.filter(post => post._id !== id);
            
            // Update package info
            if (state.packageInfo) {
              state.packageInfo.usage.socialPosts.used -= 1;
              state.packageInfo.usage.socialPosts.remaining += 1;
            }
            break;
          case 'AIImage':
            state.aiImages = state.aiImages.filter(image => image._id !== id);
            
            // Update package info
            if (state.packageInfo) {
              state.packageInfo.usage.aiImages.used -= 1;
              state.packageInfo.usage.aiImages.remaining += 1;
            }
            break;
          default:
            break;
        }
        
        // Also remove from the all content requests array
        state.allContentRequests = state.allContentRequests.filter(content => content._id !== id);
      })
      .addCase(deleteContentRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to delete content request';
      });
  }
});

export const { resetError, resetLoadingState } = contentSlice.actions;
export default contentSlice.reducer;