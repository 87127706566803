import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchItems } from "../../../slices/genericItemsSlice";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { Card, CardContent } from "../../../components/ui/card";
import { Switch } from "../../../components/ui/switch";
import { Input } from "../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { HexColorPicker, HexColorInput } from "react-colorful";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import TemplatePreview from "./TemplatePreview";

const UploadPdfFileFields = ({ step, handleFieldChange, index }) => {
  const dispatch = useDispatch();
  const pdfTemplates =
    useSelector((state) => state.genericItems.items["PdfTemplate"]) || [];
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [localPdfData, setLocalPdfData] = useState(step.pdfData || {});
  const [templateTypes, setTemplateTypes] = useState([]);

  useEffect(() => {
    dispatch(fetchItems("PdfTemplate"));
  }, [dispatch]);

  useEffect(() => {
    if (pdfTemplates.length > 0) {
      const types = [
        ...new Set(pdfTemplates.map((template) => template.templateType)),
      ].sort();
      setTemplateTypes(types);
    }
  }, [pdfTemplates]);

  useEffect(() => {
    //console.log("Step changed:", step);
    setLocalPdfData(step.pdfData || {});
  }, [step]);

  useEffect(() => {
    if (step.useHtmlTemplate && step.templateId && pdfTemplates.length > 0) {
      const template = pdfTemplates.find((t) => t._id === step.templateId);
      setSelectedTemplate(template);

      if (template) {
        const updatedPdfData = { ...localPdfData };
        template.fields.forEach((field) => {
          if (!updatedPdfData.hasOwnProperty(field.name)) {
            updatedPdfData[field.name] = field.type === "array" ? [] : "";
          }
        });
        setLocalPdfData(updatedPdfData);
        handleFieldChange("pdfData", updatedPdfData);
      }
    }
  }, [pdfTemplates, step.templateId, step.useHtmlTemplate]);

  const updatePdfData = (fieldName, value) => {
    const updatedPdfData = { ...localPdfData, [fieldName]: value };
    setLocalPdfData(updatedPdfData);
    handleFieldChange("pdfData", updatedPdfData);
    console.log("Updated pdfData:", updatedPdfData);
  };

  const presetColors = [
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ffff00",
    "#ff00ff",
    "#00ffff",
    "#000000",
    "#ffffff",
    "#808080",
    "#800000",
    "#008000",
    "#000080",
    "#006994",
    "#FF5733",
    "#C70039",
    "#900C3F",
    "#581845",
    "#FFC300",
    "#FF5733",
    "#DAF7A6",
    "#900C3F",
    "#2ECC71",
    "#3498DB",
    "#E74C3C",
    "#F39C12",
    "#8E44AD",
    "#1ABC9C",
    "#34495E",
    "#D35400",
    "#9B59B6",
    "#16A085",
    "#27AE60",
    "#2980B9",
    "#F4D03F",
    "#2E4053",
    "#7DCEA0",
    "#85C1E9",
  ];

  const colorPresets = {
    standard: [
      "#FFFFFF", // white
      "#000000", // black
      "#808080", // gray
      "#FF5733", // vibrant orange-red
      "#33FF57", // fresh green
      "#3357FF", // bold blue
      "#FFC300", // warm yellow
      "#FF33FF", // vivid pink
      "#00FFFF", // cyan
      "#FF0000", // red
      "#00FF00", // green
      "#0000FF", // blue
      "#FF8C00", // dark orange
      "#8A2BE2", // blue violet
      "#FF69B4", // hot pink
      "#FF6347", // tomato
      "#4682B4", // steel blue
      "#20B2AA", // light sea green
      "#7FFF00", // chartreuse
      "#FFD700", // gold
      "#DAA520", // goldenrod
      "#BA55D3", // medium orchid
      "#00FA9A", // medium spring green
      "#FF4500", // orange red
      "#ebebd3", // light yellow
      "#f0f0f0", // light gray
      "#ee964b", // light orange
      "#dab785", // light brown
      "#031d44", // dark blue
      "#d7263d", // dark red
      "#e01a4f", // pretty pink
      "#6610f2", // dark purple
      "#2a9d8f", // dark green
      "#7e7f9a", // dark gray
      "#3fa7d6", // dark teal
    ],
    awesome: [
      "#006994",
      "#D3A210",
      "#0F2239",
      "#FF2071",
      "#FFA400",
      "#FF6347",
      "#FFD700",
      "#FF7F50",
      "#FF1493",
      "#FFDAB9",
      "#8B0000",
      "#FF69B4",
      "#DB7093",
      "#FFA07A",
      "#FF8C00",
      "#FF4500",
      "#DAA520",
      "#CD5C5C",
    ],
    modern: [
      "#2C3E50",
      "#E74C3C",
      "#ECF0F1",
      "#3498DB",
      "#2980B9",
      "#1ABC9C",
      "#16A085",
      "#27AE60",
      "#2ECC71",
      "#F1C40F",
      "#F39C12",
      "#E67E22",
      "#6C5B7B",
      "#355C7D",
      "#C06C84",
      "#F67280",
      "#99B898",
      "#FF6B6B",
      "#4ECDC4",
      "#556270",
      "#C44D58",
      "#D8A7B1",
      "#F3FFBD",
      "#FF1654",
      "#247BA0",
      "#FFE066",
      "#70C1B3",
    ],
    neon: [
      "#FF00FF",
      "#00FFFF",
      "#00FF00",
      "#FFFF00",
      "#FF0000",
      "#FF00CC",
      "#00CCFF",
      "#00FF99",
      "#CCFF00",
      "#FF3300",
      "#FF6600",
      "#FF9900",
      "#9e0059",
      "#9b5de5",
      "#06d6a0",
      "#6930c3",
      "#390099",
      "#29bf12",
      "#564592",
      "#fb4d3d",
      "#ffb7ff",
    ],
    business: [
      "#0E2439",
      "#13315C",
      "#134074",
      "#EEF4ED",
      "#4A5859",
      "#6E7E85",
      "#8DA9C4",
      "#BBC7CE",
      "#D7DDE8",
      "#2C3A47",
      "#218C74",
      "#3B3B98",
      "#1B9CFC",
      "#55E6C1",
      "#2C3335",
      "#B33771",
      "#F8EFBA",
      "#6A89CC",
      "#FC427B",
      "#82589F",
      "#BDC581",
      "#182C61",
      "#E74292",
    ],
    bold: [
      "#D50000",
      "#C51162",
      "#AA00FF",
      "#6200EA",
      "#304FFE",
      "#2962FF",
      "#0091EA",
      "#00B8D4",
      "#00BFA5",
      "#00C853",
      "#64DD17",
      "#AEEA00",
      "#FF4136",
      "#FF851B",
      "#FFDC00",
      "#3D9970",
      "#001f3f",
      "#85144b",
      "#0074D9",
      "#39CCCC",
      "#7FDBFF",
      "#B10DC9",
      "#FF00FF",
      "#FFFF00",
      "#FFA500",
      "#00FFFF",
      "#00FF7F",
    ],
    pastel: [
      "#FFB3BA",
      "#FFDFBA",
      "#FFFFBA",
      "#BAFFC9",
      "#BAE1FF",
      "#F4BFFF",
      "#FFD1DC",
      "#FFDAC1",
      "#E2F0CB",
      "#B5EAD7",
      "#C7CEEA",
      "#FFDFD3",
      "#FFDDE1",
      "#FFE4E1",
      "#FFF0F5",
      "#FAEBD7",
      "#F5F5DC",
      "#F0FFF0",
      "#E6E6FA",
      "#D8BFD8",
      "#FFB6C1",
      "#FFC0CB",
      "#F5DEB3",
      "#D3FFCE",
      "#E0FFFF",
      "#ADD8E6",
    ],
  };

  const renderTemplateDropdown = (type) => {
    const filteredTemplates = pdfTemplates
      .filter((template) => template.templateType === type)
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div key={type} className="space-y-2 w-1/2">
        <Label
          htmlFor={`template-name-${type}-${index}`}
          className="text-inter font-bold"
        >
          <span className="capitalize">{type}</span> Designs
        </Label>
        <Select
          value={step.templateId || ""}
          onValueChange={(value) => {
            const template = pdfTemplates.find((t) => t._id === value);
            handleFieldChange("templateId", value);
            handleFieldChange("templateName", template ? template.name : "");
            setSelectedTemplate(template);
            if (template) {
              const updatedPdfData = { ...localPdfData };
              template.fields.forEach((field) => {
                if (!updatedPdfData.hasOwnProperty(field.name)) {
                  updatedPdfData[field.name] = field.type === "array" ? [] : "";
                }
              });
              setLocalPdfData(updatedPdfData);
              handleFieldChange("pdfData", updatedPdfData);
            }
          }}
        >
          <SelectTrigger className="w-full bg-gray-100 shadow-md">
            <SelectValue placeholder={`Select a ${type} template`} />
          </SelectTrigger>
          <SelectContent>
            {filteredTemplates.map((template) => (
              <SelectItem key={template._id} value={template._id}>
                {template.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const ColorPickerField = ({ field, value, onChange }) => {
    const [color, setColor] = useState(value || "#ffffff");
    const [tempColor, setTempColor] = useState(value || "#ffffff");
    const [presetGroup, setPresetGroup] = useState("standard");

    const handleColorChange = (newColor) => {
      setTempColor(newColor);
    };

    const applyColor = () => {
      setColor(tempColor);
      onChange(tempColor);
    };

    const handlePresetClick = (presetColor) => {
      setColor(presetColor);
      setTempColor(presetColor);
      onChange(presetColor);
    };

    return (
      <div className="flex items-center space-x-2 w-1/2">
        <Popover>
          <PopoverTrigger asChild>
            <div
              className="w-12 h-10 rounded-md cursor-pointer border border-gray-300"
              style={{ backgroundColor: color }}
            />
          </PopoverTrigger>
          <PopoverContent className="p-4 w-64">
            <HexColorPicker color={tempColor} onChange={handleColorChange} />
            <div className="mt-2">
              <Label htmlFor={`hex-input-${field.name}`}>Hex</Label>
              <HexColorInput
                id={`hex-input-${field.name}`}
                color={tempColor}
                onChange={handleColorChange}
                prefixed
                className="w-full p-2 mt-1 border rounded"
              />
            </div>
            <Button className="mt-2 w-full" onClick={applyColor}>
              Apply Color
            </Button>
            <div className="mt-4">
              <Label>Presets</Label>
              <Select value={presetGroup} onValueChange={setPresetGroup}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Choose preset group" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(colorPresets).map((group) => (
                    <SelectItem key={group} value={group}>
                      {group.charAt(0).toUpperCase() + group.slice(1)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div className="flex flex-wrap gap-2 mt-2">
                {colorPresets[presetGroup].map((presetColor) => (
                  <Button
                    key={presetColor}
                    className="w-6 h-6 p-0 border-gray-600 border-2 hover:border-green-500"
                    style={{ backgroundColor: presetColor }}
                    onClick={() => handlePresetClick(presetColor)}
                  />
                ))}
              </div>
            </div>
          </PopoverContent>
        </Popover>
        <Input
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
            setTempColor(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="#000000"
          className="flex-grow"
        />
      </div>
    );
  };

  return (
    <Card>
      <CardContent className="pt-6 space-y-4">
        <div className="flex items-center space-x-2">
          <Switch
            id={`use-html-template-${index}`}
            checked={step.useHtmlTemplate || false}
            onCheckedChange={(checked) => {
              handleFieldChange("useHtmlTemplate", checked);
              if (!checked) {
                setLocalPdfData({ plainText: localPdfData.plainText || "" });
                handleFieldChange("pdfData", {
                  plainText: localPdfData.plainText || "",
                });
              }
            }}
          />
          <Label
            htmlFor={`use-html-template-${index}`}
            className="text-inter font-bold"
          >
            Use Design Template
          </Label>
        </div>
        {step.useHtmlTemplate ? (
          <>
            <div className="flex items-center space-x-2">
              {templateTypes.map((type) => renderTemplateDropdown(type))}
            </div>
            {selectedTemplate && (
              <>
                {/* Display screenshots and description */}
                <div className="space-y-2">
                  {selectedTemplate.screenshotUrls && (
                    <div className="flex flex-wrap -mx-1">
                      {selectedTemplate.templateType === "image" && (
                        <div className="w-1/3 px-1">
                          <TemplatePreview
                            templateCode={selectedTemplate.templateCode}
                            pdfData={localPdfData}
                            templateWidth={selectedTemplate.width || 1080}
                            templateHeight={selectedTemplate.height || 1080}
                          />
                        </div>
                      )}
                      {selectedTemplate.screenshotUrls.map((url, idx) => (
                        <div key={idx} className="w-1/3 px-1">
                          <img
                            src={url}
                            alt={`${selectedTemplate.name} Screenshot ${
                              idx + 1
                            }`}
                            className="w-full h-auto"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedTemplate.description && (
                    <p className="mt-1 text-sm text-gray-500">
                      {selectedTemplate.description}
                    </p>
                  )}
                  {selectedTemplate.screenshotUrls && selectedTemplate.templateType === "ebook" && (
                    <p className="mt-1 text-sm text-black">
                      *Note: The preview above is a sample screenshot of the template. The actual output will vary based on the data you provide.
                    </p>
                  )}
                </div>
                {/* Generate form fields dynamically */}
                <div className="flex flex-wrap -mx-2">
                  {selectedTemplate.fields.map((field, idx) => (
                    <div key={idx} className="w-1/2 px-2 mb-4">
                      <Label
                        htmlFor={`${field.name}-${index}`}
                        className="text-inter font-bold capitalize"
                      >
                        {field.name}
                        {field.required ? "*" : ""}
                      </Label>
                      {(field.type === "string" || field.type === "image") && (
                        <Input
                          id={`${field.name}-${index}`}
                          value={localPdfData[field.name] || ""}
                          onChange={(e) =>
                            updatePdfData(field.name, e.target.value)
                          }
                          required={field.required}
                          placeholder={field.placeholder || ""}
                        />
                      )}
                      {(field.type === "textarea" ||
                        field.type === "array") && (
                        <Textarea
                          id={`${field.name}-${index}`}
                          value={
                            Array.isArray(localPdfData[field.name])
                              ? localPdfData[field.name].join("\n")
                              : localPdfData[field.name] || ""
                          }
                          onChange={(e) =>
                            updatePdfData(
                              field.name,
                              field.type === "array"
                                ? e.target.value.split("\n")
                                : e.target.value
                            )
                          }
                          required={field.required}
                          placeholder={field.placeholder || ""}
                        />
                      )}
                      {field.type === "color" && (
                        <ColorPickerField
                          field={field}
                          value={localPdfData[field.name] || ""}
                          onChange={(value) => updatePdfData(field.name, value)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="space-y-2">
            <Label
              htmlFor={`plain-text-content-${index}`}
              className="text-inter font-bold"
            >
              Plain Text Content*
            </Label>
            <Textarea
              id={`plain-text-content-${index}`}
              placeholder="Enter the plain text content to be converted to PDF"
              value={localPdfData.plainText || ""}
              onChange={(e) => updatePdfData("plainText", e.target.value)}
              className="min-h-[200px]"
              required
            />
            <p className="mt-1 text-xs text-gray-500">
              Enter the plain text content to be converted to PDF. You can use
              placeholders like &#123;&#123;stepX_outputKey&#125;&#125; to
              reference outputs from previous steps.
            </p>
          </div>
        )}
        <div className="space-y-2">
          <Label
            htmlFor={`output-key-${index}`}
            className="text-inter font-bold lg:text-lg"
          >
            Output Name (used to reference this response in later steps)
          </Label>
          <Input
            id={`output-key-${index}`}
            placeholder="e.g., uploadedPdfFileUrl"
            value={step.outputKey || ""}
            onChange={(e) => handleFieldChange("outputKey", e.target.value)}
            required
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default UploadPdfFileFields;
