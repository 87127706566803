// src/pages/AdminComponents/AnalyticsDashboard.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersAnalytics, fetchRecordsAnalytics } from '../../slices/adminAnalyticsSlice';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { Info, RefreshCcw } from 'lucide-react';

const recordOptions = [
    { value: 'chat', label: 'Chats' },
    { value: 'userChatbot', label: 'Custom Assistants' },
    { value: 'execution', label: 'Executions' },
    { value: 'flow', label: 'Flows' },
    { value: 'generatedImage', label: 'Generated Images' },
    { value: 'promptStack', label: 'Prompt Stacks' },
    { value: 'schedule', label: 'Schedules' },
    { value: 'team', label: 'Teams' },
    { value: 'todo', label: 'To-dos' },
    { value: 'user', label: 'Users' },
    { value: 'userAddedChatbot', label: 'User Added Chatbots' }
];

const AnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const [selectedRecordType, setSelectedRecordType] = useState('user');

  const {
    data: usersAnalytics,
    loading: usersLoading,
    error: usersError,
  } = useSelector((state) => state.adminAnalytics.usersAnalytics);
  const {
    data: recordsAnalytics,
    loading: recordsLoading,
    error: recordsError,
  } = useSelector((state) => state.adminAnalytics.recordsAnalytics);

  // Fetch analytics data on mount
  useEffect(() => {
    dispatch(fetchUsersAnalytics());
    dispatch(fetchRecordsAnalytics());
  }, [dispatch]);

  // Prepare data for charts if available
  const lastLoginData = usersAnalytics?.lastLoginAnalytics || [];
  const totalUsers = usersAnalytics?.totalUsers;
  const subscriptionDetails = usersAnalytics?.subscriptionDetails || [];
  const selectedRecordsData = recordsAnalytics ? recordsAnalytics[selectedRecordType] || [] : [];

  // Utility function to render error messages safely
  const renderError = (error) =>
    typeof error === 'object' && error !== null ? error.message : error;

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <h2 className="text-2xl font-semibold" title="This dashboard shows real-time analytics for user activity and record counts over time. Hover over charts for more details.">Analytics Overview</h2>
        </div>
        <button
          onClick={() => {
            dispatch(fetchUsersAnalytics());
            dispatch(fetchRecordsAnalytics());
          }}
          className="flex items-center space-x-1 text-indigo-600 hover:text-indigo-500"
          title="Refresh Data"
        >
          <RefreshCcw className="w-5 h-5" />
          <span className="text-sm">Refresh</span>
        </button>
      </div>

      

      {/* Records Analytics Section */}
      <div className="bg-white shadow rounded p-4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-xl font-medium">Records Growth Over Time</h3>
          <div className="flex items-center space-x-2">
            <label htmlFor="recordType" className="text-sm text-gray-600">
              Model:
            </label>
            <select
              id="recordType"
              className="border rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={selectedRecordType}
              onChange={(e) => setSelectedRecordType(e.target.value)}
              title="Select a model to view its record growth"
            >
              {recordOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {recordsLoading ? (
          <p className="text-gray-500">Loading records analytics...</p>
        ) : recordsError ? (
          <p className="text-red-500">
            Error loading records analytics: {renderError(recordsError)}
          </p>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={selectedRecordsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_id" />
                <YAxis />
                <RechartsTooltip />
                <Bar dataKey="count" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
            <p className="text-sm text-gray-500 mt-2">
              This chart displays the daily count of records for the{' '}
              <span className="font-medium">
                {recordOptions.find((opt) => opt.value === selectedRecordType)?.label}
              </span>{' '}
              model.
            </p>
            <div className="mt-4">
              <h4 className="text-lg font-medium mb-2">Subscription Breakdown</h4>
              <ul className="list-disc pl-5">
                {subscriptionDetails.map((item, index) => (
                  <li key={index} className="text-sm text-gray-600">
                    Tier: <span className="font-medium">{item._id.tier}</span>, Status:{' '}
                    <span className="font-medium">{item._id.status}</span> - {item.count} users
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>

      {/* User Analytics Section */}
      <div className="bg-white shadow rounded p-4">
        <h3 className="text-xl font-medium mb-2">User Logins Over Time</h3>
        {usersLoading ? (
          <p className="text-gray-500">Loading user analytics...</p>
        ) : usersError ? (
          <p className="text-red-500">
            Error loading user analytics: {renderError(usersError)}
          </p>
        ) : (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={lastLoginData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_id" />
                <YAxis />
                <RechartsTooltip />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
            <p className="text-sm text-gray-500 mt-2">
              Total Users: <span className="font-medium">{totalUsers}</span>.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
