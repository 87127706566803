import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFlowById } from "../../slices/flowsSlice";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { ArrowLeft } from "lucide-react";
import { Alert, AlertDescription } from "../../components/ui/alert";
import FilterSidebar from "./PremadeFlowComponents/FilterSidebar";
import ActiveFilters from "./PremadeFlowComponents/ActiveFilters";
import SortDropdown from "./PremadeFlowComponents/SortDropdown";
import FlowCard from "./PremadeFlowComponents/FlowCard";
import SearchBar from "./PremadeFlowComponents/SearchBar";
import FlowForm from "../FlowForm";

const PreMadeFlows = ({
  globalFlows,
  selectedTeamInfo,
  onSubmit,
  isSubmitting,
  toast,
  limits,
  totalItems,
}) => {
  const dispatch = useDispatch();

  const [selectedPreMadeFlow, setSelectedPreMadeFlow] = useState(null);
  const [isLoadingPreMadeFlow, setIsLoadingPreMadeFlow] = useState(false);
  const [preMadeFlowError, setPreMadeFlowError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedStepRanges, setSelectedStepRanges] = useState([]);
  const [showAvailableOnly, setShowAvailableOnly] = useState(false);
  const [sortBy, setSortBy] = useState("name");
  const [isTemplateSelectionExpanded, setIsTemplateSelectionExpanded] =
    useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Handle window resize for mobile detection
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Extract unique categories and tags
  const tagCounts = {};
  globalFlows.forEach((flow) => {
    (flow.tags || []).forEach((tag) => {
      if (!tagCounts[tag]) tagCounts[tag] = 0;
      tagCounts[tag]++;
    });
  });

  const tagsByCount = Object.entries(tagCounts).sort((a, b) => b[1] - a[1]);
  let popularTags = tagsByCount.filter(([tag, count]) => count > 1);
  if (popularTags.length < 25) {
    const needed = 25 - popularTags.length;
    const remainder = tagsByCount
      .filter(([tag, count]) => count <= 1)
      .slice(0, needed);
    popularTags = [...popularTags, ...remainder];
  }
  popularTags = popularTags.slice(0, 25);
  const tags = popularTags.map(([tag]) => tag);

  const categories = [
    ...new Set(globalFlows.map((flow) => flow.flowCategory)),
  ].filter(Boolean);

  const handleStepRangeChange = (range) => {
    setSelectedStepRanges((prev) =>
      prev.includes(range) ? prev.filter((r) => r !== range) : [...prev, range]
    );
  };

  const getFlowRangeCategory = (flow) => {
    const length = flow.steps?.length || 0;
    if (length >= 1 && length <= 5) return "Quick (1-5 steps)";
    if (length >= 6 && length <= 14) return "Medium (6-14 steps)";
    if (length >= 15) return "Detailed (15+ steps)";
    return null;
  };

  // Filter and sort flows
  const filteredFlows = globalFlows
  .filter((flow) => {
    const matchesSearch = (flow.name + flow.description)
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategories.length === 0 ||
      (flow.flowCategory && selectedCategories.includes(flow.flowCategory));
    const matchesTags =
      selectedTags.length === 0 ||
      (flow.tags && selectedTags.every((tag) => flow.tags.includes(tag)));
    const matchesStepRanges =
      selectedStepRanges.length === 0 ||
      selectedStepRanges.includes(getFlowRangeCategory(flow));
    const matchesAvailability = !showAvailableOnly || 
      (flow.steps?.length || 0) <= limits.flowSteps;
    return (
      matchesSearch && 
      matchesCategory && 
      matchesTags && 
      matchesStepRanges && 
      matchesAvailability
    );
  })
    .sort((a, b) => {
      switch (sortBy) {
        case "steps":
          return (b.steps?.length || 0) - (a.steps?.length || 0);
        case "newest":
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return a.name.localeCompare(b.name);
      }
    });

  const handlePreMadeFlowSelect = async (flowId) => {
    console.log('limits.activeFlows', limits.activeFlows, 'totalItems', totalItems);
    if (limits.activeFlows <= totalItems ) {
      toast({
        title: "Limit Reached",
        description: "You have reached the limit of active flows for your plan. Please upgrade your plan to create more flows.",
        variant: "destructive",
      });
      return;
    }
    setIsLoadingPreMadeFlow(true);
    setPreMadeFlowError(null);
    try {
      const fullFlow = await dispatch(fetchFlowById(flowId)).unwrap();
      setSelectedPreMadeFlow({
        ...fullFlow,
        name: `${fullFlow.name} (Template)`,
        team:
          selectedTeamInfo.id !== "all" && selectedTeamInfo.id !== "personal"
            ? selectedTeamInfo.id
            : null,
      });
      setIsTemplateSelectionExpanded(false);
    } catch (error) {
      setPreMadeFlowError(
        "Failed to load the selected flow. Please try again."
      );
      toast({
        title: "Error",
        description: "Failed to load the selected flow",
        variant: "destructive",
      });
    } finally {
      setIsLoadingPreMadeFlow(false);
    }
  };

  if (!limits || !globalFlows) {
    return <div className="text-center">Loading subscription details...</div>;
  }

  return (
    <Card className="m-2 lg:m-4 mt-0 border-none">
      <CardHeader className="lg:p-2 lg:pl-6 -mt-4 lg:mt-0">
      {!isMobile && <CardTitle className="text-sm lg:text-base">Pre-made Flows</CardTitle>}
      </CardHeader>
      
      <CardContent>
        <div className="flex flex-col md:flex-row gap-6">
          {!selectedPreMadeFlow && (
            <FilterSidebar
            categories={categories}
            selectedCategories={selectedCategories}
            tags={tags}
            selectedTags={selectedTags}
            onCategoryChange={(category) => {
              setSelectedCategories((prev) =>
                prev.includes(category)
                  ? prev.filter((c) => c !== category)
                  : [...prev, category]
              );
            }}
            onTagChange={(tag) => {
              setSelectedTags((prev) =>
                prev.includes(tag)
                  ? prev.filter((t) => t !== tag)
                  : [...prev, tag]
              );
            }}
            selectedStepRanges={selectedStepRanges}
            onStepRangeChange={handleStepRangeChange}
            isMobile={isMobile}
            limits={limits}
            showAvailableOnly={showAvailableOnly}
  onAvailabilityChange={() => setShowAvailableOnly(prev => !prev)}
          />
          )}

          <div className="flex-1">
            <div className="space-y-1">
            {!isMobile && (<><div className="flex flex-col lg:flex-row justify-between items-center -mt-6 lg:mt-0">
              <h3 className="lg:text-lg font-semibold leading-none">
                  {selectedPreMadeFlow
                    ? selectedPreMadeFlow.name
                    : "Choose a Template"}
                </h3>
                {/*<div className="flex items-center gap-2 text-sm lg:text-base">
                  Max Steps: {limits.flowSteps}
                </div>*/}
                {!isMobile && selectedPreMadeFlow && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setSelectedPreMadeFlow(null);
                      setIsTemplateSelectionExpanded(true);
                    }}
                    className="flex items-center"
                  >
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    Back to Templates
                  </Button>
                )}
              </div>
              </>)}

              {isTemplateSelectionExpanded && (
                <>
                  <div className="flex flex-col md:flex-row gap-4 mb-4">
                    <SearchBar
                      searchTerm={searchTerm}
                      onSearchChange={setSearchTerm}
                    />
                    <SortDropdown sortBy={sortBy} onSortChange={setSortBy} />
                  </div>

                  <ActiveFilters
                    selectedCategories={selectedCategories}
                    selectedTags={selectedTags}
                    onRemoveCategory={(category) => {
                      setSelectedCategories((prev) =>
                        prev.filter((c) => c !== category)
                      );
                    }}
                    onRemoveTag={(tag) => {
                      setSelectedTags((prev) => prev.filter((t) => t !== tag));
                    }}
                  />

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {filteredFlows.map((flow) => (
                      <FlowCard
                        key={flow._id}
                        flow={flow}
                        isSelected={selectedPreMadeFlow?._id === flow._id}
                        onClick={handlePreMadeFlowSelect}
                        limits={limits}
                      />
                    ))}
                  </div>
                </>
              )}

              {isLoadingPreMadeFlow && (
                <div className="flex justify-center py-4">
                  <p>Loading pre-made flow...</p>
                </div>
              )}

              {preMadeFlowError && (
                <Alert variant="destructive">
                  <AlertDescription>{preMadeFlowError}</AlertDescription>
                </Alert>
              )}

              {selectedPreMadeFlow && !isLoadingPreMadeFlow && (
                <FlowForm
                  initialFlow={selectedPreMadeFlow}
                  onSubmit={onSubmit}
                  submitButtonText="Update Flow"
                  isSubmitting={isSubmitting}
                  submittingText="Creating Flow..."
                  isEditMode={false}
                />
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PreMadeFlows;
