import React, { useState } from "react";
import { Button } from "../../../components/ui/button";
import { useDispatch } from "react-redux";
import { openFlowStackModal } from "../../../slices/flowStackModalSlice";
import {
  Waypoints,
  BotMessageSquare,
  SearchCheck,
  Sparkles,
  BrainCircuit,
  Save,
  ArrowRight,
  ChevronDown,
  ChevronUp
} from "lucide-react";

const FlowSuggestion = ({ fetchedSuggestions, onSaveFlow, onRunFlow }) => {
  const dispatch = useDispatch();
  const [isProTipsExpanded, setIsProTipsExpanded] = useState(false);
  const [expandedFlows, setExpandedFlows] = useState({});

  const toggleProTips = () => {
    setIsProTipsExpanded(!isProTipsExpanded);
  };

  const toggleFlowExpand = (flowId) => {
    setExpandedFlows(prevState => ({
      ...prevState,
      [flowId]: !prevState[flowId]
    }));
  };

  return (
    <div className="bg-white border border-gray-200 rounded shadow-md p-2 lg:p-6">
      <div className="text-xl font-semibold mb-4 flex items-center text-gray-900" title="Streamline your content creation with a Flow! These AI-powered
            sequences create your content step-by-step, ensuring consistency and
            saving you time. Let automation enhance your efficiency!">
        <Waypoints className="h-7 w-7 mr-2 magical-gradient text-white p-1 rounded-full" />
        <h3 className="text-base lg:text-xl font-semibold">
          Suggestion: Use a Flow
        </h3>
      </div>
      {fetchedSuggestions.data.suggestions.flows.length > 0 && (
        <>
          <div className="space-y-4">
            {fetchedSuggestions.data.suggestions.flows.map((flow) => (
              <div 
                key={flow._id}
                className="border border-gray-200 rounded-md shadow-md overflow-hidden"
              >
                {/* Collapsed Flow Header */}
                <div 
                  className="flex items-center justify-between p-2 bg-gray-100 cursor-pointer"
                  onClick={() => toggleFlowExpand(flow._id)}
                >
                  <div className="flex items-center gap-2">
                    <Waypoints className="h-5 w-5 text-black w-1/12" />
                    <h4 className="font-semibold text-gray-900 w-11/12">{flow.name}</h4>
                  </div>
                  {expandedFlows[flow._id] ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </div>
                
                {/* Expanded Flow Content */}
                {expandedFlows[flow._id] && (
                  <Button
                    variant="outline"
                    type="button"
                    className="cursor-default flex items-between border-none gap-2 group bg-gray-100 hover:bg-gray-900 hover:text-gray-100 text-black p-2 lg:p-3 h-fit w-full justify-between"
                  >
                    <div className="flex flex-col items-start w-full min-w-0">
                      <h4 className="font-semibold text-lg w-full whitespace-normal break-words">
                        {flow.name}
                      </h4>
                      <p className="text-sm px-4 text-gray-700 group-hover:text-white w-full whitespace-normal break-words">
                        {flow.description} - {flow.stepCount} Steps
                      </p>
                      <div className="mt-1 w-full flex flex-col lg:flex-row items-between justify-center gap-2">
                        <div onClick={(e) => onRunFlow(e, flow._id)} className="mt-1 mx-auto flex flex-row items-center justify-center bg-white text-gray-900 hover:text-gray-100 gap-2 hover:bg-gray-600 py-1 px-3 rounded cursor-pointer">
                          <Waypoints className="h-6 w-6 bg-black text-white rounded-full p-1" />
                          Run this Flow
                        </div>
                        <div onClick={(e) => onSaveFlow(e, flow._id)} className="mt-1 mx-auto flex flex-row items-center justify-center bg-white text-gray-900 hover:text-gray-100 gap-2 hover:bg-gray-600 py-1 px-3 rounded cursor-pointer">
                          <Save className="h-6 w-6 bg-black text-white rounded-full p-1" />
                          Save this Flow
                        </div>
                      </div>
                    </div>
                  </Button>
                )}
              </div>
            ))}
          </div>
          <div className="mt-4 mb-2 text-sm text-black bg-gray-200 p-2 rounded w-fit">
            More Options:
          </div>
        </>
      )}

      <div className="space-y-4">
        <Button
          variant="outline"
          onClick={() => dispatch(openFlowStackModal())}
          className="flex items-center border-none shadow-md gap-2 magical-gradient text-white hover:text-gray-200"
        >
          <Sparkles className="h-4 w-4" />
          Create a New Flow with Flow Agent
        </Button>
        <Button
          variant="outline"
          className="flex items-center border-none shadow-md gap-2 bg-gray-900 hover:bg-gray-100 hover:text-gray-900 text-white"
        >
          Browse All Pre-Made Flows
          <ArrowRight className="h-4 w-4 magical-gradient text-white rounded-full" />
        </Button>
      </div>
      <div className="mt-4 text-sm text-black mt-6 lg:mt-8">
        <Button
          variant="ghost"
          className="w-full p-0 flex items-center justify-between hover:bg-transparent"
          onClick={toggleProTips}
        >
          <h4 className="font-semibold text-md flex items-center gap-2">
            <BrainCircuit className="h-4 w-4" />
            Pro Follow Up Tips:
          </h4>
          {isProTipsExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
        <div 
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isProTipsExpanded ? "max-h-40 opacity-100 mt-2" : "max-h-0 opacity-0"
          }`}
        >
          <div className="space-y-1">
            <div>
              <span className="font-semibold">Tip:</span> Create a new schedule for
              your flow to automate content creation.
            </div>
            <div>
              <span className="font-semibold">Tip:</span> Experiment with different
              prompts in your Flow to discover new content angles and styles.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowSuggestion;