import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../components/ui/tabs";
import DashboardAgent from "./DashboardComponents/DashboardAgent";
import DashboardOverview from "./DashboardComponents/DashboardOverview";

const DashboardNew = () => {
  const [activeTab, setActiveTab] = useState("agent");
  
  const { selectedTeamFull, selectedTeam } = useSelector(
    (state) => state.teams
  );

  return (
    <div className="space-y-2 lg:space-y-4 pb-10">
      <div className="flex flex-col lg:flex-row justify-between items-center mt-2 gap-2">
      <h1 className="text-3xl font-bold text-inter capitalize">
          {selectedTeam === 'all' ? 'Dashboard' : `${selectedTeamFull?.name || selectedTeam} Dashboard`}
        </h1>
        
        <Tabs 
          value={activeTab} 
          onValueChange={setActiveTab}
          className="w-auto"
        >
          <TabsList className="magical-gradient text-white">
            <TabsTrigger value="agent">Dashboard Agent</TabsTrigger>
            <TabsTrigger value="overview">Overview</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {activeTab === "agent" ? (
        <DashboardAgent />
      ) : (
        <DashboardOverview />
      )}
    </div>
  );
};

export default DashboardNew;