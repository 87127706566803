// src/components/FileUpload.js
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { FileBarChart2 } from 'lucide-react';

export const FileUpload = ({ onFileUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    onFileUpload(acceptedFiles);
  }, [onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    maxFiles: 5,
    maxSize: 10 * 1024 * 1024 // 10 MB
  });

  return (<>
  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
    <div {...getRootProps()} className="border-2 border-dashed  border-gray-300 rounded-lg p-1 lg:p-4 text-center cursor-pointer hover:border-blue-500 transition-colors w-full">
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>
          <FontAwesomeIcon icon={faUpload} className="mr-2" />
          <span className='hidden lg:block'>Drag 'n' drop files here, or click to select files</span>
          <span className='lg:hidden'>Select files</span>
        </p>
      )}
    </div>
    </TooltipTrigger>
                  <TooltipContent
                    className="flex items-center"
                    side="top"
                    align="start"
                  >
                    <p className="max-w-[400px] flex">
                    <FileBarChart2 className="h-4 w-4 mr-2" />
                    Add a file(s) to add it to your conversation.<br />Supported file types include:<br />
                    .txt, .csv, .pdf, .jpg, .jpeg, .png, .doc, and .docx.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
  </>);
};