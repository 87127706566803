import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Badge } from '../../components/ui/badge';
import { Check } from 'lucide-react';
import useSubscription from '../../hooks/useSubscription';
import { TIER_LEVELS } from '../../utils/subscriptionUtils';
import { useToast } from '../../components/ui/use-toast';
import SubscriptionTypeModal from './SubscriptionTypeModal';

const PlanCard = ({ 
  name, 
  price,               // assumed to be a numeric value
  description, 
  features, 
  tier,
  seats,
  chatCredits,
  executionCredits,
  imageCredits,
  isPopular,
  onSelectPlan 
}) => {
  const { toast } = useToast();
  const { currentTier } = useSubscription();
  const [showTypeModal, setShowTypeModal] = useState(false);

  // === BEGIN: Special Launch Pricing State & Helpers ===
  // Toggle whether to show launch pricing (25% off by default).
  const [launchPricingEnabled] = useState(true);
  const [discountPercentage] = useState(25);

  // Quick helper to compute and format discounted price.
  const getDiscountedPrice = (basePrice, discount) => {
    const discounted = basePrice - (basePrice * (discount / 100));
    // Round to 2 decimals or use your preferred formatting
    return discounted.toFixed(2);
  };

  const isPaidPlan = price > 0;
  const isLaunchPricingActive = launchPricingEnabled && isPaidPlan;

  // This is the final displayed price (numeric) when launch pricing is on
  const displayedPrice = isLaunchPricingActive 
    ? getDiscountedPrice(price, discountPercentage)
    : price;
  // === END: Special Launch Pricing State & Helpers ===

  // Existing logic for checking plan status
  const isCurrentPlan = currentTier === tier;
  const isDowngrade = TIER_LEVELS[tier] < TIER_LEVELS[currentTier];
  const hasMultipleSeats = seats > 1;

  // Existing handler to select plan
  const handleSelectPlan = () => {
    if (isDowngrade) {
      toast({
        title: "Please Contact Support",
        description: "Please contact support to downgrade your plan.",
        variant: "destructive"
      });
      return;
    }
    if (hasMultipleSeats) {
      setShowTypeModal(true);
    } else {
      onSelectPlan(tier);
    }
  };

  // Existing handler for subscription type selection
  const handleTypeSelection = (subscriptionType) => {
    onSelectPlan(tier, subscriptionType);
  };

  return (
    <>
      <Card className={`w-full relative shadow-lg ${isPopular ? 'border-2 border-green-600' : ''}`}>
        
        {isPopular && (
          <Badge 
            className="absolute -top-2 right-4 bg-green-500 text-white" 
            variant="default"
          >
            Most Popular
          </Badge>
        )}

        <CardHeader>
          <div className="space-y-1">
            <h3 className="font-bold text-2xl">{name}</h3>
            <p className="text-sm text-muted-foreground">{description}</p>
          </div>

          {/* Conditionally show "Special Launch Pricing" badge if enabled and plan is paid */}
          {isLaunchPricingActive && (
            <div className="mt-2">
              <span className="inline-block text-xs bg-red-600 text-white px-4 py-1 my-1 rounded-full text-center leading-tight">
                        Special Launch Pricing: {discountPercentage}% OFF<br></br>for your first 12 months!
                      </span>
            </div>
          )}

          <div className="mt-4 flex items-baseline space-x-2">
            {/* Final (possibly discounted) price */}
            <span className="text-4xl font-bold">
              {isPaidPlan ? `$${displayedPrice}` : 'Free'}
            </span>
            
            {/* Show original price in strikethrough if discount applies */}
            {isLaunchPricingActive && (
              <span className="text-sm text-gray-500 line-through">
                ${price}
              </span>
            )}

            {isPaidPlan && (
              <span className="text-muted-foreground">/month</span>
            )}
          </div>
        </CardHeader>

        <CardContent>
          <ul className="space-y-3">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center space-x-2">
                <Check className="h-4 w-4 text-green-600" />
                <span className="text-sm">{feature}</span>
              </li>
            ))}
          </ul>
        </CardContent>

        <CardFooter>
          <Button 
            className="w-full hover:bg-green-500 hover:text-white" 
            variant={isPopular ? "default" : "outline"}
            disabled={isCurrentPlan}
            onClick={handleSelectPlan}
          >
            {isCurrentPlan ? 'Current Plan' : 'Select Plan'}
          </Button>
        </CardFooter>
      </Card>

      {/* Existing Modal for seat-based plans */}
      <SubscriptionTypeModal
        isOpen={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        onSelect={handleTypeSelection}
        planDetails={{
          name,
          seats,
          chatCredits,
          executionCredits,
          imageCredits
        }}
      />
    </>
  );
};

export default PlanCard;