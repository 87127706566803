import React from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import {
  RefreshCw,
  Search,
  HelpCircle,
  Grid3X3,
  List,
  X
} from "lucide-react";

const DashboardHeader = ({
  searchTerm,
  setSearchTerm,
  onRefresh,
  viewMode,
  setViewMode,
  showDocumentation,
  setShowDocumentation,
  selectedAssetType,
  assetTypes
}) => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-between mb-4 gap-4">
      <div className="flex items-center">
        <h1 className="text-2xl font-bold mr-4">Content Dashboard</h1>
        {selectedAssetType && (
          <div className="hidden md:flex items-center">
            <span className="text-gray-400 mx-2">›</span>
            <span className="text-lg font-medium capitalize">
              {assetTypes.find(t => t.id === selectedAssetType)?.name || 
               selectedAssetType.replace("-", " ")}
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-1 sm:flex-none items-center space-x-2 w-full sm:w-auto">
        {(selectedAssetType || searchTerm) && selectedAssetType !== 'notifications' && selectedAssetType !== 'content-dashboard' && selectedAssetType !== 'overview' && (
          <div className="relative flex-1 max-w-sm">
            <Search className="absolute left-2.5 top-5 h-4 w-4 text-gray-500" />
            <Input
              type="text"
              placeholder="Search assets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-9 h-10 bg-white mt-2"
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="absolute right-2.5 top-2.5"
              >
                <X className="h-4 w-4 text-gray-500" />
              </button>
            )}
          </div>
        )}

        <Button
          variant="outline"
          size="sm"
          onClick={onRefresh}
          className="border-none shadow-md h-10 px-3 mt-2"
          title="Refresh assets"
        >
          <RefreshCw className="h-4 w-4" />
          <span className="ml-2 hidden md:inline">Refresh</span>
        </Button>

        {selectedAssetType && selectedAssetType !== 'notifications' && selectedAssetType !== 'content-dashboard' && selectedAssetType !== 'overview' && (
          <div className="hidden lg:flex bg-gray-100 dark:bg-gray-800 px-2 lg:pt-2 rounded-md">
            <Button
              variant={viewMode === "cards" ? "default" : "ghost"}
              size="sm"
              onClick={() => setViewMode("cards")}
              className={`h-10 px-3 shadow-md ${
                viewMode === "table" ? "bg-white" : "text-gray-100"} `}
              title="Card view"
            >
              <Grid3X3 className="h-5 w-5" />
            </Button>
            <Button
              variant={viewMode === "table" ? "default" : "ghost"}
              size="sm"
              onClick={() => setViewMode("table")}
              className={`h-10 px-3 shadow-md ${
                viewMode !== "table" ? "bg-white" : "text-gray-100"} `}
              title="Table view"
            >
              <List className="h-5 w-5" />
            </Button>
          </div>
        )}

        <Button
          variant={showDocumentation ? "default" : "outline"}
          size="sm"
          onClick={() => setShowDocumentation(!showDocumentation)}
          className="border-none shadow-md h-10 mt-2"
        >
          <HelpCircle className="h-4 w-4 mr-2" />
          <span className="hidden md:inline">Documentation</span>
        </Button>
      </div>
    </div>
  );
};

export default DashboardHeader;