// components/GoogleAuthCallback.js
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser, setIsAuthenticated } from '../slices/authSlice';

const GoogleAuthCallback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
  
    useEffect(() => {
      const handleCallback = async () => {
        try {
          console.log('GoogleAuthCallback component mounted');
          const encodedData = searchParams.get('data');
          console.log('Received encoded data:', encodedData);
          
          if (!encodedData) {
            console.log('No data received in callback');
            throw new Error('No data received');
          }
  
          const decodedData = JSON.parse(atob(encodedData));
          console.log('Decoded auth data:', {
            hasAccessToken: !!decodedData.accessToken,
            hasRefreshToken: !!decodedData.refreshToken,
            user: decodedData.user
          });
  
          // Store tokens
          localStorage.setItem('accessToken', decodedData.accessToken);
          localStorage.setItem('refreshToken', decodedData.refreshToken);
  
          // Update Redux state
          console.log('Updating Redux state...');
          dispatch(setUser(decodedData.user));
          dispatch(setIsAuthenticated(true));
          
          console.log('Navigation to dashboard...');
          navigate('/dashboard', { replace: true });
        } catch (error) {
          console.error('Detailed callback error:', error);
          console.error('Error stack:', error.stack);
          navigate('/login', { 
            replace: true,
            state: { error: 'Failed to authenticate with Google' }
          });
        }
      };
  
      handleCallback();
    }, [dispatch, navigate, searchParams]);
  
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4">Completing sign in...</p>
        </div>
      </div>
    );
  };

export default GoogleAuthCallback;