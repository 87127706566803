import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, refreshAuthToken, clearAuth } from '../slices/authSlice';

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const { loading, isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    const initializeAuth = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        try {
          // Try to fetch the current user
          await dispatch(getCurrentUser()).unwrap();
        } catch (error) {
          // Check if the error is due to an expired/invalid token.
          // (Adjust the condition as needed based on your error structure)
          if (
            (error?.message && error.message.toLowerCase().includes('expired')) ||
            error?.status === 401
          ) {
            try {
              // Attempt to refresh the token
              await dispatch(refreshAuthToken()).unwrap();
              // Try fetching the current user again
              await dispatch(getCurrentUser()).unwrap();
            } catch (refreshError) {
              // Refresh failed – clear tokens and redirect to login
              dispatch(clearAuth());
              window.location.href = '/login';
              return;
            }
          } else {
            // Some other error – clear tokens and redirect to login
            dispatch(clearAuth());
            window.location.href = '/login';
            return;
          }
        }
      }
      setIsInitialized(true);
    };

    initializeAuth();
  }, [dispatch]);

  if (!isInitialized) {
    return <div>Loading your subscription data...</div>;
  }
  return children;
};

export default AuthWrapper;
