import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Circle, Trash2, MoreVertical, Eye } from "lucide-react";

const AssetsTable = ({
  assets,
  selectedAssetId,
  onSelectAsset,
  onDeleteAsset,
  onUpdateStatus
}) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const truncateText = (text, maxLength = 100) => {
    if (!text) return "N/A";
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="rounded-md overflow-hidden">
      <Table>
        <TableHeader className="bg-white">
          <TableRow>
            <TableHead className="w-12"></TableHead>
            <TableHead>Title</TableHead>
            <TableHead>Content Preview</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Created</TableHead>
            <TableHead>Last Updated</TableHead>
            <TableHead className="w-24 text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {assets.map((asset) => (
            <TableRow
              key={asset._id}
              className={`cursor-pointer ${
                selectedAssetId === asset._id
                  ? "bg-primary/5"
                  : !asset.isRead
                  ? "bg-blue-50 dark:bg-blue-900/20"
                  : ""
              }`}
              onClick={() => onSelectAsset(asset._id)}
            >
              <TableCell className="p-2">
                {!asset.isRead && (
                  <div className="flex justify-center">
                    <Circle className="h-3 w-3 text-blue-500 fill-current" />
                  </div>
                )}
              </TableCell>
              <TableCell className="font-medium">
                {asset.title ||
                  asset.name ||
                  (asset.course && asset.course.post_title) ||
                  "Untitled"}
              </TableCell>
              <TableCell className="max-w-xs">
                <div className="truncate text-sm text-gray-500">
                  {truncateText(
                    asset.content ||
                      asset.text ||
                      asset.message ||
                      asset.description ||
                      (asset.course && asset.course.post_content) ||
                      "Click to see details",
                    100
                  )}
                </div>
              </TableCell>
              <TableCell>
                <Badge variant={asset.status === "draft" ? "outline" : "default"}>
                  {asset.status || "Draft"}
                </Badge>
              </TableCell>
              <TableCell className="text-sm">{formatDate(asset.date)}</TableCell>
              <TableCell className="text-sm">
                {asset?.statusChangeDate && (formatDate(asset.statusChangeDate))}
              </TableCell>
              <TableCell className="text-right p-2">
                <div className="flex justify-end" onClick={(e) => e.stopPropagation()}>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelectAsset(asset._id);
                    }}
                    className="h-8 w-8 p-0 mr-1"
                    title="View details"
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                  
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel className="text-center bg-gray-100">Actions</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() =>
                          onUpdateStatus(
                            asset._id,
                            asset.status === "published" ? "draft" : "published"
                          )
                        }
                      className="hover:bg-gray-900 hover:text-gray-100"
                      >
                        {asset.status === "published" ? "Set as Draft" : "Mark Published"}
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <DropdownMenuItem
                            className="text-red-500 focus:text-red-500 hover:bg-gray-900"
                            onSelect={(e) => e.preventDefault()}
                          >
                            <Trash2 className="h-4 w-4 mr-2" />
                            Delete
                          </DropdownMenuItem>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Delete Asset</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to delete this asset? This action
                              cannot be undone.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={() => onDeleteAsset(asset._id)}
                            >
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AssetsTable;