// components/dashboard/forms/CreateAIImageForm.jsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchItems } from "../../slices/genericItemsSlice";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Plus,
  X,
  Loader2,
  NotebookPen,
  LayoutGrid,
  Paintbrush,
  Scaling,
  Square,
  Maximize,
  AlignVerticalJustifyCenter,
} from "lucide-react";
import { Badge } from "../../components/ui/badge";
import { useForm } from "react-hook-form";
import { createAIImageRequest } from "../../slices/contentSlice";
import { DialogFooter } from "../../components/ui/dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";

// Updated imageSizeOptions with icons to represent shapes
const imageSizeOptions = [
  { 
    value: "1024x1024", 
    label: "Square (1024x1024)",
    icon: "square" 
  },
  { 
    value: "1024x1792", 
    label: "Portrait (1024x1792)",
    icon: "portrait" 
  },
  { 
    value: "1792x1024", 
    label: "Landscape (1792x1024)",
    icon: "landscape" 
  },
];

const CreateAIImageForm = ({ onClose, setIsSubmitting }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.genericItems.items);
  const isLoadingItems = useSelector((state) => state.genericItems.isLoading);
  const errorItems = useSelector((state) => state.genericItems.error);
  const styles = items["ImageStyle"] || [];
  const stylesLoading = isLoadingItems["ImageStyle"] || false;
  const stylesError = errorItems["ImageStyle"] || null;
  const [topics, setTopics] = useState([]);
  const [currentTopic, setCurrentTopic] = useState("");

  useEffect(() => {
    if (!stylesLoading && !stylesError && styles.length === 0) {
      dispatch(fetchItems("ImageStyle"));
    }
  }, [dispatch, stylesLoading, stylesError, styles.length]);

  const form = useForm({
    defaultValues: {
      imageStyle: "",
      size: "",
      notes: "",
    },
  });

  const handleAddTopic = () => {
    if (currentTopic.trim() !== "" && !topics.includes(currentTopic.trim())) {
      setTopics([...topics, currentTopic.trim()]);
      setCurrentTopic("");
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(topics.filter((topic) => topic !== topicToRemove));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTopic();
    }
  };

  const onSubmit = async (data) => {
    if (topics.length === 0) {
      form.setError("topics", {
        type: "manual",
        message: "At least one topic is required",
      });
      return;
    }

    // Find the selected style to get its name
    const selectedStyle = styles.find(style => style._id === data.imageStyle);
    const styleName = selectedStyle ? selectedStyle.name : data.imageStyle;

    setIsSubmitting(true);
    try {
      await dispatch(
        createAIImageRequest({
          ...data,
          topics,
          // Pass both styleId and the style name as imageStyle
          styleId: data.imageStyle,
          imageStyle: styleName,
        })
      ).unwrap();
      onClose();
    } catch (error) {
      console.error("Failed to create AI image request:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 z-[70]">
        <div className="space-y-2">
          <FormLabel className="text-lg flex flex-row items-center gap-1">
            <LayoutGrid className="h-4 w-4 text-indigo-500" />
            Topics/Prompts
          </FormLabel>
          <div className="flex items-center gap-2">
            <Input
              value={currentTopic}
              onChange={(e) => {
                setCurrentTopic(e.target.value);
                // Clear the topics error when user starts typing
                if (form.formState.errors.topics) {
                  form.clearErrors("topics");
                }
              }}
              onKeyDown={handleKeyDown}
              placeholder="Enter a descriptive prompt and press Enter"
              className="flex-1"
            />
            <Button
              type="button"
              onClick={handleAddTopic}
              variant="outline"
              disabled={!currentTopic.trim()}
            >
              <Plus className="h-4 w-4" />
            </Button>
          </div>
          <FormDescription>
            Add one or more descriptive prompts for your AI image. Press Enter
            or click the + button to add.
          </FormDescription>
          {form.formState.errors.topics && (
            <p className="text-sm font-medium text-destructive">
              {form.formState.errors.topics.message}
            </p>
          )}
          <div className="flex flex-wrap gap-1 mt-2">
            {topics.map((topic, index) => (
              <Badge key={index} variant="secondary" className="gap-1">
                {topic}
                <button
                  type="button"
                  onClick={() => handleRemoveTopic(topic)}
                  className="ml-1 rounded-full h-4 w-4 inline-flex items-center justify-center"
                >
                  <X className="h-3 w-3" />
                </button>
              </Badge>
            ))}
          </div>
        </div>

        <FormField
          control={form.control}
          name="imageStyle"
          rules={{ required: "Image style is required" }}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-lg flex flex-row items-center gap-1">
                <Paintbrush className="h-4 w-4 text-indigo-500" />
                Image Style
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value} disabled={stylesLoading}>
                <FormControl>
                  <SelectTrigger className="shadow-md rounded bg-gray-100 h-24">
                    <SelectValue placeholder={stylesLoading ? "Loading styles..." : "Select an image style"} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className="z-[60] md:w-auto w-[calc(100vw-2rem)] mx-auto">
                  <SelectItem value="no-style">No style</SelectItem>
                  {styles.length > 0 && styles.map((style) => (
                    <SelectItem key={style._id} value={style._id} className="whitespace-normal break-words">
                      <div className="flex items-center justify-between w-full gap-2">
                        {style.screenshotUrls?.[0] && (
                          <img
                            src={style.screenshotUrls[0]}
                            alt={style.name}
                            className="w-12 h-12 lg:w-16 lg:h-16 rounded-sm object-cover"
                          />
                        )}
                        <div className="flex-1">
                          <span className="text-xs lg:text-sm">{style.name}</span>
                          {style.description && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <p className="ml-2 text-xs text-gray-500 lg:w-11/12">{style.description}</p>
                                </TooltipTrigger>
                                <TooltipContent>
                                  {style.description}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                        </div>
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                Choose the visual style for your AI-generated image.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="size"
          rules={{ required: "Image size is required" }}
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-lg flex flex-row items-center gap-1">
                <Scaling className="h-4 w-4 text-indigo-500" />
                Image Size
              </FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="shadow-md rounded bg-gray-100">
                    <SelectValue placeholder="Select an image size" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className="z-[60]">
                  {imageSizeOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      <div className="flex items-center gap-2">
                        {option.icon === "square" && (
                          <Square className="h-5 w-5 text-indigo-500" />
                        )}
                        {option.icon === "portrait" && (
                          <AlignVerticalJustifyCenter className="h-5 w-5 text-indigo-500" />
                        )}
                        {option.icon === "landscape" && (
                          <Maximize className="h-5 w-5 text-indigo-500" />
                        )}
                        <span>{option.label}</span>
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                Choose the dimensions for your AI-generated image.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-lg flex flex-row items-center gap-1">
                <NotebookPen className="h-4 w-4 text-indigo-500" />
                Additional Notes (Optional)
              </FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Add any specific instructions or details here..."
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Provide any additional instructions or details for your AI
                image.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={form.formState.isSubmitting}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={form.formState.isSubmitting}>
            {form.formState.isSubmitting && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Create AI Image
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default CreateAIImageForm;