import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent } from "./ui/dialog";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import { 
  Play, 
  Pause, 
  SkipForward, 
  SkipBack, 
  Volume2, 
  VolumeX,
  Maximize,
  Minimize,
  X
} from "lucide-react";

// VideoPlayer component handles the actual video playback and controls
const VideoPlayer = ({ 
    currentVideo, 
    onEnded, 
    onNext, 
    onPrevious, 
    hasNext, 
    hasPrevious 
  }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const videoRef = useRef(null);
    const containerRef = useRef(null);
  
    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        video.load();
        setCurrentTime(0);
        // Auto-play the video when it's loaded
        const playVideo = async () => {
          try {
            await video.play();
            setIsPlaying(true);
          } catch (err) {
            console.warn('Auto-play failed:', err);
            // Some browsers block auto-play without user interaction
            setIsPlaying(false);
          }
        };
        playVideo();
      }
    }, [currentVideo]);
  
    const togglePlay = () => {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };
  
    const handleTimeUpdate = () => {
      setCurrentTime(videoRef.current.currentTime);
    };
  
    const handleLoadedMetadata = () => {
      setDuration(videoRef.current.duration);
    };
  
    const handleSeek = (e) => {
      const time = parseFloat(e.target.value);
      videoRef.current.currentTime = time;
      setCurrentTime(time);
    };
  
    const handleVolumeChange = (e) => {
      const value = parseFloat(e.target.value);
      setVolume(value);
      videoRef.current.volume = value;
      setIsMuted(value === 0);
    };
  
    const toggleMute = () => {
      if (isMuted) {
        videoRef.current.volume = volume;
        setIsMuted(false);
      } else {
        videoRef.current.volume = 0;
        setIsMuted(true);
      }
    };
  
    const toggleFullscreen = () => {
      if (!document.fullscreenElement) {
        containerRef.current.requestFullscreen();
        setIsFullscreen(true);
      } else {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    };
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
  
    return (
      <div ref={containerRef} className="relative w-full max-w-4xl mx-auto z-[70]">
        <video
          ref={videoRef}
          className="w-full rounded-lg z-[70]"
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={onEnded}
          muted={isMuted}
        >
          <source src={currentVideo.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
  
        {/* Video Controls */}
        <div className="absolute bottom-0 left-0 right-0 bg-black/70 p-2 rounded-b-lg">
          {/* Progress Bar */}
          <input
            type="range"
            min={0}
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            className="w-full h-1 bg-gray-600 rounded-lg appearance-none cursor-pointer"
          />
  
          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center space-x-2">
              {/* Play/Pause */}
              <Button 
                variant="ghost" 
                size="icon" 
                onClick={togglePlay}
                className="text-white hover:text-black"
              >
                {isPlaying ? <Pause size={20} /> : <Play size={20} />}
              </Button>
  
              {/* Previous/Next */}
              {hasPrevious && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={onPrevious}
                  className="text-white hover:text-black"
                >
                  <SkipBack size={20} />
                </Button>
              )}
              
              {hasNext && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={onNext}
                  className="text-white hover:text-black"
                >
                  <SkipForward size={20} />
                </Button>
              )}
  
              {/* Time Display */}
              <span className="text-white text-sm">
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>
  
            <div className="flex items-center space-x-2">
              {/* Volume Control */}
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleMute}
                className="text-white hover:text-black"
              >
                {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
              </Button>
              <input
                type="range"
                min={0}
                max={1}
                step={0.1}
                value={isMuted ? 0 : volume}
                onChange={handleVolumeChange}
                className="w-20 h-1 bg-gray-600 rounded-lg appearance-none cursor-pointer"
              />
  
              {/* Fullscreen Toggle */}
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleFullscreen}
                className="text-white hover:text-black"
              >
                {isFullscreen ? <Minimize size={20} /> : <Maximize size={20} />}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Main VideoModal component
  const VideoModal = ({ 
    isOpen, 
    onClose, 
    videos // Array of {url, title} objects or single object
  }) => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    
    // Add safety checks for videos prop
    const videoArray = React.useMemo(() => {
      if (!videos) return [];
      return Array.isArray(videos) ? videos : [videos];
    }, [videos]);
  
    // If there are no videos, don't render the modal
    if (!videoArray.length) {
      return null;
    }
  
    const handleVideoEnd = () => {
      if (currentVideoIndex < videoArray.length - 1) {
        setCurrentVideoIndex(prev => prev + 1);
      }
    };
  
    const handleNext = () => {
      if (currentVideoIndex < videoArray.length - 1) {
        setCurrentVideoIndex(prev => prev + 1);
      }
    };
  
    const handlePrevious = () => {
      if (currentVideoIndex > 0) {
        setCurrentVideoIndex(prev => prev - 1);
      }
    };
  
    // Ensure currentVideoIndex is within bounds
    const safeCurrentIndex = Math.min(currentVideoIndex, videoArray.length - 1);
    const currentVideo = videoArray[safeCurrentIndex];
  
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-4xl z-[70]">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">
              {currentVideo.title}
            </h2>
          </div>
  
          <VideoPlayer
            currentVideo={currentVideo}
            onEnded={handleVideoEnd}
            onNext={handleNext}
            onPrevious={handlePrevious}
            hasNext={safeCurrentIndex < videoArray.length - 1}
            hasPrevious={safeCurrentIndex > 0}
          />
  
          {videoArray.length > 1 && (
            <ScrollArea className="mt-4 h-32">
              <div className="grid grid-cols-1 gap-2">
                {videoArray.map((video, index) => (
                  <Button
                    key={index}
                    variant={index === safeCurrentIndex ? "secondary" : "ghost"}
                    className="w-full justify-start"
                    onClick={() => setCurrentVideoIndex(index)}
                  >
                    {video.title}
                  </Button>
                ))}
              </div>
            </ScrollArea>
          )}
        </DialogContent>
      </Dialog>
    );
  };
  
  export default VideoModal;