import React, { useState } from 'react';
import { Button } from "../../../components/ui/button";
import { Lightbulb, PlusCircle, ChevronUp, ChevronDown } from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../../components/ui/tooltip";

const SuggestedPrompts = ({ selectedAssistantFull, handleSuggestionClick, expanded = false }) => {
    const [isCollapsed, setIsCollapsed] = useState(!expanded);

    const suggestedPrompts = 
        selectedAssistantFull?.interactions?.suggestedPrompts || 
        selectedAssistantFull?.suggested_prompts || 
        [];

    const hasSuggestedPrompts = suggestedPrompts.length > 0;

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="max-h-[300px] bg-background w-full overflow-y-auto rounded-lg shadow-lg">
            <div className="p-2 md:p-4">
                <div className="flex justify-between items-start cursor-pointer"
                        onClick={toggleCollapse}>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div>
                                    <h3 className="font-medium mb-2 flex items-center">
                                        <Lightbulb className="h-5 w-5 mr-1 text-yellow-500" />
                                        Suggested Prompts
                                    </h3>
                                    <div className="text-xs text-muted-foreground leading-tight">
                                        Suggested prompts are curated conversation starters that help you get the most out of your AI assistant.
                                    </div>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent
                                className="flex items-center max-w-[400px]"
                                side="top"
                                align="start"
                            >
                                <Lightbulb className="h-8 w-8 mr-2 text-yellow-500" />
                                Click on a suggested prompt to insert it into the chat input. You can include additional prompts or details in the same message.
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    {/* Collapse button - only visible on md and larger screens */}
                    <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="hidden md:flex items-center text-gray-500 hover:text-gray-700"
                    >
                        {isCollapsed ? (
                            <ChevronDown className="h-4 w-4" />
                        ) : (
                            <ChevronUp className="h-4 w-4" />
                        )}
                    </Button>
                </div>

                {/* Content - always visible on small screens, collapsible on md and larger */}
                <div className={`space-y-2 ${isCollapsed ? 'hidden md:hidden' : 'block'}`}>
                    {hasSuggestedPrompts ? (
                        <div className="space-y-2">
                            {suggestedPrompts.map((prompt, index) => (
                                <Button
                                    key={index}
                                    type="button"
                                    variant="ghost"
                                    className="w-full justify-start text-left text-black font-normal h-auto whitespace-normal border-none bg-[#fafafa] shadow-md"
                                    onClick={() => handleSuggestionClick(prompt.text || prompt)}
                                >
                                    <PlusCircle className="h-4 w-4 mr-2 text-gray-500" />
                                    {prompt.text || prompt}
                                </Button>
                            ))}
                        </div>
                    ) : (
                        <div className="p-4 text-muted-foreground">
                            No suggested prompts available
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SuggestedPrompts;