// flowStackModalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const flowStackModalSlice = createSlice({
  name: 'flowStackModal',
  initialState,
  reducers: {
    openFlowStackModal: (state) => {
      state.isOpen = true;
    },
    closeFlowStackModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openFlowStackModal, closeFlowStackModal } = flowStackModalSlice.actions;
export default flowStackModalSlice.reducer;