import React, { useState } from 'react';
import { Mail } from 'lucide-react';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Label } from './ui/label';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "./ui/tooltip";

const ShareViaEmailButton = ({ 
  content, 
  title = 'Share Content', 
  variant = 'outline', 
  size = 'sm',
  iconOnly = false,
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState(content || '');
  const [recipientEmail, setRecipientEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
    setIsOpen(false);
    setSubject('');
    setEmailBody(content || '');
    setRecipientEmail('');
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
      <DialogTrigger asChild>
        {iconOnly ? (
          <Button
            size="icon"
            variant="ghost"
            className={className}
          >
            <Mail className="h-4 w-4" />
          </Button>
        ) : (
          <Button variant={variant} size={size} className={`gap-2 border-none bg-gray-100 shadow-md ${className}`}>
            <Mail className="h-4 w-4" />
            Share via Email
          </Button>
        )}
      </DialogTrigger>
      </TooltipTrigger>
                  <TooltipContent
                    className="flex items-center"
                    side="top"
                    align="start"
                  >
                    <Mail className="h-4 w-4 mr-2" />
                    Share this content via email. You can customize the message before sending.
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
      <DialogContent className="sm:max-w-[500px] z-[60]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            Share this content via email. You can customize the message before sending. Your email client will open when you click the Draft Email button. If you do not have an email client set up, you can copy the email content and send it manually.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="recipient">Recipient Email</Label>
            <Input
              id="recipient"
              type="email"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              placeholder="recipient@example.com"
              required
              className="w-full"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="subject">Subject Line</Label>
            <Input
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter email subject"
              className="w-full"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="body">Email Body</Label>
            <Textarea
              id="body"
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
              placeholder="Enter your message"
              className="min-h-[200px]"
            />
          </div>
          <DialogFooter className="gap-2">
            <Button
              type="button"
              variant="ghost"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button type="submit" className="gap-2">
              <Mail className="h-4 w-4" />
              Draft Email
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ShareViaEmailButton;