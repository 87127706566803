import React from 'react';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import { ScrollArea } from '../../components/ui/scroll-area';
import { Users, Calendar, Sparkles, BrainCircuit } from 'lucide-react';

const UserChatbotDetails = ({ chatbot, onEditClick, onDeleteChatbot }) => {
  const handleDelete = () => {
    if (onDeleteChatbot) {
      onDeleteChatbot(chatbot._id);
    }
  };

  return (
    <ScrollArea className="h-full px-0 lg:px-0">
      <Card className="py-2 px-1 lg:px-8 border-none shadow-lg bg-white">
        {/* Header Section */}
        <div className="flex flex-col items-center mb-2 lg:mb-4 bg-gray-100 rounded-xl p-4 shadow-sm">
          <img 
            src={chatbot.avatarUrl} 
            alt={chatbot.name} 
            className="w-24 h-24 lg:w-32 lg:h-32 rounded-full mb-2 shadow-md object-cover border-2 shadow-xl border-black" 
          />
          <h2 className="text-2xl lg:text-3xl font-bold mb-3 text-gray-900">{chatbot.name}</h2>
          <p className="text-sm lg:text-base text-gray-600 text-center max-w-2xl">
            {chatbot.description}
          </p>
        </div>
        
        {/* Custom Training Details Section */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-1 lg:mb-3 text-gray-900 flex items-center">
            <BrainCircuit className="w-5 h-5 mr-2" />
            Custom Training Details
          </h3>
          <div className="bg-gray-100 p-6 rounded-xl shadow-sm">
            <p className="whitespace-pre-wrap text-sm lg:text-base text-gray-700">
              {chatbot.prompt}
            </p>
          </div>
        </div>

        {/* Teams Section 
        {chatbot.team && chatbot.team.length > 0 && (
          <div className="mb-8">
            <h3 className="text-lg font-semibold mb-3 text-gray-900 flex items-center">
              <Users className="w-5 h-5 mr-2" />
              Shared With
            </h3>
            <div className="bg-gray-100 p-4 rounded-xl shadow-sm">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {chatbot.team.map((team, index) => (
                  <div key={team._id || index} className="flex items-center bg-white p-2 rounded-lg shadow-sm">
                    <div className="w-2 h-2 rounded-full bg-green-500 mr-2"></div>
                    <span className="text-sm text-gray-700">{team.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}*/}

        {/* Suggested Prompts Section */}
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-1 lg:mb-3 text-gray-900 flex items-center">
            <Sparkles className="w-5 h-5 mr-2" />
            Suggested Prompts
          </h3>
          <div className="bg-gray-100 p-4 rounded-xl shadow-sm">
            <ul className="space-y-2">
              {chatbot.suggested_prompts && chatbot.suggested_prompts.map((prompt, index) => (
                <li 
                  key={index} 
                  className="flex items-start bg-white p-3 rounded-lg shadow-md"
                >
                  <span className="text-sm text-gray-700">{prompt}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Date Added Section */}
        <div className="mb-8">
          <div className="flex items-center text-sm text-gray-600">
            <Calendar className="w-4 h-4 mr-2" />
            <span>Added on {new Date(chatbot.date_added).toLocaleDateString()}</span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row gap-3 mt-6">
          <Button 
            variant="outline" 
            onClick={onEditClick}
            className="flex-1 sm:flex-none sm:min-w-[120px] bg-white hover:bg-gray-100"
          >
            Edit
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button 
                variant="destructive"
                className="flex-1 sm:flex-none sm:min-w-[120px]"
              >
                Delete
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="max-w-[90vw] lg:max-w-lg z-[100]">
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the chatbot
                  and remove its data from our servers.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex-col sm:flex-row gap-2">
                <AlertDialogCancel className="w-full sm:w-auto">Cancel</AlertDialogCancel>
                <AlertDialogAction 
                  onClick={handleDelete}
                  className="w-full sm:w-auto"
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </Card>
    </ScrollArea>
  );
};

export default UserChatbotDetails;