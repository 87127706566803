import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from './ui/button';
import { Popover, PopoverTrigger, PopoverContent } from './ui/popover';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './ui/command';
import { Check, ChevronsUpDown, Gem, Shield } from 'lucide-react';
import { cn } from '../lib/utils';
import { addTeamToRecord } from '../services/api';
import { useToast } from './ui/use-toast';

const AddToTeamButton = ({ model, _id, onSuccess=null }) => {
    const { toast } = useToast();
    const { teams } = useSelector((state) => state.teams);
    const [open, setOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
  
    const filteredTeams = teams.filter(team => !['All Teams', 'Personal'].includes(team.name));
  
    const handleSave = async () => {
      if (selectedTeam) {
        try {
          await addTeamToRecord(model, _id, selectedTeam._id);
          toast({
            title: `Team added`,
            description: `The team has been successfully added.`,
            variant: "success",
          });
          setOpen(false);
          if (onSuccess) {
            onSuccess();
          }
          setSelectedTeam(null);
        } catch (error) {
            toast({
                title: "Error",
                description: "Error adding team. Please try again.",
                variant: "destructive",
              });
        }
      }
    };
  
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[200px] justify-between bg-[#fafafa] text-black border-none shadow-sm"
          >
            {selectedTeam ? selectedTeam.name : "Add to Team"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[280px] p-0 z-[80]">
          <Command>
            <CommandInput placeholder="Search team..." />
            <CommandList>
              <CommandEmpty>No team found.</CommandEmpty>
              <CommandGroup heading="Available Teams">
                {filteredTeams.map((team) => (
                  <CommandItem
                    key={team._id}
                    onSelect={() => setSelectedTeam(team)}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedTeam?._id === team._id ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {team.isSeatsTeam && <Gem className="mr-2 h-4 w-4 text-yellow-500" />}
                    {team.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
          <div className="flex justify-end space-x-2 p-2">
            <Button variant="outline" onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleSave} disabled={!selectedTeam}>Save</Button>
          </div>
        </PopoverContent>
      </Popover>
    );
  };
  
  export default AddToTeamButton;
  