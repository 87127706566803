// src/pages/SchedulingComponents/EditScheduleForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchedule } from '../../slices/schedulesSlice';
import { Button } from '../../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import { timezones } from '../../services/timezones';
import ScheduleConfigForm from './ScheduleConfigForm';
import { getLocalTimezone } from '../../utils/timezone';
import { Gem, Shield } from 'lucide-react';

const EditScheduleForm = ({ schedule, onCancel }) => {
  const dispatch = useDispatch();
  const { flows } = useSelector((state) => state.flows);
  const { teams } = useSelector((state) => state.teams);
  const [formData, setFormData] = useState({
    type: '',
    config: {},
    timezone: '',
    team: null
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (schedule) {
      setFormData({
        flowId: schedule.flow._id,
        type: schedule.type,
        config: schedule.config || {},
        timezone: schedule.timezone || getLocalTimezone(), // Use schedule timezone if exists, otherwise use local
        team: schedule.team?.[0]?._id || null
      });
    }
  }, [schedule]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = useCallback((name, value) => {
    setFormData(prevData => {
      if (name === 'type') {
        // Reset config when changing schedule type
        return { ...prevData, [name]: value, config: {} };
      }
      // For team selection, convert "null" string to actual null
      if (name === 'team') {
        return { ...prevData, [name]: value === "null" ? null : value };
      }
      return { ...prevData, [name]: value };
    });
  }, []);

  const handleConfigChange = useCallback((config) => {
    setFormData(prevData => ({ ...prevData, config }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare the update data
      const updateData = {
        type: formData.type,
        config: formData.config,
        timezone: formData.timezone,
        team: formData.team ? [formData.team] : []
      };

      await dispatch(updateSchedule({ 
        id: schedule._id, 
        scheduleData: updateData 
      })).unwrap();
      
      onCancel(); // Close the edit form
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-inter font-bold text-2xl mb-4">Edit Schedule</h2>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      
        {/* Flow ID Display (read-only) */}
        <div className="lg:w-1/2">
          <label className="block font-bold text-gray-900 lg:text-lg">Flow</label>
          <span className='text-sm text-gray-500'>(The selected flow cannot be edited once a schedule is created.)</span>
          <div className="mt-1 p-2 text-sm border-none rounded-md bg-gray-100">
            {schedule?.flow?.name}
          </div>
        </div>

        {/* Team Selection */}
        <div className="lg:w-1/2">
          <label htmlFor="team" className="block font-bold text-gray-900 lg:text-lg">Team</label>
          <Select 
            name="team" 
            onValueChange={(value) => handleSelectChange('team', value)} 
            value={formData.team ?? "null"}
          >
            <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
              <SelectValue placeholder="Select a team (optional)" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="null">No team</SelectItem>
              {teams.map((team) => (
                <SelectItem key={team._id} value={team._id}><div className='flex items-center'>{team.isSeatsTeam && <Gem className='h-4 w-4 text-yellow-500 mr-1' />}{team.name}</div></SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

      {/* Schedule Type Selection */}
      <div className="lg:w-1/2">
        <label htmlFor="type" className="block font-bold text-gray-900 lg:text-lg">Schedule Type</label>
        <Select 
          name="type" 
          onValueChange={(value) => handleSelectChange('type', value)} 
          value={formData.type}
        >
          <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
            <SelectValue placeholder="Select schedule type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="hourly">Hourly</SelectItem>
            <SelectItem value="daily">Daily</SelectItem>
            <SelectItem value="twice_daily">Twice Daily</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="custom">Custom</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Schedule Config Form */}
      <ScheduleConfigForm
        type={formData.type}
        config={formData.config}
        onChange={handleConfigChange}
      />

      {/* Timezone Selection */}
      <div className="lg:w-1/2">
        <label htmlFor="timezone" className="block font-bold text-gray-900 lg:text-lg">Timezone</label>
        <Select 
          name="timezone" 
          onValueChange={(value) => handleSelectChange('timezone', value)} 
          value={formData.timezone}
        >
          <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
            <SelectValue placeholder="Select a timezone" />
          </SelectTrigger>
          <SelectContent>
            {timezones.map((tz) => (
              <SelectItem key={tz.value} value={tz.value}>{tz.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={onCancel}>Cancel</Button>
        <Button type="submit">Update Schedule</Button>
      </div>
    </form>
  );
};

export default EditScheduleForm;