// components/dashboard/SocialPostCard.jsx
import React, { useState } from 'react';
import { 
  Card, 
  CardContent
} from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from '../../components/ui/dropdown-menu';
import { Badge } from '../../components/ui/badge';
import { 
  Edit, 
  Trash2, 
  MoreVertical,
  AlertCircle, 
  Clock, 
  CheckCircle2,
  XCircle,
  Share2
} from 'lucide-react';
import EditSocialPostModal from './EditSocialPostModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// Pure presentational component - no data fetching
const SocialPostCard = ({ post }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleEdit = () => setEditModalOpen(true);
  const handleDelete = () => setDeleteModalOpen(true);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <AlertCircle className="h-4 w-4 text-yellow-500" />;
      case 'in-progress':
        return <Clock className="h-4 w-4 text-blue-500" />;
      case 'completed':
        return <CheckCircle2 className="h-4 w-4 text-green-500" />;
      case 'rejected':
        return <XCircle className="h-4 w-4 text-red-500" />;
      default:
        return <AlertCircle className="h-4 w-4" />;
    }
  };

  const getStatusBadge = (status) => {
    let variant = 'outline';
    
    switch (status) {
      case 'pending':
        variant = 'warning';
        break;
      case 'in-progress':
        variant = 'secondary';
        break;
      case 'completed':
        variant = 'success';
        break;
      case 'rejected':
        variant = 'destructive';
        break;
    }
    
    return (
      <Badge variant={variant} className="flex items-center gap-1">
        {getStatusIcon(status)}
        <span className="capitalize">{status}</span>
      </Badge>
    );
  };

  // Social platform badge with appropriate colors
  const getPlatformBadge = (platform) => {
    let color = "bg-gray-100 text-gray-700";
    
    switch (platform.toLowerCase()) {
      case 'facebook':
        color = "bg-blue-100 text-blue-700";
        break;
      case 'twitter':
      case 'x':
        color = "bg-sky-100 text-sky-700";
        break;
      case 'instagram':
        color = "bg-pink-100 text-pink-700";
        break;
      case 'linkedin':
        color = "bg-blue-100 text-blue-800";
        break;
      case 'tiktok':
        color = "bg-black text-white";
        break;
    }
    
    return (
      <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${color}`}>
        {platform}
      </span>
    );
  };

  return (
    <>
      <Card className="overflow-hidden shadow-md hover:shadow-lg transition-shadow mb-4 w-full">
        <CardContent className="p-0">
          <div className="grid grid-cols-12 gap-2 items-center">
            {/* Social Post Icon - hidden on mobile, shown on larger screens */}
            <div className="col-span-12 sm:col-span-3 md:col-span-2 lg:col-span-1 hidden lg:block">
              <div className="flex items-center justify-center aspect-square sm:aspect-auto sm:h-16 bg-muted">
                <Share2 className="h-10 w-10 text-indigo-500" />
              </div>
            </div>

            {/* Main Content */}
            <div className="col-span-12 sm:col-span-9 md:col-span-10 lg:col-span-11 p-4 sm:p-3">
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 items-center">
                {/* Title and Topics */}
                <div className="sm:col-span-4 lg:col-span-5">
                  <h3 className="text-base font-semibold line-clamp-1">{post.topics[0]?.topic || 'Untitled'}</h3>
                  <p className="text-xs text-muted-foreground">
                    {post.topics.length > 1 ? `${post.topics.length} topics defined` : '1 topic defined'}
                  </p>
                </div>

                {/* Social Post Details */}
                <div className="sm:col-span-4 lg:col-span-3 text-sm">
                  <div className="grid grid-cols-2 gap-x-4">
                    <div className="col-span-2 mb-1">
                      <span className="text-muted-foreground">Platform:</span>
                      <span className="ml-1">{getPlatformBadge(post.platform)}</span>
                    </div>
                    <div>
                      <span className="text-muted-foreground">Type:</span>
                      <span className="font-medium ml-1 capitalize">{post.socialContentType}</span>
                    </div>
                  </div>
                </div>

                {/* Status and Dates */}
                <div className="sm:col-span-2 lg:col-span-3 text-xs">
                  {/* <div className="mb-2">{getStatusBadge(post.status)}</div> */}
                  <p className="text-muted-foreground">
                    Last Updated: {new Date(post.updatedAt).toLocaleDateString()}
                  </p>
                  <p className="text-muted-foreground">
                    Created: {new Date(post.createdAt).toLocaleDateString()}
                  </p>
                  {post.status === 'completed' && 
                    <p className="text-muted-foreground">
                      Completed: {new Date(post.completionDate).toLocaleDateString()}
                    </p>
                  }
                </div>

                {/* Actions */}
                <div className="sm:col-span-2 lg:col-span-1 flex justify-end gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={handleEdit} disabled={post.status === 'completed'}>
                        <Edit className="mr-2 h-4 w-4" />
                        <span>Edit</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={handleDelete} disabled={post.status === 'completed'} className="text-red-600">
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Delete</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {editModalOpen && (
        <EditSocialPostModal
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          socialPost={post}
        />
      )}

      {deleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          contentId={post._id}
          contentType="SocialPost"
          contentName={post.topics[0]?.topic || 'this social post'}
        />
      )}
    </>
  );
};

export default SocialPostCard;