import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../../components/ui/button";
import { Play, ChevronDown, HelpCircle, ArrowRight } from "lucide-react";
import { useDispatch } from 'react-redux';
import { openVideoModal } from '../../slices/videoModalSlice';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
  } from "../../components/ui/collapsible";

const VideoTutorials = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const tutorials = [
    {
      title: "How to Create A Flow From Scratch",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Flow%20form%20Scratch%20%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Manage Your Flows",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Manage%20Your%20Flows%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Build a Pre-Made Flow",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Pre-Made%20Flow%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "What is Flow Orchestra?",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/What%20is%20Flow%20Orchestra%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Build a Prompt Stack",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Prompt%20Stack%20%20-%20Explainer%20Vid.mp4",
    }
  ];

  const handleOpenVideo = (video) => {
    dispatch(openVideoModal({
      url: video.url,
      title: video.title
    }));
  };

  return (
    <div className="w-full px-4 pt-1 pb-2 lg:pb-4 lg:pt-2 border-none shadow-lg rounded-lg bg-[#fafafa]">
      {/* Desktop View */}
      <div className="hidden sm:block">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center justify-between flex-1 mr-4">
            <h2 className="text-xl font-semibold mb-1">Video Tutorials</h2>
            <div className="text-md text-black flex items-center">
              <HelpCircle className='mr-1 h-4 w-4' />
              Not sure how to get started? Watch these short explainer videos to learn more.
            </div>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => navigate('/documentation')}
            className="whitespace-nowrap bg-gray-400 text-gray-100 hover:bg-gray-100 hover:text-black"
          >
            See All
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
          {tutorials.map((tutorial, index) => (
            <Button
            key={index}
            variant="outline"
            size="sm"
            className="group text-gray-100 hover:text-black bg-black hover:bg-gray-100 transition-colors duration-200 w-full"
            onClick={() => handleOpenVideo(tutorial)}
          >
            <div className="flex items-center justify-start w-full">
              <Play className="h-4 w-4 mr-2 flex-shrink-0 text-gray-100 group-hover:text-black" />
              <span className="truncate">{tutorial.title}</span>
            </div>
          </Button>
          ))}
        </div>
      </div>

      {/* Mobile View with Collapsible */}
      <div className="sm:hidden">
        <Collapsible
          open={isOpen}
          onOpenChange={setIsOpen}
          className="w-full space-y-2"
        >
          <div className="flex items-center justify-between space-x-4 px-1">
            <h2 className="text-xl font-semibold">Video Tutorials</h2>
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="w-9 p-0"
              >
                <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${
                  isOpen ? "transform rotate-180" : ""
                }`}/>
                <span className="sr-only">Toggle video tutorials</span>
              </Button>
            </CollapsibleTrigger>
          </div>
          
          <CollapsibleContent className="space-y-2">
            {tutorials.map((tutorial, index) => (
              <Button
                key={index}
                variant="outline"
                size="sm"
                className="text-gray-100 hover:text-gray-800 transition-colors duration-200 w-full bg-black"
                onClick={() => handleOpenVideo(tutorial)}
              >
                <div className="flex items-center justify-start w-full">
                  <Play className="h-4 w-4 mr-2 flex-shrink-0" />
                  <span className="truncate">{tutorial.title}</span>
                </div>
              </Button>
            ))}
            <Button
              variant="outline"
              size="sm"
              onClick={() => navigate('/documentation')}
              className="w-full bg-white hover:bg-white hover:text-green-500"
            >
              See All Videos
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};

export default VideoTutorials;