import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { useToast } from "../../components/ui/use-toast";
import { cloneGlobalFlow, updateFlow, fetchFlowById } from "../../slices/flowsSlice";
import { generateImage } from "../../slices/imageGenerationSlice";
import { startExecution } from "../../slices/executionsSlice";
import { updateCredits } from "../../slices/authSlice";
import useSubscription from "../../hooks/useSubscription";
import QuestionSection from "./QuestionSection";
import ResponseSection from "./ResponseSection";
import NavigationSection from "./NavigationSection";
import AddContextModal from "../FlowFormComponents/AddContextModal";
import { submitDashboardAgent, getDashboardSuggestions, getDashboardSuggestedPrompts, getDashboardSuggestedFlowStacks, fetchGlobalFlowByIdForDash, submitSingleTurnChat, autoCreateTodo } from "../../services/api";
import {
  setQuestion,
  setResponse,
  setIsLoadingResponse,
  setIsLoadingSuggestions,
  setFetchedSuggestions,
  setFetchSuggestedPrompts,
  setSuggestedFlowStacks,
  setContextSteps,
  setExecutingFlowId,
  setExecutingFlow,
  setShowContextModal,
  setAdditionalChatDetails,
  setAdditionalImageDetails,
  setShowAdditionalStrategyDetails,
  setShowAdditionalChatPromptDetails,
  setShowAdditionalImagePromptDetails,
  setAdditionalStrategyDetails,
  setChatPromptResponse,
  setStrategyResponse,
  setIsLoadingSecondaryResponse,
  setIsCreatingTodo,
  setLocalQuestion,
  resetDashboardState
} from "../../slices/dashboardAgentSlice";

const DashboardAgent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  
  // Get image generation state
  const { currentImage } = useSelector((state) => state.imageGeneration);
  
  // Get dashboard agent state from Redux
  const {
    question,
    response,
    isLoadingResponse,
    isLoadingSuggestions,
    fetchedSuggestions,
    fetchSuggestedPrompts,
    suggestedFlowStacks,
    contextSteps,
    executingFlowId,
    executingFlow,
    showContextModal,
    additionalChatDetails,
    additionalImageDetails,
    showAdditionalStrategyDetails,
    showAdditionalChatPromptDetails,
    showAdditionalImagePromptDetails,
    additionalStrategyDetails,
    chatPromptResponse,
    strategyResponse,
    isLoadingSecondaryResponse,
    isCreatingTodo,
    localQuestion
  } = useSelector((state) => state.dashboardAgent);

  const { loading: subscriptionLoading, credits, limits } = useSubscription();

  const { selectedTeam, selectedTeamFull } = useSelector(
    (state) => state.teams
  );

  const submitQuestion = async (e) => {
    e.preventDefault();
    dispatch(setIsLoadingSuggestions(true));
    dispatch(setIsLoadingResponse(true));
    setTimeout(() => dispatch(setIsLoadingResponse(false)), 5200);
    
    try {
      dispatch(setLocalQuestion(question));
      const localLocalQuestion = question;
      dispatch(setQuestion("Got it. Give me just a few moments to mull it over..."));
      
      const response = await submitDashboardAgent(
        { question: localLocalQuestion },
        selectedTeam || null,
        limits.flowSteps || null
      );
      
      let suggestions;
      if (typeof response.data.suggestions === "string") {
        try {
          suggestions = JSON.parse(response.data.suggestions);
        } catch (parseError) {
          suggestions = response.data.suggestions;
        }
      } else {
        suggestions = response.data.suggestions;
      }
      
      dispatch(setResponse(suggestions));

      if (suggestions?.suggestedTypes?.flows || suggestions?.suggestedTypes?.promptStacks) {
        const suggestedFlowStacksPromise = await getDashboardSuggestedFlowStacks({
          question: localLocalQuestion,
          suggestions: suggestions,
          team: selectedTeam || null,
          flowStepsLimit: limits.flowSteps || null,
        });
        dispatch(setSuggestedFlowStacks(suggestedFlowStacksPromise.data));
        dispatch(setIsLoadingSuggestions(false));
      }
      
      // Call both getDashboardSuggestions and getDashboardSuggestedPrompts concurrently
      const suggestionsPromise = getDashboardSuggestions({
        question: localLocalQuestion,
        suggestions: suggestions,
        team: selectedTeam || null,
        flowStepsLimit: limits.flowSteps || null
      });
      
      const suggestedPromptsPromise = getDashboardSuggestedPrompts({
        question: localLocalQuestion,
        suggestions: suggestions,
        team: selectedTeam || null,
        flowStepsLimit: limits.flowSteps || null
      });
      
      // Build an array for the race, including the flow stacks promise only if it exists.
      const racePromises = [suggestionsPromise, suggestedPromptsPromise];
      
      // As soon as any promise returns, turn off the loading indicator.
      Promise.race(racePromises)
        .then(() => dispatch(setIsLoadingSuggestions(false)))
        .catch(() => dispatch(setIsLoadingSuggestions(false)));
      
      // Update state as each promise resolves individually.
      suggestionsPromise
        .then((suggestionsResponse) => {
          console.log("suggestionsResponse.data", suggestionsResponse.data);
          dispatch(setFetchedSuggestions(suggestionsResponse.data));
        })
        .catch((err) => {
          console.error("Error fetching suggestions:", err);
        });
      
      suggestedPromptsPromise
        .then((suggestedPromptsResponse) => {
          console.log("suggestedPromptsResponse.data", suggestedPromptsResponse.data);
          dispatch(setFetchSuggestedPrompts(suggestedPromptsResponse.data));
        })
        .catch((err) => {
          console.error("Error fetching suggested prompts:", err);
        });      
      
    } catch (error) {
      dispatch(setResponse("An error occurred. Please try again."));
    }
  };  

  const handleNewQuestion = () => {
    dispatch(resetDashboardState());
  };

  const handleCreateTodo = async (e) => {
    e.stopPropagation();
    dispatch(setIsCreatingTodo(true));
    const allSuggestions = {
      assistants: fetchedSuggestions?.data?.suggestions?.suggestions?.assistants || [],
      imageGenerator: fetchedSuggestions?.data?.suggestions?.suggestions?.imageGenerator || [],
      flows: fetchedSuggestions?.data?.suggestions?.flows || [],
      promptStacks: fetchedSuggestions?.data?.suggestions?.promptStacks || [],
      prompts: fetchSuggestedPrompts?.data?.suggestions?.assistants || [],
    }
    const suggestions = JSON.stringify(allSuggestions, null, 2);
    const todoData = await autoCreateTodo({
      question: localQuestion || null,
      suggestions: suggestions || null,
      teamId: selectedTeam || null,
    });
    toast({
      title: "Success",
      description: "Todo created successfully! Check your Todo List.",
      variant: "default",
    });
    dispatch(setIsCreatingTodo(false));
  }

  const handleChatNow = (e, assistantId) => {
    e.stopPropagation();
    navigate(`/chat?assistantId=${assistantId}`);
  };

  const handleGenerateImage = (e, styleId) => {
    e.stopPropagation();
    navigate(`/image-generation?styleId=${styleId}`);
  };

  const handleRunFlow = async (e, id) => {
    e.stopPropagation();
    if (!id) return;
    const newFlowForRun = await fetchGlobalFlowByIdForDash(id, selectedTeam || null);
    dispatch(setExecutingFlowId(newFlowForRun.data._id));
    dispatch(setExecutingFlow(newFlowForRun.data));
    
    const addContextSteps = newFlowForRun.data.steps.filter(step => step.type === 'addContext');
    if (addContextSteps.length > 0) {
      dispatch(setContextSteps(addContextSteps));
      dispatch(setShowContextModal(true));
      return;
    }
    proceedWithExecution(newFlowForRun.data._id);
  };
  
  const proceedWithExecution = async (id) => {
    dispatch(updateCredits({ type: "execution", amount: 1, subtract: true }));
    
    try {
      const result = await dispatch(
        startExecution({
          flowId: id,
          teamId: selectedTeam || null,
        })
      ).unwrap();
      
      navigate(`/execution/${result.executionId}`, {
        state: { isNewExecution: true },
      });
    } catch (error) {
      dispatch(updateCredits({ type: "execution", amount: 1, subtract: false }));
      toast({
        title: "Error",
        description: "Failed to start execution: " + error.message,
        variant: "destructive",
      });
    }
  };

  const handleContextSubmit = async (updatedPrompts) => {
    if (!executingFlowId) return;
  
    const flow = executingFlow;
    if (!flow) return;
    const updatedSteps = flow.steps.map(step => {
      if (step.type === 'addContext' && updatedPrompts[step._id] !== undefined) {
        return { ...step, prompt: updatedPrompts[step._id] };
      }
      return step;
    });
  
    try {
      await handleSaveFlowForRun({ ...flow, steps: updatedSteps });
      dispatch(setShowContextModal(false));
      proceedWithExecution(executingFlowId);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update flow: " + error.message,
        variant: "destructive",
      });
    }
  };

  const handleSaveFlowForRun = async (flowData) => {
    try {
      await dispatch(updateFlow({ 
        id: flowData._id,
        flowData 
      })).unwrap();
      
      await dispatch(fetchFlowById(flowData._id)).unwrap();
      
    } catch (err) {
      console.error('Error in handleSave:', err);
      toast({
        title: "Error",
        description: "Failed to save flow",
        variant: "destructive",
      });
    }
  };

  const handleSaveFlow = (e, id) => {
    e.stopPropagation();
    dispatch(cloneGlobalFlow(id, selectedTeam || null))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Flow saved successfully",
          variant: "default",
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to save flow: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleSendMessage = async (e, message, purpose=null) => {
    e.stopPropagation();
    if (!purpose) {
      return;
    }
    dispatch(setIsLoadingSecondaryResponse(true));
    const assistantId = fetchedSuggestions?.data?.suggestions?.assistants?.assistants[0]?.assistantId || null;

    let finalMessage = message;
    if (purpose === "chatPrompt") {
      dispatch(setChatPromptResponse(null));
      finalMessage = message + " /// " + additionalChatDetails;
    } else if (purpose === "strategy") {
      dispatch(setStrategyResponse(null));
      const allSuggestions = {
        assistants: fetchedSuggestions?.data?.suggestions?.suggestions?.assistants || [],
        imageGenerator: fetchedSuggestions?.data?.suggestions?.suggestions?.imageGenerator || [],
        flows: fetchedSuggestions?.data?.suggestions?.flows || [],
        promptStacks: fetchedSuggestions?.data?.suggestions?.promptStacks || [],
        prompts: fetchSuggestedPrompts?.data?.suggestions?.assistants || [],
      }
      const allSuggestionsString = JSON.stringify(allSuggestions, null, 2);
      finalMessage = message + " /// Suggestions: " + allSuggestionsString + " /// Additional context: " + additionalStrategyDetails;
    }

    const singleTurnChatResponse = await submitSingleTurnChat({
      message: finalMessage,
      assistantId: assistantId,
      teamId: selectedTeam || null,
    })
    if (purpose === "chatPrompt") {
      dispatch(setChatPromptResponse(singleTurnChatResponse.data.singleTurnResponse));
    } else if (purpose === "strategy") {
      dispatch(setStrategyResponse(singleTurnChatResponse.data.singleTurnResponse));
    }
    dispatch(setIsLoadingSecondaryResponse(false));
  };

  const handleImagePromptClick = async (e, prompt) => {
    e.stopPropagation();
    dispatch(setIsLoadingSecondaryResponse(true));

    if (credits.image <= 0) {
      toast({
        title: "Error",
        description:
          "You have run out of image credits. Please upgrade your subscription to generate more images.",
        variant: "destructive",
      });
      return;
    }
  
    dispatch(updateCredits({ type: 'image', amount: 1, subtract: true }));
    dispatch(generateImage({
      prompt: `${prompt} /// ${additionalImageDetails}`,
      size: "1024x1024",
      styleId: "",
      styleModelName: "ImageStyle",
      teamId: selectedTeam || null,
    }))
    .unwrap()
    .then(() => {
      dispatch(setIsLoadingSecondaryResponse(false));
    })
    .catch(error => {
      console.log(error.message);
      dispatch(updateCredits({ type: 'image', amount: 1, subtract: false }));
    });
  };

  const isSubscriptionLoading = subscriptionLoading;

  if (isSubscriptionLoading || !credits || !limits) {
    return (
      <Alert>
        <AlertTitle>Loading</AlertTitle>
        <AlertDescription>Loading subscription data...</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="space-y-2 lg:space-y-6 pb-4">
      {/* Main Content Section - Shows either Question or Response */}
      <div className="min-h-[80vh] flex flex-col">
        {(!isLoadingSuggestions && !response) || isLoadingResponse ? (
          <QuestionSection
            question={question}
            setQuestion={(newQuestion) => dispatch(setQuestion(newQuestion))}
            submitQuestion={submitQuestion}
            isLoadingResponse={isLoadingResponse}
          />
        ) : (
          <ResponseSection
            response={response}
            onNewQuestion={handleNewQuestion}
            isLoadingSuggestions={isLoadingSuggestions}
            fetchedSuggestions={fetchedSuggestions}
            fetchSuggestedPrompts={fetchSuggestedPrompts}
            suggestedFlowStacks={suggestedFlowStacks}
            setAdditionalChatDetails={(details) => dispatch(setAdditionalChatDetails(details))}
            additionalChatDetails={additionalChatDetails}
            setAdditionalImageDetails={(details) => dispatch(setAdditionalImageDetails(details))}
            additionalImageDetails={additionalImageDetails}
            setAdditionalStrategyDetails={(details) => dispatch(setAdditionalStrategyDetails(details))}
            additionalStrategyDetails={additionalStrategyDetails}
            setShowAdditionalStrategyDetails={(show) => dispatch(setShowAdditionalStrategyDetails(show))}
            setShowAdditionalChatPromptDetails={(show) => dispatch(setShowAdditionalChatPromptDetails(show))}
            setShowAdditionalImagePromptDetails={(show) => dispatch(setShowAdditionalImagePromptDetails(show))}
            showAdditionalStrategyDetails={showAdditionalStrategyDetails}
            showAdditionalChatPromptDetails={showAdditionalChatPromptDetails}
            showAdditionalImagePromptDetails={showAdditionalImagePromptDetails}
            onChatNow={handleChatNow}
            onGenerateImage={handleGenerateImage}
            onSaveFlow={handleSaveFlow}
            onRunFlow={handleRunFlow}
            onSendMessage={handleSendMessage}
            onImagePromptClick={handleImagePromptClick}
            currentImage={currentImage}
            chatPromptResponse={chatPromptResponse}
            strategyResponse={strategyResponse}
            isLoadingSecondaryResponse={isLoadingSecondaryResponse}
            setIsLoadingSecondaryResponse={(loading) => dispatch(setIsLoadingSecondaryResponse(loading))}
            onCreateTodo={handleCreateTodo}
            isCreatingTodo={isCreatingTodo}
          />
        )}
      </div>

      {/* Navigation Section */}
      <NavigationSection />
      <AddContextModal
        isOpen={showContextModal}
        onClose={() => {
          dispatch(setShowContextModal(false));
          dispatch(setExecutingFlowId(null));
          dispatch(setContextSteps([]));
        }}
        contextSteps={contextSteps}
        onSubmit={handleContextSubmit}
        isSubmitting={false}
      />
    </div>
  );
};

export default DashboardAgent;