import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { 
  fetchSavedAssets, 
  deleteSavedAsset,
  updateAssetStatus,
  markAssetAsRead
} from "../slices/savedAssetsSlice";
import {
  fetchNotifications,
  markNotificationAsRead,
  markNotificationAsUnread,
  deleteNotification,
  deleteAllNotifications,
  markAllNotificationsAsRead,
} from "../slices/notificationsSlice";

// UI Components
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import {
  LayoutDashboard,
  FileText,
  ShoppingBag,
  Image,
  Share2,
  BookOpen,
  Bell,
  RefreshCw,
  HelpCircle,
  ListView,
  Grid3X3,
  Globe,
  Pencil
} from "lucide-react";

// Dashboard Components
import AssetTypeCard from "./AssetsDashboardComponents/AssetTypeCard";
import AssetsList from "./AssetsDashboardComponents/AssetsList";
import AssetsTable from "./AssetsDashboardComponents/AssetsTable";
import AssetDetail from "./AssetsDashboardComponents/AssetDetail";
import NotificationsPanel from "./AssetsDashboardComponents/NotificationsPanel";
import DocumentationPanel from "./AssetsDashboardComponents/DocumentationPanel";
import DashboardHeader from "./AssetsDashboardComponents/DashboardHeader";
import DashboardStats from "./AssetsDashboardComponents/DashboardStats";
import EmptyState from "./AssetsDashboardComponents/EmptyState";
import RecentAssetsPanel from "./AssetsDashboardComponents/RecentAssetsPanel";
import ContentDashboard from "./ContentDashboard";

const AssetsDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Redux state
  const { 
    assets, 
    status: savedAssetsStatus, 
    error: savedAssetsError 
  } = useSelector((state) => state.savedAssets);
  
  const { 
    notifications, 
    status: notificationsStatus, 
    error: notificationsError 
  } = useSelector((state) => state.notifications);

  // Local state
  const [activeTab, setActiveTab] = useState("overview");
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState("cards"); // cards or table
  const [showDocumentation, setShowDocumentation] = useState(false);
  
  // Asset type definitions with metadata
  const assetTypes = [
    { id: "blog-posts", name: "Blog Posts", icon: <FileText className="h-6 w-6" /> },
    { id: "social-posts", name: "Social Posts", icon: <Share2 className="h-6 w-6" /> },
    { id: "general-assets", name: "General Content", icon: <Globe className="h-6 w-6" /> },
    { id: "general-images", name: "Images", icon: <Image className="h-6 w-6" /> },
    { id: "products", name: "Products", icon: <ShoppingBag className="h-6 w-6" /> }
  ];

    {/*{ id: "courses", name: "Courses", icon: <BookOpen className="h-6 w-6" /> }*/}
  // Fetch all asset types on component mount
  useEffect(() => {
    // Fetch all asset types
    assetTypes.forEach(type => {
      dispatch(fetchSavedAssets(type.id));
    });
    
    // Fetch notifications
    dispatch(fetchNotifications());
  }, [dispatch]);

  // Calculate asset counts
  const assetTypeCounts = assetTypes.reduce((acc, type) => {
    acc[type.id] = assets.filter(asset => asset.assetType === type.id).length;
    return acc;
  }, {});
  
  // Add notification count
  assetTypeCounts["notifications"] = notifications.length;
  
  // Calculate unread counts
  const unreadCounts = assetTypes.reduce((acc, type) => {
    acc[type.id] = assets.filter(
      asset => asset.assetType === type.id && (asset.isRead === false || asset.isRead === null)
    ).length;
    return acc;
  }, {});
  
  // Add unread notification count
  unreadCounts["notifications"] = notifications.filter(n => !n.isRead).length;

  // Handle refreshing assets
  const handleRefresh = () => {
    if (selectedAssetType) {
      dispatch(fetchSavedAssets(selectedAssetType));
    } else if (activeTab === "notifications") {
      dispatch(fetchNotifications());
    } else {
      // Refresh all
      assetTypes.forEach(type => {
        dispatch(fetchSavedAssets(type.id));
      });
      dispatch(fetchNotifications());
    }
  };

  // Handle selecting an asset
  const handleSelectAsset = (assetId, assetType) => {
    setSelectedAssetId(assetId);
    
    // Mark as read
    if (assetType === "notifications") {
      dispatch(markNotificationAsRead(assetId));
    } else {
      dispatch(markAssetAsRead({ assetType, id: assetId }));
    }
  };

  const handleCloseDetails = () => {
    setSelectedAssetId(null);
    };

  // Handle deleting an asset
  const handleDeleteAsset = (assetId, assetType) => {
    if (assetType === "notifications") {
      dispatch(deleteNotification(assetId))
        .unwrap()
        .then(() => {
          if (selectedAssetId === assetId) {
            setSelectedAssetId(null);
          }
        });
    } else {
      dispatch(deleteSavedAsset({ assetType, id: assetId }))
        .unwrap()
        .then(() => {
          if (selectedAssetId === assetId) {
            setSelectedAssetId(null);
          }
        });
    }
  };

  // Handle updating asset status
  const handleUpdateStatus = (assetId, assetType, status) => {
    dispatch(updateAssetStatus({ assetType, id: assetId, status }));
  };

  // Filter assets based on selected type and search term
  const getFilteredAssets = () => {
    if (!selectedAssetType) return [];
    
    const typeAssets = assets.filter(asset => asset.assetType === selectedAssetType);
    
    if (!searchTerm) return typeAssets;
    
    return typeAssets.filter(asset => {
      const searchFields = [
        asset.title,
        asset.name,
        asset.content,
        asset.message,
        asset.description,
        asset.course?.post_title,
        asset.course?.post_content
      ];
      
      return searchFields.some(field => 
        field && field.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  // Get the selected asset object
  const selectedAsset = selectedAssetId ? 
    (selectedAssetType === "notifications" 
      ? notifications.find(n => n._id === selectedAssetId)
      : assets.find(a => a._id === selectedAssetId)) 
    : null;

  return (
    <div className="flex flex-col h-full pb-8">
      <DashboardHeader 
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onRefresh={handleRefresh}
        viewMode={viewMode}
        setViewMode={setViewMode}
        showDocumentation={showDocumentation}
        setShowDocumentation={setShowDocumentation}
        selectedAssetType={selectedAssetType}
        assetTypes={assetTypes}
      />

      {showDocumentation ? (
        <DocumentationPanel onClose={() => setShowDocumentation(false)} />
      ) : (
        <Tabs value={activeTab} onValueChange={setActiveTab} className="flex-1 h-full">
          <TabsList className="flex justify-start w-full rounded-none bg-transparent p-0">
            <TabsTrigger
              value="overview"
              className="px-6 py-3 rounded-t-lg data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-x data-[state=active]:border-b-0 border-gray-200 dark:border-gray-700 transition"
              onClick={() => {
                setSelectedAssetType(null);
                setSelectedAssetId(null);
              }}
            >
              <LayoutDashboard className="h-5 w-5 mr-2" />
              Overview
            </TabsTrigger>
            
            <TabsTrigger
              value="notifications"
              className="px-6 py-3 rounded-t-lg data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-x data-[state=active]:border-b-0 border-gray-200 dark:border-gray-700 transition"
              onClick={() => {
                setSelectedAssetType("notifications");
                setSelectedAssetId(null);
              }}
            >
              <Bell className="h-5 w-5 mr-2" />
              Notifications
              {unreadCounts["notifications"] > 0 && (
                <span className="ml-2 text-indigo-500 text-xs font-bold border border-indigo-500 rounded-full px-2 py-1">
                  {unreadCounts["notifications"]}
                </span>
              )}
            </TabsTrigger>

            <TabsTrigger
              value="content-dashboard"
              className="px-6 py-3 rounded-t-lg data-[state=active]:bg-white dark:data-[state=active]:bg-gray-800 data-[state=active]:border-t data-[state=active]:border-x data-[state=active]:border-b-0 border-gray-200 dark:border-gray-700 transition"
              onClick={() => {
                setSelectedAssetType("content-dashboard");
                setSelectedAssetId(null);
              }}
            >
              <Pencil className="h-5 w-5 mr-2" />
              Content Settings
            </TabsTrigger>
          </TabsList>

          <TabsContent value="overview" className="mt-0 flex-1 bg-white dark:bg-gray-800 shadow-md rounded-b-lg p-2 lg:p-6">
            {savedAssetsStatus === "loading" ? (
              <div className="flex justify-center items-center h-64">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <DashboardStats 
                  assetTypeCounts={assetTypeCounts}
                  unreadCounts={unreadCounts}
                />
                
                <h2 className="text-2xl font-bold mt-16 mb-4 text-center lg:text-left">Browse by Category</h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 mb-20">
                  {assetTypes.map((type) => (
                    <AssetTypeCard
                      key={type.id}
                      assetType={type}
                      count={assetTypeCounts[type.id] || 0}
                      unreadCount={unreadCounts[type.id] || 0}
                      onClick={() => {
                        setActiveTab("asset-view");
                        setSelectedAssetType(type.id);
                        setSelectedAssetId(null);
                      }}
                    />
                  ))}
                </div>
                <RecentAssetsPanel
  assets={assets}
  onViewAssetType={(assetType) => {
    setActiveTab("asset-view");
    setSelectedAssetType(assetType);
    setSelectedAssetId(null);
  }}
  onSelectAsset={(assetId, assetType) => {
    setActiveTab("asset-view");
    setSelectedAssetType(assetType);
    handleSelectAsset(assetId, assetType);
  }}
/>
              </>
            )}
          </TabsContent>

          <TabsContent value="content-dashboard" className="mt-0 flex-1 bg-white shadow-md dark:bg-gray-800 rounded-b-lg">
            <ContentDashboard />
          </TabsContent>

          <TabsContent value="notifications" className="mt-0 flex-1 bg-white shadow-md dark:bg-gray-800 rounded-b-lg">
            <NotificationsPanel 
              notifications={notifications}
              selectedNotificationId={selectedAssetId}
              onSelectNotification={(id) => handleSelectAsset(id, "notifications")}
              onMarkAsRead={(id) => dispatch(markNotificationAsRead(id))}
              onMarkAsUnread={(id) => dispatch(markNotificationAsUnread(id))}
              onDelete={(id) => handleDeleteAsset(id, "notifications")}
              onMarkAllAsRead={() => dispatch(markAllNotificationsAsRead())}
              onDeleteAll={() => dispatch(deleteAllNotifications())}
              searchTerm={searchTerm}
              viewMode={viewMode}
              status={notificationsStatus}
            />
          </TabsContent>

          <TabsContent value="asset-view" className="mt-0 flex-1 bg-white dark:bg-gray-800 shadow-md rounded-b-lg p-0 min-h-screen">
            {selectedAssetType && (
              <div className="flex h-full">
                <div className={`flex-1 ${selectedAssetId ? 'lg:w-2/3' : 'w-full'} h-full overflow-hidden flex flex-col`}>
                  <div className="p-4">
                    <h2 className="text-xl font-bold capitalize">
                      {assetTypes.find(t => t.id === selectedAssetType)?.name || selectedAssetType.replace("-", " ")}
                    </h2>
                    <p className="text-sm text-gray-500 mt-1">
                      {assetTypeCounts[selectedAssetType] || 0} items 
                      {unreadCounts[selectedAssetType] > 0 && ` (${unreadCounts[selectedAssetType]} unread)`}
                    </p>
                  </div>
                  
                  <div className="flex-1 overflow-auto p-4">
                    {getFilteredAssets().length === 0 ? (
                      <EmptyState 
                        assetType={selectedAssetType}
                        searchTerm={searchTerm}
                      />
                    ) : viewMode === "cards" ? (
                      <AssetsList
                        assets={getFilteredAssets()}
                        selectedAssetId={selectedAssetId}
                        onSelectAsset={(id) => handleSelectAsset(id, selectedAssetType)}
                        onDeleteAsset={(id) => handleDeleteAsset(id, selectedAssetType)}
                        onUpdateStatus={(id, status) => handleUpdateStatus(id, selectedAssetType, status)}
                      />
                    ) : (
                      <AssetsTable
                        assets={getFilteredAssets()}
                        selectedAssetId={selectedAssetId}
                        onSelectAsset={(id) => handleSelectAsset(id, selectedAssetType)}
                        onDeleteAsset={(id) => handleDeleteAsset(id, selectedAssetType)}
                        onUpdateStatus={(id, status) => handleUpdateStatus(id, selectedAssetType, status)}
                      />
                    )}
                  </div>
                </div>
                
                {selectedAssetId && (
                  <div className="hidden lg:block lg:w-1/3 h-full overflow-auto">
                    <AssetDetail
                      asset={selectedAsset}
                      assetType={selectedAssetType}
                      onDeleteAsset={(id) => handleDeleteAsset(id, selectedAssetType)}
                      onUpdateStatus={(status) => handleUpdateStatus(selectedAssetId, selectedAssetType, status)}
                      onClose={handleCloseDetails}
                    />
                  </div>
                )}
              </div>
            )}
          </TabsContent>
        </Tabs>
      )}

      {/* Mobile asset detail view */}
      {selectedAssetId && (
        <div className="lg:hidden fixed inset-0 bg-white dark:bg-gray-800 z-50 overflow-auto">
          <div className="sticky top-0 flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-700">
            <Button 
              variant="ghost" 
              onClick={() => setSelectedAssetId(null)}
            >
              Back
            </Button>
            <h2 className="text-lg font-bold">Asset Details</h2>
            <div className="w-10"></div> {/* Spacer for centering */}
          </div>
          <div className="p-4">
            <AssetDetail
              asset={selectedAsset}
              assetType={selectedAssetType}
              onDeleteAsset={(id) => handleDeleteAsset(id, selectedAssetType)}
              onUpdateStatus={(status) => handleUpdateStatus(selectedAssetId, selectedAssetType, status)}
                onClose={handleCloseDetails}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetsDashboard;