import React from 'react';
import { motion } from 'framer-motion';
import {
  Zap,
  Clock,
  Users,
  Star,
  Expand,
  PaintBucket,
  Workflow,
  Headphones,
  LineChart,
  Settings,
  Shield,
  Rocket
} from 'lucide-react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';

const benefits = [
  {
    icon: Clock,
    title: "Stop Feeling Overwhelmed",
    description: "Let Flow Orchestra handle the heavy lifting so you can reclaim your time and focus on creativity without the stress of managing tasks manually.",
    delay: 0.1
  },
  {
    icon: Zap,
    title: "Say Goodbye to Tedious Tasks",
    description: "Automate the boring stuff and free up your energy for what you love—crafting compelling content and bringing your ideas to life.",
    delay: 0.2
  },
  {
    icon: Users,
    title: "Collaborate Effortlessly",
    description: "No more miscommunication or missed deadlines. Work seamlessly with your team and watch projects come together faster than ever.",
    delay: 0.3
  },
  {
    icon: Star,
    title: "Elevate Your Brand Instantly",
    description: "With top-notch content quality at your fingertips, you can impress your audience and establish authority without spending hours refining your work.",
    delay: 0.4
  },
  /*{
    icon: Expand,
    title: "Grow at Your Own Pace",
    description: "Fear of outgrowing your tools? With our flexible subscription options, scale up or down effortlessly as your needs change, without breaking a sweat.",
    delay: 0.5
  },*/
  {
    icon: PaintBucket,
    title: "Turn Ideas into Visuals in Seconds",
    description: "Transform your thoughts into stunning graphics without needing design skills. Wow your audience with visuals that captivate and engage.",
    delay: 0.6
  },
  {
    icon: Workflow,
    title: "Automate Like a Pro",
    description: "Create complex workflows that work for you, and enjoy the confidence of knowing you're operating at peak efficiency without needing an engineering degree.",
    delay: 0.7
  },
  {
    icon: Headphones,
    title: "Get Expert Help When You Need It",
    description: "Access specialized AI assistants that act like your personal experts, helping you tackle niche projects with precision and ease.",
    delay: 0.8
  },
  /*{
    icon: LineChart,
    title: "Make Smart Decisions Fast",
    description: "Use powerful insights to understand what works for you and your audience, ensuring you're always on the right track and maximizing your impact.",
    delay: 0.9
  },*/
  {
    icon: Settings,
    title: "Create Your Perfect Workflow",
    description: "Enjoy a personalized experience that allows you to create unique, imaginative processes, making the app feel like it was built just for you.",
    delay: 1.0
  },
  /*{
    icon: Shield,
    title: "Never Worry About Mistakes",
    description: "With built-in error handling, you can trust that your workflows will run smoothly, giving you peace of mind to explore new ideas without fear.",
    delay: 1.1
  },*/
  {
    icon: Rocket,
    title: "Experience the Future of Work",
    description: "Embrace cutting-edge technology that empowers you to innovate and achieve results you never thought possible, all with a few clicks.",
    delay: 1.2
  }
];

const BenefitCard = ({ icon: Icon, title, description, delay }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
      className="rounded-xl bg-gray-100 bg-opacity-60 p-6 hover:bg-opacity-80 transition-all duration-300"
    >
      <div className="flex items-start gap-4">
        <div className="mt-1 p-2 rounded-lg bg-black">
          <Icon className="h-5 w-5 text-blue-400" />
        </div>
        <div className="space-y-2">
          <h3 className="text-lg font-semibold text-white leading-tight">
            {title}
          </h3>
          <p className="text-black text-sm leading-relaxed">
            {description}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

const BenefitsSection = () => {
  return (
    <section className="pt-24 bg-black min-h-screen">
      <div className="container mx-auto px-4 max-w-7xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center mb-16 space-y-4"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-white">
            Why Choose Flow Orchestra?
          </h2>
          <p className="text-lg text-[#94a3b8] max-w-3xl mx-auto">
            Unlock your full potential with powerful features designed to transform your workflow
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;