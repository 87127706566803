import React from 'react';
import { Card } from "../../components/ui/card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Mail } from 'lucide-react';

const ConnectSection = () => {
  const emails = [
    {
      type: 'Support',
      address: 'support@floworchestra.com'
    },
    {
      type: 'Sales',
      address: 'sales@floworchestra.com'
    }
  ];

  const socials = [
    {
      name: 'Facebook',
      icon: faFacebook,
      url: 'https://www.facebook.com/orchestraflow',
      color: 'hover:text-blue-600'
    },
    {
      name: 'LinkedIn',
      icon: faLinkedin,
      url: 'https://www.linkedin.com/company/flow-orchestra/',
      color: 'hover:text-blue-700'
    },
    {
      name: 'Instagram',
      icon: faInstagram,
      url: 'https://www.instagram.com/_floworchestra/',
      color: 'hover:text-pink-600'
    },
    {
      name: 'X/Twitter',
      icon: faXTwitter,
      url: 'https://x.com/floworchestra',
      color: 'hover:text-blue-400'
    },
    {
      name: 'YouTube',
      icon: faYoutube,
      url: 'https://www.youtube.com/@Flow-Orchestra',
      color: 'hover:text-red-400'
    }
  ];

  return (
    <div className="min-h-screen flex flex-col justify-start items-center p-6 lg:p-12 bg-white">
      <div className="pt-20 lg:pt-12 w-full max-w-3xl text-center">
        <h2 className="text-3xl font-bold mb-4">Connect With Us</h2>
        <p className="text-gray-600 mb-12">Get in touch with our team for support, sales inquiries, or just to say hello!</p>

        {/* Email Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {emails.map((email, index) => (
            <Card key={index} className="p-6 shadow-lg hover:shadow-xl transition-shadow bg-[#fafafa]">
              <div className="flex flex-col items-center space-y-3">
                <Mail className="w-8 h-8 text-gray-600" />
                <h3 className="font-semibold text-lg">{email.type}</h3>
                <a 
                  href={`mailto:${email.address}`}
                  className="text-blue-600 hover:text-blue-800 transition-colors"
                >
                  {email.address}
                </a>
              </div>
            </Card>
          ))}
        </div>

        {/* Social Media Links */}
        <div className="space-y-4">
          <h3 className="text-xl font-semibold mb-6">Follow Us</h3>
          <div className="flex justify-center space-x-8">
            {socials.map((social, index) => (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-gray-600 ${social.color} transition-colors duration-300`}
                aria-label={social.name}
              >
                <FontAwesomeIcon icon={social.icon} className="text-3xl" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectSection;