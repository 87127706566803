// components/dashboard/UsageStats.jsx
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../components/ui/card';
import { Progress } from '../../components/ui/progress';

const UsageStats = ({ packageInfo }) => {
  if (!packageInfo || !packageInfo.usage) {
    return null;
  }

  const { usage, packageType } = packageInfo;

  const calculatePercentage = (used, total) => {
    if (total === 0) return 0;
    return (used / total) * 100;
  };

  return (
    <Card className="mb-6">
      <CardHeader>
        <CardTitle>Available Content Usage</CardTitle>
        <CardDescription>Your current {packageType} package content usage</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid gap-6 md:grid-cols-3">
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">Blog Posts</h3>
              <span className="text-sm text-muted-foreground">
                {usage.blogPosts.used} / {usage.blogPosts.total}
              </span>
            </div>
            <Progress 
              value={calculatePercentage(usage.blogPosts.used, usage.blogPosts.total)} 
              className="h-2" 
            />
            <p className="text-xs text-muted-foreground">
              {usage.blogPosts.remaining} available
            </p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">Social Posts</h3>
              <span className="text-sm text-muted-foreground">
                {usage.socialPosts.used} / {usage.socialPosts.total}
              </span>
            </div>
            <Progress 
              value={calculatePercentage(usage.socialPosts.used, usage.socialPosts.total)} 
              className="h-2" 
            />
            <p className="text-xs text-muted-foreground">
              {usage.socialPosts.remaining} available
            </p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">AI Images</h3>
              <span className="text-sm text-muted-foreground">
                {usage.aiImages.used} / {usage.aiImages.total}
              </span>
            </div>
            <Progress 
              value={calculatePercentage(usage.aiImages.used, usage.aiImages.total)} 
              className="h-2" 
            />
            <p className="text-xs text-muted-foreground">
              {usage.aiImages.remaining} available
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UsageStats;