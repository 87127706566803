import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Badge } from '../../components/ui/badge';
import { Skeleton } from '../../components/ui/skeleton';
import { AlertCircle, Edit, MoreVertical, PlayCircle, Star, Clock, Calendar, CheckCircle2, XCircle } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../../components/ui/dropdown-menu';
import { Alert, AlertDescription } from '../../components/ui/alert';

// This component would typically fetch data from your Redux store or API
// For now, using sample data for demonstration
const FlowCards = ({ type = 'recent' }) => {
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // In a real implementation, you would fetch flows from Redux
  // const { flows, loading, error } = useSelector(state => state.flows);
  // const dispatch = useDispatch();
  
  useEffect(() => {
    // Simulate API call
    const fetchFlows = async () => {
      try {
        setLoading(true);
        // Mock delay
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock data based on type
        let mockFlows = [];
        
        if (type === 'recent') {
          mockFlows = [
            {
              id: 'flow-1',
              name: 'Customer Data Processing',
              description: 'Process new customer registrations from CRM',
              status: 'active',
              lastRun: '2023-09-15T14:30:00',
              executionCount: 128,
              successRate: 98.4,
              favorite: true,
              trigger: 'webhook',
              createdAt: '2023-08-01T10:15:00',
            },
            {
              id: 'flow-2',
              name: 'Weekly Report Generation',
              description: 'Generate and send weekly analytics reports',
              status: 'active',
              lastRun: '2023-09-11T09:00:00',
              executionCount: 42,
              successRate: 100,
              favorite: false,
              trigger: 'schedule',
              createdAt: '2023-08-15T11:30:00',
            },
            {
              id: 'flow-3',
              name: 'Inventory Alert System',
              description: 'Send alerts when inventory levels are low',
              status: 'inactive',
              lastRun: '2023-09-05T16:45:00',
              executionCount: 78,
              successRate: 89.7,
              favorite: false,
              trigger: 'event',
              createdAt: '2023-09-01T08:45:00',
            },
          ];
        } else if (type === 'favorites') {
          mockFlows = [
            {
              id: 'flow-1',
              name: 'Customer Data Processing',
              description: 'Process new customer registrations from CRM',
              status: 'active',
              lastRun: '2023-09-15T14:30:00',
              executionCount: 128,
              successRate: 98.4,
              favorite: true,
              trigger: 'webhook',
              createdAt: '2023-08-01T10:15:00',
            },
            {
              id: 'flow-4',
              name: 'Order Processing Pipeline',
              description: 'Handle new orders from e-commerce platform',
              status: 'active',
              lastRun: '2023-09-16T11:20:00',
              executionCount: 256,
              successRate: 99.1,
              favorite: true,
              trigger: 'webhook',
              createdAt: '2023-07-10T13:15:00',
            },
          ];
        }
        
        setFlows(mockFlows);
        setError(null);
      } catch (err) {
        setError('Failed to load flows. Please try again later.');
        console.error('Error fetching flows:', err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchFlows();
  }, [type]);
  
  const handleToggleFavorite = (id) => {
    // In a real app, this would dispatch an action to update in Redux/backend
    setFlows(flows.map(flow => 
      flow.id === id ? { ...flow, favorite: !flow.favorite } : flow
    ));
  };
  
  if (loading) {
    return <FlowCardsSkeleton />;
  }
  
  if (error) {
    return (
      <Alert variant="destructive" className="mt-4">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }
  
  if (flows.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-12 text-center">
        <div className="rounded-full bg-muted p-4 mb-4">
          {type === 'recent' ? <Clock size={24} /> : <Star size={24} />}
        </div>
        <h3 className="text-lg font-medium mb-2">
          {type === 'recent' ? 'No recent flows' : 'No favorite flows'}
        </h3>
        <p className="text-muted-foreground mb-6">
          {type === 'recent' 
            ? 'You haven\'t created any flows yet.' 
            : 'Mark flows as favorites to see them here.'}
        </p>
        <Button asChild>
          <Link to="/create-flow">Create a Flow</Link>
        </Button>
      </div>
    );
  }
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
      {flows.map(flow => (
        <FlowCard 
          key={flow.id}
          flow={flow}
          onToggleFavorite={() => handleToggleFavorite(flow.id)}
        />
      ))}
    </div>
  );
};

const FlowCard = ({ flow, onToggleFavorite }) => {
  // Format the date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { 
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  // Get status badge color
  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800 hover:bg-green-100';
      case 'inactive':
        return 'bg-gray-100 text-gray-800 hover:bg-gray-100';
      case 'error':
        return 'bg-red-100 text-red-800 hover:bg-red-100';
      default:
        return 'bg-blue-100 text-blue-800 hover:bg-blue-100';
    }
  };
  
  // Get trigger icon
  const getTriggerIcon = (trigger) => {
    switch (trigger) {
      case 'webhook':
        return <Badge variant="outline" className="flex items-center gap-1"><PlayCircle size={12} /> Webhook</Badge>;
      case 'schedule':
        return <Badge variant="outline" className="flex items-center gap-1"><Calendar size={12} /> Schedule</Badge>;
      case 'event':
        return <Badge variant="outline" className="flex items-center gap-1"><Clock size={12} /> Event</Badge>;
      default:
        return <Badge variant="outline">Manual</Badge>;
    }
  };
  
  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <div className="space-y-1">
            <CardTitle className="text-lg">{flow.name}</CardTitle>
            <CardDescription>{flow.description}</CardDescription>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={onToggleFavorite}
            className="h-8 w-8"
            title={flow.favorite ? "Remove from favorites" : "Add to favorites"}
          >
            <Star
              size={16}
              className={flow.favorite ? "fill-yellow-400 text-yellow-400" : "text-muted-foreground"}
            />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="pb-2">
        <div className="flex items-center gap-2 mb-4">
          {getTriggerIcon(flow.trigger)}
          <Badge className={`${getStatusColor(flow.status)}`}>
            {flow.status.charAt(0).toUpperCase() + flow.status.slice(1)}
          </Badge>
        </div>
        
        <div className="grid grid-cols-2 gap-y-2 text-sm">
          <div className="flex items-center gap-1 text-muted-foreground">
            <Calendar size={14} />
            <span>Last Run:</span>
          </div>
          <div className="text-right">
            {flow.lastRun ? formatDate(flow.lastRun) : 'Never'}
          </div>
          
          <div className="flex items-center gap-1 text-muted-foreground">
            <PlayCircle size={14} />
            <span>Executions:</span>
          </div>
          <div className="text-right">{flow.executionCount.toLocaleString()}</div>
          
          <div className="flex items-center gap-1 text-muted-foreground">
            <CheckCircle2 size={14} />
            <span>Success Rate:</span>
          </div>
          <div className="text-right">{flow.successRate}%</div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between pt-2">
        <Button variant="outline" size="sm" asChild>
          <Link to={`/manage-flows/${flow.id}`}>
            <Edit size={14} className="mr-1" />
            Edit
          </Link>
        </Button>
        
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="h-8 w-8">
              <MoreVertical size={16} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <PlayCircle size={14} className="mr-2" />
              Execute Now
            </DropdownMenuItem>
            <DropdownMenuItem>
              <CheckCircle2 size={14} className="mr-2" />
              View Executions
            </DropdownMenuItem>
            <DropdownMenuItem>
              {flow.status === 'active' ? (
                <>
                  <XCircle size={14} className="mr-2" />
                  Deactivate
                </>
              ) : (
                <>
                  <CheckCircle2 size={14} className="mr-2" />
                  Activate
                </>
              )}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardFooter>
    </Card>
  );
};

const FlowCardsSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
      {[1, 2, 3].map(i => (
        <Card key={i}>
          <CardHeader>
            <Skeleton className="h-6 w-3/4 mb-2" />
            <Skeleton className="h-4 w-full" />
          </CardHeader>
          <CardContent>
            <div className="flex gap-2 mb-4">
              <Skeleton className="h-6 w-20" />
              <Skeleton className="h-6 w-16" />
            </div>
            <div className="space-y-3">
              <div className="flex justify-between">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-4 w-24" />
              </div>
              <div className="flex justify-between">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-4 w-12" />
              </div>
              <div className="flex justify-between">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-4 w-16" />
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-between">
            <Skeleton className="h-9 w-20" />
            <Skeleton className="h-8 w-8 rounded-full" />
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default FlowCards;