import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileImage, faFilePdf, faFileWord, faFileAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const FilePreview = ({ file, onRemove, isSentFile = false }) => {
  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'image/jpeg':
      case 'image/png':
        return faFileImage;
      case 'application/pdf':
        return faFilePdf;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return faFileWord;
      case 'text/plain':
      case 'text/csv':
        return faFileAlt;
      default:
        return faFile;
    }
  };

  return (
    <div className="flex items-center p-2 bg-gray-100 rounded-md">
      <FontAwesomeIcon icon={getFileIcon(file.type)} className="mr-2 text-gray-600" />
      <span className="flex-grow truncate">{file.name}</span>
      {onRemove && (
        <button 
          type="button" // Add type="button" to prevent form submission
          onClick={(e) => {
            e.preventDefault(); // Prevent form submission
            e.stopPropagation(); // Stop event bubbling
            onRemove(file);
          }} 
          className="ml-2 text-red-500 hover:text-red-700"
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      )}
    </div>
  );
};

export default FilePreview;