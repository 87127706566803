export const TIER_LEVELS = {
  free: 0,
  individual: 1,
  growth: 2,
  creator: 3,
  enterprise: 4
};

export const hasFeatureAccess = (userPlan, requiredTier) => {
  return TIER_LEVELS[userPlan?.tier || 'free'] >= TIER_LEVELS[requiredTier];
};

export const isSubscriptionActive = (subscription) => {
  return subscription?.status === 'active';
};

export const getSubscriptionStatus = (subscription) => {
  if (!subscription) return 'inactive';
  if (subscription.status === 'active' && subscription.cancelAtPeriodEnd) {
    return 'cancelling';
  }
  return subscription.status;
};

export const getSubscriptionType = (subscription) => {
  if (subscription?.subscriptionId?.subscriptionType === 'dfy') {
    return 'dfy';
  } else {
    return 'standard';
  }
}

export const formatSubscriptionDates = (subscription) => {
  if (!subscription?.currentPeriodEnd) return null;
  return {
    start: new Date(subscription.currentPeriodStart).toLocaleDateString(),
    end: new Date(subscription.currentPeriodEnd).toLocaleDateString()
  };
};