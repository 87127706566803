import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSchedule, fetchSchedules } from '../../slices/schedulesSlice';
import { Button } from '../../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import ScheduleConfigForm from './ScheduleConfigForm';
import { timezones } from '../../services/timezones';
import { getLocalTimezone } from '../../utils/timezone';
import { Gem, PlusCircle } from 'lucide-react';

const CreateScheduleForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flows } = useSelector((state) => state.flows);
  const { teams } = useSelector((state) => state.teams);
  const [formData, setFormData] = useState({
    flowId: '',
    type: 'hourly',
    config: {
      minutes: [],
      hours: [],
      daysOfMonth: [],
      daysOfWeek: [],
    },
    timezone: getLocalTimezone(),
    team: null,
  });
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
  }, []);

  const handleSelectChange = useCallback((name, value) => {
    setFormData(prevData => {
      if (name === 'type') {
        // Initialize appropriate config structure based on schedule type
        const newConfig = {
          minutes: [],
          hours: [],
          daysOfMonth: [],
          daysOfWeek: [],
        };
        
        // For twice_daily, initialize with empty array for two hour selections
        if (value === 'twice_daily') {
          newConfig.hours = [];
        }
        
        return { 
          ...prevData, 
          [name]: value, 
          config: newConfig 
        };
      }
      
      if (name === 'team') {
        return { ...prevData, [name]: value === "null" ? null : value };
      }
      
      return { ...prevData, [name]: value };
    });
  }, []);

  const handleConfigChange = useCallback((config) => {
    setFormData(prevData => ({
      ...prevData,
      config: {
        ...prevData.config,
        ...config
      }
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const scheduleData = {
        ...formData,
        team: formData.team ? formData.team : [] // Removed the extra array nesting
      };
      await dispatch(createSchedule(scheduleData)).unwrap();
      await dispatch(fetchSchedules()).unwrap();
      navigate('/scheduling');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 lg:space-y-8">
      <h2 className="text-inter font-bold text-2xl mb-4 flex items-center"><PlusCircle className='h-6 w-6 mr-2' />Create New Schedule</h2>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
        <div className="w-1/2">
          <label htmlFor="flowId" className="block font-bold text-gray-900 lg:text-lg">Choose the flow to schedule</label>
          <Select name="flowId" onValueChange={(value) => handleSelectChange('flowId', value)} value={formData.flowId}>
            <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
              <SelectValue placeholder="Select a flow" />
            </SelectTrigger>
            <SelectContent>
              {flows.map((flow) => (
                <SelectItem key={flow._id} value={flow._id}>{flow.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="w-1/2">
          <label htmlFor="team" className="block font-bold text-gray-900 lg:text-lg">Would you like to assign this schedule to a team? (Optional)</label>
          <Select 
            name="team" 
            onValueChange={(value) => handleSelectChange('team', value)} 
            value={formData.team ?? "null"}
          >
            <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
              <SelectValue placeholder="Select a team (optional)" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="null">No team</SelectItem>
              {teams.map((team) => (
                <SelectItem key={team._id} value={team._id}>
                  <div className='flex items-center'>
                    {team.isSeatsTeam && <Gem className='h-4 w-4 text-yellow-500 mr-1' />}
                    {team.name}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      
      
      <div className="lg:w-1/2">
        <label htmlFor="type" className="block font-bold text-gray-900 lg:text-lg">What type of schedule would you like?</label>
        <Select 
          name="type" 
          onValueChange={(value) => handleSelectChange('type', value)} 
          value={formData.type}
          
        >
          <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
            <SelectValue placeholder="Select schedule type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="hourly">Hourly</SelectItem>
            <SelectItem value="daily">Daily</SelectItem>
            <SelectItem value="twice_daily">Twice Daily</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="custom">Custom</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="lg:w-1/2">
        <label htmlFor="timezone" className="block font-bold text-gray-900 lg:text-lg">Which timezone should we use?</label>
        <Select 
          name="timezone" 
          onValueChange={(value) => handleSelectChange('timezone', value)} 
          value={formData.timezone}
        >
          <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
            <SelectValue placeholder="Select a timezone" />
          </SelectTrigger>
          <SelectContent>
            {timezones.map((tz) => (
              <SelectItem key={tz.value} value={tz.value}>{tz.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <ScheduleConfigForm
        type={formData.type}
        config={formData.config}
        onChange={handleConfigChange}
      />

      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={() => navigate('/scheduling')}>Cancel</Button>
        <Button type="submit">Create Schedule</Button>
      </div>
    </form>
  );
};

export default CreateScheduleForm;