import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { RotateCcw } from "lucide-react";

const HeaderSection = ({ onNewQuestion }) => {
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const headerText = "Here's what I would suggest...";
  
  useEffect(() => {
    // Start typing header text immediately on component load
    setIsTyping(true);
    setDisplayText("");
    setCurrentIndex(0);
    
    const typingInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex >= headerText.length) {
          clearInterval(typingInterval);
          setIsTyping(false);
          return prevIndex;
        }
        setDisplayText((prev) => prev + headerText[prevIndex]);
        return prevIndex + 1;
      });
    }, 35); // Same typing speed as ResponseDisplaySection
    
    return () => clearInterval(typingInterval);
  }, []);
  
  return (
    <div className="flex flex-col-reverse lg:flex-row justify-between items-start mb-2">
      <h2 className="text-2xl font-semibold mt-2 lg:mt-0">
        {displayText}
        {isTyping && (
          <span className="inline-block w-2 h-4 ml-1 bg-black blink" />
        )}
      </h2>
      <div className="flex gap-2">
        <Button
          variant="outline"
          onClick={onNewQuestion}
          className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-white hover:shadow-md"
        >
          <RotateCcw className="h-6 w-6 magical-gradient text-white p-1 hover:text-white rounded-full" />
          Do Something Different
        </Button>
      </div>
    </div>
  );
};

export default HeaderSection;
