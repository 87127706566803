import React, { useState } from "react";
import { Button } from "../../../components/ui/button";
import { Skeleton } from "../../../components/ui/skeleton";
import { 
  ImagePlus, 
  Paintbrush, 
  CirclePlus, 
  ArrowRight, 
  BrainCircuit, 
  Info,
  ChevronDown,
  ChevronUp 
} from "lucide-react";

const ImageGenerationSuggestion = ({ 
  fetchedSuggestions, 
  onGenerateImage, 
  navigate, 
  setAdditionalImageDetails, 
  additionalImageDetails, 
  setShowAdditionalImagePromptDetails, 
  showAdditionalImagePromptDetails 
}) => {
  const [isProTipsExpanded, setIsProTipsExpanded] = useState(false);
  const [expandedStyles, setExpandedStyles] = useState({});

  const toggleProTips = () => {
    setIsProTipsExpanded(!isProTipsExpanded);
  };

  const toggleStyleExpand = (styleId) => {
    setExpandedStyles(prevState => ({
      ...prevState,
      [styleId]: !prevState[styleId]
    }));
  };

  return (
    <div className="bg-white border border-gray-200 rounded shadow-md p-2 lg:p-6">
      <div className="text-xl font-semibold mb-4 flex items-center text-gray-900" title="Bring your ideas to life with our image generation feature! This tool transforms your text prompts into stunning visuals, perfect for enhancing your content and capturing attention. Whether you're designing marketing materials or social media posts, generating images can elevate your brand's story and make a lasting impact.">
        <ImagePlus className="h-7 w-7 mr-2 magical-gradient text-white p-1 rounded-full" />
        <h3 className="text-base lg:text-xl font-semibold">
          Suggestion: Generate an Image
        </h3>
      </div>
      <div className="text-muted-foreground text-sm mb-2">
        <Info className="inline h-4 w-4 mr-1 text-black" />Click on an image style to go to the image generation page to create an image using that style.
      </div>
      {fetchedSuggestions?.data?.suggestions?.imageGenerator?.imageStyle?.length > 0 ? (
        <>
          <div className="space-y-4 flex items-center text-gray-900 gap-1">
            <Paintbrush className="h-4 w-4" />
            Suggested Image Styles:
          </div>
          {/*<div className="flex items-center text-gray-900 gap-4 px-4 mb-4">
            {fetchedSuggestions.data.suggestions.imageGenerator.imageStyle.map((style) => (
              <img
                key={style._id}
                src={style.screenshotUrl}
                alt={style.description}
                title={style.description}
                className="w-12 h-12 lg:w-24 lg:h-24 rounded-sm object-cover"
              />
            ))}
          </div>*/}
          <div className="space-y-4">
            {fetchedSuggestions.data.suggestions.imageGenerator.imageStyle.map((style) => (
              <div 
                key={style._id}
                className="border border-gray-200 rounded-md shadow-md overflow-hidden"
              >
                {/* Collapsed Style Header */}
                <div 
                  className="flex items-center justify-between p-2 bg-gray-100 cursor-pointer"
                  onClick={() => toggleStyleExpand(style._id)}
                >
                  <div className="flex items-center gap-2">
                    <img 
                      src={style.screenshotUrl} 
                      alt={style.name} 
                      className="w-8 h-8 rounded-md flex-shrink-0 shadow-sm" 
                    />
                    <h4 className="font-semibold text-gray-900">{style.name}</h4>
                  </div>
                  {expandedStyles[style._id] ? (
                    <ChevronUp className="h-4 w-4 text-gray-600" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-gray-600" />
                  )}
                </div>
                
                {/* Expanded Style Content */}
                {expandedStyles[style._id] && (
                  <Button
                    variant="outline"
                    onClick={(e) => onGenerateImage(e, style._id)}
                    type="button"
                    className="flex items-center border-none gap-2 group bg-gray-100 hover:bg-gray-900 hover:text-gray-100 text-black p-2 lg:p-3 h-fit w-full justify-between"
                  >
                    <img src={style.screenshotUrl} alt={style.name} className="w-10 h-10 lg:w-20 lg:h-20 rounded-md flex-shrink-0 shadow-lg" />
                    <div className="flex flex-col items-start flex-1 min-w-0">
                      <h4 className="font-semibold text-lg truncate w-full">{style.name}</h4>
                      <p className="text-sm text-gray-700 group-hover:text-white w-full whitespace-normal break-words">{style.description}</p>
                      <div className="mt-1 mx-auto flex flex-row items-center justify-center gap-2">
                        <CirclePlus className="h-5 w-5 magical-gradient text-white rounded-full" />
                        Generate with {style.name || "this Image Style"}
                      </div>
                    </div>
                  </Button>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="flex flex-col space-y-3">
          <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
          <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
          <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
        </div>
      )}
      <div className="mt-4">
        <Button
          variant="outline"
          className="flex items-center border-none shadow-md gap-2 bg-gray-900 hover:bg-gray-100 hover:text-gray-900 text-white"
          onClick={() => navigate("/generate-image")}
        >
          Go to the Image Generator
          <ArrowRight className="h-4 w-4 magical-gradient text-white rounded-full" />
        </Button>
      </div>
      <div className="text-sm text-black mt-6 lg:mt-8">
        <Button
          variant="ghost"
          className="w-full p-0 flex items-center justify-between hover:bg-transparent"
          onClick={toggleProTips}
        >
          <h4 className="font-semibold text-md flex items-center gap-2">
            <BrainCircuit className="h-4 w-4" />
            Pro Follow Up Tips:
          </h4>
          {isProTipsExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </Button>
        <div 
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isProTipsExpanded ? "max-h-40 opacity-100 mt-2" : "max-h-0 opacity-0"
          }`}
        >
          <div className="space-y-1">
            <div>
              <span className="font-semibold">Tip:</span> Experiment with different styles and themes in your prompts to create diverse visuals for various platforms.
            </div>
            <div>
              <span className="font-semibold">Tip:</span> Incorporate specific color schemes or branding elements in your prompts to ensure the images align with your brand identity.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGenerationSuggestion;