import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Play, Users } from "lucide-react";
import { useDispatch } from "react-redux";
import { Card } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { 
  Clock, 
  BarChart2, 
  Plus, 
  Trash2, 
  X 
} from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Alert, AlertDescription } from "../../components/ui/alert";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import TeamShareButton from "../../components/TeamShare/TeamShareButton";
import { openVideoModal } from "../../slices/videoModalSlice";

const UserChatbotList = ({
  chatbots,
  activeChatbot,
  onChatbotSelect,
  onNewChatbot,
  onDeleteChatbot,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  credits,
  limits,
  onChatbotUpdate, // New prop for handling chatbot updates
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredChatbots, setFilteredChatbots] = useState(chatbots);
  const [chatbotToDelete, setChatbotToDelete] = useState(null);

  useEffect(() => {
    const filtered = chatbots.filter(
      (chatbot) =>
        chatbot.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        chatbot.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChatbots(filtered);
  }, [chatbots, searchTerm]);

  const handleOpenSingleVideo = () => {
    dispatch(
      openVideoModal({
        url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Custom%20Assistant%20-%20Explainer%20Vid.mp4",
        title: "How to Build a Custom Assistant",
      })
    );
  };

  const handleDeleteClick = (e, chatbot) => {
    e.stopPropagation();
    setChatbotToDelete(chatbot);
  };

  const handleConfirmDelete = () => {
    if (chatbotToDelete && onDeleteChatbot) {
      onDeleteChatbot(chatbotToDelete._id);
    }
    setChatbotToDelete(null);
  };

  const handleShareSuccess = (chatbotId, updatedTeams) => {
    if (onChatbotUpdate) {
      // Find the chatbot and update its teams
      const updatedChatbot = chatbots.find(bot => bot._id === chatbotId);
      if (updatedChatbot) {
        onChatbotUpdate({
          ...updatedChatbot,
          team: updatedTeams
        });
      }
    }
  };

  return (
    <div className="space-y-4 h-full flex flex-col">
      <div className="flex flex-col xl:flex-row justify-start lg:justify-between lg:items-center">
        <div className="flex flex-col items-start">
          <h2 className="text-2xl font-bold mt-4 lg:mt-2">Custom Assistants</h2>
          <div
            className={`text-sm ${
              chatbots.length >= limits.customAssistants
                ? "text-red-500 font-bold"
                : "text-muted-foreground"
            } `}
          >
            Using {chatbots.length}/{limits.customAssistants}
          </div>
        </div>

        <div className="flex items-center gap-2 justify-between">
          <Button
            onClick={onNewChatbot}
            className="bg-black hover:bg-gray-700 text-white mt-2"
            disabled={chatbots.length >= limits.customAssistants}
          >
            <Plus className="w-4 h-4 mr-1" /> New Assistant
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="lg:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <X className="h-5 w-5" />
          </Button>
        </div>
      </div>
      <Button
        variant="outline"
        size="sm"
        className="group text-black hover:text-gray-800 hover:bg-gray-200 transition-colors duration-100 w-full lg:w-fit bg-gray-100 border-none shadow-md"
        onClick={handleOpenSingleVideo}
      >
        <div className="flex items-center">
          <Play className="h-4 w-4 mr-2 text-black group-hover:text-gray-800" />
          How to Build an Assistant
        </div>
      </Button>
      {chatbots.length >= limits.customAssistants && (
        <Alert className="mt-4 border border-red-300 bg-red-100">
          <AlertDescription>
            You have reached the limit of custom assistants for your
            subscription. You can edit your existing custom assistants, but you
            will not be able to create a new assistant unless you delete one of
            your current assistants.<br></br>
            <br></br>
            <span
              className="cursor-pointer underline"
              onClick={() => navigate("/subscription")}
            >
              Please upgrade your plan to create more custom assistants
            </span>
            .
          </AlertDescription>
        </Alert>
      )}
      <div className="text-sm">
        Design personalized AI assistants tailored to your needs by setting
        prompts, instructions, and more. Once created, your assistants are
        available to chat with directly in the Chat section of the app, making
        it easy to get the help or interaction you need anytime.
      </div>
      <Input
        type="text"
        placeholder="Search custom assistants..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full border-none shadow-md text-gray-900"
      />
      <div className="space-y-2 flex-1 overflow-y-auto pb-4">
        {filteredChatbots.map((chatbot) => (
          <Card
            key={chatbot._id}
            className={`p-4 cursor-pointer border-none shadow-md  ${
              activeChatbot && activeChatbot._id === chatbot._id
                ? "bg-black text-white"
                : "hover:bg-gray-200 text-gray-900"
            }`}
            onClick={() => onChatbotSelect(chatbot)}
          >
            <div className="flex flex-col xl:flex-row justify-between items-start">
              <div>
                <h3 className="font-semibold text-lg">{chatbot.name}</h3>
                <p
                  className={`text-sm text-gray-600 mt-1 ${
                    activeChatbot && activeChatbot._id === chatbot._id
                      ? "text-gray-200"
                      : ""
                  }`}
                >
                  {chatbot.description}
                </p>
              </div>
              <div className="flex items-center">
              {(user?._id === chatbot.creator || user?.role === 'admin') && (
                <TeamShareButton
                  assetId={chatbot._id}
                  modelName="UserChatbot"
                  currentTeams={chatbot.team}
                  onShareSuccess={(updatedTeams) => handleShareSuccess(chatbot._id, updatedTeams)}
                />
              )}
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={(e) => handleDeleteClick(e, chatbot)}
                    >
                      <Trash2 className="h-4 w-4 text-gray-400" />
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent className="z-[100]">
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        Are you absolutely sure?
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently
                        delete the custom assistant and remove its data from our
                        servers.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={handleConfirmDelete}>
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
            <div
              className={`flex items-center space-x-4 mt-2 text-sm ${
                activeChatbot && activeChatbot._id === chatbot._id
                  ? "text-gray-300"
                  : "text-gray-500"
              }`}
            >
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-1" />
                {chatbot.date_added
                  ? new Date(chatbot.date_added).toLocaleDateString()
                  : "N/A"}
              </div>
              {/*<div className="flex items-center">
                <BarChart2 className="h-4 w-4 mr-1" />
                {chatbot.usageCount || 0}
              </div>*/}
              {chatbot.team && chatbot.team.length > 0 && (
                <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center">
                      {/*<Users className="h-4 w-4 mr-1" />*/}
                      <span className="text-muted-foreground mr-1">Shared with:</span>
                      <span className="truncate max-w-[150px]">
                        {chatbot.team.slice(0, 2).map(team => team.name).join(", ")}
                        {chatbot.team.length > 2 && ` +${chatbot.team.length - 2}`}
                      </span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-[200px]">
                      Shared with:<br />
                      {chatbot.team.map((team, index) => (
                        <span key={team._id || index} className="flex ml-2"><Users className="h-4 w-4 mr-1" /> {team.name}</span>
                      ))}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default UserChatbotList;