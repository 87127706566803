// File: /src/components/ui/pagination.js
import React from 'react';
import { Button } from "./button";

export function Pagination({ currentPage, itemsPerPage, totalItems, paginate }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const range = 1; // How many pages to show before and after the current page
    const leftLimit = Math.max(1, currentPage - range);
    const rightLimit = Math.min(totalPages, currentPage + range);

    // Add the first page
    if (leftLimit > 1) {
      pageNumbers.push(1);
      if (leftLimit > 2) pageNumbers.push("..."); // Ellipsis if there's a gap
    }

    // Add the pages around the current page
    for (let i = leftLimit; i <= rightLimit; i++) {
      pageNumbers.push(i);
    }

    // Add the last page
    if (rightLimit < totalPages) {
      if (rightLimit < totalPages - 1) pageNumbers.push("..."); // Ellipsis if there's a gap
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <nav className="flex justify-center">
      <ul className="flex space-x-1">
        {getPageNumbers().map((number, index) => (
          <li key={index}>
            {typeof number === "number" ? (
              <Button
                variant={currentPage === number ? "default" : "outline"}
                size="sm"
                onClick={() => paginate(number)}
                className="border-none shadow-xl"
              >
                {number}
              </Button>
            ) : (
              <span className="px-2 py-1 text-sm text-gray-500">...</span> // Render ellipsis
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}