import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FileUpload } from "../../components/FileUpload";
import { FilePreview } from "../../components/FilePreview";
import {
  ChevronDown,
  Command,
  Lightbulb,
  MessageSquare,
  PlusCircle,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { ScrollArea } from "../../components/ui/scroll-area";
import SuggestedPrompts from "./ChatInputComponents/SuggestedPrompts";
import CommandPrompts from "./ChatInputComponents/CommandPrompts";
import { improvePrompt } from "../../services/api";
import { updateCredits } from "../../slices/authSlice";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import ConversationOptions from "./ChatInputComponents/ConversationOptions";
import VoiceCommands from "../../components/VoiceCommands";

const ChatInput = forwardRef(
  (
    {
      message,
      setMessage,
      handleSendMessage,
      currentChat,
      selectedAssistant,
      chatTitle,
      files,
      setFiles,
      sentFiles,
      setSentFiles,
      credits,
      limits,
      selectedAssistantFull,
      selectedTeam,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [showFiles, setShowFiles] = useState(false);
    const [suggestionOpen, setSuggestionOpen] = useState(false);
    const [localMessage, setLocalMessage] = useState(message);
    const [isImproving, setIsImproving] = useState(false);
    const containerRef = useRef(null);
    const textareaRef = useRef(null);

    useImperativeHandle(ref, () => ({
      resetLocalMessage: () => {
        if (textareaRef.current) {
          textareaRef.current.value = '';
          textareaRef.current.style.height = 'auto';
        }
        setLocalMessage('');
        setMessage('');
      },
      focus: () => {
        textareaRef.current?.focus();
      },
      // Expose the textareaRef itself if needed
      textareaRef: textareaRef.current
    }));

    useEffect(() => {
      setLocalMessage(message);
    }, [message]);

    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        handleSendMessage(e);
        setLocalMessage("");
      }
    };

    const handleImprovePrompt = async (e, hardMessage) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      if (isImproving) return;
      // Use hardMessage if provided; otherwise, use localMessage.
      const messageToImprove = typeof hardMessage === "string" ? hardMessage : localMessage;
      if (!messageToImprove.trim()) return;
      setIsImproving(true);
      
      dispatch(updateCredits({ type: "chat", amount: 1, subtract: true }));
      
      
      try {
        const promptData = {
          prompt: `Improve this prompt: ${messageToImprove}`,
          assistantId: "67292e68f34fdd6986fe4159",
          ...(selectedTeam &&
            selectedTeam !== "null" &&
            selectedTeam !== "undefined" &&
            selectedTeam !== "all" &&
            selectedTeam !== "personal" && { teamId: selectedTeam }),
        };
        setLocalMessage("Improving prompt...");
        setMessage("Improving prompt...");
        const { data } = await improvePrompt(promptData);
        console.log("Improved Prompt Data:", data.improvedPrompt);
        setLocalMessage(data.improvedPrompt);
        setMessage(data.improvedPrompt);
      } catch (error) {
        dispatch(updateCredits({ type: "chat", amount: 1, subtract: false }));
        console.error("Error improving prompt:", error);
      } finally {
        setIsImproving(false);
      }
    };
    

    const handleFileUpload = (uploadedFiles) => {
      if (credits.chat <= 0) return;
      if (uploadedFiles.length === 0) return;
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles].slice(0, 5));
    };

    const removeFile = (fileToRemove) => {
      setFiles(files.filter((file) => file !== fileToRemove));
    };

    const removeSentFile = (fileToRemove) => {
      setSentFiles(sentFiles.filter((file) => file !== fileToRemove));
    };

    const handleSuggestionClick = (suggestion) => {
      setLocalMessage((prev) =>
        prev ? `${suggestion}\n\n${prev}` : suggestion
      );
      setMessage((prev) => (prev ? `${suggestion}\n\n${prev}` : suggestion));
      setSuggestionOpen(false);
    };

    const handleLocalChange = (e) => {
      const newValue = e.target.value;
      setLocalMessage(newValue);
      setMessage(newValue);
      if (!textareaRef.current) return;
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Expand to fit content

      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    };

    const truncateLastWord = (text) => {
      const words = text.trim().split(" ");
      if (words.length > 2) {
        console.log("words:", words);
        words.pop();
        words.pop();
        console.log("words after popping two:", words);
        return words.join(" ");
      }
      // If there’s only one word (or none), return an empty string.
      return "";
    };

    // Callback to update localMessage from the VoiceCommands component
    const handleTranscriptChange = (transcribedText) => {
      // If the transcript is empty (or just whitespace), do nothing.
      if (!transcribedText.trim()) return;
    
      // Only update if the textarea isn’t focused.
      if (document.activeElement !== textareaRef.current) {
        setLocalMessage(transcribedText);
        setMessage(transcribedText);
      }
    };

    const handleVoiceSubmitMessage = ({ truncate }) => {
      if (message.trim() === "") return;
      if (truncate) {
        const truncatedMessage = truncateLastWord(message);
        // Update both state variables.
        setMessage(truncatedMessage);
        setLocalMessage(truncatedMessage);
        handleSendMessage(null, truncatedMessage);
      } else {
        handleSendMessage();
      }
      // Clear the message states.
      console.log("Clearing message states");
      setMessage("");
      setLocalMessage("");
    };

    // Updated handleVoiceImprovePrompt to accept a boolean "truncate"
    const handleVoiceImprovePrompt = (truncate) => {
      if (message.trim() === "") return;
      if (truncate) {
        const truncatedMessage = truncateLastWord(message);
        // Update both state variables.
        setMessage(truncatedMessage);
        setLocalMessage(truncatedMessage);
        handleImprovePrompt(null, truncatedMessage);
      } else {
        handleImprovePrompt();
      }
    };

    return (
      <form
        onSubmit={handleSendMessage}
        ref={containerRef}
        className="p-2 lg:px-6 lg:pb-6 lg:pt-0 border-none absolute lg:relative bottom-0 w-full left-0 lg:mx-auto bg-[#fafafa] lg:rounded lg:shadow-xl lg:max-h-[calc(100vh-4.5rem)] lg:overflow-y-auto"
      >
        <div className="flex flex-col md:flex-col md:items-center gap-2 lg:ml-0 px-2 lg:px-0">
          {/* Feature Menu */}

          {/* Conversation Options */}
          {selectedAssistantFull && (
            <div className="hidden lg:block w-full">
              <ConversationOptions
                handleSuggestionClick={handleSuggestionClick}
              />
            </div>
          )}

          {/* Suggested Prompts */}
          {(selectedAssistantFull?.interactions?.suggestedPrompts?.length > 0 ||
            selectedAssistantFull?.suggested_prompts?.length > 0) && (
            <div className="hidden lg:block w-full">
              <SuggestedPrompts
                selectedAssistantFull={selectedAssistantFull}
                handleSuggestionClick={handleSuggestionClick}
              />
            </div>
          )}

          {/* Desktop Command Prompts */}
          {selectedAssistantFull?.interactions?.commandPalette?.length > 0 && (
            <div className="hidden lg:block w-full">
              <CommandPrompts
                selectedAssistantFull={selectedAssistantFull}
                handleSuggestionClick={handleSuggestionClick}
              />
            </div>
          )}

          {/* Voice Commands */}
          {selectedAssistantFull && (
            <div className="hidden lg:block items-center justify-end lg:justify-between w-full">
              <VoiceCommands
                onSubmitMessage={handleVoiceSubmitMessage}
                onImprovePrompt={handleVoiceImprovePrompt}
                onTranscriptChange={handleTranscriptChange}
              />
            </div>
          )}

          {/* File Upload and Toggle */}
          <div className="hidden lg:flex items-center justify-end lg:justify-between w-full">
            <FileUpload onFileUpload={handleFileUpload} />
            {files.length > 0 && (
              <button
                type="button"
                onClick={() => setShowFiles(!showFiles)}
                className="text-sm text-gray-600 underline ml-2"
              >
                {showFiles ? "Hide Files" : `Show Files (${files.length})`}
              </button>
            )}
          </div>

          {/* Input and Send Button */}
          <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex flex-col w-full">
              <div className="flex items-center w-full justify-between">
                <div
                  className={`text-sm ${
                    credits.chat <= 25
                      ? "text-red-500 font-bold"
                      : "text-gray-600"
                  } mb-1`}
                >
                  {credits.chat > 0
                    ? `Chat Credits: ${credits.chat}`
                    : "Out of Chat Credits"}
                </div>
                <div className="flex items-center gap-2 lg:hidden">
                  {(selectedAssistantFull?.interactions?.suggestedPrompts
                    ?.length > 0 ||
                    selectedAssistantFull?.suggested_prompts?.length > 0) && (
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          type="button"
                          className="rounded h-10 flex items-center justify-center p-2"
                        >
                          <Lightbulb className="h-5 w-5" />
                          <span className="sr-only">Suggested Prompts</span>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[90vw] sm:w-[400px]">
                        <ScrollArea className="max-h-[300px]">
                          <SuggestedPrompts
                            selectedAssistantFull={selectedAssistantFull}
                            handleSuggestionClick={handleSuggestionClick}
                            expanded={true}
                          />
                        </ScrollArea>
                      </PopoverContent>
                    </Popover>
                  )}
                  {selectedAssistantFull?.interactions?.commandPalette?.length >
                    0 && (
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          type="button"
                          className="rounded h-10 flex items-center justify-center p-2"
                        >
                          <Command className="h-5 w-5" />
                          <span className="sr-only">Command Prompts</span>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[90vw] sm:w-[400px]">
                        <ScrollArea className="max-h-[300px]">
                          <CommandPrompts
                            selectedAssistantFull={selectedAssistantFull}
                            handleSuggestionClick={handleSuggestionClick}
                            expanded={true}
                          />
                        </ScrollArea>
                      </PopoverContent>
                    </Popover>
                  )}
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        type="button"
                        className="rounded h-10 flex items-center justify-center p-2"
                      >
                        <MessageSquare className="h-5 w-5" />
                        <span className="sr-only">Conversation Options</span>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[90vw] sm:w-[400px]">
                      <ScrollArea className="max-h-[300px]">
                        <ConversationOptions
                          selectedAssistantFull={selectedAssistantFull}
                          handleSuggestionClick={handleSuggestionClick}
                          expanded={true}
                        />
                      </ScrollArea>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>

              <div className="relative flex items-center w-full">
                <Textarea
                  ref={textareaRef}
                  value={localMessage}
                  onChange={(e) => {
                    handleLocalChange(e);
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="Type your message..."
                  className="flex-grow pr-10 border-none shadow-xl bg-background min-h-[80px]"
                />
              </div>
            </div>

            <div className="flex flex-col xl:flex-row gap-2 w-full">
              <Button
                type="submit"
                onClick={() => setLocalMessage("")}
                disabled={
                  (!currentChat && (!selectedAssistant || !chatTitle)) ||
                  (!message.trim() && files.length === 0) ||
                  credits.chat <= 0
                }
                className="rounded lg:rounded h-10 flex items-center justify-center p-2 flex-1"
              >
                <span className="inline-block text-lg text-gray-100">
                  Send Message
                </span>
                <FontAwesomeIcon icon={faPaperPlane} className="h-4 w-4 ml-4" />
                <span className="sr-only">Send</span>
              </Button>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      onClick={handleImprovePrompt}
                      disabled={
                        !message.trim() || credits.chat <= 0 || isImproving
                      }
                      className="rounded lg:rounded h-10 flex items-center justify-center p-2 flex-1 hidden lg:flex"
                    >
                      <span className="inline-block text-lg text-gray-100">
                        {isImproving ? "Improving..." : "Improve Prompt"}
                      </span>
                      <Command className={`h-4 w-4 ml-4 ${isImproving ? "animate-spin" : ""}`} />
                      <span className="sr-only">Improve</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent
                    className="flex items-center"
                    side="right"
                    align="start"
                  >
                    <Command className="h-4 w-4 mr-2" />
                    Use a chat credit to improve this prompt
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>

        {/* File Previews */}
        {showFiles && files.length > 0 && (
          <div className="mt-2">
            {files.map((file, index) => (
              <FilePreview
                key={index}
                file={file}
                onRemove={removeFile}
                isSentFile={false}
              />
            ))}
          </div>
        )}

        {/* Sent Files Section */}
        {sentFiles && sentFiles.length > 0 && (
          <div className="mt-2">
            <h4 className="text-sm font-medium mb-1">
              Previously Attached Files:
            </h4>
            <div className="flex flex-wrap gap-2">
              {sentFiles.map((file, index) => (
                <FilePreview
                  key={index}
                  file={file}
                  onRemove={removeSentFile}
                  isSentFile={true}
                />
              ))}
            </div>
          </div>
        )}
      </form>
    );
  }
);

export default ChatInput;
