import React from "react";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";

export const OutputKeyField = ({ step, handleFieldChange, placeholder }) => (
  <div>
    <Label htmlFor="output-key" className="text-inter font-bold lg:text-lg">
    Output Name (used to reference this response in later steps)
    </Label>
    <Input
      id="output-key"
      placeholder={placeholder || "e.g., stepOutputKey"}
      value={step.outputKey || ""}
      onChange={(e) => handleFieldChange("outputKey", e.target.value)}
      required
    />
  </div>
);

export const handleFieldChange = (field, value, onStepChange) => {
  onStepChange(field, value);
};