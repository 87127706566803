import React from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import HeaderSection from "./ResponseSectionComponents/HeaderSection";
import ResponseDisplaySection from "./ResponseSectionComponents/ResponseDisplaySection";
import LoadingSuggestions from "./ResponseSectionComponents/LoadingSuggestions";
import FlowSuggestion from "./ResponseSectionComponents/FlowSuggestion";
import ImageGenerationSuggestion from "./ResponseSectionComponents/ImageGenerationSuggestion";
import ImagePromptsSuggestion from "./ResponseSectionComponents/ImagePromptsSuggestion";
import AssistantSuggestion from "./ResponseSectionComponents/AssistantSuggestion";
import ChatPromptsSuggestion from "./ResponseSectionComponents/ChatPromptsSuggestion";
import AIImageDisplay from "../FlowFormComponents/AIImageDisplay";
import {
  AlarmClockPlus,
  ArrowRight,
  Asterisk,
  ChevronUp,
  ClipboardList,
  Copy,
  MessageCirclePlus,
  RotateCcw,
} from "lucide-react";

const ResponseSection = ({
  response,
  onNewQuestion,
  isLoadingSuggestions,
  fetchedSuggestions,
  fetchSuggestedPrompts,
  suggestedFlowStacks,
  onChatNow,
  onGenerateImage,
  onSaveFlow,
  onRunFlow,
  setAdditionalChatDetails,
  setAdditionalImageDetails,
  setAdditionalStrategyDetails,
  additionalChatDetails,
  additionalImageDetails,
  additionalStrategyDetails,
  setShowAdditionalImagePromptDetails,
  setShowAdditionalStrategyDetails,
  setShowAdditionalChatPromptDetails,
  showAdditionalImagePromptDetails,
  showAdditionalChatPromptDetails,
  showAdditionalStrategyDetails,
  onSendMessage,
  onImagePromptClick,
  currentImage,
  chatPromptResponse,
  strategyResponse,
  isLoadingSecondaryResponse,
  setIsLoadingSecondaryResponse,
  onCreateTodo,
  isCreatingTodo,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const renderMessage = (content) => (
    <ReactMarkdown
      className="markdown-content"
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <div className="min-h-[60vh] w-full p-2 lg:p-6 lg:pt-4 bg-gray-100 rounded-lg overflow-y-auto shadow-md">
      <HeaderSection onNewQuestion={onNewQuestion} />

      {response && response.initialResponse ? (
        <ResponseDisplaySection initialResponse={response.initialResponse} />
      ) : (
        <div className="text-lg text-gray-700 flex items-center">
          Thinking... <Asterisk className="h-6 w-6 ml-2 animate-spin" />
        </div>
      )}

      {isLoadingSuggestions && <LoadingSuggestions />}

      {!isLoadingSuggestions &&
        response &&
        response.suggestedTypes &&
        response.context &&
        response.context.intent === "contentCreation" && (
          <>
            <div className="flex flex-col lg:flex-row mt-2 lg:mt-4 gap-2 lg:justify-end">
              {/*<div className="flex gap-2">
                <Button
                  variant="outline"
                  onClick={() => alert("This feature is coming soon!")}
                  className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-white hover:shadow-md"
                >
                  <MessageCirclePlus className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                  Continue this Conversation
                </Button>
              </div>*/}
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  onClick={() =>
                    setShowAdditionalStrategyDetails(
                      !showAdditionalStrategyDetails
                    )
                  }
                  className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-white hover:shadow-md"
                >
                  <ClipboardList className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                  Create a Strategy for this Task
                </Button>
              </div>
              <div className="flex gap-2">
                <Button
                  variant="outline"
                  onClick={(e) => onCreateTodo(e)}
                  className={`flex items-center gap-2 border-none hover:text-gray-700 hover:shadow-md ${
                    isCreatingTodo
                      ? "magical-gradient text-white"
                      : "bg-white text-black"
                  }`}
                >
                  <AlarmClockPlus
                    className={`h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full ${
                      isCreatingTodo ? "animate-spin" : ""
                    }`}
                  />
                  {isCreatingTodo
                    ? "Creating your To-Do"
                    : "Add a To-Do for this Task"}
                </Button>
              </div>
            </div>
            {showAdditionalStrategyDetails && (
              <div className="mt-2 lg:max-w-4xl">
                <div className="flex items-end justify-between mb-2">
                  <div className="flex items-center gap-2 font-semibold text-gray-900 mb-2">
                    <ClipboardList className="h-5 w-5" />
                    Enter any additional details for your strategy:
                  </div>

                  <Button
                    onClick={() => {
                      setShowAdditionalStrategyDetails(false);
                    }}
                    className="px-6 w-fit flex items-center justify-center ml-2 rounded text-gray-900 bg-gray-100 hover:bg-gray-200 border-none shadow-md"
                  >
                    <ChevronUp className="h-4 w-4" />
                  </Button>
                </div>
                <textarea
                  value={additionalStrategyDetails}
                  onChange={(e) => setAdditionalStrategyDetails(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      onSendMessage(
                        e,
                        "Create a tactical plan of action to accomplish our goal based on the suggestions provided.",
                        "strategy"
                      );
                      setShowAdditionalStrategyDetails(false);
                    }
                  }}
                  className="w-full h-28 p-4 border rounded-lg bg-white backdrop-blur-sm shadow-md
                        border-none focus:border-gray-900 focus:ring-2 
                        focus:ring-gray-900 focus:ring-opacity-50 
                        transition-all duration-200 ease-in-out
                        resize-none text-lg"
                  placeholder="Add business details, project specifications, etc. here..."
                />
                <div className="mt-2 w-full flex items-center justify-end">
                  <Button
                    onClick={(e) => {
                      onSendMessage(
                        e,
                        "Create a tactical plan of action to accomplish our goal based on the suggestions provided.",
                        "strategy"
                      );
                      setShowAdditionalStrategyDetails(false);
                    }}
                    className="px-6 w-fit flex items-center justify-center ml-2 rounded text-white magical-gradient"
                  >
                    Create a Strategy
                    <ArrowRight className="h-4 w-4 ml-2" />
                  </Button>
                </div>
              </div>
            )}
            {isLoadingSecondaryResponse && <LoadingSuggestions />}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
              {response && strategyResponse && (
                <div className="mt-4 bg-white rounded-lg shadow-md p-4 lg:px-8">
                  <div className="flex items-end justify-between mb-2">
                    <div className="lg:text-xl flex items-center gap-2 font-bold text-gray-700 leading-tight">
                      Here's your strategy:
                    </div>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(strategyResponse);
                          toast({
                            description: "Strategy copied to clipboard",
                            duration: 2000,
                          });
                        }}
                        className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-gray-100 hover:shadow-md"
                      >
                        <Copy className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                        Copy to Clipboard
                      </Button>
                      <Button
                        variant="outline"
                        onClick={(e) => {
                          onSendMessage(
                            e,
                            "Create a tactical plan of action to accomplish our goal based on the suggestions provided.",
                            "strategy"
                          );
                          setShowAdditionalStrategyDetails(false);
                        }}
                        className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-gray-100 hover:shadow-md"
                      >
                        <RotateCcw className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                        Regenerate
                      </Button>
                    </div>
                  </div>
                  <div className="mt-2 lg:max-w-4xl pb-2">
                    <div className={`prose prose-sm max-w-[100%] break-words`}>
                      {renderMessage(strategyResponse)}
                    </div>
                  </div>

                  <div className="flex items-center gap-2 justify-end">
                    <Button
                      variant="outline"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(strategyResponse);
                        toast({
                          description: "Strategy copied to clipboard",
                          duration: 2000,
                        });
                      }}
                      className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-gray-100 hover:shadow-md"
                    >
                      <Copy className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                      Copy to Clipboard
                    </Button>
                    <Button
                      variant="outline"
                      onClick={(e) => {
                        onSendMessage(
                          e,
                          "Create a tactical plan of action to accomplish our goal based on the suggestions provided.",
                          "strategy"
                        );
                        setShowAdditionalStrategyDetails(false);
                      }}
                      className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-gray-100 hover:shadow-md"
                    >
                      <RotateCcw className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                      Regenerate
                    </Button>
                  </div>
                </div>
              )}
              {response.suggestedTypes.assistants && chatPromptResponse && (
                <div className="mt-4 bg-white rounded-lg shadow-md p-4 lg:px-8">
                  <div className="flex items-end justify-between mb-2">
                    <div className="lg:text-xl flex items-center gap-2 font-bold text-gray-700 leading-tight">
                      Here are my thoughts:
                    </div>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(chatPromptResponse);
                          toast({
                            description: "Strategy copied to clipboard",
                            duration: 2000,
                          });
                        }}
                        className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-gray-100 hover:shadow-md"
                      >
                        <Copy className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                        Copy to Clipboard
                      </Button>
                    </div>
                  </div>
                  <div className="mt-2 lg:max-w-4xl pb-2">
                    <div className={`prose prose-sm max-w-[100%] break-words`}>
                      {renderMessage(chatPromptResponse)}
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-2">
                    <Button
                      variant="outline"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(chatPromptResponse);
                        toast({
                          description: "Strategy copied to clipboard",
                          duration: 2000,
                        });
                      }}
                      className="flex items-center gap-2 border-none text-black hover:text-gray-700 bg-gray-100 hover:shadow-md"
                    >
                      <Copy className="h-6 w-6 bg-black text-white p-1 hover:text-white rounded-full" />
                      Copy to Clipboard
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {response.suggestedTypes.imageGenerator && currentImage && (
              <div className="mt-4 lg:max-w-2xl">
                <div className="flex items-end justify-between mb-2">
                  <div className="flex items-center gap-2 font-semibold text-gray-700 leading-tight">
                    Here's your image. Feel free to generate more! This image
                    has been saved to your profile and can be found in your
                    "Generate Images" page.
                  </div>
                </div>

                <AIImageDisplay imageUrl={currentImage.imageUrl} />
              </div>
            )}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-12 mt-6">
              {response.suggestedTypes.flows &&
                (suggestedFlowStacks ? (
                  suggestedFlowStacks?.data?.suggestions?.flows?.length > 0 ? (
                    <FlowSuggestion
                      fetchedSuggestions={suggestedFlowStacks}
                      onSaveFlow={onSaveFlow}
                      onRunFlow={onRunFlow}
                    />
                  ) : null // Return nothing if API returned but flows array is empty
                ) : (
                  // Show skeleton loader only when we're waiting for the API response
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
                    <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
                  </div>
                ))}

              {response.suggestedTypes.imageGenerator &&
                (fetchedSuggestions ? (
                  <ImageGenerationSuggestion
                    fetchedSuggestions={fetchedSuggestions}
                    onGenerateImage={onGenerateImage}
                    navigate={navigate}
                    setAdditionalImageDetails={setAdditionalImageDetails}
                    additionalImageDetails={additionalImageDetails}
                    setShowAdditionalImagePromptDetails={
                      setShowAdditionalImagePromptDetails
                    }
                    showAdditionalImagePromptDetails={
                      showAdditionalImagePromptDetails
                    }
                  />
                ) : (
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
                    <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
                  </div>
                ))}

              {response.suggestedTypes.imageGenerator &&
                (fetchSuggestedPrompts ? (
                  fetchSuggestedPrompts?.data?.suggestions?.imageGenerator
                    ?.imagePrompts?.length > 0 && (
                    <ImagePromptsSuggestion
                      imagePrompts={
                        fetchSuggestedPrompts?.data?.suggestions?.imageGenerator
                          ?.imagePrompts
                      }
                      onImagePromptClick={onImagePromptClick}
                      setAdditionalImageDetails={setAdditionalImageDetails}
                      additionalImageDetails={additionalImageDetails}
                      setShowAdditionalImagePromptDetails={
                        setShowAdditionalImagePromptDetails
                      }
                      showAdditionalImagePromptDetails={
                        showAdditionalImagePromptDetails
                      }
                    />
                  )
                ) : (
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
                    <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
                  </div>
                ))}

              {response.suggestedTypes.assistants &&
                (fetchedSuggestions ? (
                  <AssistantSuggestion
                    fetchedSuggestions={fetchedSuggestions}
                    onChatNow={onChatNow}
                  />
                ) : (
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
                    <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
                  </div>
                ))}
              {response.suggestedTypes.assistants &&
                (fetchSuggestedPrompts ? (
                  fetchSuggestedPrompts?.data?.suggestions?.assistants?.prompts
                    ?.length > 0 && (
                    <ChatPromptsSuggestion
                      chatPrompts={
                        fetchSuggestedPrompts?.data?.suggestions?.assistants
                          ?.prompts
                      }
                      onSendMessage={onSendMessage}
                      setAdditionalChatDetails={setAdditionalChatDetails}
                      additionalChatDetails={additionalChatDetails}
                      setShowAdditionalChatPromptDetails={
                        setShowAdditionalChatPromptDetails
                      }
                      showAdditionalChatPromptDetails={
                        showAdditionalChatPromptDetails
                      }
                    />
                  )
                ) : (
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
                    <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
                    <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
                  </div>
                ))}
            </div>
          </>
        )}
    </div>
  );
};

export default ResponseSection;
