// components/SurveyModal.js
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeSurveyModal } from "../slices/surveyModalSlice";
import { X, Loader2, ArrowRight } from "lucide-react";
import { surveyData } from "./surveyData/screenerSurvey";
import { AnimatePresence, motion } from "framer-motion";
import { submitSurvey } from "../services/api";
import FinalScreenerResponseScreen from "./surveyData/FinalScreenerResponseScreen";
import LoadingScreen from "./LoadingScreen";

// Variants for the overall modal animation.
const modalVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
  exit: { opacity: 0, scale: 0.95, transition: { duration: 0.3 } },
};

// Variants for transitioning between questions.
const questionVariants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, x: -50, transition: { duration: 0.3 } },
};

function SurveyModal() {
  const isOpen = useSelector((state) => state.surveyModal.isOpen);
  const dispatch = useDispatch();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionResult, setSubmissionResult] = useState(null);

  const currentQuestion = surveyData[currentQuestionIndex];

  const handleInputChange = (e) => {
    setCurrentAnswer(e.target.value);
  };

  // For questions that use buttons (inputType "none")
  const handleButtonClick = (value) => {
    const fieldName = currentQuestion.fieldName;
    setAnswers((prev) => ({ ...prev, [fieldName]: value }));
    setCurrentAnswer("");
    moveToNextQuestion();
  };

  // Advance to the next question or, if this was the final question, submit the survey.
  const moveToNextQuestion = () => {
    if (currentQuestionIndex < surveyData.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      handleSubmit();
    }
  };

  // For questions that need manual “Next” (text, textarea, dropdown) record the answer and move on.
  const handleNextClick = () => {
    const fieldName = currentQuestion.fieldName;
    setAnswers((prev) => ({ ...prev, [fieldName]: currentAnswer }));
    setCurrentAnswer("");
    moveToNextQuestion();
  };

  // Submit the survey answers to the back end.
  const handleSubmit = async () => {
    setIsSubmitting(true);
    console.log('isSubmitting:', isSubmitting);
    try {
      const response = await submitSurvey(answers);
      setSubmissionResult(response.data.suggestions);
      console.log('response.message:', response.data.suggestions);
    } catch (error) {
      setSubmissionResult("An error occurred. Please try again.");
    }
    setIsSubmitting(false);
  };

  // Close the modal and reset local state.
  const handleClose = () => {
    dispatch(closeSurveyModal());
    setCurrentQuestionIndex(0);
    setAnswers({});
    setCurrentAnswer("");
    setIsSubmitting(false);
    setSubmissionResult(null);
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-[70] flex items-center justify-center bg-black/50"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="bg-white rounded-lg w-full h-full relative flex flex-col">
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={handleClose}
            >
              <X size={24} />
            </button>

            {/* Progress Bar */}
            <div className="p-0 mt-0">
              <div className="w-full h-2 bg-gray-200 rounded-full mb-4">
                <motion.div
                  className="h-2 bg-indigo-600 rounded-full"
                  initial={{ width: "0%" }}
                  animate={{
                    width: `${((currentQuestionIndex + 1) / surveyData.length) * 100}%`,
                  }}
                  transition={{ duration: 0.5 }}
                />
              </div>
            </div>

            <div className="p-2 lg:p-8 px-6 lg:px-8 lg:flex-grow flex flex-col justify-center items-center">
              {/* Survey Content */}
              {!submissionResult && !isSubmitting ? (
                // Replace exitBeforeEnter with mode="wait" here:
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentQuestion.id}
                    className="max-w-xl w-full"
                    variants={questionVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <div className="mb-6">
                      {currentQuestion.imageUrl && (
                        <motion.img
                          src={currentQuestion.imageUrl}
                          alt="Question"
                          className="w-auto mx-auto mb-0 lg:mb-4 rounded max-h-48"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        />
                      )}
                      <h2 className="text-2xl font-semibold mb-4 lg:text-center">
                        {currentQuestion.questionText}
                      </h2>

                      {/* Render input based on type */}
                      {currentQuestion.inputType === "text" && (
                        <motion.input
                          type="text"
                          name={currentQuestion.fieldName}
                          value={currentAnswer}
                          onChange={handleInputChange}
                          className="w-full border border-gray-300 rounded px-3 py-2"
                          whileFocus={{ scale: 1.02 }}
                        />
                      )}
                      {currentQuestion.inputType === "textarea" && (
                        <motion.textarea
                          name={currentQuestion.fieldName}
                          value={currentAnswer}
                          onChange={handleInputChange}
                          className="w-full border border-gray-300 rounded px-3 py-2"
                          whileFocus={{ scale: 1.02 }}
                        />
                      )}
                      {currentQuestion.inputType === "dropdown" && (
                        <motion.select
                          name={currentQuestion.fieldName}
                          value={currentAnswer}
                          onChange={handleInputChange}
                          className="w-full border border-gray-300 rounded px-3 py-2"
                          whileFocus={{ scale: 1.02 }}
                        >
                          <option value="">Select an option</option>
                          {currentQuestion.dropdownOptions &&
                            currentQuestion.dropdownOptions.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                        </motion.select>
                      )}
                      {/* For questions with buttons */}
                      {currentQuestion.inputType === "none" &&
                        currentQuestion.buttons && (
                          <div className="flex flex-col space-y-1 lg:space-y-4 lg:max-w-md lg:mt-10 lg:mx-auto">
                            {currentQuestion.buttons.map((button, index) => (
                              <motion.button
                                key={index}
                                onClick={() => handleButtonClick(button.value)}
                                className="px-4 py-2 bg-black text-white rounded hover:bg-indigo-600 w-full lg:w-auto lg:leading-tight flex items-center gap-1 justify-center"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                              >
                                {button.label}<ArrowRight className="w-4 h-4 text-indigo-400" />
                              </motion.button>
                            ))}
                          </div>
                        )}
                    </div>
                  </motion.div>
                </AnimatePresence>
              ) : (
                // Final screen after submission.
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 0.5 } }}
                >
                  {isSubmitting ? (
                    <div className="flex items-center justify-center space-x-0 h-screen w-[100vw] absolute top-0 left-0">
                      <LoadingScreen />
                    </div>
                  ) : (
                    <>
                      {submissionResult && (
                        <FinalScreenerResponseScreen response={submissionResult} onClose={handleClose} />
                      )}
                    </>
                  )}
                </motion.div>
              )}
            </div>
            {/* Next/Submit Button for input-based questions */}
            {currentQuestion &&
              currentQuestion.inputType !== "none" &&
              !submissionResult && (
                <div className="w-full max-w-xl flex justify-end ml-auto p-8">
                  <motion.button
                    onClick={handleNextClick}
                    className="px-6 py-2 bg-black text-white rounded hover:bg-indigo-700 disabled:opacity-50"
                    disabled={
                      isSubmitting ||
                      (currentAnswer === "" && currentQuestion.inputType !== "none")
                    }
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {currentQuestionIndex === surveyData.length - 1
                      ? "Submit"
                      : "Next"}
                  </motion.button>
                </div>
              )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default SurveyModal;