import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchFlowById, updateFlow } from '../slices/flowsSlice';
import { useToast } from "../components/ui/use-toast";
import FlowForm from './FlowForm';

const FlowEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const flow = useSelector(state => state.flows.selectedFlow);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(fetchFlowById(id));
  }, [dispatch, id]);

  const handleSubmit = async (flowData) => {
    setIsSubmitting(true);
    try {
      await dispatch(updateFlow({ id, flowData })).unwrap();
      toast({
        title: "Success",
        description: "Flow updated successfully",
        variant: "default",
      });
      navigate('/manage-flows');
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to update flow",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSave = async (flowData) => {
    setIsSaving(true);
    try {
      const result = await dispatch(updateFlow({ id, flowData })).unwrap();
      
      await dispatch(fetchFlowById(id)).unwrap();
      
      toast({
        title: "Success",
        description: "Flow saved successfully",
        variant: "default",
      });
    } catch (err) {
      console.error('Error in handleSave:', err); // Debug error log
      toast({
        title: "Error",
        description: "Failed to save flow",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-0 lg:space-y-2 mt-2">
      <h1 className="lg:text-3xl font-bold">Edit Flow</h1>
      <FlowForm
        initialFlow={flow}
        onSubmit={handleSubmit}
        onSave={handleSave}
        submitButtonText="Save & Close"
        saveButtonText="Save"
        isSubmitting={isSubmitting}
        isSaving={isSaving}
        submittingText="Updating..."
        savingText="Saving..."
        isEditMode={true}
      />
    </div>
  );
};

export default FlowEdit;