// File: /src/slices/flowsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchFlows = createAsyncThunk(
  'flows/fetchFlows',
  async ({ page, limit, search, teamId }) => {
    const response = await api.fetchFlows({ page, limit, search, teamId });
    return response.data;
  }
);

export const fetchFlowById = createAsyncThunk(
    'flows/fetchFlowById',
    async (id, { rejectWithValue }) => {
      try {
        const response = await api.fetchFlowById(id);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

export const createFlow = createAsyncThunk(
  'flows/createFlow',
  async (flowData) => {
    const response = await api.createFlow(flowData);
    return response.data;
  }
);

export const updateFlow = createAsyncThunk(
    'flows/updateFlow',
    async ({ id, flowData }) => {
      const response = await api.updateFlow(id, flowData);
      return response.data;
    }
  );

export const deleteFlow = createAsyncThunk(
  'flows/deleteFlow',
  async (id) => {
    await api.deleteFlow(id);
    return id;
  }
);

export const fetchAssistants = createAsyncThunk(
  'flows/fetchAssistants',
  async () => {
    const response = await api.fetchAssistants();
    return response.data
      .filter(assistant => assistant.assistantType === 'flow')
      .map(assistant => ({
        ...assistant,
        type: 'FlowChatbot'
      }));
  }
);

export const cloneFlow = createAsyncThunk(
  'flows/cloneFlow',
  async (id) => {
    const response = await api.cloneFlow(id);
    return response.data;
  }
);

export const cloneGlobalFlow = createAsyncThunk(
  'flows/cloneGlobalFlow',
  async ( id, selectedTeam ) => {
    console.log('slice id', id);
    console.log('slice teamId', selectedTeam);
    const response = await api.cloneGlobalFlow(id, {teamId: selectedTeam});
    return response.data;
  }
);

export const fetchGlobalFlows = createAsyncThunk(
  'flows/fetchGlobalFlows',
  async () => {
    const response = await api.fetchGlobalFlows();
    return response.data;
  }
);

const flowsSlice = createSlice({
  name: 'flows',
  initialState: {
    flows: [],
    selectedFlow: null,
    status: 'idle',
    error: null,
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    assistants: [],
    assistantsStatus: 'idle',
    assistantsError: null,
    globalFlows: [],
    globalFlowsStatus: 'idle',
    globalFlowsError: null,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlows.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlows.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.flows = action.payload.flows;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchFlows.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFlowById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlowById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedFlow = action.payload;
      })
      .addCase(fetchFlowById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createFlow.fulfilled, (state, action) => {
        state.flows.push(action.payload);
        state.totalItems += 1;
      })
      .addCase(updateFlow.fulfilled, (state, action) => {
        const index = state.flows.findIndex(flow => flow._id === action.payload._id);
        if (index !== -1) {
          state.flows[index] = action.payload;
        }
        state.selectedFlow = action.payload;
      })
      .addCase(deleteFlow.fulfilled, (state, action) => {
        state.flows = state.flows.filter(flow => flow.id !== action.payload);
        state.totalItems -= 1;
      })
      .addCase(fetchAssistants.pending, (state) => {
        state.assistantsStatus = 'loading';
      })
      .addCase(fetchAssistants.fulfilled, (state, action) => {
        state.assistantsStatus = 'succeeded';
        state.assistants = action.payload;
      })
      .addCase(fetchAssistants.rejected, (state, action) => {
        state.assistantsStatus = 'failed';
        state.assistantsError = action.error.message;
      })
      .addCase(cloneFlow.fulfilled, (state, action) => {
        state.flows.push(action.payload);
        state.totalItems += 1;
      })
      .addCase(cloneGlobalFlow.fulfilled, (state, action) => {
        state.flows.push(action.payload);
        state.totalItems += 1;
      })
      .addCase(fetchGlobalFlows.pending, (state) => {
        state.globalFlowsStatus = 'loading';
      })
      .addCase(fetchGlobalFlows.fulfilled, (state, action) => {
        state.globalFlowsStatus = 'succeeded';
        state.globalFlows = action.payload;
      })
      .addCase(fetchGlobalFlows.rejected, (state, action) => {
        state.globalFlowsStatus = 'failed';
        state.globalFlowsError = action.error.message;
      });
  },
});

export const { setCurrentPage, setItemsPerPage } = flowsSlice.actions;
export default flowsSlice.reducer;