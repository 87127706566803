import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Loader2 } from "lucide-react";

const LoadingFlowStackScreen = () => {
  const [messageIndex, setMessageIndex] = useState(-1);
  const [showContent, setShowContent] = useState(false);

  const messages = [
    "Sit back and relax while we run your awesome new tool...",
    "This shouldn't take any longer than 60 seconds at the most...",
    "When it's complete, you'll be redirected to the output...",
    "I mean, could content creation BE any easier?",
    "You could've topped off your coffee while we did all the work for you...",
    "Isn't life easier when everything is done with magic?",
    "Remember, your next content breakthrough is always just a click away...",
  ];

  useEffect(() => {

    // Initial delay of 1.5 seconds before showing loader and first message
    const initialDelay = setTimeout(() => {
      setShowContent(true);
      setMessageIndex(0);
    }, 1000);

    // Set up message progression
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        if (prevIndex < messages.length - 1) {
          return prevIndex + 1;
        }
        clearInterval(messageInterval);
        return prevIndex;
      });
    }, 7000); // Change message every 7 seconds

    // Cleanup
    return () => {
      clearTimeout(initialDelay);
      clearInterval(messageInterval);
    };
  }, []);

  return (
    <div className="relative w-full h-screen">
      
      <motion.div
        className="relative flex flex-col items-center justify-center h-full space-y-4"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.5 } }}
      >
        <div className="p-10 rounded magical-gradient flex flex-col items-center justify-center text-center space-y-4 w-full h-full lg:w-2/5 lg:h-2/5">
        <AnimatePresence>
          {showContent && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Loader2 className="animate-spin text-white" size={48} />
            </motion.div>
          )}
        </AnimatePresence>
        
        <AnimatePresence mode="wait">
          {messageIndex >= 0 && (
            <motion.p
              key={messageIndex}
              className="text-lg text-gray-100 font-medium"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {messages[messageIndex]}
            </motion.p>
          )}
        </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default LoadingFlowStackScreen;