import React from "react";
import { Asterisk, LoaderPinwheel } from "lucide-react";
import { Skeleton } from "../../../components/ui/skeleton";

const LoadingSuggestions = () => (
  <div className="flex flex-col items-center">
    <div className="mt-10 flex items-center justify-center gap-2 text-black p-2 rounded-full w-fit px-8 mx-auto">
      <Asterisk className="h-8 w-8 animate-spin" />
      <h2 className=" blink-slow">Loading up your response...</h2>
    </div>
    
    <div className="mt-12 w-full grid grid-cols-1 md:grid-cols-3 gap-20 px-4 md:px-6 max-w-full mx-auto">
      {/* Column 1 */}
      <div className="flex flex-col space-y-3">
        <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
        <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
        <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
        <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
        <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
      </div>
      
      {/* Column 2 */}
      <div className="flex flex-col space-y-3">
        <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
        <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
        <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
        <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
        <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
      </div>
      
      {/* Column 3 */}
      <div className="flex flex-col space-y-3">
        <Skeleton className="h-12 w-full rounded-lg bg-gray-200" />
        <Skeleton className="h-64 w-full rounded-lg bg-gray-200" />
        <Skeleton className="h-4 w-3/4 rounded-lg bg-gray-200" />
        <Skeleton className="h-4 w-1/2 rounded-lg bg-gray-200" />
        <Skeleton className="h-10 w-1/4 rounded-lg bg-gray-200" />
      </div>
    </div>
  </div>
);

export default LoadingSuggestions;
