import React, { useEffect, createContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store } from './store';
import { getCurrentUser } from './slices/authSlice';
import AuthWrapper from './components/AuthWrapper';
import AdminRoute from './components/AdminRoute';
import Layout from './pages/Layout';
import DashboardNew from './pages/Dashboard';
import AssetsDashboard from './pages/AssetsDashboard';
import Dashboard from './pages/zDashboard_Standard';
import AdminDashboard from './pages/AdminDashboard';
import Flows from './pages/Flows';
import FlowCreation from './pages/FlowCreation';
import FlowEdit from './pages/FlowEdit';
import FlowManagement from './pages/FlowManagement';
import FlowExecution from './pages/FlowExecution';
import Scheduling from './pages/Scheduling';
import SubscriptionPlans from './pages/SubscriptionPlans';
import LoginPage from './pages/LoginPage';
import ExecutionPage from './pages/ExecutionPage';
import ManageTeams from './pages/ManageTeams';
import AcceptInvitation from './pages/AcceptInvitation';
import AIImageGenerationPage from './pages/AIImageGenerationPage';
import AssetsPage from './pages/AssetsPage';
import ChatComponent from './pages/ChatComponent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import TermsOfService from './pages/TermsOfService';
import DataDeletionRequest from './pages/DataDeletion';
import UserChatbotPage from './pages/UserChatbotPage';
import Chatbots from './pages/Chatbots';
import SupportPage from './pages/SupportPage';
import TodoPage from './pages/TodoPage';
import { PurchaseCancelPage } from './pages/ChatbotComponents/PurchaseResultPages';
import { PurchaseSuccessPage } from './pages/ChatbotComponents/PurchaseResultPages';
import { initSocket, closeSocket } from './services/socket';
import { Toaster } from "./components/ui/toaster";
import AudioPlayer from './components/AudioPlayer';
import AudioBrowser from './components/AudioComponents/AudioBrowser';
import VideoModal from './components/VideoModal';
import IframeModal from './components/IframeModal';
import SurveyModal from './components/SurveyModal';
import CreateFlowStackModal from './components/CreateFlowStackModal';
import { closeVideoModal } from './slices/videoModalSlice';
import { closeIframeModal } from './slices/iframeModalSlice';
import { closeSurveyModal } from './slices/surveyModalSlice';
import { closeFlowStackModal } from './slices/flowStackModalSlice';
import DocumentationPage from './pages/DocumentationPage';
import GoogleAuthCallback from './components/GoogleAuthCallback';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import LoadingFlowStackScreen from './components/LoadingFlowStackScreen';
import { Loader } from 'lucide-react';
import { Alert, AlertDescription } from './components/ui/alert';
import ContentDashboard from './pages/ContentDashboard';

export const SocketContext = createContext(null);

const LoadingSpinner = () => (
  <div className="animate-spin">
    <Loader className="h-6 w-6 text-indigo-600" />
  </div>
);

const ConditionalDashboard = () => {
  const { user } = useSelector((state) => state.auth);
  // Check if user has one of the specified subscription IDs
  const hasSpecialSubscription = user?.subscription?.subscriptionId?._id === '67c768ff75cefd1a181b0a7d' || 
                                user?.subscription?.subscriptionId?._id === '67c7b00e75cefd1a181b0a94' ||
                                user?.subscription?.subscriptionId?._id === '67c7b04375cefd1a181b0a95';
  
  // Redirect based on subscription
  return hasSpecialSubscription ? <AssetsDashboard /> : <DashboardNew />;
};

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(timer);
    }
    // Reset countdown if loading changes
    setCountdown(3);
  }, [loading]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[200px] space-y-4">
        <LoadingSpinner />
        <Alert variant="default" className="max-w-md border border-red-500">
          <AlertDescription className="text-center">
            Your login has expired. Please log in again.
            <br />
            Redirecting to login page in {countdown} seconds
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

const AppRoutes = () => {
  const { isAuthenticated, accessToken, loading, error } = useSelector((state) => state.auth);
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const { isOpen: isVideoModalOpen, videos } = useSelector((state) => state.videoModal);
  const { isOpen: isIframeModalOpen, iframes } = useSelector((state) => state.iframeModal);
  const { isOpen: isSurveyModalOpen } = useSelector((state) => state.surveyModal);
  const { isOpen: isFlowStackModalOpen } = useSelector((state) => state.flowStackModal);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    //console.log('AppRoutes: Auth state changed', { isAuthenticated, accessToken: !!accessToken });
    if (isAuthenticated && accessToken) {
      console.log('Initializing socket');
      const newSocket = initSocket(accessToken);
      setSocket(newSocket);
      return () => {
        console.log('Closing socket');
        closeSocket();
        setSocket(null);
      };
    }
  }, [isAuthenticated, accessToken]);

  useEffect(() => {
    //console.log('Socket state in AppRoutes:', socket ? 'Connected' : 'Not connected');
  }, [socket]);

  const handleCloseVideoModal = () => {
    dispatch(closeVideoModal());
  };

  const handleCloseIframeModal = () => {
    dispatch(closeIframeModal());
  };

  const handleCloseSurveyModal = () => {
    dispatch(closeSurveyModal());
  };

  const handleCloseFlowStackModal = () => {
    dispatch(closeFlowStackModal());
  };

  const GlobalModals = ({ isVideoModalOpen, isIframeModalOpen, handleCloseVideoModal, handleCloseIframeModal, videos, iframes, isSurveyModalOpen, handleCloseSurveyModal }) => {
    return (
      <>
        <VideoModal
          isOpen={isVideoModalOpen}
          onClose={handleCloseVideoModal}
          videos={videos}
        />
        <IframeModal
          isOpen={isIframeModalOpen}
          onClose={handleCloseIframeModal}
          iframes={iframes}
        />
        <SurveyModal
          isOpen={isSurveyModalOpen}
          onClose={handleCloseSurveyModal}
        />
      </>
    );
  };

  const PrivateModals = ({ isVideoModalOpen, isIframeModalOpen, handleCloseVideoModal, handleCloseIframeModal, videos, iframes, isSurveyModalOpen, handleCloseSurveyModal, isFlowStackModalOpen, handleCloseFlowStackModal }) => {
    return (
      <>
        <VideoModal
          isOpen={isVideoModalOpen}
          onClose={handleCloseVideoModal}
          videos={videos}
        />
        <IframeModal
          isOpen={isIframeModalOpen}
          onClose={handleCloseIframeModal}
          iframes={iframes}
        />
        <SurveyModal
          isOpen={isSurveyModalOpen}
          onClose={handleCloseSurveyModal}
        />
        <CreateFlowStackModal
          isOpen={isFlowStackModalOpen}
          onClose={handleCloseFlowStackModal}
        />
      </>
    );
  };


  if (error) {
    return <div>Uh-oh. There has been an error. Please try refreshing your browser or logging out and back in. Please contact support at support@floworchestra.com if the issue persists.</div>;
  }

  if (!isAuthenticated) {
    return (<>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth/success" element={<GoogleAuthCallback />} />
<Route path="/auth/error" element={<Navigate to="/login" state={{ error: 'Google authentication failed' }} replace />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/data-deletion" element={<DataDeletionRequest />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
      <GlobalModals
        isVideoModalOpen={isVideoModalOpen}
        isIframeModalOpen={isIframeModalOpen}
        handleCloseVideoModal={handleCloseVideoModal}
        handleCloseIframeModal={handleCloseIframeModal}
        videos={videos}
        iframes={iframes}
        isSurveyModalOpen={isSurveyModalOpen}
        handleCloseSurveyModal={handleCloseSurveyModal}
      />
    </>);
  }

  return (
    <SocketContext.Provider value={socket}>
      <Layout>
        <Routes>
        <Route path="/" element={<ProtectedRoute><ConditionalDashboard /></ProtectedRoute>} />
          <Route path="/dashboarddfy" element={<ProtectedRoute><AssetsDashboard /></ProtectedRoute>} />
          <Route path="/editcontent" element={<ProtectedRoute><ContentDashboard /></ProtectedRoute>} />
          <Route path="/loading" element={<ProtectedRoute><LoadingFlowStackScreen /></ProtectedRoute>} />
          <Route path="/audio" element={<ProtectedRoute><AudioBrowser /></ProtectedRoute>} />
          <Route path="/image-generation" element={<ProtectedRoute><AIImageGenerationPage /></ProtectedRoute>} />
          <Route path="/assets/:assetType" element={<ProtectedRoute><AssetsPage /></ProtectedRoute>} />
          <Route path="/chat/:chatId" element={<ProtectedRoute><ChatComponent /></ProtectedRoute>} />
          <Route path="/chat" element={<ProtectedRoute><ChatComponent /></ProtectedRoute>} />
          <Route path="/create-flow" element={<ProtectedRoute><FlowCreation /></ProtectedRoute>} />
          <Route path="/edit-flow/:id" element={<ProtectedRoute><FlowEdit /></ProtectedRoute>} />
          <Route path="/flows" element={<ProtectedRoute><Flows /></ProtectedRoute>} />
          <Route path="/manage-flows" element={<ProtectedRoute><FlowManagement /></ProtectedRoute>} />
          <Route path="/manage-teams" element={<ProtectedRoute><ManageTeams /></ProtectedRoute>} />
          <Route path="/execute-flow/:id" element={<ProtectedRoute><FlowExecution /></ProtectedRoute>} />
          <Route path="/execution/:executionId" element={<ProtectedRoute><ExecutionPage /></ProtectedRoute>} />
          <Route path="/execution" element={<ProtectedRoute><ExecutionPage /></ProtectedRoute>} />
          <Route path="/scheduling" element={<ProtectedRoute><Scheduling /></ProtectedRoute>} />
          <Route path="/scheduling/:scheduleId" element={<ProtectedRoute><Scheduling /></ProtectedRoute>} />
          <Route path="/custom-assistants" element={<ProtectedRoute><UserChatbotPage /></ProtectedRoute>} />
          <Route path="/subscription" element={<ProtectedRoute><SubscriptionPlans /></ProtectedRoute>} />
          <Route path="/subscription/cancel" element={<ProtectedRoute><SubscriptionPlans /></ProtectedRoute>} />
          <Route path="/marketplace" element={<ProtectedRoute><Chatbots /></ProtectedRoute>} />
          <Route path="/marketplace/purchase/success" element={<ProtectedRoute><PurchaseSuccessPage /></ProtectedRoute>} />
          <Route path="/marketplace/cancel" element={<ProtectedRoute><PurchaseCancelPage /></ProtectedRoute>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/data-deletion" element={<DataDeletionRequest />} />
          <Route path="/accept-invitation/:token" element={<AcceptInvitation />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/todo" element={<ProtectedRoute><TodoPage /></ProtectedRoute>} />
          <Route path="/documentation" element={<ProtectedRoute><DocumentationPage /></ProtectedRoute>} />

          {/* Admin Routes */}
          <Route
            path="/admindash"
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
          />
          <Route
            path="/dashnew"
            element={
              <AdminRoute>
                <DashboardNew />
              </AdminRoute>
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
      <PrivateModals
          isVideoModalOpen={isVideoModalOpen}
          isIframeModalOpen={isIframeModalOpen}
          handleCloseVideoModal={handleCloseVideoModal}
          handleCloseIframeModal={handleCloseIframeModal}
          videos={videos}
          iframes={iframes}
          isSurveyModalOpen={isSurveyModalOpen}
          handleCloseSurveyModal={handleCloseSurveyModal}
          isFlowStackModalOpen={isFlowStackModalOpen}
          handleCloseFlowStackModal={handleCloseFlowStackModal}
        />
    </SocketContext.Provider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AuthWrapper>
        <Router>
          <AppRoutes />
          <AudioPlayer />
          <Toaster />
        </Router>
      </AuthWrapper>
    </Provider>
  );
};

export default App;