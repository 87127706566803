import React, { useState } from 'react';
import { Button } from "../../../components/ui/button";
import { MessageSquare, PlusCircle, ChevronUp, ChevronDown } from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../../components/ui/tooltip";
import { conversationOptions } from './conversationOptionsData';

const ConversationOptions = ({ handleSuggestionClick, expanded = false }) => {
    const [isCollapsed, setIsCollapsed] = useState(!expanded);

    const hasOptions = conversationOptions.length > 0;

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="max-h-[300px] bg-background w-full overflow-y-auto rounded-lg shadow-lg">
            <div className="p-2 md:p-4">
            <div className="flex justify-between items-start cursor-pointer"
                        onClick={toggleCollapse}>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div>
                                    <h3 className="font-medium mb-2 flex items-center">
                                        <MessageSquare className="h-5 w-5 mr-1 text-blue-500" />
                                        Conversation Options
                                    </h3>
                                    <div className="text-xs text-muted-foreground leading-tight">
                                        Select a conversation option to help guide your interaction with the AI assistant. Your assistant will remember your choices throughout this conversation.
                                    </div>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent
                                className="flex items-center max-w-[400px]"
                                side="top"
                                align="start"
                            >
                                <MessageSquare className="h-8 w-8 mr-2 text-blue-500" />
                                Click on an option to insert it into the chat input. You can include additional prompts or details in the same message.
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    {/* Collapse button - only visible on md and larger screens */}
                    <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="hidden md:flex items-center text-gray-500 hover:text-gray-700"
                    >
                        {isCollapsed ? (
                            <ChevronDown className="h-4 w-4" />
                        ) : (
                            <ChevronUp className="h-4 w-4" />
                        )}
                    </Button>
                </div>

                {/* Content - always visible on small screens, collapsible on md and larger */}
                <div className={`space-y-2 ${isCollapsed ? 'hidden md:hidden' : 'block'}`}>
                    {hasOptions ? (
                        <div className="space-y-2">
                            {conversationOptions.map((option, index) => (
                                <Button
                                    key={index}
                                    type="button"
                                    variant="ghost"
                                    className="w-full justify-start text-left text-black font-normal h-auto whitespace-normal border-none bg-[#fafafa] shadow-md"
                                    onClick={() => handleSuggestionClick(`[Conversation Guidance]: ${option.text} - ${option.description}`)}
                                >
                                    <div>
                                        <div className="flex items-center">
                                            <PlusCircle className="h-4 w-4 mr-2 text-gray-500" />
                                            <span>{option.text}</span>
                                        </div>
                                        {option.description && (
                                            <div className="ml-6 text-xs text-muted-foreground mt-1">
                                                {option.description}
                                            </div>
                                        )}
                                    </div>
                                </Button>
                            ))}
                        </div>
                    ) : (
                        <div className="p-4 text-muted-foreground">
                            No conversation options available
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConversationOptions;