import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { ExternalLink, ChevronDown, HelpCircle, Sparkles } from "lucide-react";
import { useDispatch } from 'react-redux';
import { openIframeModal } from '../../slices/iframeModalSlice';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
  } from "../../components/ui/collapsible";

const InteractiveDemosAll = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  // Tutorial iframe data - replace with your actual iframe content
  const tutorials = [
    {
      title: "Getting Started with Flow Orchestra",
      url: "https://demo.arcade.software/4cy0I3lWOeuLK8jMujC0?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true",
    },
    {
      title: "Building a Custom Assistant",
      url: "https://app.sharefable.com/embed/demo/build-a-custom-assista-p1l2mpfg3querwql",
    },
    {
      title: "Creating a Team and Inviting a Teammate",
      url: "https://app.sharefable.com/embed/demo/creating-a-team-inviti-69e7rq20wbovpkih",
    },
    {
      title: "Creating and Managing Your To-Do List",
      url: "https://app.sharefable.com/embed/demo/creating-a-to-do-in-fl-wgv2gzxqfv9ph8qs",
    },
    {
      title: "Generating an AI Image",
      url: "https://app.sharefable.com/live/demo/generating-an-image-on-3938fhi9olz8fn7f",
    },
    {
      title: "Scheduling an Automated Flow",
      url: "https://app.sharefable.com/embed/demo/mastering-flow-orchest-u30p8gjp5ga6fbxm",
    }
  ];

  const handleOpenIframe = (tutorial) => {
    dispatch(openIframeModal({
      url: tutorial.url,
      title: tutorial.title
    }));
  };

  return (
    <div className="w-full px-4 pt-1 pb-2 lg:pb-4 lg:pt-2 border rounded-lg bg-gray-100 border-none shadow-md">
      {/* Desktop View */}
      <div className="hidden sm:block">
        <div className="flex items-center justify-between mb-10">
          <h2 className="text-xl font-semibold mb-1 flex items-center"><Sparkles className='h-5 w-5 mr-2' />Interactive Demos</h2>
          <div className="text-md text-black mb-2 flex items-center">
            <HelpCircle className='mr-1 h-4 w-4' />
            Learn how to get the most out of Flow Orchestra with our interactive demos.
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3 gap-6">
          {tutorials.map((tutorial, index) => (
            <Button
              key={index}
              variant="outline"
              size="sm"
              className="group text-black hover:text-black bg-[#fafafa] hover:bg-gray-200 transition-colors duration-200 w-full border-none shadow-md"
              onClick={() => handleOpenIframe(tutorial)}
            >
              <div className="flex items-center justify-start w-full">
                <ExternalLink className="h-4 w-4 mr-2 flex-shrink-0 text-black group-hover:text-black" />
                <span className="truncate">{tutorial.title}</span>
              </div>
            </Button>
          ))}
        </div>
      </div>

      {/* Mobile View with Collapsible */}
      <div className="sm:hidden">
        <Collapsible
          open={isOpen}
          onOpenChange={setIsOpen}
          className="w-full space-y-2"
        >
          <div className="flex items-center justify-between space-x-4 px-1">
            <h2 className="text-xl font-semibold flex items-center"><Sparkles className='h-5 w-5 mr-2' />Interactive Demos</h2>
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="w-9 p-0"
              >
                <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${
                  isOpen ? "transform rotate-180" : ""
                }`}/>
                <span className="sr-only">Toggle documentation & guides</span>
              </Button>
            </CollapsibleTrigger>
          </div>
          
          <CollapsibleContent className="space-y-2">
            {tutorials.map((tutorial, index) => (
              <Button
                key={index}
                variant="outline"
                size="sm"
                className="text-gray-900 hover:text-gray-800 transition-colors duration-200 w-full bg-white shadow-md"
                onClick={() => handleOpenIframe(tutorial)}
              >
                <div className="flex items-center justify-start w-full">
                  <ExternalLink className="h-4 w-4 mr-2 flex-shrink-0" />
                  <span className="truncate">{tutorial.title}</span>
                </div>
              </Button>
            ))}
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};

export default InteractiveDemosAll;