import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

const FAQSection = () => {
    const faqs = [
        {
          question: "What is Flow Orchestra?",
          answer: "Flow Orchestra is a powerful workflow automation platform that combines AI capabilities with customizable workflows to streamline your business processes. It enables individuals and teams to create, manage, and automate complex workflows while leveraging advanced AI features like natural language processing and image generation. Whether you're looking to automate routine tasks, enhance creative processes, or improve team collaboration, Flow Orchestra can handle your unique needs."
        },
        {
          question: "How does Flow Orchestra use AI?",
          answer: "Flow Orchestra integrates advanced AI capabilities throughout the platform. You can use AI to generate content, analyze data, create images, brainstorm strategy plans, and even build custom workflows. Our AI features include ChatGPT-style conversations, DALL-E image generation, expertly-trained custom AI assistants, and a ton of writing styles precisely tuned to create any kind of content you can imagine."
        },
        {
          question: "Is Flow Orchestra suitable for non-technical users?",
          answer: "Absolutely! Flow Orchestra is designed with a user-friendly interface that requires no coding knowledge. We offer easy-to-use workflow builders, pre-built templates, and intuitive controls that make automation accessible to everyone. While technical users can dive deep into customization, non-technical users can easily create powerful workflows using our simple tools."
        },
        {
          question: "What types of workflows can I create?",
          answer: "The possibilities are endless! Common use cases include content creation, social media planning, marketing strategy, product ecosystem design, and AI-powered creative processes. Our platform supports both simple task automation and complex, multi-step workflows with advanced logic and AI integration."
        },
        {
          question: "How much does Flow Orchestra cost?",
          answer: "Flow Orchestra offers flexible pricing tiers to suit different needs. We start with a free tier that lets you explore basic features, followed by Individual ($19/month), Growth ($49/month - Most Popular), and Creator ($99/month) plans that include advanced AI capabilities, increased workflow limits, and collaboration features. Enterprise plans with custom pricing are available for larger organizations needing additional support and customization."
        },
        {
          question: "Can I try Flow Orchestra before purchasing?",
          answer: "Yes! We offer a free tier that gives you access to core features and basic workflow automation. You can sign up for a free account, explore our template library, and start building your own workflows right away. If you need more advanced features, you can upgrade to a paid plan at any time to unlock additional capabilities and support."
        },
        {
          question: "How secure is Flow Orchestra?",
          answer: "Security is our top priority. We use secure cloud infrastructure, implement strict access controls, and ensure your data is protected with regular backups. Flow Orchestra is GDPR-compliant and follows industry best practices for data protection. Our platform is designed to keep your workflows and data safe, so you can focus on growing your business with confidence."
        },
        {
          question: "What kind of support is available?",
          answer: "We provide comprehensive support through multiple channels. This includes 24/7 email support for all users, priority support for paid plans, detailed documentation, video tutorials, and educational content posted to our social media profiles. Our team is dedicated to helping you succeed with Flow Orchestra, whether you're just getting started or looking to optimize your workflows."
        },
        {
          question: "How quickly can I get started?",
          answer: "You can start automating workflows within minutes! Sign up for a free account, choose from our template library or start building your own workflow, and watch your ideas come to life quicker than ever. Most users create their first automated workflow within 15 minutes of signing up."
        },
        {
          question: "Can I collaborate with my team?",
          answer: "Absolutely! Flow Orchestra is built for collaboration. Our paid and enterprise plans include advanced collaboration features like shared workflows and generated content, with additional exciting collaboration tools coming in the future. With our Growth and Creator plans, you can invite team members to work together on complex workflows and creative projects, share credits, and streamline your team's productivity and creativity. If you don't want or need to share seats with others, you can simply use all of the subscription's credits for yourself."
        },
        {
          question: "What makes Flow Orchestra different from other automation tools?",
          answer: "Flow Orchestra stands out through its unique combination of powerful AI capabilities, intuitive workflow design, and extensive customization options. Unlike traditional automation tools, we focus on providing intelligent automation that adapts to your needs. Our platform offers unmatched flexibility in workflow design while maintaining ease of use, and our AI integration capabilities go beyond simple task automation to provide creative and analytical support."
        }
      ];

  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-6 pb-20 lg:p-12">
      <div className="max-w-3xl w-full mb-10">
        <h2 className="text-3xl font-bold text-center mb-4">Frequently Asked Questions</h2>
        <p className="text-gray-600 text-center mb-12">
          Everything you need to know about Flow Orchestra
        </p>

        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger className="text-left">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-600">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQSection;