import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faChevronLeft, faChevronRight, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../components/ui/button';
import { Pagination } from '../../components/ui/pagination';
import { Input } from '../../components/ui/input';
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogHeader, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from '../../components/ui/alert-dialog';
import { fetchChatHistory, deleteChat } from '../../services/api';
import { setError } from '../../slices/chatSlice';

const ChatHistorySidebar = ({ isOpen, toggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [chatHistory, setChatHistory] = useState([]);
    const [filteredChatHistory, setFilteredChatHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25); // Assuming 10 items per page
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [deleteChatId, setDeleteChatId] = useState(null);
 
    useEffect(() => {
      loadChatHistory(currentPage);
    }, [currentPage]);
 
    useEffect(() => {
      filterChatHistory();
    }, [searchTerm, chatHistory]);

    const loadChatHistory = async (page) => {
      try {
        const response = await fetchChatHistory(page, itemsPerPage);
        setChatHistory(response.data.chats);
        setTotalItems(response.data.total);
      } catch (err) {
        dispatch(setError('Failed to load chat history'));
      }
    };
 
    const filterChatHistory = () => {
      const filtered = chatHistory.filter(chat => 
        chat.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredChatHistory(filtered);
    };

    const handleChatSelect = (chatId) => {
      navigate(`/chat/${chatId}`);
      toggleSidebar();
    };
 
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const handleDeleteChat = async (chatId) => {
      try {
        const result = await deleteChat(chatId);
    
        if (result) {
          setChatHistory((prevChats) => prevChats.filter((chat) => chat._id !== chatId));
          setFilteredChatHistory((prevFilteredChats) => prevFilteredChats.filter((chat) => chat._id !== chatId));
        }
    
        setDeleteChatId(null);
      } catch (error) {
        console.error('Delete chat failed:', error);
        dispatch(setError('Failed to delete chat'));
      }
    };
 
    return (
      <div className={`bg-gray-100 border shadow-lg transition-all duration-300 lg:h-[calc(100vh-0rem)] ${isOpen ? 'w-96' : 'w-12'} flex flex-col`}>
        <Button
          onClick={toggleSidebar}
          className="w-full bg-black text-white hover:bg-gray-800"
        >
          <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
        </Button>
       
        {isOpen ? (
          <div className="flex-grow flex flex-col overflow-hidden">
            <h2 className="text-inter font-bold text-xl p-4">Chat History</h2>
            <div className="px-4 mb-4">
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Search chats..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="pl-10 pr-4 py-2 w-full border-none shadow-md"
                />
                <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div className="flex-grow overflow-y-auto px-4">
            {filteredChatHistory && filteredChatHistory.length > 0 ? (
              filteredChatHistory.map((chat) => (
                <div
                  key={chat._id}
                  className="flex items-center justify-between cursor-pointer hover:bg-gray-200 p-2 rounded mb-0 border-gray-200"
                >
                  <div onClick={() => handleChatSelect(chat._id)} className="flex-grow mr-2">
  <h3 className="text-sm font-semibold whitespace-pre-wrap leading-snug mb-1">{chat.title}</h3>
  <p className="text-xs text-gray-500">
    {formatDistanceToNow(new Date(chat.lastMessageDate), { addSuffix: true })}
  </p>
</div>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-red-500 cursor-pointer hover:text-red-700"
                        onClick={() => setDeleteChatId(chat._id)}
                      />
                    </AlertDialogTrigger>
                    <AlertDialogContent className="z-[70]">
                      <AlertDialogHeader>
                        <h3 className="text-lg font-semibold">Delete Chat</h3>
                        <p>Are you sure you want to delete this chat? This action cannot be undone.</p>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={() => handleDeleteChat(chat._id)}>
                          Delete
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              ))
              ) : (
                <p className="text-gray-500">No matching chats found.</p>
              )}
            </div>
            <div className="p-4">
            <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={handlePageChange}
          />
            </div>
          </div>
        ) : (
          <div className="flex-grow flex items-center justify-center cursor-pointer lg:mt-2 lg:py-2 hover:bg-gray-200" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faHistory} className="text-xl" />
          </div>
        )}
      </div>
    );
  };
 
  export default ChatHistorySidebar;