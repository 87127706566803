import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

const DFYPlanComparison = ({ plans }) => {
  // Special Launch Pricing States (can be fetched or hardcoded)
  const [launchPricingEnabled] = useState(false);
  const [discountPercentage] = useState(25);

  // Helper to apply discount and format price
  const getDiscountedPrice = (price) => {
    if (!price || price <= 0) return price;
    const discounted = price - price * (discountPercentage / 100);
    return discounted.toFixed(2);
  };

  // Define features for DFY plans
  const features = [
    { 
      key: 'price_monthly', 
      label: 'Monthly Price', 
      format: (val) => {
        if (launchPricingEnabled && val > 0) {
          return (
            <div className="flex flex-col items-center">
              <span className="line-through text-gray-400">${val}</span>
              <span className="text-red-500 font-bold">${getDiscountedPrice(val)}</span>
            </div>
          );
        } else {
          return `$${val}`;
        }
      }
    },
    { 
      key: 'blogPostsMonthly', 
      label: 'Monthly Blog Posts',
      format: (val) => val || 0
    },
    { 
      key: 'socialPostsMonthly', 
      label: 'Monthly Social Posts',
      format: (val) => val || 0
    },
    { 
      key: 'imagesMonthly', 
      label: 'Monthly Images',
      format: (val) => val || 0
    },
  ];

  return (
    <div className="rounded-lg border bg-card">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Feature</TableHead>
            {plans.map((plan) => (
              <TableHead key={plan._id} className="text-center">
                {plan.name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.key}>
              <TableCell className="font-medium">{feature.label}</TableCell>
              {plans.map((plan) => (
                <TableCell key={plan._id} className="text-center">
                  {feature.format ? feature.format(plan[feature.key]) : plan[feature.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DFYPlanComparison;