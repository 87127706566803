// components/dashboard/CreateContentModal.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import CreateBlogPostForm from './CreateBlogPostForm';
import CreateSocialPostForm from './CreateSocialPostForm';
import CreateAIImageForm from './CreateAIImageForm';
import { PlusCircle } from 'lucide-react';

const CreateContentModal = ({ isOpen, onClose, contentType }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    if (!isSubmitting) {
      onClose();
    }
  };

  const getTitle = () => {
    switch (contentType) {
      case 'BlogPost':
        return 'Create New Blog Post';
      case 'SocialPost':
        return 'Create New Social Post';
      case 'AIImage':
        return 'Create New AI Image';
      default:
        return 'Create New Content';
    }
  };

  const getDescription = () => {
    switch (contentType) {
      case 'BlogPost':
        return 'Define the details for your new blog post.';
      case 'SocialPost':
        return 'Define the details for your new social media post.';
      case 'AIImage':
        return 'Define the details for your new AI-generated image.';
      default:
        return 'Define the details for your new content.';
    }
  };

  const renderForm = () => {
    switch (contentType) {
      case 'BlogPost':
        return (
          <CreateBlogPostForm 
            onClose={handleClose} 
            setIsSubmitting={setIsSubmitting} 
          />
        );
      case 'SocialPost':
        return (
          <CreateSocialPostForm 
            onClose={handleClose} 
            setIsSubmitting={setIsSubmitting} 
          />
        );
      case 'AIImage':
        return (
          <CreateAIImageForm 
            onClose={handleClose} 
            setIsSubmitting={setIsSubmitting} 
          />
        );
      default:
        return <p>Invalid content type</p>;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px] z-[60] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className='flex flex-row gap-1 items-center'><PlusCircle className='h-5 w-5' />{getTitle()}</DialogTitle>
          <DialogDescription>{getDescription()}</DialogDescription>
        </DialogHeader>
        
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default CreateContentModal;