import React from 'react';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Check, Mail } from 'lucide-react';

const DFYPlanCard = ({
  _id,
  tier,
  name,
  price,
  description,
  blogPostsMonthly,
  socialPostsMonthly,
  imagesMonthly,
  onSelectPlan,
  isPopular
}) => {
  const features = [
    `${blogPostsMonthly} Blog Posts per Month`,
    `${socialPostsMonthly} Social Media Posts per Month`,
    `${imagesMonthly} Images per Month`
  ];

  return (
    <Card className="relative flex flex-col justify-between h-full shadow-md">
      {isPopular && (
        <div className="absolute top-0 right-0 bg-green-500 text-white px-2 py-1 text-xs font-bold rounded-bl-lg">
          Most Popular
        </div>
      )}
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold mb-2">{name}</h2>
        <p className="text-lg font-semibold mb-4">${price}/month</p>
        {/*<p className="text-muted-foreground mb-4">
          {description ||
            "Enjoy expertly curated content delivered monthly—our DFY account takes care of your content creation so you can focus on growing your business."}
        </p>*/}
        <ul className="space-y-2 mb-4">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <Check className='text-green-500 h-4 w-4 mr-2' /><span>{feature}</span>
            </li>
          ))}
        </ul>
      </CardContent>
      <div className="p-6 pt-0">
        <Button 
          onClick={() => onSelectPlan(tier, 'dfy')} 
          className="w-full"
          variant={isPopular ? "default" : "outline"}
        >
          Select Plan
        </Button>
        {/*<Button 
              variant={isPopular ? "default" : "outline"}
              className="w-full gap-2"
              onClick={() => (window.location.href = 'mailto:sales@example.com')}
            >
              <Mail className="h-4 w-4" />
              Contact Us To Get Started
            </Button>*/}
      </div>
    </Card>
  );
};

export default DFYPlanCard;