import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateImage, fetchUserImages } from "../slices/imageGenerationSlice";
import { fetchItems } from "../slices/genericItemsSlice";
import { updateCredits } from "../slices/authSlice";
import AIImageDisplay from "./FlowFormComponents/AIImageDisplay";
import ImageHistorySidebar from "./ImageGenerationComponents/ImageHistorySidebar";
import { useToast } from "../components/ui/use-toast";
import SocialShareComponent from "./ImageGenerationComponents/SocialShareComponent";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faExpandAlt,
  faPaintBrush,
  faInfoCircle,
  faBolt,
  faBrush,
  faImages,
  faHistory,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import useSubscription from "../hooks/useSubscription";
import { useLocation } from "react-router-dom"; // <-- New import

const AIImageGenerationPage = () => {
  const dispatch = useDispatch();
  const { currentTier, status, credits, limits } = useSubscription();
  const {
    currentImage,
    isLoading,
    error: imageError,
  } = useSelector((state) => state.imageGeneration);
  
  // Access generic items state
  const items = useSelector((state) => state.genericItems.items);
  const isLoadingItems = useSelector((state) => state.genericItems.isLoading);
  const errorItems = useSelector((state) => state.genericItems.error);
  const { selectedTeam } = useSelector((state) => state.teams);
  
  // Extract styles and loading/error states for ImageStyle
  const styles = items["ImageStyle"] || [];
  const stylesLoading = isLoadingItems["ImageStyle"] || false;
  const stylesError = errorItems["ImageStyle"] || null;

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedSize, setSelectedSize] = useState("1024x1024");
  const [selectedStyle, setSelectedStyle] = useState("no-style");
  const [prompt, setPrompt] = useState("");
  const [formError, setFormError] = useState("");

  const { toast } = useToast();
  const location = useLocation(); // <-- Grab the location to read query params

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleGenerateImage(e);
    }
  };

  useEffect(() => {
    dispatch(fetchUserImages());
    dispatch(fetchItems("ImageStyle"));
  }, [dispatch]);

  // <-- New useEffect to read the styleId query parameter and update selectedStyle accordingly
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStyleId = params.get("styleId");
    if (
      queryStyleId &&
      styles.length > 0 &&
      styles.some((style) => style._id === queryStyleId)
    ) {
      setSelectedStyle(queryStyleId);
    }
  }, [location.search, styles]);

  const handleGenerateImage = (e) => {
    e.preventDefault();
    if (isLoading) return;

    if (credits.image <= 0) {
      setFormError("You have run out of image credits. Please upgrade your subscription to generate more images.");
      return;
    }

    if (!selectedSize || !prompt.trim()) {
      console.error("Validation failed:", {
        selectedSize,
        prompt: prompt.trim(),
      });
      setFormError(
        "Please select a size and enter a prompt before generating an image."
      );
      return;
    }

    dispatch(updateCredits({ type: 'image', amount: 1, subtract: true }));
    setFormError("");
    dispatch(generateImage({
      prompt,
      size: selectedSize,
      styleId: selectedStyle === "no-style" ? "" : selectedStyle,
      styleModelName: "ImageStyle",
      teamId: selectedTeam || null,
    }))
    .unwrap()
    .catch(error => {
      setFormError(error.message);
      dispatch(updateCredits({ type: 'image', amount: 1, subtract: false }));
    });
  };

  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const sizeOptions = [
    { value: "1024x1024", label: "1024x1024 (square)" },
    { value: "1792x1024", label: "1792x1024 (landscape)" },
    { value: "1024x1792", label: "1024x1792 (portrait)" },
  ];

  if (!credits || !limits) {
    return <div className="text-center">Loading subscription details...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-4rem)] lg:h-[calc(100vh-0rem)]">
      {/* Mobile Header - unchanged */}
      <div className="lg:hidden flex items-center justify-between px-4 border-b bg-background z-[60] -mx-6 -mt-4">
        <div className="flex items-center">
          <Button variant="ghost" size="sm" onClick={toggleSidebar} className="mr-2">
            <FontAwesomeIcon icon={faBars} className="h-5 w-5 text-indigo-500" />
          </Button>
          <h2 className="text-base font-medium truncate">AI Image Generation</h2>
        </div>
        <div className={`flex items-center text-sm ${credits.image <= 25 ? 'text-red-500 font-bold' : 'text-muted-foreground'}`}>
          <FontAwesomeIcon icon={faImages} className={`h-5 w-5 mr-2 ${credits.image <= 25 ? 'text-red-500 font-bold' : 'text-indigo-500'}`} />
          {credits.image}
        </div>
      </div>
  
      {/* Sidebar - unchanged */}
      <div className={`fixed inset-y-0 left-0 z-[70] lg:-ml-6 transform transition-transform duration-200 ease-in-out lg:relative h-[100vh] lg:h-auto ${isSidebarOpen ? 'translate-x-0 lg:translate-x-0' : '-translate-x-full lg:translate-x-0'}`}>
        <ImageHistorySidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>
  
      {/* Main Content */}
      <div className="flex-grow flex flex-col">
        <div className="hidden lg:flex justify-between items-center p-4 lg:py-1 ">
          <h1 className="text-inter font-bold text-2xl">AI Image Generation</h1>
          <span className={`text-sm ${credits.image <= 25 ? 'text-red-400 font-bold' : 'text-muted-foreground'}`}>Image Credits: {credits.image}</span>
        </div>
        
        <div className="flex flex-col lg:flex-row flex-grow">
          <div className="flex-grow overflow-y-auto p-1 lg:p-4">
            <AnimatePresence>
              {currentImage && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="mb-4"
                >
                  <AIImageDisplay imageUrl={currentImage.imageUrl} />
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {(imageError || stylesError || formError) && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="mb-4"
                >
                  <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {imageError || stylesError || formError}
                    </AlertDescription>
                  </Alert>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
  
          <form onSubmit={handleGenerateImage} className="p-4 lg:pt-1 lg:flex lg:flex-col lg:justify-end lg:rounded-lg lg:w-2/5 lg:ml-auto">
            <div className="flex flex-col lg:flex-col mb-4 space-y-4 lg:space-y-4 bg-gray-100 lg:space-x-2 lg:border lg:border-gray-200 lg:shadow-xl p-4 lg:rounded-lg">
              {/* Left column for size and style on desktop */}
              <div className="w-full flex flex-col space-y-4">
                <div>
                  <label className="flex items-center text-lg font-bold text-gray-900 mb-2">
                    <FontAwesomeIcon icon={faExpandAlt} className="mr-2 text-indigo-500" />
                    Image Size
                  </label>
                  <Select value={selectedSize} onValueChange={setSelectedSize}>
                    <SelectTrigger className="w-full border-none shadow-md text-gray-900">
                      <SelectValue placeholder="Select size" />
                    </SelectTrigger>
                    <SelectContent>
                      {sizeOptions.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <label className="flex items-center text-lg font-bold text-gray-900 mb-2">
                    <FontAwesomeIcon icon={faBrush} className="mr-2 text-indigo-500" />
                    Image Style
                  </label>
                  <Select value={selectedStyle} onValueChange={setSelectedStyle} disabled={stylesLoading}>
                    <SelectTrigger className="w-full border-none shadow-md text-gray-900 h-24">
                      <SelectValue placeholder={stylesLoading ? "Loading styles..." : "Select style"} />
                    </SelectTrigger>
                    <SelectContent className="md:w-auto w-[calc(100vw-2rem)] mx-auto">
                      <SelectItem value="no-style">No style</SelectItem>
                      {styles.length > 0 && styles.map((style) => (
                        <SelectItem key={style._id} value={style._id} className="whitespace-normal break-words">
                          <div className="flex items-center justify-between w-full gap-2">
                            {style.screenshotUrls?.[0] && (
                              <img 
                                src={style.screenshotUrls[0]} 
                                alt={style.name}
                                className="w-12 h-12 lg:w-16 lg:h-16 rounded-sm object-cover"
                              />
                            )}
                            <div className="flex-1">
                              <span className="text-xs lg:text-sm">{style.name}</span>
                              {style.description && (
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger asChild>
                                      <p className="ml-2 text-xs text-gray-500 lg:w-11/12">{style.description}</p>
                                    </TooltipTrigger>
                                  </Tooltip>
                                </TooltipProvider>
                              )}
                            </div>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              
              {/* Right column for prompt on desktop */}
              <div className="w-full">
                <label htmlFor="prompt" className="flex items-center text-lg font-bold text-gray-900 mb-2">
                  <FontAwesomeIcon icon={faPaintBrush} className="mr-2 text-indigo-500" />
                  Image Prompt
                </label>
                <textarea
                  id="prompt"
                  name="prompt"
                  rows="6"
                  className="shadow-md focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-none bg-white rounded-md p-2 px-4"
                  placeholder="Enter your image prompt here..."
                  value={prompt}
                  onChange={handlePromptChange}
                  onKeyDown={handleKeyDown}
                ></textarea>
              </div>
            </div>
            <Button type="submit" disabled={isLoading || credits.image <= 0 || !selectedSize || !prompt.trim()} className="w-full">
              <FontAwesomeIcon icon={faBolt} className={`mr-2 ${isLoading ? "animate-spin" : ""}`} />
              {isLoading ? "Generating..." : "Generate Image"}
            </Button>
          </form>
        </div>
      </div>
  
      {/* Mobile Menu Overlay - unchanged */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black/50 z-[65] lg:hidden" onClick={toggleSidebar} />
      )}
    </div>
  );
};

export default AIImageGenerationPage;
