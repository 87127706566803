// components/dashboard/AIImageCard.jsx
import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardFooter, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from '../../components/ui/dropdown-menu';
import { Badge } from '../../components/ui/badge';
import { 
  Edit, 
  Trash2, 
  MoreVertical,
  AlertCircle, 
  Clock, 
  CheckCircle2,
  XCircle,
  Image,
  Download,
  ImageIcon
} from 'lucide-react';
import EditAIImageModal from './EditAIImageModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// Pure presentational component - no data fetching
const AIImageCard = ({ image }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleEdit = () => setEditModalOpen(true);
  const handleDelete = () => setDeleteModalOpen(true);

  const handleDownload = () => {
    if (image.imageUrl) {
      // Create a temporary link to download the image
      const link = document.createElement('a');
      link.href = image.imageUrl;
      link.download = `ai-image-${image._id}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <AlertCircle className="h-4 w-4 text-yellow-500" />;
      case 'in-progress':
        return <Clock className="h-4 w-4 text-blue-500" />;
      case 'completed':
        return <CheckCircle2 className="h-4 w-4 text-green-500" />;
      case 'rejected':
        return <XCircle className="h-4 w-4 text-red-500" />;
      default:
        return <AlertCircle className="h-4 w-4" />;
    }
  };

  const getStatusBadge = (status) => {
    let variant = 'outline';
    
    switch (status) {
      case 'pending':
        variant = 'warning';
        break;
      case 'in-progress':
        variant = 'secondary';
        break;
      case 'completed':
        variant = 'success';
        break;
      case 'rejected':
        variant = 'destructive';
        break;
    }
    
    return (
      <Badge variant={variant} className="flex items-center gap-1">
        {getStatusIcon(status)}
        <span className="capitalize">{status}</span>
      </Badge>
    );
  };

  return (
    <>
      <Card className="overflow-hidden shadow-md hover:shadow-lg transition-shadow">
        <CardContent className="p-0">
          <div className="grid grid-cols-12 gap-2 items-center">
            {/* Image Thumbnail - larger on mobile, smaller on desktop */}
            <div className="col-span-12 sm:col-span-3 md:col-span-2 lg:col-span-1 hidden lg:block">
              {image.status === 'completed' && image.imageUrl ? (
                <div className="relative aspect-square sm:aspect-auto sm:h-24">
                  <img 
                    src={image.imageUrl} 
                    alt={image.topics[0]?.topic || 'AI Generated Image'} 
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center aspect-square sm:aspect-auto sm:h-16 bg-muted">
                  <ImageIcon className="h-10 w-10 text-indigo-500" />
                </div>
              )}
            </div>

            {/* Main Content */}
            <div className="col-span-12 sm:col-span-9 md:col-span-10 lg:col-span-11 p-4 sm:p-3">
              <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 items-center">
                {/* Title and Status */}
                <div className="sm:col-span-4 lg:col-span-5">
                  <h3 className="text-base font-semibold line-clamp-1">{image.topics[0]?.topic || 'Untitled'}</h3>
                </div>

                {/* Image Details */}
                <div className="sm:col-span-4 lg:col-span-3 text-sm">
                  <div className="grid grid-cols-2 gap-x-4">
                    <div>
                      <span className="text-muted-foreground">Style:</span>
                      <span className="font-medium ml-1 capitalize">{image.imageStyle}</span>
                    </div>
                    <div>
                      <span className="text-muted-foreground">Size:</span>
                      <span className="font-medium ml-1">{image.size}</span>
                    </div>
                  </div>
                </div>

                {/* Dates */}
                <div className="sm:col-span-2 lg:col-span-3 text-xs text-muted-foreground">
                  {/* <div className="mb-2">{getStatusBadge(post.status)}</div> */}
                  <p className="text-muted-foreground">
                    Last Updated: {new Date(image.updatedAt).toLocaleDateString()}
                  </p>
                  <p>Created: {new Date(image.createdAt).toLocaleDateString()}</p>
                  {image.status === 'completed' && 
                    <p>Completed: {new Date(image.completionDate).toLocaleDateString()}</p>
                  }
                </div>

                {/* Actions */}
                <div className="sm:col-span-2 lg:col-span-1 flex justify-end gap-2">
                  {image.status === 'completed' && image.imageUrl && (
                    <Button 
                      variant="secondary" 
                      size="sm"
                      onClick={handleDownload}
                      className="h-8"
                    >
                      <Download className="h-4 w-4 mr-1" /> 
                      <span className="hidden sm:inline">Download</span>
                    </Button>
                  )}
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={handleEdit} disabled={image.status === 'completed'}>
                        <Edit className="mr-2 h-4 w-4" />
                        <span>Edit</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={handleDelete} disabled={image.status === 'completed'} className="text-red-600">
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Delete</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {editModalOpen && (
        <EditAIImageModal
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          aiImage={image}
        />
      )}

      {deleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          contentId={image._id}
          contentType="AIImage"
          contentName={image.topics[0]?.topic || 'this AI image'}
        />
      )}
    </>
  );
};

export default AIImageCard;