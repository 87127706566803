// components/dashboard/forms/CreateSocialPostForm.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
  Form, 
  FormControl, 
  FormDescription, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Button } from '../../components/ui/button';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '../../components/ui/select';
import { Plus, X, Loader2, LayoutGrid, NotebookPen, NotepadText, MessageCircleQuestion } from 'lucide-react';
import { Badge } from '../../components/ui/badge';
import { useForm } from 'react-hook-form';
import { createSocialPostRequest } from '../../slices/contentSlice';
import { DialogFooter } from '../../components/ui/dialog';

const platformOptions = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'twitter', label: 'Twitter/X' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'tiktok', label: 'TikTok' },
];

const contentTypeOptions = [
  { value: 'textPost', label: 'Text Post' },
  { value: 'imageCaption', label: 'Image Caption' },
  { value: 'carousel', label: 'Carousel Copy' },
  { value: 'hashtags', label: 'Hashtag Set' },
  { value: 'callToAction', label: 'Call to Action' },
  { value: 'reelScript', label: 'Reel/Video Script' },
  { value: 'thread', label: 'Thread' },
  { value: 'poll', label: 'Poll' },
];

const CreateSocialPostForm = ({ onClose, setIsSubmitting }) => {
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  const [currentTopic, setCurrentTopic] = useState('');
  
  const form = useForm({
    defaultValues: {
      platform: '',
      socialContentType: '',
      notes: ''
    }
  });

  const handleAddTopic = () => {
    if (currentTopic.trim() !== '' && !topics.includes(currentTopic.trim())) {
      setTopics([...topics, currentTopic.trim()]);
      setCurrentTopic('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(topics.filter(topic => topic !== topicToRemove));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTopic();
    }
  };

  const onSubmit = async (data) => {
    if (topics.length === 0) {
      form.setError("topics", {
        type: "manual",
        message: "At least one topic is required",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await dispatch(createSocialPostRequest({
        ...data,
        topics
      })).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to create social post:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="space-y-2">
          <FormLabel className='text-lg flex flex-row items-center gap-1'><LayoutGrid className='h-4 w-4 text-indigo-500' />Topics</FormLabel>
          <div className="flex items-center gap-2">
            <Input
              value={currentTopic}
              onChange={(e) => setCurrentTopic(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Enter a topic and press Enter"
              className="flex-1"
            />
            <Button 
              type="button" 
              onClick={handleAddTopic} 
              variant="outline"
              disabled={!currentTopic.trim()}
            >
              <Plus className="h-4 w-4" />
            </Button>
          </div>
          <FormDescription>
            Add one or more topics for your social post. Press Enter or click the + button to add.
          </FormDescription>
          {form.formState.errors.topics && (
            <p className="text-sm font-medium text-destructive">
              {form.formState.errors.topics.message}
            </p>
          )}
          <div className="flex flex-wrap gap-1 mt-2">
            {topics.map((topic, index) => (
              <Badge key={index} variant="secondary" className="gap-1">
                {topic}
                <button
                  type="button"
                  onClick={() => handleRemoveTopic(topic)}
                  className="ml-1 rounded-full h-4 w-4 inline-flex items-center justify-center"
                >
                  <X className="h-3 w-3" />
                </button>
              </Badge>
            ))}
          </div>
        </div>

        <FormField
          control={form.control}
          name="platform"
          rules={{ required: 'Platform is required' }}
          render={({ field }) => (
            <FormItem>
              <FormLabel className='text-lg flex flex-row items-center gap-1'><MessageCircleQuestion className='h-4 w-4 text-indigo-500' />Platform</FormLabel>
              <Select 
                onValueChange={field.onChange} 
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger className='shadow-md rounded bg-gray-100'>
                    <SelectValue placeholder="Select a platform" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className='z-[60]'>
                  {platformOptions.map(option => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                Choose which social media platform this post is for.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="socialContentType"
          rules={{ required: 'Content type is required' }}
          render={({ field }) => (
            <FormItem>
              <FormLabel className='text-lg flex flex-row items-center gap-1'><NotepadText className='h-4 w-4 text-indigo-500' />Content Type</FormLabel>
              <Select 
                onValueChange={field.onChange} 
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger className='shadow-md rounded bg-gray-100'>
                    <SelectValue placeholder="Select a content type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className='z-[60]'>
                  {contentTypeOptions.map(option => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                Choose what type of social content you need.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel className='text-lg flex flex-row items-center gap-1'><NotebookPen className='h-4 w-4 text-indigo-500' />Additional Notes (Optional)</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Add any specific instructions or notes here..."
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Provide any additional instructions or requirements for your social post.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          <Button type="button" variant="outline" onClick={onClose} disabled={form.formState.isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={form.formState.isSubmitting}>
            {form.formState.isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            Create Social Post
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default CreateSocialPostForm;