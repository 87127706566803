import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { MessageCirclePlus, AlarmClockPlusIcon } from "lucide-react";

const ResponseDisplaySection = ({ initialResponse }) => {
  const [displayText, setDisplayText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!initialResponse) return;
    setIsTyping(true);
    setDisplayText("");
    setCurrentIndex(0);

    const typingInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex >= initialResponse.length) {
          clearInterval(typingInterval);
          setIsTyping(false);
          return prevIndex;
        }
        setDisplayText((prev) => prev + initialResponse[prevIndex]);
        return prevIndex + 1;
      });
    }, 15);

    return () => clearInterval(typingInterval);
  }, [initialResponse]);

  return (
    <div className="relative min-h-[60px]">
      <div className="flex flex-col items-start lg:justify-between">
        <p className="text-lg whitespace-pre-wrap leading-tight lg:max-w-4xl">
          {displayText}
          {isTyping && (
            <span className="inline-block w-2 h-4 ml-1 bg-black blink" />
          )}
        </p>
      </div>
    </div>
  );
};

export default ResponseDisplaySection;
