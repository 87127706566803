// src/components/TeamShare/TeamShareButton.js
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from "../ui/button";
import { Share2 } from 'lucide-react';
import TeamShareDialog from './TeamShareDialog';
import { fetchUserTeams } from '../../slices/teamsSlice';

const TeamShareButton = ({ assetId, modelName, currentTeams = [], onShareSuccess }) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { teams, selectedTeam } = useSelector((state) => state.teams);

  useEffect(() => {
    dispatch(fetchUserTeams());
  }, [dispatch]);
  
  if (teams === 0) return null;

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDialogOpen(true);
  };

  const handleOpenChange = (open) => {
    setDialogOpen(open);
  };

  return (
    <>
      <Button 
        variant="outline" 
        size="sm" 
        onClick={handleClick}
        className="gap-2 text-black border-none shadow-md hover:shadow-lg bg-[#fafafa]"
      >
        <Share2 className="h-4 w-4" />
        Share with Team
      </Button>

      <TeamShareDialog 
        open={dialogOpen}
        onOpenChange={handleOpenChange}
        assetId={assetId}
        modelName={modelName}
        currentTeams={currentTeams}
        onShareSuccess={onShareSuccess}
      />
    </>
  );
};

export default TeamShareButton;