import React from 'react';
import { Card, CardContent } from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import { ScrollArea } from "../../components/ui/scroll-area";
import {
  BrainCircuit,
  User,
  Target,
  Tags,
} from "lucide-react";

const SelectedAssistantDetails = ({ selectedAssistantFull }) => {
  return (
    <ScrollArea className="h-full w-full">
      <div className="p-4 max-w-5xl mx-auto">
        {/* Main Info Card */}
        <div className="flex items-start gap-4 mb-4">
          {selectedAssistantFull.avatarUrl && (
            <img
              src={selectedAssistantFull.avatarUrl}
              alt={`${selectedAssistantFull.name} avatar`}
              className="w-16 h-16 rounded-lg object-cover"
            />
          )}
          <div className="flex-1 min-w-0">
            <h2 className="text-xl font-semibold mb-1">
              {selectedAssistantFull.name}
            </h2>
            <p className="text-sm text-muted-foreground mb-2 line-clamp-2">
              {selectedAssistantFull.description}
            </p>
            <div className="flex flex-wrap gap-1.5">
              <Badge variant="default">{selectedAssistantFull.category}</Badge>
              {selectedAssistantFull.subcategories?.slice(0, 3).map((sub, index) => (
                <Badge key={index} variant="outline">{sub}</Badge>
              ))}
              {selectedAssistantFull.subcategories?.length > 3 && (
                <Badge variant="outline">+{selectedAssistantFull.subcategories.length - 3} more</Badge>
              )}
            </div>
          </div>
        </div>

        {/* Info Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Expertise Card */}
          <Card className="bg-background/50">
            <CardContent className="p-4">
              <div className="flex items-center gap-2 mb-3">
                <BrainCircuit className="h-4 w-4 text-primary" />
                <h3 className="text-sm font-medium">Expertise</h3>
              </div>
              <div className="space-y-2">
                <Badge className="mb-2" variant="default">
                  {selectedAssistantFull.expertise?.primary}
                </Badge>
                <div className="flex flex-wrap gap-1.5">
                  {selectedAssistantFull.expertise?.secondary?.slice(0, 4).map((skill, index) => (
                    <Badge key={index} variant="secondary" className="text-xs">
                      {skill}
                    </Badge>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Personality Card */}
          <Card className="bg-background/50">
            <CardContent className="p-4">
              <div className="flex items-center gap-2 mb-3">
                <User className="h-4 w-4 text-primary" />
                <h3 className="text-sm font-medium">Personality</h3>
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
                <div>
                  <span className="text-muted-foreground">Tone:</span>
                  <span className="ml-2">{selectedAssistantFull.personality?.tone}</span>
                </div>
                <div>
                  <span className="text-muted-foreground">Style:</span>
                  <span className="ml-2">{selectedAssistantFull.personality?.style}</span>
                </div>
              </div>
              <div className="flex flex-wrap gap-1.5 mt-2">
                {selectedAssistantFull.personality?.traits?.map((trait, index) => (
                  <Badge key={index} variant="outline" className="text-xs">
                    {trait}
                  </Badge>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* Use Cases Card */}
          <Card className="md:col-span-2 bg-background/50">
            <CardContent className="p-4">
              <div className="flex items-center gap-2 mb-3">
                <Target className="h-4 w-4 text-primary" />
                <h3 className="text-sm font-medium">Ideal For</h3>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {selectedAssistantFull.useCase?.idealFor?.map((use, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary/60" />
                    <span className="text-sm text-muted-foreground">{use}</span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Long Description Preview */}
        <Card className="mt-4 bg-background/50">
          <CardContent className="p-4">
            <p className="text-sm text-muted-foreground line-clamp-3">
              {selectedAssistantFull.longDescription}
            </p>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  );
};

export default SelectedAssistantDetails;