import React, { useState, useRef, useEffect } from 'react';
import { Button } from "../../components/ui/button";
import { Download, Copy, Expand, X, Link, Share2 } from 'lucide-react';
import { useToast } from "../../components/ui/use-toast";

const AIImageDisplay = ({ imageUrl }) => {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { toast } = useToast();
    const canvasRef = useRef(null);
    const downloadLinkRef = useRef(null);

    // Detect if the user is on a mobile device
    useEffect(() => {
        const checkMobile = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            const mobileRegex = /android|iphone|ipad|ipod|blackberry|windows phone/i;
            setIsMobile(mobileRegex.test(userAgent));
        };
        
        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleDownload = async () => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            
            if (isMobile) {
                // Mobile approach: Open the image in a new tab for saving
                // This works on most mobile browsers
                const newTab = window.open(blobUrl, '_blank');
                
                // If popup is blocked or not supported
                if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
                    // Fallback: Use hidden anchor with download attribute
                    if (downloadLinkRef.current) {
                        downloadLinkRef.current.href = blobUrl;
                        downloadLinkRef.current.download = 'ai-generated-image.png';
                        downloadLinkRef.current.click();
                    }
                    
                    toast({
                        title: "Save Image",
                        description: "Long press on the image and select 'Save Image'",
                        duration: 5000,
                    });
                }
            } else {
                // Desktop approach: Use download attribute
                const link = downloadLinkRef.current || document.createElement('a');
                link.href = blobUrl;
                link.download = 'ai-generated-image.png';
                link.click();
            }
            
            // Clean up the blob URL after a delay
            setTimeout(() => {
                window.URL.revokeObjectURL(blobUrl);
            }, 1000);
        } catch (error) {
            toast({
                title: "Download Failed",
                description: "Failed to download image: " + error,
                variant: "destructive",
                duration: 3000,
            });
        }
    };

    // Share image on mobile devices (for browsers that support Web Share API)
    const shareImage = async () => {
        if (!navigator.share) {
            toast({
                title: "Share Not Supported",
                description: "Your browser doesn't support sharing.",
                variant: "destructive",
                duration: 3000,
            });
            return;
        }

        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const file = new File([blob], 'ai-generated-image.png', { type: 'image/png' });
            
            await navigator.share({
                title: 'AI Generated Image',
                text: 'Check out this AI generated image!',
                files: [file]
            });
            
            toast({
                title: "Shared Successfully",
                description: "The image has been shared.",
                duration: 2000,
            });
        } catch (error) {
            // User cancelled or share failed
            if (error.name !== 'AbortError') {
                toast({
                    title: "Share Failed",
                    description: error.message || "Failed to share image",
                    variant: "destructive",
                    duration: 3000,
                });
            }
        }
    };

    const copyImageToClipboard = async () => {
        try {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
                const canvas = canvasRef.current;
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(async (blob) => {
                    try {
                        await navigator.clipboard.write([
                            new ClipboardItem({ 'image/png': blob })
                        ]);
                        toast({
                            title: "Image Copied",
                            description: "The image has been copied to your clipboard.",
                            duration: 2000,
                        });
                    } catch (err) {
                        toast({
                            title: "Copy Failed",
                            description: "Failed to copy image: " + err,
                            variant: "destructive",
                            duration: 3000,
                        });
                    }
                }, 'image/png');
            };
            img.src = imageUrl;
        } catch (error) {
            toast({
                title: "Copy Failed",
                description: "Failed to copy image: " + error,
                variant: "destructive",
                duration: 3000,
            });
        }
    };

    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(imageUrl).then(() => {
            toast({
                title: "URL Copied",
                description: "The image URL has been copied to your clipboard.",
                duration: 2000,
            });
        }).catch((err) => {
            toast({
                title: "Copy Failed",
                description: "Failed to copy URL: " + err,
                variant: "destructive",
                duration: 3000,
            });
        });
    };

    const ActionButton = ({ onClick, children }) => (
        <Button
            onClick={onClick}
            className="flex items-center space-x-2 bg-gray-200 hover:bg-gray-300 text-gray-800 transition-colors duration-200"
        >
            {children}
        </Button>
    );

    return (
        <div className="mt-4 lg:mt-0 space-y-4">
            <div className="relative">
                <img 
                    src={imageUrl} 
                    alt="AI Generated Image" 
                    className="max-w-full h-auto lg:max-h-[82vh] object-contain rounded-lg shadow-lg"
                />
                <Button 
                    variant="outline" 
                    size="sm" 
                    className="absolute top-2 right-2 bg-white hover:bg-gray-100"
                    onClick={() => setIsLightboxOpen(true)}
                >
                    <Expand className="h-4 w-4" />
                </Button>
            </div>
            <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2">
                <div className='hidden lg:block'>
                <ActionButton onClick={handleDownload}>
                    <Download className="h-4 w-4" />
                    <span>Download</span>
                </ActionButton>
                </div>
                <div className='lg:hidden text-center text-sm text-gray-600'>
                    Long press on the image to save
                </div>
                <ActionButton onClick={copyImageToClipboard}>
                    <Copy className="h-4 w-4" />
                    <span>Copy Image</span>
                </ActionButton>
                <ActionButton onClick={copyUrlToClipboard}>
                    <Link className="h-4 w-4" />
                    <span>Copy URL</span>
                </ActionButton>
                {isMobile && navigator.share && (
                    <ActionButton onClick={shareImage}>
                        <Share2 className="h-4 w-4" />
                        <span>Share</span>
                    </ActionButton>
                )}
            </div>

            {isLightboxOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]">
                    <div className="relative bg-white p-2 rounded-lg max-w-[90vw] max-h-[90vh]">
                        <img 
                            src={imageUrl} 
                            alt="AI Generated Image (Lightbox)" 
                            className="max-w-full max-h-[calc(90vh-4rem)] object-contain"
                        />
                        <Button 
                            variant="outline" 
                            size="sm" 
                            className="absolute top-3 right-3 bg-white hover:bg-gray-100"
                            onClick={() => setIsLightboxOpen(false)}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            )}
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <a ref={downloadLinkRef} style={{ display: 'none' }} />
        </div>
    );
};

export default AIImageDisplay;