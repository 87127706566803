import React, { useState, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';

const ScheduleConfigForm = ({ type, config, onChange }) => {
  const [selectedMinute, setSelectedMinute] = useState(config.minutes?.[0] ?? 0);
  const [selectedHour, setSelectedHour] = useState(config.hours?.[0] ?? 0);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(config.daysOfWeek?.[0] ?? 0);
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(config.daysOfMonth?.[0] ?? 1);
  const [firstHour, setFirstHour] = useState(config.hours?.[0] ?? 0);
  const [secondHour, setSecondHour] = useState(config.hours?.[1] ?? 12);

  useEffect(() => {
    setSelectedMinute(config.minutes?.[0] ?? 0);
    setSelectedHour(config.hours?.[0] ?? 0);
    setSelectedDayOfWeek(config.daysOfWeek?.[0] ?? 0);
    setSelectedDayOfMonth(config.daysOfMonth?.[0] ?? 1);
    
    // Update twice daily hours when config changes
    if (type === 'twice_daily') {
      setFirstHour(config.hours?.[0] ?? 0);
      setSecondHour(config.hours?.[1] ?? 12);
    }
  }, [config.minutes, config.hours, config.daysOfWeek, config.daysOfMonth, type]);

  const handleConfigChange = (key, value) => {
    onChange({ ...config, [key]: Array.isArray(value) ? value : [value] });
  };

  const renderHourlyConfig = () => (
    <div className="space-y-2 lg:w-1/2">
      <Label className="font-bold text-black lg:text-lg">Minute of the hour</Label>
      <Select
        value={selectedMinute.toString()}
        onValueChange={(value) => {
          const minute = parseInt(value);
          setSelectedMinute(minute);
          handleConfigChange('minutes', minute);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue>{selectedMinute}</SelectValue>
        </SelectTrigger>
        <SelectContent>
          {[0, 15, 30, 45].map((minute) => (
            <SelectItem key={minute} value={minute.toString()}>
              {minute}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderDailyConfig = () => (
    <div className="space-y-2 lg:w-1/2">
      <Label className="font-bold text-black lg:text-lg">Hour of the day (24-hour format)</Label>
      <Select
        value={selectedHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSelectedHour(hour);
          handleConfigChange('hours', hour);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderTwiceDailyConfig = () => (
    <div className="space-y-2 lg:w-1/2">
      <Label className="font-bold text-black lg:text-lg">First hour of the day (24-hour format)</Label>
      <Select
        value={firstHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setFirstHour(hour);
          handleConfigChange('hours', [hour, secondHour]);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select first hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label className="font-bold text-black lg:text-lg">Second hour of the day (24-hour format)</Label>
      <Select
        value={secondHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSecondHour(hour);
          handleConfigChange('hours', [firstHour, hour]);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select second hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderWeeklyConfig = () => (
    <div className="space-y-2 lg:w-1/2">
      <Label className="font-bold text-black lg:text-lg">Day of the week</Label>
      <Select
        value={selectedDayOfWeek.toString()}
        onValueChange={(value) => {
          const day = parseInt(value);
          setSelectedDayOfWeek(day);
          handleConfigChange('daysOfWeek', day);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select day of week" />
        </SelectTrigger>
        <SelectContent>
          {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
            <SelectItem key={index} value={index.toString()}>
              {day}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label className="font-bold text-black lg:text-lg">Hour of the day (24-hour format)</Label>
      <Select
        value={selectedHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSelectedHour(hour);
          handleConfigChange('hours', hour);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderMonthlyConfig = () => (
    <div className="space-y-2 lg:w-1/2">
      <Label className="font-bold text-black lg:text-lg">Day of the month</Label>
      <Select
        value={selectedDayOfMonth.toString()}
        onValueChange={(value) => {
          const day = parseInt(value);
          setSelectedDayOfMonth(day);
          handleConfigChange('daysOfMonth', day);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select day of month" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
            <SelectItem key={day} value={day.toString()}>
              {day}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label className="font-bold text-black lg:text-lg">Hour of the day (24-hour format)</Label>
      <Select
        value={selectedHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSelectedHour(hour);
          handleConfigChange('hours', hour);
        }}
      >
        <SelectTrigger className="border-none shadow-sm bg-[#fafafa] text-black">
          <SelectValue placeholder="Select hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderCustomConfig = () => (
    <div className="space-y-2 lg:w-1/2">
      <Label className="font-bold text-black lg:text-lg">Custom Cron Expression</Label>
      <Input
        type="text"
        value={config.customCron || ''}
        onChange={(e) => handleConfigChange('customCron', e.target.value)}
        placeholder="Enter custom cron expression"
         className="border-none shadow-sm bg-[#fafafa] text-black"
      />
    </div>
  );

  switch (type) {
    case 'hourly':
      return renderHourlyConfig();
    case 'daily':
      return renderDailyConfig();
    case 'twice_daily':
      return renderTwiceDailyConfig();
    case 'weekly':
      return renderWeeklyConfig();
    case 'monthly':
      return renderMonthlyConfig();
    case 'custom':
      return renderCustomConfig();
    default:
      return null;
  }
};

export default ScheduleConfigForm;