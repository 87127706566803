import React from "react";
import { Button } from "../../components/ui/button";
import { BotMessageSquare, ArrowRight, Brain, Asterisk } from "lucide-react";

const SUGGESTED_PROMPTS_2 = [
  "I want to create a blog post about life hacks",
  "I need help with my marketing strategy",
  "I want to create a new flow for daily LinkedIn posts",
  "I'd like to generate an image for my social media profile",
  "I need to chat with an ecommerce expert",
  "I want to build a custom assistant for my team",
  "I need to create a content marketing plan",
  "I want to generate a list of blog post ideas",
];

const SUGGESTED_PROMPTS = [
  "I want to Craft a Viral Social Media Campaign",
  "I need help with Developing a Monthly Content Calendar",
  "I want to Generate SEO-Optimized Blog Posts",
  "I'd like to Create Customized Email Campaigns",
  "I need to Design Compelling Product Descriptions",
  "I want to Analyze and Optimize Current Marketing Strategies",
  "I need to Transform Case Studies into Engaging Narratives",
  "I want to Create Diverse Content Formats",
];

const SUGGESTED_PROMPTS_1 = [
  "I want to craft a viral social media campaign that engages my audience",
  "I need to develop a monthly content calendar to organize my marketing efforts",
  "I want to generate SEO-optimized blog posts that rank on Google",
  "I'd like to create customized email campaigns that tell a story",
  "I need compelling product descriptions that drive sales",
  "I want to design infographics that present complex information visually",
  "I need an interactive learning experience to engage my audience",
  "I want to analyze and optimize my current marketing strategies",
  "I'd like to transform case studies into engaging narratives for my brand",
  "I want to create diverse content formats like videos and podcasts"
];

const QuestionSection = ({
  question,
  setQuestion,
  submitQuestion,
  isLoadingResponse,
}) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 mb-6">
      {/* Left Column - Question, Textarea, Button */}
      <div className="lg:col-span-3 flex flex-col space-y-4 lg:space-y-6 lg:ml-12 lg:pt-16">
        <h2 className="text-4xl lg:text-5xl font-semibold mt-2 flex items-center text-center lg:text-left">
          <BotMessageSquare className="h-12 w-12 mr-4 text-white magical-gradient bg-black rounded-full p-2 hidden lg:block" />
          What do you want to do today?
        </h2>
        <div className="mb-6 px-4 text-sm lg:text-base text-muted-foreground leading-tight">
          Tell us your goal - whether it's creating content, growing your
          business, or tackling your to-do list. We'll match you with the
          perfect mix of AI tools and assistants to help you get it done faster
          and better. No task is too big or too small.
        </div>

        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(e) => {
            if ((e.key === "Enter" || e.keyCode === 13) && !e.shiftKey) {
              e.preventDefault();
              submitQuestion(e);
            }
          }}
          className="w-full h-32 p-4 border rounded-lg bg-white backdrop-blur-sm
                     border-none focus:border-gray-900 focus:ring-2 
                     focus:ring-gray-900 focus:ring-opacity-50 
                     transition-all duration-200 ease-in-out
                     resize-none text-lg"
          placeholder="Type your goal, question, request..."
        />

{question && (
  <Button
          onClick={submitQuestion}
          className="px-6 w-fit flex items-center justify-center ml-2 rounded text-white magical-gradient"
          disabled={!question || isLoadingResponse}
        >
          {isLoadingResponse ? (<>
            Thinking... <Asterisk className="h-6 w-6 ml-2 animate-spin" />
            </>) : (
            <>
              Show Me The Way <ArrowRight className="h-4 w-4 ml-2" />
            </>
          )}
        </Button>
)}
        
      </div>

      {/* Right Column - Suggested Prompts */}
      <div className="lg:col-span-2 bg-gray-50 rounded-lg p-6">
        <div className="text-xl font-semibold mb-4 flex items-center text-gray-900">
          <Brain className="h-5 w-5 mr-2" />
          Need some inspiration?
        </div>
        <div className="space-y-2 lg:space-y-3">
          {SUGGESTED_PROMPTS.map((prompt, index) => (
            <div
              key={index}
              onClick={() => setQuestion(prompt)}
              className="p-2 lg:p-3 bg-gray-50 rounded-md cursor-pointer hover:bg-gray-100 text-muted-foreground
               transition-colors duration-200 shadow-sm border border-gray-100"
            >
              {prompt}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionSection;
