// modalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const surveyModalSlice = createSlice({
  name: 'surveyModal',
  initialState,
  reducers: {
    openSurveyModal: (state) => {
      state.isOpen = true;
    },
    closeSurveyModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openSurveyModal, closeSurveyModal } = surveyModalSlice.actions;
export default surveyModalSlice.reducer;