// src/pages/FlowFormComponents/RenderFields/SavedProductFields.js

import React from "react";
import { Label } from "../../../../components/ui/label";
import { Textarea } from "../../../../components/ui/textarea";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";

const SavedProductFields = ({ step, handleFieldChange, index }) => {
  const handleCategoriesChange = (e) => {
    const categoriesString = e.target.value;
    const categoriesArray = categoriesString.split(",").map((id) => id.trim());
    handleFieldChange("categories", categoriesArray);
  };

  const handleTagsChange = (e) => {
    const tagsString = e.target.value;
    const tagsArray = tagsString.split(",").map((id) => id.trim());
    handleFieldChange("tags", tagsArray);
  };

  const handleImagesChange = (e) => {
    const imagesString = e.target.value;
    const imagesArray = imagesString.split(",").map((url) => url.trim());
    handleFieldChange("images", imagesArray);
  };

  const handleTutorLMSChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      handleFieldChange("meta_data", [
        {
          key: "_tutor_product",
          value: "yes"
        }
      ]);
    } else {
      handleFieldChange("meta_data", undefined);
    }
  };

  const handleDownloadsChange = (e, downloadIndex, field) => {
    const newDownloads = [...(step.downloads || [])];
    if (!newDownloads[downloadIndex]) {
      newDownloads[downloadIndex] = { name: '', file: '' };
    }
    newDownloads[downloadIndex][field] = e.target.value;
    handleFieldChange("downloads", newDownloads);
  };

  const addDownload = () => {
    const newDownloads = [...(step.downloads || []), { name: '', file: '' }];
    handleFieldChange("downloads", newDownloads);
  };

  const removeDownload = (indexToRemove) => {
    const newDownloads = (step.downloads || []).filter((_, index) => index !== indexToRemove);
    handleFieldChange("downloads", newDownloads);
  };

  return (
    <div className="space-y-4">
      <h3 className="mt-8 font-bold ">Product Details</h3>
      <div>
        <Label htmlFor={`product-name-${index}`} className="text-inter font-bold">
          Product Name
        </Label>
        <Input
          id={`product-name-${index}`}
          placeholder="Enter product name"
          value={step.productName || ""}
          onChange={(e) => handleFieldChange("productName", e.target.value)}
        />
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
  <div style={{ flex: 1 }}>
    <Label htmlFor={`product-type-${index}`} className="text-inter font-bold">
      Product Type
    </Label>
    <select
      id={`product-type-${index}`}
      value={step.type || "simple"}
      onChange={(e) => handleFieldChange("type", e.target.value)}
      className="w-full p-2 border rounded"
    >
      <option value="simple">Simple</option>
      <option value="grouped">Grouped</option>
      <option value="external">External</option>
      <option value="variable">Variable</option>
    </select>
  </div>
  <div style={{ flex: 1 }}>
    <Label htmlFor={`product-status-${index}`} className="text-inter font-bold">
      Status
    </Label>
    <select
      id={`product-status-${index}`}
      value={step.status || "draft"}
      onChange={(e) => handleFieldChange("status", e.target.value)}
      className="w-full p-2 border rounded"
    >
      <option value="draft">Draft</option>
      <option value="pending">Pending</option>
      <option value="private">Private</option>
      <option value="publish">Publish</option>
    </select>
  </div>
</div>

      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <input
            id={`product-virtual-${index}`}
            type="checkbox"
            checked={step.virtual || false}
            onChange={(e) => handleFieldChange("virtual", e.target.checked)}
          />
          <Label htmlFor={`product-virtual-${index}`} className="text-inter font-bold">
            Virtual
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <input
            id={`product-downloadable-${index}`}
            type="checkbox"
            checked={step.downloadable || false}
            onChange={(e) => handleFieldChange("downloadable", e.target.checked)}
          />
          <Label htmlFor={`product-downloadable-${index}`} className="text-inter font-bold">
            Downloadable
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <input
            id={`product-tutor-lms-${index}`}
            type="checkbox"
            checked={step.meta_data && step.meta_data[0]?.key === "_tutor_product"}
            onChange={handleTutorLMSChange}
          />
          <Label htmlFor={`product-tutor-lms-${index}`} className="text-inter font-bold">
            For Tutor LMS
          </Label>
        </div>
      </div>
      {step.downloadable && (
        <div>
          <Label htmlFor={`product-downloads-${index}`} className="text-inter font-bold">
            Downloads
          </Label>
          {(step.downloads || []).map((download, downloadIndex) => (
            <div key={downloadIndex} className="flex space-x-2 mb-2">
              <Input
                placeholder="File name"
                value={download.name}
                onChange={(e) => handleDownloadsChange(e, downloadIndex, 'name')}
              />
              <Input
                placeholder="File URL"
                value={download.file}
                onChange={(e) => handleDownloadsChange(e, downloadIndex, 'file')}
              />
              <Button onClick={() => removeDownload(downloadIndex)} variant="destructive">
                Remove
              </Button>
            </div>
          ))}
          <Button onClick={addDownload} variant="secondary">
            Add Download
          </Button>
        </div>
      )}
      {/*<div>
        <Label htmlFor={`product-sku-${index}`} className="text-inter font-bold">
          SKU
        </Label>
        <Input
          id={`product-sku-${index}`}
          placeholder="Enter SKU"
          value={step.sku || ""}
          onChange={(e) => handleFieldChange("sku", e.target.value)}
        />
      </div>*/}
      <div style={{ display: "flex", gap: "16px" }}>
  <div style={{ flex: 1 }}>
    <Label htmlFor={`product-regular-price-${index}`} className="text-inter font-bold">
      Regular Price
    </Label>
    <Input
      id={`product-regular-price-${index}`}
      placeholder="Enter regular price"
      value={step.regularPrice || ""}
      onChange={(e) => handleFieldChange("regularPrice", e.target.value)}
    />
  </div>
  <div style={{ flex: 1 }}>
    <Label htmlFor={`product-sale-price-${index}`} className="text-inter font-bold">
      Sale Price
    </Label>
    <Input
      id={`product-sale-price-${index}`}
      placeholder="Enter sale price"
      value={step.salePrice || ""}
      onChange={(e) => handleFieldChange("salePrice", e.target.value)}
    />
  </div>
</div>

      <div>
        <Label htmlFor={`product-description-${index}`} className="text-inter font-bold">
          Description
        </Label>
        <Textarea
          id={`product-description-${index}`}
          placeholder="Enter description"
          value={step.longDescription || ""}
          onChange={(e) => handleFieldChange("longDescription", e.target.value)}
        />
      </div>
      <div>
        <Label htmlFor={`product-short-description-${index}`} className="text-inter font-bold">
          Short Description
        </Label>
        <Textarea
          id={`product-short-description-${index}`}
          placeholder="Enter short description"
          value={step.description || ""}
          onChange={(e) => handleFieldChange("shortDescription", e.target.value)}
        />
      </div>
      
      <div>
        <Label
          htmlFor={`product-meta-description-${index}`}
          className="text-inter font-bold"
        >
          Meta Description
        </Label>
        <Textarea
          id={`product-meta-description-${index}`}
          placeholder="Enter meta description for SEO"
          value={step.meta_description || ""}
          onChange={(e) =>
            handleFieldChange("meta_description", e.target.value)
          }
          className="h-24"
        />
      </div>
      <div>
        <Label htmlFor={`product-categories-${index}`} className="text-inter font-bold">
          Categories <span className="text-sm font-normal">(Separate multiple categories with commas.)</span>
        </Label>
        <Input
          id={`product-categories-${index}`}
          placeholder="Enter categories separated by commas"
          value={
            Array.isArray(step.categories)
              ? step.categories.join(", ")
              : step.categories || ""
          }
          onChange={handleCategoriesChange}
        />
      </div>
      <div>
        <Label htmlFor={`product-tags-${index}`} className="text-inter font-bold">
          Tags <span className="text-sm font-normal">(Separate multiple tags with commas.)</span>
        </Label>
        <Input
          id={`product-tags-${index}`}
          placeholder="Enter tags separated by commas"
          value={
            Array.isArray(step.tags)
              ? step.tags.join(", ")
              : step.tags || ""
          }
          onChange={handleTagsChange}
        />
      </div>
      <div>
        <Label htmlFor={`product-images-${index}`} className="text-inter font-bold">
          Images <span className="text-sm font-normal">(Separate multiple URLs with commas. The first image will be used as the featured image.)</span>
        </Label>
        <Input
          id={`product-images-${index}`}
          placeholder="Enter image URLs separated by commas"
          value={
            Array.isArray(step.images)
              ? step.images.join(", ")
              : step.images || ""
          }
          onChange={handleImagesChange}
        />
      </div>
    </div>
  );
};

export default SavedProductFields;
