import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Card, CardContent } from '../components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { useToast } from '../components/ui/use-toast';
import { Button } from '../components/ui/button';
import PlanCard from './SubscriptionComponents/PlanCard';
import DFYPlanCard from './SubscriptionComponents/DFYPlanCard';
import PlanComparison from './SubscriptionComponents/PlanComparison';
import DFYPlanComparison from './SubscriptionComponents/DFYPlanComparison';
import useSubscription from '../hooks/useSubscription';
import * as api from '../services/api';
import { Loader2, Mail } from 'lucide-react';
import FAQSection from '../components/FAQSection';

const stripePromise = loadStripe('pk_test_51QgtRaAc8dniylqxUZsDe7T1SEzQCKbbUKXoSpzlJnIfQR32LdEVDtvfbHcPtAMSOw2gUqrJLxssFg9fiFHZIi1U00NSqmdewj');
//loadStripe('pk_live_51QgtRaAc8dniylqxX9SHcE95h6VHPiByeG12czEsFhGzNlSYMtGZ51nP5eY3J6GfLtviyojG6bP9ugzoFltetLIj007FP6XnBY');

const SubscriptionPlans = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState([]);
  const { currentTier, isActive } = useSubscription();
  const [launchPricingEnabled] = useState(true);
  const [discountPercentage] = useState(25);
  const [activePlanType, setActivePlanType] = useState('standard'); // 'standard' or 'dfy'

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await api.getSubscriptionPlans();
        // Exclude enterprise plans and format features based on subscriptionType
        const formattedPlans = response.data.data
          .filter(plan => plan.tier !== 'enterprise' && plan.tier !== 'dfyfree' && plan.tier !== 'dfyenterprise')
          .map(plan => {
            if (plan.subscriptionType === 'standard') {
              return {
                ...plan,
                activeFlows: plan.activeFlows === 999 ? 'Unlimited' : plan.activeFlows,
                flowSteps: plan.flowSteps === 999 ? 'Unlimited' : plan.flowSteps,
                chatCredits: plan.chatCredits === 999 ? 'Unlimited' : plan.chatCredits,
                executionCredits: plan.executionCredits === 999 ? 'Unlimited' : plan.executionCredits,
                imageCredits: plan.imageCredits === 999 ? 'Unlimited' : plan.imageCredits,
                customAssistants: plan.customAssistants === 999 ? 'Unlimited' : plan.customAssistants,
                seats: plan.seats === 999 ? 'Unlimited' : plan.seats,
              };
            }
            // DFY plans are expected to include blogPostsMonthly, socialPostsMonthly, and imagesMonthly as provided
            return plan;
          });
        setPlans(formattedPlans);
      } catch (error) {
        setError(error.message || 'Failed to fetch subscription plans');
        toast({
          title: "Error",
          description: "Failed to load subscription plans",
          variant: "destructive"
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [toast]);

  const handleSelectPlan = async (tier, subscriptionType = 'individual') => {
    console.log('Selected plan:', tier, subscriptionType);
    try {
      setLoading(true);
      
      if (tier === 'free') {
        toast({
          title: "Contact Support",
          description: "Please contact support to downgrade to the free plan.",
        });
        return;
      }
  
      const response = await api.createSubscriptionSession({
        tier,
        subscriptionType
      });
  
      if (!response.data?.sessionId) {
        throw new Error('No session ID returned from server');
      }
  
      const stripe = await stripePromise;
      
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });
  
      if (error) {
        console.error('Stripe error:', error);
        throw new Error(error.message);
      }
      
    } catch (error) {
      console.error('Subscription error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to process subscription",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const getStandardFeaturesList = (plan) => [
    `${plan.activeFlows} Active Flows`,
    `${plan.flowSteps} Steps per Flow`,
    `${plan.chatCredits} ${plan.tier !== 'free' ? 'Monthly' : ''} Chat Credits`,
    `${plan.executionCredits} ${plan.tier !== 'free' ? 'Monthly' : ''} Execution Credits`,
    `${plan.imageCredits} ${plan.tier !== 'free' ? 'Monthly' : ''} Image Credits`,
    `${plan.customAssistants} Custom Assistants`,
    `${plan.seats === 1 || plan.seats === 'Unlimited' ? '' : 'Up to '}${plan.seats} ${plan.seats === 1 || plan.seats === 'Unlimited' ? 'Seat' : 'Team Seats'}`,
    plan.isAdvancedTemplates ? 'Advanced Templates' : 'Basic Templates',
    `Unlimited Prompt Stacks`
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  // Filter plans based on the selected subscription type
  const filteredPlans = plans.filter(plan => plan.subscriptionType === activePlanType);

  return (
    <div className="container mx-auto py-2 space-y-2 px-0 lg:px-8 lg:py-8 lg:space-y-8">
      <div className="text-center space-y-4">
        <h1 className="text-4xl font-bold">Choose Your Plan</h1>
        {launchPricingEnabled && (
          <div className="max-w-2xl mx-auto mb-6 text-center">
            <span className="inline-block border-2 border-red-600 shadow-xl text-black font-bold bg-[#fafafa] px-8 py-4 rounded-full text-center leading-tight">
              Special Launch Pricing: For a limited time, we want to give you {discountPercentage}% OFF<br />
              your first full year with Flow Orchestra! 🤯
            </span>
          </div>
        )}
        <p className="text-xl text-muted-foreground">
          Select the perfect plan for your needs
        </p>
      </div>

      {/* Toggle Section for Subscription Type */}
      <div className="flex flex-col items-center mb-6">
        <div className="flex justify-center gap-4 mb-4">
          <Button 
            variant={activePlanType === 'standard' ? "default" : "outline"} 
            onClick={() => setActivePlanType('standard')}
          >
            Standard Account
          </Button>
          <Button 
            variant={activePlanType === 'dfy' ? "default" : "outline"} 
            onClick={() => setActivePlanType('dfy')}
          >
            Done-For-You Account
          </Button>
        </div>
        {activePlanType === 'standard' ? (
          <p className="text-center text-muted-foreground max-w-xl">
            Standard accounts offer complete access to our powerful content-generating app, giving you the freedom to create and customize your own content.
          </p>
        ) : (
          <p className="text-center text-muted-foreground max-w-xl">
            DFY accounts provide expertly curated content delivered monthly. Let our professionals handle your content creation so you can concentrate on growing your business.
          </p>
        )}
      </div>

      {isActive && (
        <Alert className="border-none shadow-xl">
          <AlertTitle>
            Current Plan: <span className="capitalize">{currentTier}</span>
          </AlertTitle>
          <AlertDescription>
            You can upgrade your plan at any time. Downgrading requires contacting support.
          </AlertDescription>
        </Alert>
      )}

      <Tabs defaultValue="plans" className="w-full">
        <TabsList className="grid w-full grid-cols-2 shadow-md">
          <TabsTrigger value="plans">Subscription Plans</TabsTrigger>
          <TabsTrigger value="compare">Compare Features</TabsTrigger>
        </TabsList>
        
        <TabsContent value="plans">
          <div
            className={
              activePlanType === 'dfy'
                ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                : "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
            }
          >
            {activePlanType === 'standard'
              ? filteredPlans.map((plan) => (
                  <PlanCard
                    key={plan._id}
                    tier={plan.tier}
                    name={plan.name}
                    price={plan.price_monthly}
                    description={`Perfect for ${plan.tier} use`}
                    features={getStandardFeaturesList(plan)}
                    isPopular={plan.tier === 'growth'}
                    seats={plan.seats}
                    chatCredits={plan.chatCredits}
                    executionCredits={plan.executionCredits}
                    imageCredits={plan.imageCredits}
                    onSelectPlan={handleSelectPlan}
                  />
                ))
              : filteredPlans.map((plan, index) => (
                  <DFYPlanCard
                    key={plan._id}
                    tier={plan.tier}
                    name={plan.name}
                    price={plan.price_monthly}
                    description={`Perfect for ${plan.tier} DFY subscription`}
                    blogPostsMonthly={plan.blogPostsMonthly}
                    socialPostsMonthly={plan.socialPostsMonthly}
                    imagesMonthly={plan.imagesMonthly}
                    // Mark the middle card (or every second card in a row) as most popular
                    isPopular={index % 3 === 1}
                    onSelectPlan={handleSelectPlan}
                  />
                ))}
          </div>

          <div className="mt-8 text-center p-8 border-none rounded-lg bg-gray-200 shadow-md">
            <h3 className="text-2xl font-bold mb-4">Looking for Enterprise Solutions?</h3>
            <p className="text-muted-foreground mb-6">
              Get in touch with our sales team to discuss custom enterprise options tailored to your needs.
            </p>
            <Button 
              variant="default" 
              className="gap-2"
              onClick={() => (window.location.href = 'mailto:sales@example.com')}
            >
              <Mail className="h-4 w-4" />
              Contact Sales Team
            </Button>
          </div>
        </TabsContent>
        
        <TabsContent value="compare">
          {activePlanType === 'standard' ? (
            <Card>
              <CardContent className="p-3 lg:px-4 pt-6">
                <PlanComparison plans={plans.filter(plan => plan.subscriptionType === 'standard')} />
              </CardContent>
            </Card>
          ) : (
            <Card>
              <CardContent className="p-3 lg:px-4 pt-6">
                <DFYPlanComparison plans={plans.filter(plan => plan.subscriptionType === 'dfy')} />
              </CardContent>
            </Card>
          )}
        </TabsContent>
      </Tabs>

      <FAQSection type={`${activePlanType === 'dfy' ? 'dfysubscriptions' : 'subscriptions'}`} className="mt-8" />
    </div>
  );
};

export default SubscriptionPlans;