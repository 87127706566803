import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import { 
  FileText, 
  ShoppingBag, 
  Image as ImageIcon, 
  Share2, 
  BookOpen,
  ArrowRight,
  Circle,
  Globe
} from "lucide-react";

const RecentAssetsPanel = ({ 
  assets, 
  onViewAssetType, 
  onSelectAsset 
}) => {
  // Sort all assets by the most recent statusChangeDate or date
  const sortedAssets = [...assets].sort((a, b) => {
    const dateA = new Date(a.createdAt || a.date || 0);
    const dateB = new Date(b.createdAt || b.date || 0);
    return dateB - dateA; // Descending order (newest first)
  });

  // Take only the 10 most recent
  const recentAssets = sortedAssets.slice(0, 10);

  // Get asset icon based on type
  const getAssetIcon = (assetType) => {
    switch (assetType) {
      case "blog-posts":
        return <FileText className="h-5 w-5 text-gray-900" />;
      case "products":
        return <ShoppingBag className="h-5 w-5 text-gray-900" />;
      case "social-posts":
        return <Share2 className="h-5 w-5 text-gray-900" />;
      case "general-assets":
        return <Globe className="h-5 w-5 text-gray-900" />;
      case "general-images":
        return <ImageIcon className="h-5 w-5 text-gray-900" />;
      case "courses":
        return <BookOpen className="h-5 w-5 text-gray-900" />;
      default:
        return <FileText className="h-5 w-5 text-gray-900" />;
    }
  };

  // Format asset type name for display
  const formatAssetTypeName = (assetType) => {
    switch (assetType) {
      case "blog-posts":
        return "Blog Post";
      case "products":
        return "Product";
      case "social-posts":
        return "Social Post";
      case "general-assets":
        return "General Content";
      case "general-images":
        return "Image";
      case "courses":
        return "Course";
      default:
        return assetType.replace("-", " ");
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now - date;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) {
      // Today - show time
      return `Today at ${date.toLocaleTimeString(undefined, { 
        hour: '2-digit', 
        minute: '2-digit' 
      })}`;
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else {
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  };

  // Group assets by type for "See all" buttons
  const assetTypeGroups = recentAssets.reduce((acc, asset) => {
    if (!acc[asset.assetType]) {
      acc[asset.assetType] = [];
    }
    acc[asset.assetType].push(asset);
    return acc;
  }, {});

  return (
    <div className="mt-8">
      <div className="flex justify-center lg:justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Recently Added</h2>
      </div>
      
      <div className="space-y-3">
        {recentAssets.length === 0 ? (
          <Card>
            <CardContent className="p-6 text-center">
              <p className="text-gray-500">No recently updated assets found.</p>
            </CardContent>
          </Card>
        ) : (
          <>
            {/* Asset list */}
            <div className="grid grid-cols-1 gap-3">
              {recentAssets.map((asset) => (
                <Card
                  key={asset._id}
                  className={`cursor-pointer transition-colors hover:bg-gray-50 dark:hover:bg-gray-800 ${
                    !asset.isRead
                      ? "bg-blue-50 dark:bg-blue-900/20 border-blue-100 dark:border-blue-900"
                      : ""
                  }`}
                >
                  <CardContent className="p-4 flex items-center" onClick={() => onSelectAsset(asset._id, asset.assetType)}>
                    {/* Left: Icon or Image */}
                    <div className="flex-shrink-0 mr-4">
                      {asset.assetType === "general-images" && asset.imageUrl ? (
                        <div className="w-12 h-12 rounded overflow-hidden bg-gray-100 border">
                          <img 
                            src={asset.imageUrl} 
                            alt={asset.title || "Asset image"} 
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/placeholder-image.jpg";
                            }}
                          />
                        </div>
                      ) : (
                        <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                          {getAssetIcon(asset.assetType)}
                        </div>
                      )}
                    </div>
                    
                    {/* Middle: Asset info */}
                    <div className="flex-grow min-w-0">
                      <div className="flex items-center">
                        {!asset.isRead && (
                          <Circle className="h-3 w-3 text-blue-500 fill-current mr-2 flex-shrink-0" />
                        )}
                        <h3 className="font-semibold text-base truncate">
                          {asset.title || 
                           asset.name || 
                           (asset.course && asset.course.post_title) || 
                           "Untitled"}
                        </h3>
                      </div>
                      <div className="flex flex-col lg:flex-row items-center mt-1">
                        <Badge variant="outline" className="mr-2">
                          {formatAssetTypeName(asset.assetType)}
                        </Badge>
                        <span className="text-xs text-gray-500">
                          {formatDate(asset.statusChangeDate || asset.date)}
                        </span>
                      </div>
                    </div>
                    
                    {/* Right: Status */}
                    <div className="flex-shrink-0 ml-4">
                      <Badge variant={asset.status === 'published' ? 'default' : 'outline'}>
                        {asset.status || 'Draft'}
                      </Badge>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
            
            {/* "See all" buttons */}
            <div className="flex flex-wrap gap-2 mt-4 justify-end">
              {Object.keys(assetTypeGroups).map((assetType) => (
                <Button
                  key={assetType}
                  variant="outline"
                  size="sm"
                  onClick={() => onViewAssetType(assetType)}
                  className="flex items-center"
                >
                  {getAssetIcon(assetType)}
                  <span className="ml-2 mr-1">
                    See all {formatAssetTypeName(assetType)}s
                  </span>
                  <ArrowRight className="h-3 w-3" />
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RecentAssetsPanel;