export const surveyData = [
  {
      id: 1,
      questionText: "What's your main gig?",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-LaptopDoodle.png",
      inputType: "none", // changed from dropdown to buttons
      fieldName: "mainGig",
      dropdownOptions: null,
      buttons: [
          { label: "Content Creation", value: "Content Creation" },
          { label: "Marketing", value: "Marketing" },
          { label: "Product Development", value: "Product Development" },
          { label: "Education", value: "Education" },
          { label: "Business Strategy", value: "Business Strategy" },
          { label: "Influencer", value: "Influencer" },
          { label: "Other", value: "Other" },
      ],
  },
  {
      id: 2,
      questionText: "What do you spend most of your time on?",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-ClockDoodle.png",
      inputType: "none", // changed from dropdown to buttons
      fieldName: "mostTimeSpent",
      dropdownOptions: null,
      buttons: [
          { label: "Writing (blogs, social posts, etc.)", value: "Writing (blogs, social posts, etc.)" },
          { label: "Brainstorming Ideas", value: "Brainstorming Ideas" },
          { label: "Strategy Planning", value: "Strategy Planning" },
          { label: "Research", value: "Research" },
          { label: "Tedious Prep Work", value: "Tedious Prep Work" },
          { label: "Content Repurposing", value: "Content Repurposing" },
          { label: "Other", value: "Other" },
      ],
  },
  {
      id: 3,
      questionText: "What's your biggest workflow headache?",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-HeadacheDoodle.png",
      inputType: "none",
      fieldName: "biggestHeadache",
      dropdownOptions: null,
      buttons: [
          { label: "Too much on my plate", value: "Too much on my plate" },
          { label: "Keeping content consistent", value: "Keeping content consistent" },
          { label: "Coming up with fresh content", value: "Coming up with fresh content" },
          { label: "Collaborating with teammates", value: "Collaborating with teammates" },
      ],
  },
  {
      id: 4,
      questionText: "What's your most important goal?",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-BullseyeDoodle.png",
      inputType: "none",
      fieldName: "mainGoal",
      dropdownOptions: null,
      buttons: [
          { label: "Get more stuff done", value: "Get more stuff done" },
          { label: "Up my content game", value: "Up my content game" },
          { label: "Save time on repetitive tasks", value: "Save time on repetitive tasks" },
          { label: "Streamline my marketing efforts", value: "Streamline my marketing efforts" },
          { label: "Learn how to leverage AI for my work", value: "Learn how to leverage AI for my work" },
          { label: "Enhance creativity and spark new ideas", value: "Enhance creativity and spark new ideas" },
      ],
  },
  {
      id: 5,
      questionText: "Ready to see how Flow Orchestra can take you from so-so to so legendary?",
      imageUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/FlowOrchestraAI-RocketDoodle.png",
      inputType: "none",
      fieldName: "showResults",
      dropdownOptions: null,
      buttons: [
          { label: "Of course I am", value: true },
          { label: "Maybe later", value: false },
      ],
  },
];