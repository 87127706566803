// components/dashboard/EditAIImageModal.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import { 
  Form, 
  FormControl, 
  FormDescription, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '../../components/ui/select';
import { Plus, X, Loader2, Scaling, NotebookPen, Paintbrush, LayoutGrid, Pencil } from 'lucide-react';
import { Badge } from '../../components/ui/badge';
import { useForm } from 'react-hook-form';
import { updateContentRequest } from '../../slices/contentSlice';

const imageStyleOptions = [
  { value: 'realistic', label: 'Realistic' },
  { value: 'cartoon', label: 'Cartoon' },
  { value: 'sketch', label: 'Sketch' },
  { value: 'painting', label: 'Painting' },
  { value: 'digital-art', label: 'Digital Art' },
  { value: 'abstract', label: 'Abstract' },
  { value: 'minimalist', label: 'Minimalist' },
  { value: 'pixel-art', label: 'Pixel Art' },
  { value: 'photographic', label: 'Photographic' },
  { value: '3d-render', label: '3D Render' },
];

const imageSizeOptions = [
  { value: '1024x1024', label: 'Square (1024x1024)' },
  { value: '1024x1792', label: 'Portrait (1024x1792)' },
  { value: '1792x1024', label: 'Landscape (1792x1024)' },
];

const EditAIImageModal = ({ isOpen, onClose, aiImage }) => {
  const dispatch = useDispatch();
  const [topics, setTopics] = useState([]);
  const [currentTopic, setCurrentTopic] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const form = useForm({
    defaultValues: {
      imageStyle: aiImage?.imageStyle || '',
      size: aiImage?.size || '',
      notes: aiImage?.notes || ''
    }
  });

  useEffect(() => {
    if (aiImage?.topics && aiImage.topics.length > 0) {
      setTopics(aiImage.topics.map(t => t.topic));
    }
  }, [aiImage]);

  const handleAddTopic = () => {
    if (currentTopic.trim() !== '' && !topics.includes(currentTopic.trim())) {
      setTopics([...topics, currentTopic.trim()]);
      setCurrentTopic('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopics(topics.filter(topic => topic !== topicToRemove));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTopic();
    }
  };

  const onSubmit = async (data) => {
    if (topics.length === 0) {
      form.setError("topics", {
        type: "manual",
        message: "At least one topic is required",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      await dispatch(updateContentRequest({
        id: aiImage._id,
        contentType: 'AIImage',
        data: {
          ...data,
          topics
        }
      })).unwrap();
      onClose();
    } catch (error) {
      console.error('Failed to update AI image request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] z-[60] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
        <DialogTitle className='flex flex-row gap-2 items-center'><Pencil className='h-5 w-5' />Edit AI Image</DialogTitle>
          <DialogDescription>
            Update the details for your AI-generated image.
          </DialogDescription>
        </DialogHeader>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="space-y-2">
              <FormLabel className='text-lg flex flex-row items-center gap-1'><LayoutGrid className='h-4 w-4 text-indigo-500' />Topics/Prompts</FormLabel>
              <div className="flex items-center gap-2">
                <Input
                  value={currentTopic}
                  onChange={(e) => setCurrentTopic(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter a descriptive prompt and press Enter"
                  className="flex-1"
                />
                <Button 
                  type="button" 
                  onClick={handleAddTopic} 
                  variant="outline"
                  disabled={!currentTopic.trim()}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
              <FormDescription>
                Add one or more descriptive prompts for your AI image. Press Enter or click the + button to add.
              </FormDescription>
              {form.formState.errors.topics && (
                <p className="text-sm font-medium text-destructive">
                  {form.formState.errors.topics.message}
                </p>
              )}
              <div className="flex flex-wrap gap-1 mt-2">
                {topics.map((topic, index) => (
                  <Badge key={index} variant="secondary" className="gap-1">
                    {topic}
                    <button
                      type="button"
                      onClick={() => handleRemoveTopic(topic)}
                      className="ml-1 rounded-full h-4 w-4 inline-flex items-center justify-center"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </Badge>
                ))}
              </div>
            </div>

            <FormField
              control={form.control}
              name="imageStyle"
              rules={{ required: 'Image style is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><Paintbrush className='h-4 w-4 text-indigo-500' />Image Style</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className='shadow-md rounded bg-gray-100'>
                        <SelectValue placeholder="Select an image style" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className='z-[60]'>
                      {imageStyleOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Choose the visual style for your AI-generated image.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="size"
              rules={{ required: 'Image size is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><Scaling className='h-4 w-4 text-indigo-500' />Image Size</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className='shadow-md rounded bg-gray-100'>
                        <SelectValue placeholder="Select an image size" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className='z-[60]'>
                      {imageSizeOptions.map(option => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    Choose the dimensions for your AI-generated image.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-lg flex flex-row items-center gap-1'><NotebookPen className='h-4 w-4 text-indigo-500' />Additional Notes (Optional)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Add any specific instructions or details here..."
                      {...field}
                    />
                  </FormControl>
                  <FormDescription>
                    Provide any additional instructions or details for your AI image.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="button" variant="outline" onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Save Changes
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EditAIImageModal;