import React from "react";
import { FileX, Search, Bell, FileText, ShoppingBag, Image, Share2, BookOpen, Globe } from "lucide-react";

const EmptyState = ({ assetType, searchTerm }) => {
  const getIcon = () => {
    if (searchTerm) {
      return <Search className="h-12 w-12 text-gray-300 mb-4" />;
    }

    switch (assetType) {
      case "notifications":
        return <Bell className="h-12 w-12 text-gray-300 mb-4" />;
      case "blog-posts":
        return <FileText className="h-12 w-12 text-gray-300 mb-4" />;
      case "products":
        return <ShoppingBag className="h-12 w-12 text-gray-300 mb-4" />;
      case "general-images":
        return <Image className="h-12 w-12 text-gray-300 mb-4" />;
      case "social-posts":
        return <Share2 className="h-12 w-12 text-gray-300 mb-4" />;
      case "general-assets":
        return <Globe className="h-12 w-12 text-gray-300 mb-4" />;
      default:
        return <FileX className="h-12 w-12 text-gray-300 mb-4" />;
    }
  };

  const getMessage = () => {
    if (searchTerm) {
      return {
        title: "No results found",
        description: `We couldn't find any ${assetType === "notifications" ? "notifications" : "assets"} matching "${searchTerm}".`,
        suggestion: "Try a different search term or clear the search."
      };
    }

    switch (assetType) {
      case "notifications":
        return {
          title: "No notifications",
          description: "You don't have any notifications at the moment.",
          suggestion: "New notifications will appear here when they arrive."
        };
      case "blog-posts":
        return {
          title: "No blog posts",
          description: "You don't have any saved blog posts yet.",
          suggestion: "Create content using the AI tools and save it here for future use."
        };
      case "products":
        return {
          title: "No products",
          description: "You don't have any saved products yet.",
          suggestion: "Generate product details and save them here for your e-commerce store."
        };
      case "social-posts":
        return {
          title: "No social posts",
          description: "You don't have any saved social media posts yet.",
          suggestion: "Create engaging social media content and save it here for scheduling."
        };
      case "general-assets":
        return {
          title: "No general content",
          description: "You don't have any saved general content yet.",
          suggestion: "Save text content, snippets, and other assets here for future reference."
        };
      case "general-images":
        return {
          title: "No images",
          description: "You don't have any saved images yet.",
          suggestion: "Generate AI images for your content and save them here."
        };
      case "courses":
        return {
          title: "No courses",
          description: "You don't have any saved courses yet.",
          suggestion: "Create educational content and organize it into courses for your audience."
        };
      default:
        return {
          title: "No assets found",
          description: "You don't have any saved assets in this category yet.",
          suggestion: "Create content using the AI tools and save it here for future use."
        };
    }
  };

  const message = getMessage();

  return (
    <div className="flex flex-col items-center justify-center h-full py-16 px-4 text-center">
      {getIcon()}
      <h3 className="text-xl font-semibold mb-2">{message.title}</h3>
      <p className="text-gray-500 mb-1 max-w-md">{message.description}</p>
      <p className="text-gray-500 max-w-md">{message.suggestion}</p>
    </div>
  );
};

export default EmptyState;