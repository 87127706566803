import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Circle, Trash2, MoreVertical } from "lucide-react";

const AssetsList = ({
  assets,
  selectedAssetId,
  onSelectAsset,
  onDeleteAsset,
  onUpdateStatus
}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      {assets.map((asset) => (
        <Card
          key={asset._id}
          className={`cursor-pointer transition-colors ${
            selectedAssetId === asset._id
              ? "bg-primary/5 border-primary"
              : !asset.isRead
              ? "bg-gray-100 border-2 border-blue-200"
              : "bg-white hover:bg-gray-50"
          }`}
        >
          <CardContent className="p-4 pt-0 lg:pt-4 flex flex-col h-full">
            <div className="flex justify-between items-start mb-2">
              <div 
                className="flex-grow cursor-pointer" 
                onClick={() => onSelectAsset(asset._id)}
              >
                <div className="flex items-center">
                  {!asset.isRead && (
                    <Circle className="h-3 w-3 text-blue-500 fill-current mr-2 flex-shrink-0" />
                  )}
                  <div className="flex flex-col">
                  <h3 className="text-md lg:text-lg font-semibold flex-wrap leading-tight">
                    {asset.title || 
                     asset.name || 
                     (asset.course && asset.course.post_title) || 
                     "Untitled"}
                  </h3>
                  
                <Badge variant={asset.status === 'draft' ? 'outline' : 'default'} className="mr-2 w-fit mt-1">
                  {asset.status || 'Draft'}
                </Badge>
                </div>
                </div>
              </div>
              
              <div className="flex items-center ml-2">
                
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel className='bg-gray-100 text-center'>Actions</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        onUpdateStatus(asset._id, asset.status === 'published' ? 'draft' : 'published');
                      }}
                      className="hover:bg-gray-900 hover:text-gray-100"
                    >
                      {asset.status === 'published' ? 'Set as Draft' : 'Mark Published'}
                    </DropdownMenuItem>
                    <DropdownMenuItem 
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectAsset(asset._id);
                      }}
                      className="hover:bg-gray-900 hover:text-gray-100"
                    >
                      View Details
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <DropdownMenuItem
                          className="text-red-500 focus:text-red-500 hover:bg-gray-900"
                          onSelect={(e) => e.preventDefault()}
                        >
                            <Trash2 className="h-4 w-4 mr-2" />
                          Delete
                        </DropdownMenuItem>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Delete Asset</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete this asset? This action cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => onDeleteAsset(asset._id)}
                          >
                            Delete
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            
            <div 
              className="flex-grow cursor-pointer" 
              onClick={() => onSelectAsset(asset._id)}
            >
              <p className="text-sm text-gray-500 mb-2">
                {asset.creator ? `${asset.creator.firstName} ${asset.creator.lastName}` : "Unknown"}
              </p>
              
              <div className="line-clamp-3 text-sm mb-3">
                {asset.content || 
                 asset.text || 
                 asset.message || 
                 asset.description || 
                 asset.imageUrl ||
                 (asset.course && asset.course.post_content) || 
                 "No content available"}
              </div>
              {asset.imageUrl && (
                <img 
                  src={asset.imageUrl} 
                  alt={asset.title || asset.name || "Asset"} 
                  className="w-full h-60 object-cover rounded-md mb-2"
                />
              )}
            </div>
            
            <div className="flex justify-between items-center text-xs text-gray-500 mt-auto">
              <span>Created: {formatDate(asset.date)}</span>
              {asset.statusChangeDate && (
                <span>Last Updated: {formatDate(asset.statusChangeDate)}</span>
              )}
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default AssetsList;