import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Layers, Hash, Lock, ArrowRight } from 'lucide-react';
import { Card, CardContent } from "../../../components/ui/card";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import CategoryIcon from './CategoryIcon';

const FlowCard = ({ flow, isSelected, onClick, limits }) => {
  const navigate = useNavigate();
  const isDisabled = limits.flowSteps < flow.steps.length;

  const handleClick = (e) => {
    if (!isDisabled) {
      onClick(flow._id);
    }
  };

  const handleUpgradeClick = (e) => {
    e.stopPropagation(); // Prevent card onClick from firing
    navigate('/subscription');
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Card
            className={`relative transition-all duration-200 shadow-md bg-[#fafafa] hover:shadow-xl ${
              isSelected ? 'border-blue-500 shadow-lg' : 'border-none'
            } ${
              isDisabled 
                ? 'cursor-not-allowed hover:opacity-95' 
                : 'cursor-pointer'
            }`}
            onClick={handleClick}
          >
            {isDisabled && (
              <div className="absolute inset-0 bg-gradient-to-b from-background/30 to-background/60 rounded-lg z-10">
                <div className="absolute inset-0 flex flex-col items-center justify-end p-4 backdrop-blur-[0.5px]">
                  <div className='bg-gray-500 bg-opacity-60 p-2 px-4 rounded-lg text-white flex flex-col items-center'>
                  <Lock className="w-6 h-6 text-gray-900 mb-2" />
                  <p className="text-sm font-medium text-center text-white mb-1">
                    Unlock this flow
                  </p>
                  <p className="text-xs text-center text-red-700 font-bold mb-1">
                    Requires {flow.steps.length} steps
                    <br />
                    Your plan includes {limits.flowSteps} steps
                  </p>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={handleUpgradeClick}
                    className="group bg-black hover:bg-background/90 transition-colors"
                  >
                    Upgrade Plan
                    <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
                  </Button>
                  </div>
                </div>
              </div>
            )}
  
            <CardContent className="pt-4">
              <h4 className="font-semibold text-lg mb-1">{flow.name}</h4>
              {flow.flowCategory && (
                <div className="flex items-center mb-2 text-sm text-black font-bold">
                  <CategoryIcon category={flow.flowCategory} className="w-5 h-5 mr-1" />
                  <span>{flow.flowCategory}</span>
                </div>
              )}
              <p className="text-sm text-gray-600 mb-3">
                {flow.description}
              </p>
              {flow.tags && flow.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-3">
                  {flow.tags.map((tag, index) => (
                    <Badge
                      key={index}
                      variant="secondary"
                      className="text-xs font-light"
                    >
                      <Tag className="w-3 h-3 mr-1" />
                      {tag}
                    </Badge>
                  ))}
                </div>
              )}
              <div className="grid grid-cols-2 gap-2 text-xs text-gray-500">
                <div className="flex items-center">
                  <Layers className="w-3 h-3 mr-1" />
                  <span className={isDisabled ? "font-semibold text-primary/70" : ""}>
                    {(flow.steps && flow.steps.length) || 0} steps
                  </span>
                </div>
                {/*<div className="flex items-center">
                  <Hash className="w-3 h-3 mr-1" />
                  <span>{(flow.tags && flow.tags.length) || 0} tags</span>
                </div>*/}
              </div>
            </CardContent>
          </Card>
        </TooltipTrigger>
        {isDisabled && (
          <TooltipContent side="top" className="max-w-xs">
            <p>Upgrade your plan to access advanced flows with more steps.</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default FlowCard;