import React, { useState, useEffect, createElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Add this import
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faComment,
  faPencilAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import {
  updateChatTitle,
  setCurrentChat,
  addMessage,
} from "../../slices/chatSlice";
import { updateCredits } from "../../slices/authSlice";
import { updateTitle } from "../../services/api";
import { useToast } from "../../components/ui/use-toast";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../components/ui/sheet";
import {
  Menu,
  PencilIcon,
  MessageSquare,
  ListPlus,
  Stack,
  Layers,
  PlusIcon,
  LoaderPinwheel,
  BrainCircuit, // Represents initial analysis
  Cpu, // Processing
  Network, // Connecting thoughts
  Sparkles, // Generation
  Loader2,
} from "lucide-react";
import AssistantSelector from "./AssistantSelector";
import PromptStackDrawer from "../PromptStackComponents/PromptStackDrawer";
import SavedPromptStacks from "../PromptStackComponents/SavedPromptStacks";
import { fetchPromptStacks, executeStack, fetchGlobalPromptStacks } from "../../slices/promptStackSlice"; // Add executeStack

// First, let's create a separate Particles component with proper styling
const Particles = () => {
  return (
    <>
      <style>
        {`
          @keyframes float-up {
            0% {
              transform: translateY(100px) scale(0);
              opacity: 0;
            }
            30% {
              opacity: 0.05;
            }
            55% {
              opacity: 0.95;
            }
            100% {
              transform: translateY(-200px) scale(1);
              opacity: 0;
            }
          }
        `}
      </style>
      <div className="absolute inset-0 w-full h-full">
        {[...Array(15)].map((_, i) => (
          <div
            key={i}
            className="absolute w-2 h-2 rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              bottom: "0",
              background: "rgba(74, 222, 128, 0.4)",
              animation: `float-up ${2 + Math.random() * 2}s linear infinite`,
              animationDelay: `${Math.random() * 2}s`,
            }}
          />
        ))}
      </div>
    </>
  );
};

const ChatHeader = ({
  currentChat,
  handleNewChat,
  selectedAssistant,
  handleSwitchAssistant,
  chatTitle,
  setChatTitle,
  assistants,
  toggleSidebar,
  credits,
  limits,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [isPromptStackOpen, setIsPromptStackOpen] = useState(false);
  const [isStackListOpen, setIsStackListOpen] = useState(false);
  const [stackResponding, setStackResponding] = useState(false);
  const [stackToEdit, setStackToEdit] = useState(null);
  const promptStacks = useSelector((state) => state.promptStack.promptStacks);
  const globalPromptStacks = useSelector((state) => state.promptStack.globalPromptStacks);
  const { selectedTeam } = useSelector((state) => state.teams);
  const loadingPairs = [
    { message: "Analyzing your prompt stack...", icon: BrainCircuit },
    { message: "Processing instructions...", icon: Cpu },
    { message: "Preparing response...", icon: Network },
    { message: "Assembling thoughts...", icon: Sparkles },
    { message: "Almost there...", icon: Loader2 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fakeProgress, setFakeProgress] = useState(0);
 //console.log("assistants in header", assistants);

  useEffect(() => {
    let progressInterval;
    if (stackResponding) {
      progressInterval = setInterval(() => {
        setFakeProgress((prev) => {
          // Slow down progress as it gets higher
          const increment = Math.max(0.5, (100 - prev) / 20);
          return Math.min(95, prev + increment);
        });
      }, 900);
    } else {
      setFakeProgress(0);
    }
    return () => clearInterval(progressInterval);
  }, [stackResponding]);

  useEffect(() => {
    let intervalId;
    if (stackResponding) {
      intervalId = setInterval(() => {
        setCurrentIndex((prev) => {
          if (prev < loadingPairs.length - 1) {
            return prev + 1;
          }
          return prev;
        });
      }, 5000);
    } else {
      setCurrentIndex(0);
    }
    return () => clearInterval(intervalId);
  }, [stackResponding]);

  useEffect(() => {
    dispatch(fetchPromptStacks());
    dispatch(fetchGlobalPromptStacks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPromptStacks(selectedTeam));
    //console.log("fetching prompt stacks for team", selectedTeam);
  }, [dispatch, selectedTeam]);

  const handleEditTitle = () => {
    setIsEditingTitle(true);
    setEditedTitle(currentChat.title);
  };

  const handleSaveTitle = async () => {
    if (editedTitle.trim() !== "") {
      try {
        await updateTitle(currentChat._id, editedTitle);
        dispatch(
          updateChatTitle({ chatId: currentChat._id, title: editedTitle })
        );
        setIsEditingTitle(false);
        toast({
          title: "Title updated",
          description: "The chat title has been successfully updated.",
          duration: 3000,
        });
      } catch (error) {
        //console.error("Failed to update title:", error);
        toast({
          title: "Oops! Something went wrong.",
          description: "Failed to update the chat title. Please try again.",
          variant: "destructive",
          duration: 3000,
        });
      }
    }
  };

  const handleEditStack = (stack) => {
    setStackToEdit(stack);
    setIsPromptStackOpen(true);
    setIsStackListOpen(false);
  };

  const handleStackFromDrawer = async (stack) => {
    dispatch(updateCredits({ type: 'chat', amount: stack.prompts.length, subtract: true }));
    try {
      setIsStackListOpen(false);
      setStackResponding(true);

      const stackTitle = {
        role: "user",
        content: stack.title,
      };
      dispatch(addMessage(stackTitle));

      // If it's a temporary stack, pass it directly
      const response = await dispatch(
        executeStack({
          chatId: currentChat?._id || "new",
          stackId: stack.isTemporary ? undefined : stack._id,
          tempStack: stack.isTemporary ? stack : undefined,
          promptCount: stack.prompts.length,
          teamId: selectedTeam || null
        })
      ).unwrap();

      // If this was a new chat, update the route
      if (!currentChat?._id) {
        dispatch(setCurrentChat(response));
        navigate(`/chat/${response._id}`);
      }
      setStackResponding(false);
    } catch (error) {
      setStackResponding(false);
      dispatch(updateCredits({ type: 'chat', amount: stack.prompts.length, subtract: false }));
      toast({
        title: "Oops! Something went wrong.",
        description: error || "Failed to execute prompt stack.",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  // Add this handler for executing stacks
  const handleExecuteStack = async (stack) => {
    if (stack.prompts.length > credits.chat) {
      toast({
        title: "Oops! Insufficient Credits",
        description: "You need more chat credits to execute this stack.",
        variant: "destructive",
        duration: 3000,
      });
      return;
    }
    try {
      dispatch(updateCredits({ type: 'chat', amount: stack.prompts.length, subtract: true }));
      // Close the drawer immediately to show the chat
      setIsStackListOpen(false);
      setStackResponding(true);

      const stackTitle = {
        role: "user",
        content: stack.title,
      };
      dispatch(addMessage(stackTitle));

      const response = await dispatch(
        executeStack({
          chatId: currentChat?._id || "new",
          stackId: stack._id,
          promptCount: stack.prompts.length,
          teamId: selectedTeam || null
        })
      ).unwrap();

      // If this was a new chat, update the route
      if (!currentChat?._id) {
        dispatch(setCurrentChat(response));
        navigate(`/chat/${response._id}`);
      }
      setStackResponding(false);
    } catch (error) {
      setStackResponding(false);
      dispatch(updateCredits({ type: 'chat', amount: stack.prompts.length, subtract: false }));
      toast({
        title: "Oops! Something went wrong.",
        description: error || "Failed to execute prompt stack.",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  return (
    <div className="p-2 lg:p-4 lg:py-1 border-b border-gray-200 lg:shadow-md w-full lg:mx-0">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-4">
        <div className="flex flex-col lg:flex-row gap-2 w-full items-center space-x-2">
          {currentChat && (
            <Button
              onClick={handleNewChat}
              variant="outline"
              size="sm"
              className="gap-2 border-none bg-gray-100 text-black hover:bg-gray-200"
            >
              <PlusIcon className="h-4 w-4" />
              New Chat
            </Button>
          )}

          {/* Prompt Stack Controls */}
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              onClick={() => setIsPromptStackOpen(true)}
              className="gap-2 border-none bg-gray-100 text-black hover:bg-gray-200"
              size="sm"
            >
              <ListPlus className="h-4 w-4" />
              Create Stack
            </Button>

            <Button
              variant="outline"
              onClick={() => setIsStackListOpen(true)}
              className="gap-2 border-none bg-gray-100 text-black hover:bg-gray-200"
              size="sm"
            >
              <Layers className="h-4 w-4" />
              Saved Stacks
            </Button>
          </div>
          <div className="w-full lg:w-fit">
            <AssistantSelector
              selectedAssistant={selectedAssistant}
              onAssistantChange={handleSwitchAssistant}
              assistants={assistants}
            />
          </div>
        </div>

        {currentChat ? (
          <div className="w-full lg:w-1/3 flex items-center justify-between lg:justify-end">
            {isEditingTitle ? (
              <div className="flex w-full items-center space-x-2">
                <Input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className="flex-1 border-none shadow-sm bg-gray-100"
                />
                <Button
                  onClick={handleSaveTitle}
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 flex-shrink-0"
                >
                  <FontAwesomeIcon icon={faSave} className="text-green-500" />
                </Button>
              </div>
            ) : (
              <div className="flex w-full items-center justify-between lg:justify-end space-x-2">
                <h2 className="text-sm lg:text-lg font-semibold truncate">
                  {currentChat.title}
                </h2>
                <Button
                  onClick={handleEditTitle}
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 flex-shrink-0"
                >
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="text-gray-500"
                  />
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full lg:w-1/3 flex items-center space-x-2">
            <Avatar className="w-8 h-8 flex-shrink-0 flex items-center justify-center">
              <AvatarFallback className="bg-black">
                <FontAwesomeIcon icon={faComment} className="text-white" />
              </AvatarFallback>
            </Avatar>
            <Input
              type="text"
              placeholder="Enter chat title"
              value={chatTitle}
              onChange={(e) => setChatTitle(e.target.value)}
              className="flex-1 border-none shadow-sm bg-gray-100"
            />
          </div>
        )}
      </div>

      {stackResponding && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-black p-12 rounded-xl shadow-2xl max-w-2xl mx-4 border-2 border-green-400 relative">
            {/* Particle Effect */}
            <Particles />

            <div className="flex flex-col items-center space-y-6 relative z-10">
              <div className="relative">
                {/* Rotating outer ring */}
                <div className="absolute inset-0 animate-spin">
                  <div className="h-16 w-16 rounded-full border-4 border-white border-t-transparent" />
                </div>

                {/* Current icon */}
                <div className="relative z-10 flex items-center justify-center h-16 w-16 animate-pulse">
                  {createElement(loadingPairs[currentIndex].icon, {
                    className: "w-8 h-8 text-white",
                  })}
                </div>
              </div>

              <span className="text-xl text-white">
                {loadingPairs[currentIndex].message}
              </span>

              <div className="w-64 h-2 bg-gray-700 rounded-full overflow-hidden">
                <div
                  className="h-full bg-white transition-all duration-300 ease-out"
                  style={{ width: `${fakeProgress}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Prompt Stack Drawers */}
      <PromptStackDrawer
        isOpen={isPromptStackOpen}
        onClose={() => {
          setIsPromptStackOpen(false);
          setStackToEdit(null);
        }}
        stackToEdit={stackToEdit}
        onExecute={handleStackFromDrawer}
        assistants={assistants}
        credits={credits}
        limits={limits}
        globalPromptStacks={globalPromptStacks}
      />

<Sheet open={isStackListOpen} onOpenChange={setIsStackListOpen}>
  <SheetContent 
    side="right" 
    className="w-[95vw] sm:max-w-sm p-0 overflow-hidden h-full z-[60] mt-12 lg:mt-0 text-white"
  >
    <div className="flex flex-col h-full">
      <SheetHeader className="p-4 pb-2 lg:pb-0 bg-black">
        <SheetTitle className="text-lg sm:text-xl text-white">
          Saved Prompt Stacks
        </SheetTitle>
      </SheetHeader>
      
      <div className="flex-1 overflow-hidden">
        <SavedPromptStacks
          stacks={promptStacks}
          onExecute={handleExecuteStack}
          onEdit={handleEditStack}
          currentChatId={currentChat?._id}
          onClose={() => setIsStackListOpen(false)}
          className="h-full"
          credits={credits}
          limits={limits}
        />
      </div>
    </div>
  </SheetContent>
</Sheet>
    </div>
  );
};

export default ChatHeader;
