import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  question: "",
  response: "",
  isLoadingResponse: false,
  isLoadingSuggestions: false,
  fetchedSuggestions: [],
  fetchSuggestedPrompts: false,
  suggestedFlowStacks: [],
  contextSteps: [],
  executingFlowId: null,
  executingFlow: null,
  showContextModal: false,
  additionalChatDetails: null,
  additionalImageDetails: null,
  showAdditionalStrategyDetails: false,
  showAdditionalChatPromptDetails: false,
  showAdditionalImagePromptDetails: false,
  additionalStrategyDetails: null,
  chatPromptResponse: null,
  strategyResponse: null,
  isLoadingSecondaryResponse: false,
  isCreatingTodo: false,
  localQuestion: null
};

const dashboardAgentSlice = createSlice({
  name: "dashboardAgent",
  initialState,
  reducers: {
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setIsLoadingResponse: (state, action) => {
      state.isLoadingResponse = action.payload;
    },
    setIsLoadingSuggestions: (state, action) => {
      state.isLoadingSuggestions = action.payload;
    },
    setFetchedSuggestions: (state, action) => {
      state.fetchedSuggestions = action.payload;
    },
    setFetchSuggestedPrompts: (state, action) => {
      state.fetchSuggestedPrompts = action.payload;
    },
    setSuggestedFlowStacks: (state, action) => {
      state.suggestedFlowStacks = action.payload;
    },
    setContextSteps: (state, action) => {
      state.contextSteps = action.payload;
    },
    setExecutingFlowId: (state, action) => {
      state.executingFlowId = action.payload;
    },
    setExecutingFlow: (state, action) => {
      state.executingFlow = action.payload;
    },
    setShowContextModal: (state, action) => {
      state.showContextModal = action.payload;
    },
    setAdditionalChatDetails: (state, action) => {
      state.additionalChatDetails = action.payload;
    },
    setAdditionalImageDetails: (state, action) => {
      state.additionalImageDetails = action.payload;
    },
    setShowAdditionalStrategyDetails: (state, action) => {
      state.showAdditionalStrategyDetails = action.payload;
    },
    setShowAdditionalChatPromptDetails: (state, action) => {
      state.showAdditionalChatPromptDetails = action.payload;
    },
    setShowAdditionalImagePromptDetails: (state, action) => {
      state.showAdditionalImagePromptDetails = action.payload;
    },
    setAdditionalStrategyDetails: (state, action) => {
      state.additionalStrategyDetails = action.payload;
    },
    setChatPromptResponse: (state, action) => {
      state.chatPromptResponse = action.payload;
    },
    setStrategyResponse: (state, action) => {
      state.strategyResponse = action.payload;
    },
    setIsLoadingSecondaryResponse: (state, action) => {
      state.isLoadingSecondaryResponse = action.payload;
    },
    setIsCreatingTodo: (state, action) => {
      state.isCreatingTodo = action.payload;
    },
    setLocalQuestion: (state, action) => {
      state.localQuestion = action.payload;
    },
    resetDashboardState: (state) => {
      return initialState;
    }
  }
});

export const {
  setQuestion,
  setResponse,
  setIsLoadingResponse,
  setIsLoadingSuggestions,
  setFetchedSuggestions,
  setFetchSuggestedPrompts,
  setSuggestedFlowStacks,
  setContextSteps,
  setExecutingFlowId,
  setExecutingFlow,
  setShowContextModal,
  setAdditionalChatDetails,
  setAdditionalImageDetails,
  setShowAdditionalStrategyDetails,
  setShowAdditionalChatPromptDetails,
  setShowAdditionalImagePromptDetails,
  setAdditionalStrategyDetails,
  setChatPromptResponse,
  setStrategyResponse,
  setIsLoadingSecondaryResponse,
  setIsCreatingTodo,
  setLocalQuestion,
  resetDashboardState
} = dashboardAgentSlice.actions;

export default dashboardAgentSlice.reducer;