// src/components/TodoComponents/TodoForm.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTodo, updateTodo } from '../../slices/todoSlice';
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";
import { Separator } from "../ui/separator";
import { useToast } from "../ui/use-toast";
import {
  Calendar as CalendarIcon,
  Plus,
  X,
  Check,
  Loader2,
} from 'lucide-react';
import { Calendar } from "../ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover";
import { format } from 'date-fns';

const TodoForm = ({ 
    todo = null,
    isOpen,
    onClose,
    onSuccess
  }) => {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const { teams } = useSelector((state) => state.teams);
    const [loading, setLoading] = useState(false);
  
    // Form state
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState('medium');
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [labels, setLabels] = useState([]);
    const [newLabel, setNewLabel] = useState('');
    const [checklist, setChecklist] = useState([]);
    const [newChecklistItem, setNewChecklistItem] = useState('');
  
    useEffect(() => {
      if (todo) {
        setTitle(todo.title || '');
        setDescription(todo.description || '');
        setPriority(todo.priority || 'medium');
        setDueDate(todo.dueDate ? new Date(todo.dueDate) : null);
        setLabels(todo.labels || []);
        setChecklist(todo.checklist || []);
      } else {
        setTitle('');
        setDescription('');
        setPriority('medium');
        setDueDate(null);
        setLabels([]);
        setChecklist([]);
      }
    }, [todo]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      if (!title.trim()) {
        toast({
          title: "Error",
          description: "Title is required",
          variant: "destructive"
        });
        return;
      }
  
      setLoading(true);
      try {
        const todoData = {
          title: title.trim(),
          description: description.trim(),
          priority,
          team: selectedTeam,
          dueDate: dueDate ? dueDate.toISOString() : null, // Ensure proper date format
          labels,
          checklist: checklist.map(item => ({
            item: item.item,
            completed: item.completed || false
          }))
        };
  
        if (todo) {
          const updatedTodo = await dispatch(updateTodo({ 
            id: todo._id,
            updates: todoData
          })).unwrap();
          
          toast({
            title: "Success",
            description: "Todo updated successfully"
          });
          if (onSuccess) onSuccess({ 
            ...todo,           // Keep existing todo data as base
            ...todoData,       // Apply our updates
            _id: todo._id,     // Ensure we keep the ID
            createdAt: todo.createdAt, // Keep original creation date
            status: todo.status, // Keep status or default to pending
            ...updatedTodo.data     // Override with any additional server updates
          });
        } else {
          await dispatch(createTodo(todoData)).unwrap();
          toast({
            title: "Success",
            description: "Todo created successfully"
          });
          if (onSuccess) onSuccess();
        }
  
        
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description: error.message || "Failed to save todo",
          variant: "destructive"
        });
      } finally {
        setLoading(false);
      }
    };
  
    const handleAddLabel = (e) => {
      if (e) e.preventDefault();
      if (!newLabel.trim()) return;
      
      const label = newLabel.trim();
      if (!labels.includes(label)) {
        setLabels([...labels, label]);
      }
      setNewLabel('');
    };
  
    const handleRemoveLabel = (labelToRemove) => {
      setLabels(labels.filter(label => label !== labelToRemove));
    };
  
    const handleAddChecklistItem = (e) => {
      if (e) e.preventDefault();
      if (!newChecklistItem.trim()) return;
      
      setChecklist([
        ...checklist,
        { item: newChecklistItem.trim(), completed: false }
      ]);
      setNewChecklistItem('');
    };
  
    const handleRemoveChecklistItem = (index) => {
      setChecklist(checklist.filter((_, i) => i !== index));
    };
  
    const handleToggleChecklistItem = (index) => {
      setChecklist(checklist.map((item, i) => 
        i === index ? { ...item, completed: !item.completed } : item
      ));
    };
  
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-2xl h-[calc(100vh-2rem)] flex flex-col p-0 z-[60]">
          <DialogHeader className="px-6 py-4 border-b">
            <DialogTitle>{todo ? 'Edit To-do' : 'Create To-do'}</DialogTitle>
          </DialogHeader>
  
          <form onSubmit={handleSubmit} className="flex flex-col h-full overflow-hidden">
            <div className="flex-1 overflow-y-auto px-6 py-4">
              <div className="space-y-6">
                {/* Title */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">
                    Title <span className="text-red-500">*</span>
                  </label>
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter to-do title"
                  />
                </div>
  
                {/* Description */}
                <div className="space-y-2">
                  <label className="text-sm font-medium">Description</label>
                  <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter description"
                    rows={3}
                  />
                </div>
  
                {/* Priority & Due Date */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium">Priority</label>
                    <Select value={priority} onValueChange={setPriority}>
                      <SelectTrigger className="bg-gray-100 shadow-md text-black">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="z-[60]">
                        <SelectItem value="low">Low</SelectItem>
                        <SelectItem value="medium">Medium</SelectItem>
                        <SelectItem value="high">High</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
  
                  <div className="space-y-2">
                    <label className="text-sm font-medium">Due Date</label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={`w-full justify-start text-left font-normal border-none bg-gray-100 hover:bg-gray-200 shadow-md ${
                            !dueDate && "text-muted-foreground"
                          }`}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {dueDate ? format(dueDate, "PPP") : "Pick a date"}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0 z-[60]" align="start">
                        <Calendar
                          mode="single"
                          selected={dueDate}
                          onSelect={setDueDate}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>

                <div className="space-y-2">
  <label className="text-sm font-medium">Team (Optional)</label>
  <Select value={selectedTeam} onValueChange={setSelectedTeam}>
    <SelectTrigger className="bg-gray-100 shadow-md text-black">
      <SelectValue placeholder="Personal Todo" />
    </SelectTrigger>
    <SelectContent className="z-[60]">
  <SelectItem value="personal">Personal Todos</SelectItem>
  {teams?.map(team => (
    <SelectItem key={team._id} value={team._id}>
      {team.name}
    </SelectItem>
  ))}
</SelectContent>
  </Select>
</div>
  
                <Separator />
  
                {/* Labels */}
                <div className="space-y-4">
                  <label className="text-sm font-medium">Labels</label>
                  <div className="flex flex-wrap gap-2">
                    {labels.map((label, index) => (
                      <Badge
                        key={index}
                        variant="secondary"
                        className="gap-1"
                      >
                        {label}
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="h-4 w-4 p-0 hover:bg-transparent"
                          onClick={() => handleRemoveLabel(label)}
                        >
                          <X className="h-3 w-3" />
                        </Button>
                      </Badge>
                    ))}
                  </div>
                  <div className="flex gap-2">
  <Input
    value={newLabel}
    onChange={(e) => setNewLabel(e.target.value)}
    placeholder="Add a label"
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleAddLabel(e);
      }
    }}
  />
  <Button 
    type="button"
    size="icon" 
    className="bg-black text-gray-100 hover:bg-gray-500"
    onClick={handleAddLabel}
  >
    <Plus className="h-4 w-4" />
  </Button>
</div>
                </div>
  
                <Separator />
  
                {/* Checklist */}
                <div className="space-y-4">
                  <label className="text-sm font-medium">Checklist</label>
                  <div className="space-y-2">
                    {checklist.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 p-2 rounded-md hover:bg-accent"
                      >
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 bg-gray-200 hover:bg-gray-300 shadow-md border border-transparent hover:border-gray-500"
                          onClick={() => handleToggleChecklistItem(index)}
                        >
                          <Check className={`h-4 w-4 ${!item.completed && "opacity-0"}`} />
                        </Button>
                        <span className={item.completed ? "line-through text-muted-foreground" : ""}>
                          {item.item}
                        </span>
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 ml-auto"
                          onClick={() => handleRemoveChecklistItem(index)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-2">
  <Input
    value={newChecklistItem}
    onChange={(e) => setNewChecklistItem(e.target.value)}
    placeholder="Add checklist item"
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleAddChecklistItem(e);
      }
    }}
  />
  <Button 
    type="button"
    size="icon" 
    className="bg-black text-gray-100 hover:bg-gray-500"
    onClick={handleAddChecklistItem}
  >
    <Plus className="h-4 w-4" />
  </Button>
</div>
                </div>
              </div>
            </div>
  
            {/* Footer */}
            <div className="border-t p-4 bg-background mt-auto">
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={onClose}
                  disabled={loading}
                  className="border-none shadow-xl bg-white text-black hover:bg-gray-200"
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={loading} className="bg-black text-gray-100 hover:bg-gray-500">
                  {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  {todo ? 'Update' : 'Create'} To-do
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default TodoForm;