import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Loader2 } from "lucide-react";

const LoadingScreen = () => {
  const [videoError, setVideoError] = useState(false);
  const [messageIndex, setMessageIndex] = useState(-1);
  const [showContent, setShowContent] = useState(false);
  const videoUrl = "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/survey_AI_loading_1.mp4";

  const messages = [
    "Hang tight while we supercharge your productivity...",
    "Just adding a dash of genius...",
    "Mixing in some creative flair...",
    "Blending it all for maximum impact...",
    "Bam! Get ready to wow the world!"
  ];

  useEffect(() => {
    // Preload video
    const preloadLink = document.createElement("link");
    preloadLink.rel = "preload";
    preloadLink.href = videoUrl;
    preloadLink.as = "video";
    preloadLink.type = "video/mp4";
    document.head.appendChild(preloadLink);

    // Initial delay of 1.5 seconds before showing loader and first message
    const initialDelay = setTimeout(() => {
      setShowContent(true);
      setMessageIndex(0);
    }, 1800);

    // Set up message progression
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        if (prevIndex < messages.length - 1) {
          return prevIndex + 1;
        }
        clearInterval(messageInterval);
        return prevIndex;
      });
    }, 6000); // Change message every 6 seconds

    // Cleanup
    return () => {
      document.head.removeChild(preloadLink);
      clearTimeout(initialDelay);
      clearInterval(messageInterval);
    };
  }, []);

  return (
    <div className="relative w-full h-full">
      {!videoError && (
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          muted
          playsInline
          onError={() => setVideoError(true)}
        >
          <source
            src={videoUrl}
            type="video/mp4"
          />
        </video>
      )}
      
      <motion.div
        className="relative flex flex-col items-center justify-center h-full space-y-4 bg-black/30"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.5 } }}
      >
        <AnimatePresence>
          {showContent && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Loader2 className="animate-spin text-indigo-600" size={48} />
            </motion.div>
          )}
        </AnimatePresence>
        
        <AnimatePresence mode="wait">
          {messageIndex >= 0 && (
            <motion.p
              key={messageIndex}
              className="text-lg text-gray-100 font-medium"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {messages[messageIndex]}
            </motion.p>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default LoadingScreen;