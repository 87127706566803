// AssistantCategories.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, CardContent, CardFooter, CardHeader } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { ArrowRight, Users, Briefcase, Brain, User } from 'lucide-react';
import { 
  updateMarketplaceFilters, 
  resetMarketplaceFilters,
  selectMarketplaceFilters 
} from '../../slices/chatbotsSlice';

const AssistantCategories = () => {
  const navigate = useNavigate();
    const dispatch = useDispatch();

  const categories = [
    {
      id: 'c-suite',
      title: 'C-Suite Executive',
      description: 'Strategic AI assistants trained to provide executive-level insights and decision support for leadership teams.',
      icon: <Briefcase className="h-8 w-8 mb-2 text-blue-600" />,
      path: '/assistants/c-suite'
    },
    {
      id: 'teams',
      title: 'AI Teams',
      description: 'Collaborative AI assistants designed to augment your team\'s capabilities and streamline workflows.',
      icon: <Users className="h-8 w-8 mb-2 text-green-600" />,
      path: '/assistants/teams'
    },
    {
      id: 'experts',
      title: 'AI Experts',
      description: 'Specialized AI assistants with deep domain expertise for complex problem-solving and innovation.',
      icon: <Brain className="h-8 w-8 mb-2 text-purple-600" />,
      path: '/assistants/experts'
    }
  ];

  return (
    <div className="w-full pt-8 pb-0">
      <div className="container mx-auto px-0">
      <div className="flex items-center gap-2">
      <div className="flex items-center gap-2 border border-gray-200 rounded-full py-1 pl-2 pr-4 cursor-pointer" onClick={() => dispatch(updateMarketplaceFilters({ salesGroup: "C-Suite Executives" }))}>
              <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/avatars/CEO_avatar.jpg" className="h-8 w-8 text-blue-600 rounded-full" />
                <h3 className="text-md font-semibold">C-Suite Executives</h3>
            </div>
            <div className="flex items-center gap-2 border border-gray-200 rounded-full py-1 pl-2 pr-4 cursor-pointer" onClick={() => dispatch(updateMarketplaceFilters({ salesGroup: "Teams" }))}>
            <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/avatars/Insurance%20Agent%20Team.jpg" className="h-8 w-8 text-blue-600 rounded-full" />
                <h3 className="text-md font-semibold">AI Teams</h3>
            </div>
            <div className="flex items-center gap-2 border border-gray-200 rounded-full py-1 pl-2 pr-4 cursor-pointer" onClick={() => dispatch(updateMarketplaceFilters({ salesGroup: "Expert" }))}>
            <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/avatars/Innovation%20Ecosystem%20Architect%20expert.jpg" className="h-8 w-8 text-blue-600 rounded-full" />
                <h3 className="text-md font-semibold">AI Experts</h3>
            </div>
            <div className="flex items-center gap-2 border border-gray-200 rounded-full py-1 pl-2 pr-4 cursor-pointer" onClick={() => dispatch(updateMarketplaceFilters({ salesGroup: "Writing Style" }))}>
            <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/avatars/Writing%20Style%20Vanity%20Flair.jpg" className="h-8 w-8 text-blue-600 rounded-full" />
                <h3 className="text-md font-semibold">Writing Styles</h3>
            </div>
            <div className="flex items-center gap-2 border border-gray-200 rounded-full py-1 pl-2 pr-4 cursor-pointer" onClick={() => dispatch(updateMarketplaceFilters({ salesGroup: "Free" }))}>
            <img src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/avatars/Writing%20Style%20Sacred%20Rebel.jpg" className="h-8 w-8 text-blue-600 rounded-full" />
                <h3 className="text-md font-semibold">Free Assistants</h3>
            </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            
          {/* Card 1 - Horizontal layout 
          <Card className="overflow-hidden hover:shadow-lg transition-shadow duration-300">
            <div className="flex flex-col h-full">
              <CardHeader className="bg-blue-50 pb-4">
                <div className="flex items-center">
                  {categories[0].icon}
                  <h3 className="text-xl font-semibold ml-3">{categories[0].title}</h3>
                </div>
              </CardHeader>
              <CardContent className="py-4 flex-grow">
                <p className="text-gray-600">{categories[0].description}</p>
              </CardContent>
              <CardFooter className="pt-0">
                <Button 
                  variant="outline" 
                  className="w-full justify-between"
                  onClick={() => navigate(categories[0].path)}
                >
                  Explore <ArrowRight className="h-4 w-4 ml-2" />
                </Button>
              </CardFooter>
            </div>
          </Card>*/}

          {/* Card 2 - Image top layout 
          <Card className="overflow-hidden hover:shadow-lg transition-shadow duration-300">
            <div className="flex flex-col h-full">
              <div className="bg-green-100 p-6 flex justify-center">
                {categories[1].icon}
              </div>
              <CardHeader>
                <h3 className="text-xl font-semibold">{categories[1].title}</h3>
              </CardHeader>
              <CardContent className="py-2 flex-grow">
                <p className="text-gray-600">{categories[1].description}</p>
              </CardContent>
              <CardFooter>
                <Button 
                  className="w-full bg-green-600 hover:bg-green-700"
                  onClick={() => navigate(categories[1].path)}
                >
                  View AI Teams
                </Button>
              </CardFooter>
            </div>
          </Card>*/}

          {/* Card 3 - Border accent layout 
          <Card className="overflow-hidden border-l-4 border-purple-500 hover:shadow-lg transition-shadow duration-300">
            <div className="flex flex-col h-full">
              <CardHeader className="flex flex-row items-center justify-between">
                <h3 className="text-xl font-semibold">{categories[2].title}</h3>
                {categories[2].icon}
              </CardHeader>
              <CardContent className="py-4 flex-grow">
                <p className="text-gray-600">{categories[2].description}</p>
              </CardContent>
              <CardFooter className="bg-purple-50">
                <Button 
                  variant="ghost" 
                  className="w-full text-purple-700 hover:text-purple-900 hover:bg-purple-100"
                  onClick={() => navigate(categories[2].path)}
                >
                  Discover Experts <ArrowRight className="h-4 w-4 ml-2" />
                </Button>
              </CardFooter>
            </div>
          </Card>*/}
        </div>
      </div>
    </div>
  );
};

export default AssistantCategories;