import React from "react";
import { 
  FileText, 
  ShoppingBag, 
  Image, 
  Share2, 
  BookOpen, 
  Bell,
  FileBox
} from "lucide-react";

const DashboardStats = ({ assetTypeCounts, unreadCounts }) => {
  const stats = [
    {
      name: "Total Assets",
      value: Object.values(assetTypeCounts).reduce((a, b) => a + b, 0) - assetTypeCounts.notifications,
      icon: <FileBox className="h-8 w-8" />,
      color: "text-black bg-gray-100"
    },
    {
      name: "Notifications",
      value: assetTypeCounts.notifications || 0,
      unread: unreadCounts.notifications || 0,
      icon: <Bell className={`h-8 w-8 ${unreadCounts.notifications >0 ? 'text-indigo-500' : 'text-gray-100'}`} />,
      color: "text-black bg-gray-100"
    }
  ];

  
    {/*{
      name: "Blog Posts",
      value: assetTypeCounts["blog-posts"] || 0,
      unread: unreadCounts["blog-posts"] || 0,
      icon: <FileText className="h-8 w-8 text-green-500" />,
      color: "magical-gradient text-white"
    },
    {
      name: "Products",
      value: assetTypeCounts["products"] || 0,
      unread: unreadCounts["products"] || 0,
      icon: <ShoppingBag className="h-8 w-8 text-purple-500" />,
      color: "bg-purple-100 dark:bg-purple-900/30"
    }*/}

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="flex items-center p-4 rounded shadow-md"
        >
          <div className={`p-3 rounded-full ${stat.color}`}>
            {stat.icon}
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
              {stat.name}
            </p>
            <div className="flex items-center">
              <p className="text-2xl font-bold">{stat.value}</p>
              {stat.unread > 0 && (
                <span className="ml-2 bg-gray-100 shadow-md text-indigo-500 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  {stat.unread} unread
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardStats;