// src/slices/todoSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

// Async thunks
export const fetchTodos = createAsyncThunk(
  'todos/fetchTodos',
  async ({ status, priority, team, startDate, endDate, search, sort, page, limit } = {}, { rejectWithValue }) => {
    try {
      const response = await api.getTodos({ 
        status, 
        priority, 
        team, 
        startDate, 
        endDate, 
        search, 
        sort, 
        page, 
        limit 
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch todos');
    }
  }
);

export const fetchTeamTodos = createAsyncThunk(
  'todos/fetchTeamTodos',
  async ({ teamId, status, priority, search, sort, page, limit }, { rejectWithValue }) => {
    try {
      const response = await api.getTeamTodos(teamId, { 
        status, 
        priority, 
        search, 
        sort, 
        page, 
        limit 
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch team todos');
    }
  }
);

export const createTodo = createAsyncThunk(
  'todos/createTodo',
  async (todoData, { rejectWithValue }) => {
    try {
      const response = await api.createTodo(todoData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create todo');
    }
  }
);

export const updateTodo = createAsyncThunk(
  'todos/updateTodo',
  async ({ id, updates }, { rejectWithValue }) => {
    try {
      const response = await api.updateTodo(id, updates);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update todo');
    }
  }
);

export const deleteTodo = createAsyncThunk(
  'todos/deleteTodo',
  async (id, { rejectWithValue }) => {
    try {
      await api.deleteTodo(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete todo');
    }
  }
);

export const updateTodoStatus = createAsyncThunk(
  'todos/updateStatus',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await api.updateTodoStatus(id, status);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update todo status');
    }
  }
);

export const toggleTodoArchive = createAsyncThunk(
  'todos/toggleArchive',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.toggleTodoArchive(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to toggle todo archive status');
    }
  }
);

export const addTodoAttachment = createAsyncThunk(
  'todos/addAttachment',
  async ({ id, file }, { rejectWithValue }) => {
    try {
      const response = await api.addTodoAttachment(id, file);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add attachment');
    }
  }
);

export const cloneTodo = createAsyncThunk(
    'todos/cloneTodo',
    async (id, { rejectWithValue }) => {
      try {
        const response = await api.cloneTodo(id);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to clone todo');
      }
    }
  );
  
  export const updateTodoLabels = createAsyncThunk(
    'todos/updateLabels',
    async ({ id, labels }, { rejectWithValue }) => {
      try {
        const response = await api.updateTodoLabels(id, labels);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to update labels');
      }
    }
  );
  
  export const bulkArchiveTodos = createAsyncThunk(
    'todos/bulkArchive',
    async (todoIds, { rejectWithValue }) => {
      try {
        const response = await api.bulkArchiveTodos(todoIds);
        return { todoIds, response: response.data };
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to archive todos');
      }
    }
  );
  
  export const fetchTodoStats = createAsyncThunk(
    'todos/fetchStats',
    async (teamId = null, { rejectWithValue }) => {
      try {
        const response = await api.getTodoStats(teamId);
        //console.log('fetchTodoStats response:', response.data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to fetch todo statistics');
      }
    }
  );

  export const updateChecklistItem = createAsyncThunk(
    'todos/updateChecklistItem',
    async ({ todoId, itemIndex, completed }, { rejectWithValue }) => {
      try {
        const response = await api.updateTodoChecklistItem(todoId, itemIndex, completed);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to update checklist item');
      }
    }
  );

  const initialState = {
    items: [], // Changed from todos to items for clarity
    selectedTodo: null,
    loading: false,
    error: null,
    pagination: {
      total: 0,
      page: 1,
      pages: 1
    },
    filters: {
      status: '',
      priority: '',
      team: '',
      startDate: null,
      endDate: null,
      search: '',
      sort: '-createdAt'
    },
    stats: {
      loading: false,
      error: null,
      data: {
        statusDistribution: {},
        priorityDistribution: {},
        totalActive: 0,
        totalArchived: 0,
        dueThisWeek: 0
      }
    }
  };

const todoSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    setSelectedTodo: (state, action) => {
      state.selectedTodo = action.payload;
    },
    clearSelectedTodo: (state) => {
      state.selectedTodo = null;
    },
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch todos
      .addCase(fetchTodos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTodos.fulfilled, (state, action) => {
        state.items = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
      })
      .addCase(fetchTodos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create todo
      .addCase(createTodo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTodo.fulfilled, (state, action) => {
        state.items.unshift(action.payload.data);
        state.loading = false;
      })
      .addCase(createTodo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update todo
      .addCase(updateTodo.fulfilled, (state, action) => {
        const updatedTodo = action.payload.data;  // Extract just the todo data
        const index = state.items.findIndex(todo => todo._id === updatedTodo._id);
        if (index !== -1) {
          state.items[index] = updatedTodo;
        }
        if (state.selectedTodo?._id === updatedTodo._id) {
          state.selectedTodo = updatedTodo;
        }
      })

      // Delete todo
      .addCase(deleteTodo.fulfilled, (state, action) => {
        state.items = state.items.filter(todo => todo._id !== action.payload);
        if (state.selectedTodo?._id === action.payload) {
          state.selectedTodo = null;
        }
      })

      // Update status
      .addCase(updateTodoStatus.fulfilled, (state, action) => {
        const index = state.items.findIndex(todo => todo._id === action.payload.data._id);
        if (index !== -1) {
          state.items[index] = action.payload.data;
        }
        if (state.selectedTodo?._id === action.payload.data._id) {
          state.selectedTodo = action.payload.data;
        }
      })

      // Toggle archive
      .addCase(toggleTodoArchive.fulfilled, (state, action) => {
        const index = state.todos.findIndex(todo => todo._id === action.payload.data._id);
        if (index !== -1) {
          state.todos[index] = action.payload.data;
        }
        if (state.selectedTodo?._id === action.payload.data._id) {
          state.selectedTodo = action.payload.data;
        }
      })

      // Clone todo
  .addCase(cloneTodo.fulfilled, (state, action) => {
    state.todos.unshift(action.payload.data);
  })

  // Update labels
  .addCase(updateTodoLabels.fulfilled, (state, action) => {
    const index = state.todos.findIndex(todo => todo._id === action.payload.data._id);
    if (index !== -1) {
      state.todos[index] = action.payload.data;
    }
    if (state.selectedTodo?._id === action.payload.data._id) {
      state.selectedTodo = action.payload.data;
    }
  })
  
  // Bulk archive
  .addCase(bulkArchiveTodos.fulfilled, (state, action) => {
    const { todoIds } = action.payload;
    state.todos = state.todos.filter(todo => !todoIds.includes(todo._id));
    if (state.selectedTodo && todoIds.includes(state.selectedTodo._id)) {
      state.selectedTodo = null;
    }
  })
  
  // Fetch stats
  .addCase(fetchTodoStats.pending, (state) => {
    state.stats.loading = true;
    state.stats.error = null;
  })
  .addCase(fetchTodoStats.fulfilled, (state, action) => {
    state.stats.loading = false;
    state.stats.data = action.payload.data;
  })
  .addCase(fetchTodoStats.rejected, (state, action) => {
    state.stats.loading = false;
    state.stats.error = action.payload;
  })

  .addCase(updateChecklistItem.fulfilled, (state, action) => {
    const updatedTodo = action.payload.data;
    // Update in items array
    const index = state.items.findIndex(todo => todo._id === updatedTodo._id);
    if (index !== -1) {
      state.items[index] = updatedTodo;
    }
    // Update in selectedTodo if it's the same todo
    if (state.selectedTodo?._id === updatedTodo._id) {
      state.selectedTodo = updatedTodo;
    }
  })

      // Add attachment
      .addCase(addTodoAttachment.fulfilled, (state, action) => {
        const index = state.todos.findIndex(todo => todo._id === action.payload.data._id);
        if (index !== -1) {
          state.todos[index] = action.payload.data;
        }
        if (state.selectedTodo?._id === action.payload.data._id) {
          state.selectedTodo = action.payload.data;
        }
      });
  }
});

export const {
  setSelectedTodo,
  clearSelectedTodo,
  updateFilters,
  resetFilters,
  clearError
} = todoSlice.actions;

// Selectors
export const selectTodos = state => state.todos.items;
export const selectSelectedTodo = state => state.todos.selectedTodo;
export const selectTodoLoading = state => state.todos.loading;
export const selectTodoError = state => state.todos.error;
export const selectTodoPagination = state => state.todos.pagination;
export const selectTodoFilters = state => state.todos.filters;
export const selectTodoStats = state => state.todos.stats;

export default todoSlice.reducer;