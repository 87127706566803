import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import VideoTutorialsAll from "./DocumentationComponents/VideoTutorialsAll";
import InteractiveDemosAll from "./DocumentationComponents/InteractiveDemosAll";
import { LifeBuoy, HelpCircle, Play } from "lucide-react";

const DocumentationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-0 lg:px-4 py-2 lg:py-8">
      <Card className="w-full max-w-6xl mx-auto min-h-[70vh] shadow-xl">
        <CardHeader>
          <CardTitle className="text-lg lg:text-2xl font-bold flex items-center leading-none">
            <LifeBuoy className="mr-2 h-6 w-6 text-pink-700" />
            Help & Guides - Flow Orchestra
          </CardTitle>
          <p className="text-muted-foreground mt-2">
            Welcome to the help center. Here you'll find video tutorials and a
            growing list of guides, and resources to help you get the most out
            of Flow Orchestra. Can't find what you're looking for? Visit our
            support page for personalized assistance.
          </p>
        </CardHeader>
        <CardContent className="space-y-10 p-3 lg:p-8">
          <div className="space-y-4">
            <div className="mb-20">
            <InteractiveDemosAll />
            </div>
            
            <div className="text-lg font-semibold text-center flex items-center justify-center">
              <Play className="h-5 w-5 mr-2" />Getting Started with Flow Orchestra
            </div>
            <div className="relative w-full pb-[56.25%]">
              <iframe
                className="absolute top-0 left-0 w-full rounded shadow-xl h-full"
                src="https://www.youtube.com/embed/V4FBu4gqcCs?si=aBVNuWI58k9paVbl"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="text-center px-4">
              <a href="https://www.youtube.com/@Flow-Orchestra" target="_blank" rel="noreferrer">
                <Button className="bg-gray-100 shadow-md hover:bg-gray-200 text-black hover:text-black mt-4 max-w-full whitespace-normal h-auto py-2">
                  <span className="block text-sm sm:text-base">
                    Visit our YouTube Channel
                    <br className="sm:hidden" /> for more helpful videos
                  </span>
                </Button>
              </a>
            </div>
          </div>

          <VideoTutorialsAll />
          <div className="flex justify-center pt-4">
            <Button
              onClick={() => navigate("/support")}
              className="bg-black hover:bg-pink-700 text-white hover:text-white"
            >
              <HelpCircle className="mr-2 h-4 w-4" />
              Get Support
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DocumentationPage;
