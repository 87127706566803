// src/pages/AdminComponents/UsersDashboard.js
import React from 'react';

const UsersDashboard = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Users</h2>
      <p className="text-gray-600">This section is under construction. Check back soon for user management and detailed profiles.</p>
    </div>
  );
};

export default UsersDashboard;
