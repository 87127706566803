import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';

const PricingHeader = ({ launchPricingEnabled, discountPercentage = 25 }) => {
  return (
    <div className="w-full max-w-4xl mx-auto px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center space-y-8"
      >
        <h2 className="text-4xl md:text-5xl font-bold text-black">
          Simple, Transparent Pricing
        </h2>

        {launchPricingEnabled && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.4 }}
          >
            <div className="relative inline-block">
              <div className="absolute inset-0 bg-red-500 opacity-10 blur-xl rounded-xl" />
              <div className="relative border-2 border-red-500 bg-black px-6 py-4 md:px-8 md:py-5 rounded-xl">
                <div className="flex items-center justify-center gap-2 text-center">
                  <Sparkles className="h-5 w-5 text-red-400" />
                  <span className="font-semibold text-red-400">Special Launch Pricing</span>
                </div>
                <p className="mt-2 text-gray-100 font-medium">
                  For a limited time, we want to give you{' '}
                  <span className="text-red-400 font-bold">{discountPercentage}% OFF</span>
                  <br className="hidden sm:block" />{' '}
                  your first full year with Flow Orchestra! 🚀
                </p>
              </div>
            </div>
          </motion.div>
        )}

        <p className="text-lg text-[#94a3b8]">
          Choose the plan that's right for you.
        </p>
      </motion.div>
    </div>
  );
};

export default PricingHeader;