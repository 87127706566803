import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSubscription from "../hooks/useSubscription";
import { Card, CardContent } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../components/ui/dialog";
import {
  PlusCircle,
  Save,
  ChevronUp,
  ChevronDown,
  X,
  Copy,
  Trash2,
  Menu,
  Waypoints,
  ChevronLeft,
  ChevronRight,
  ChevronLeftCircle,
  Highlighter,
} from "lucide-react";
import { fetchAssistants } from "../slices/flowsSlice";
import { fetchUserChatbots } from "../slices/userChatbotSlice";
import StepCard from "./FlowFormComponents/StepCard";
import { fetchItems } from "../slices/genericItemsSlice";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
} from "../components/ui/alert-dialog";

const FlowForm = ({
  initialFlow,
  onSubmit,
  onSave,
  submitButtonText,
  saveButtonText,
  isSubmitting,
  submittingText,
  isSaving,
  savingText,
  isEditMode = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentTier, status, limits } = useSubscription();
  //console.log('currentTier', currentTier);
  //console.log('flow steps', limits.flowSteps);
  const assistants = useSelector((state) => state.flows.assistants);
  const userChatbots = useSelector((state) => state.userChatbots.chatbots);
  const assistantsStatus = useSelector((state) => state.flows.assistantsStatus);
  const assistantsError = useSelector((state) => state.flows.assistantsError);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const writingStyles = useSelector(
    (state) => state.genericItems.items["WritingStyle"] || []
  );
  const isLoadingWritingStyles = useSelector(
    (state) => state.genericItems.isLoading["WritingStyle"] || false
  );
  const writingStylesError = useSelector(
    (state) => state.genericItems.error["WritingStyle"]
  );

  const [flowData, setFlowData] = useState({
    name: "",
    description: "",
    steps: [],
  });

  const MAXPROMPTLENGTH = 5000;

  const [masterWritingStyleId, setMasterWritingStyleId] = useState(null);
  const [pendingMasterWritingStyleId, setPendingMasterWritingStyleId] =
    useState(null);
  const [isMasterWritingStyleDialogOpen, setIsMasterWritingStyleDialogOpen] =
    useState(false);

  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const [combinedChatbots, setCombinedChatbots] = useState([]);

  useEffect(() => {
    if (!writingStyles.length) {
      dispatch(fetchItems("WritingStyle"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (initialFlow) {
      setFlowData({
        name: initialFlow.name,
        description: initialFlow.description,
        steps: initialFlow.steps.map((step) => ({
          ...step,
          category: step.category || "generate",
          type:
            step.type ||
            (step.category === "generate" ? "aiRequest" : "saveAsset"),
        })),
      });
      setCurrentStepIndex(0);
    }
    if (assistantsStatus === "idle") {
      dispatch(fetchAssistants());
    }
    dispatch(fetchUserChatbots());
    setCombinedChatbots([...userChatbots, ...assistants]);
  }, [initialFlow, assistantsStatus, dispatch]);

  const handleSaveClick = (e) => {
    e.preventDefault();
    setError(null);
    const errors = validateForm();
    if (errors.length > 0) {
      setValidationErrors(errors);
      setIsValidationDialogOpen(true);
      return;
    }
    onSave(flowData);
  };

  const handleMasterWritingStyleChange = (value) => {
    if (
      flowData.steps.some(
        (step) => step.type === "aiRequest" && step.writingStyleId
      )
    ) {
      // There are existing AI Request steps with a writingStyleId
      setPendingMasterWritingStyleId(value);
      setIsMasterWritingStyleDialogOpen(true);
    } else {
      // No existing AI Request steps or none have a writingStyleId
      setMasterWritingStyleId(value);
    }
  };

  const handleAddStep = useCallback(() => {
    if (flowData.steps.length >= limits.flowSteps) {
      alert(`You have reached the maximum number of steps (${limits.flowSteps}). Upgrade your subscription to add more steps.`);
      return;
    }
    setFlowData((prevData) => {
      const insertIndex =
        currentStepIndex !== null
          ? currentStepIndex + 1
          : prevData.steps.length;
      const newSteps = [...prevData.steps];

      const newStep = {
        category: "generate",
        type: "aiRequest",
        name: `Step ${prevData.steps.length + 1}`,
        description: "",
        outputKey: `step${prevData.steps.length + 1}_output`,
      };

      // If the new step is an AI Request step and masterWritingStyleId is set
      if (newStep.type === "aiRequest" && masterWritingStyleId) {
        newStep.writingStyleId = masterWritingStyleId;
      }

      newSteps.splice(insertIndex, 0, newStep);

      return { ...prevData, steps: newSteps };
    });
    setCurrentStepIndex(currentStepIndex !== null ? currentStepIndex + 1 : 0);
  }, [currentStepIndex, masterWritingStyleId]);

  const handleStepChange = useCallback((index, fieldOrUpdates, value) => {
    setFlowData((prevData) => {
      const newSteps = [...prevData.steps];
      const step = { ...newSteps[index] };

      if (typeof fieldOrUpdates === "object") {
        // Handle multiple updates
        newSteps[index] = { ...step, ...fieldOrUpdates };
      } else {
        const fieldPath = fieldOrUpdates.split(".");

        if (fieldPath.length === 1) {
          // Single-level field
          step[fieldOrUpdates] = value;
        } else {
          // Nested field
          let current = step;
          for (let i = 0; i < fieldPath.length - 1; i++) {
            const key = fieldPath[i];
            if (!current[key] || typeof current[key] !== "object") {
              current[key] = {};
            } else {
              current[key] = { ...current[key] };
            }
            current = current[key];
          }
          current[fieldPath[fieldPath.length - 1]] = value;
        }

        newSteps[index] = step;
      }

      return { ...prevData, steps: newSteps };
    });
  }, []);

  const handleRemoveStep = useCallback((index) => {
    setFlowData((prevData) => ({
      ...prevData,
      steps: prevData.steps.filter((_, i) => i !== index),
    }));
    setCurrentStepIndex((prevIndex) => {
      if (prevIndex === index) {
        return index > 0 ? index - 1 : null;
      } else if (prevIndex > index) {
        return prevIndex - 1;
      }
      return prevIndex;
    });
  }, []);

  const moveStep = useCallback((fromIndex, toIndex) => {
    setFlowData((prevData) => {
      const newSteps = [...prevData.steps];
      const [movedStep] = newSteps.splice(fromIndex, 1);
      newSteps.splice(toIndex, 0, movedStep);
      return { ...prevData, steps: newSteps };
    });
    setCurrentStepIndex(toIndex);
  }, []);

  const cloneStep = useCallback((index) => {
    setFlowData((prevData) => {
      const newSteps = [...prevData.steps];
      const stepToClone = { ...newSteps[index] };

      // Create clone with modified name
      const clone = {
        ...stepToClone,
        name: `${stepToClone.name} (clone)`,
        outputKey: `${stepToClone.outputKey}_clone`,
      };

      // Insert clone after the original step
      newSteps.splice(index + 1, 0, clone);
      return { ...prevData, steps: newSteps };
    });
    // Set focus to the new cloned step
    setCurrentStepIndex(index + 1);
  }, []);

  const generatePreviousStepPlaceholders = useCallback(
    (currentIndex) => {
      return flowData.steps
        .slice(0, currentIndex)
        .map(
          (step, index) => `{{${step.outputKey || `step${index + 1}_output`}}}`
        )
        .join(", ");
    },
    [flowData.steps]
  );

  const validateForm = useCallback(() => {
    const errors = [];
    if (!flowData.name.trim()) errors.push("Flow name is required");
    if (!flowData.description.trim())
      errors.push("Flow description is required");
    if (flowData.steps.length === 0)
      errors.push("At least one step is required");
    if (flowData.steps.length > limits.flowSteps)
      errors.push(`Maximum number of steps is ${limits.flowSteps}`);
    
    flowData.steps.forEach((step, index) => {
      if (!step.name.trim()) errors.push(`Step ${index + 1} name is required`);
      if (!step.outputKey.trim())
        errors.push(`Step ${index + 1} output key name is required`);
      
      // Check prompt length
      if (step.prompt && step.prompt.length > MAXPROMPTLENGTH) {
        errors.push(`Step ${index + 1} prompt exceeds ${MAXPROMPTLENGTH} characters`);
      }
      
      // Validate AI request steps have an assistant ID
      if (step.type === 'aiRequest' && !step.assistantId) {
        errors.push(`Step ${index + 1} requires an assistant selection`);
      }
      
      // Add more step-specific validations here
    });
    return errors;
  }, [flowData]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      const errors = validateForm();
      if (errors.length > 0) {
        setValidationErrors(errors);
        setIsValidationDialogOpen(true);
        return;
      }
      try {
        // Ensure all step data is included in the submission
        const submissionData = {
          ...flowData,
          steps: flowData.steps.map((step) => ({
            ...step,
            category: step.category || "generate",
            type:
              step.type ||
              (step.category === "generate" ? "aiRequest" : "saveAsset"),
          })),
        };

        onSubmit(submissionData);
      } catch (error) {
        setError(error.message);
      }
    },
    [flowData, onSubmit, validateForm, writingStyles, assistants]
  );

  if (!limits?.flowSteps) {
    return <div className="text-center">Loading subscription limits...</div>;
  }

  return (
    <div className={`flex flex-col lg:flex-row ${isEditMode ? 'h-[calc(100vh-8rem)] lg:h-[calc(100vh-4rem)]' : 'h-[calc(100vh-9rem)] lg:h-auto'} -mx-6 -mt-6 lg:mx-0 lg:mt-0 overflow-hidden`}>
      {/* Header Bar - Desktop visible, Mobile sticky bottom */}
      <div
        className="lg:hidden flex items-center h-fit py-2 lg:h-20 px-4 z-[60] border-b
        fixed bottom-0 left-0 right-0 bg-gray-300"
      >
        <div className="flex-1 flex flex-col items-start justify-between w-full">
          {/*!isMobile && <div className="flex items-center">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className={`mr-2 lg:hidden ${
                flowData.steps.length === 0 ? "bg-green-300 animate-pulse" : ""
              }`}
            >
              <Waypoints className="h-5 w-5" />
            </Button>
            <h2 className="text-xs font-medium wrap leading-none">
              {flowData.name || (isEditMode ? "Edit Flow" : "New Flow")}
            </h2>
          </div>
          */}
          <div className="grid grid-cols-3 items-center gap-2 justify-between w-full">
          <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className={`lg:hidden ${
                flowData.steps.length === 0 ? "bg-green-300 animate-pulse" : ""
              }`}
            >
              <ChevronLeftCircle className="h-5 w-5 mr-1" />Steps
            </Button>
            <Button
              type="submit"
              size="sm"
              disabled={isSubmitting || flowData.steps.length === 0}
              onClick={handleSubmit}
              className="bg-black text-white hover:bg-gray-800"
            >
              {isSubmitting ? submittingText : submitButtonText}
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => navigate("/manage-flows")}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>

      {/* Left Column - Flow Overview */}
      <div
        className={`
          w-full lg:w-1/3 border-r bg-background
          fixed lg:relative inset-y-0 left-0 z-[70]
          transform transition-transform duration-200 ease-in-out
          lg:transform-none h-[calc(100vh-4rem)] lg:h-auto pt-0 
          ${
            isMobileMenuOpen
              ? "translate-x-0"
              : "-translate-x-full lg:translate-x-0"
          }
        `}
      >
        <div className="flex flex-col h-full rounded">
          <div className="p-4 border-b lg:border-none">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-xl font-bold">Flow Overview</h2>
              <Button
                variant="ghost"
                size="sm"
                className="lg:hidden"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <ChevronLeft className="h-5 w-5" />
              </Button>
            </div>

            {/* Flow Name and Description */}
            <Card className="mb-4 border-none shadow-xl">
              <CardContent className="p-2 lg:p-4 bg-gray-100">
                <Label htmlFor="flowName" className="text-base font-semibold">
                  Flow Name
                </Label>
                <Input
                  id="flowName"
                  value={flowData.name}
                  onChange={(e) =>
                    setFlowData((prev) => ({ ...prev, name: e.target.value }))
                  }
                  required
                  className="mt-1 border-none bg-[#fafafa] shadow-sm"
                  placeholder="Enter flow name"
                />

                <Label
                  htmlFor="flowDescription"
                  className="text-base font-semibold mt-4"
                >
                  Description
                </Label>
                <Textarea
                  id="flowDescription"
                  value={flowData.description}
                  onChange={(e) =>
                    setFlowData((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className="mt-1 border-none bg-[#fafafa] shadow-sm"
                  placeholder="Describe your flow"
                />
              </CardContent>
            </Card>

            {/* Master Writing Style Selection */}
            {/*<div className="my-4">
              <Label
                htmlFor="masterWritingStyle"
                className="text-base font-semibold"
              >
                Master Writing Style
              </Label>
              {isLoadingWritingStyles ? (
                <div className="flex items-center space-x-2 mt-2">
                  <span className="loading loading-spinner"></span>
                  <span>Loading Writing Styles...</span>
                </div>
              ) : writingStyles.length > 0 ? (
                <Select
                  value={masterWritingStyleId || ""}
                  onValueChange={handleMasterWritingStyleChange}
                >
                  <SelectTrigger id="masterWritingStyle">
                    <SelectValue placeholder="Select Writing Style" />
                  </SelectTrigger>
                  <SelectContent className="max-h-60 overflow-auto z-[70]">
                    {writingStyles.map((style) => (
                      <SelectItem key={style._id} value={style._id}>
                        <div className="flex items-start">
                          <img
                            src={style.avatarUrl}
                            alt={style.name}
                            className="w-8 h-8 rounded-full mr-2 flex-shrink-0"
                          />
                          <div>
                            <div className="font-semibold">{style.name}</div>
                            <div className="text-sm text-gray-500 truncate">
                              {style.description.length > 50
                                ? `${style.description.substring(0, 50)}...`
                                : style.description}
                            </div>
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              ) : (
                <div className="text-sm text-red-500 mt-2">
                  {writingStylesError
                    ? `Error: ${writingStylesError}`
                    : "No Writing Styles available."}
                </div>
              )}
            </div>*/}

            {/* Steps List */}
            <Card className="border-none shadow-xl">
              <CardContent className="p-2 lg:p-4 bg-gray-100 rounded">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-bold">Steps</h3>
                  <div className="flex items-center gap-2 text-sm">
                    <span className={`${flowData.steps.length >= limits.flowSteps ? 'bg-red-300 rounded' : ''} px-2 py-1`}>Max: {limits.flowSteps}</span>
                  
                  <Button
                    type="button"
                    onClick={handleAddStep}
                    variant="outline"
                    size="sm"
                    disabled={flowData.steps.length >= limits.flowSteps}
                    className="bg-black text-gray-200 hover:bg-gray-700 hover:text-white"
                  >
                    <PlusCircle className="w-4 h-4 mr-2" /> Add Step
                  </Button>
                  </div>
                </div>
                <ul className="space-y-2 max-h-[calc(100vh-32rem)] lg:max-h-[calc(100vh-28rem)] lg:h-[calc(100vh-28rem)] overflow-y-auto">
                  {flowData.steps.map((step, index) => (
                    <li
                    key={index}
                    className={`px-2 py-1.5 rounded flex items-center justify-between cursor-pointer 
                      ${currentStepIndex === index ? "bg-black text-white font-bold" : "bg-white"} ${step.type === "addContext" ? "border border-indigo-700" : ""}`}
                    onClick={() => {
                      setCurrentStepIndex(index);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    <div className="flex flex-col gap-0 w-2/3">
                    <div className="truncate mr-2">{step.name}</div>
                    {step.type === "addContext" && <div className="text-xs text-indigo-500 flex items-center"><Highlighter className="h-4 w-4 mr-1 text-indigo-500" />Update With Your Details</div>}
                    </div>
                    <div className="flex shrink-0 gap-0.5">
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="px-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          moveStep(index, index - 1);
                        }}
                        disabled={index === 0}
                      >
                        <ChevronUp className="w-4 h-4" />
                      </Button>
                      <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          className="px-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            moveStep(index, index + 1);
                          }}
                          disabled={index === flowData.steps.length - 1}
                        >
                          <ChevronDown className="w-4 h-4" />
                        </Button>
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          className="px-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            cloneStep(index);
                          }}
                        >
                          <Copy className="w-4 h-4" />
                        </Button>
<AlertDialog>
                          <AlertDialogTrigger
                            asChild
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Button
                              type="button"
                              variant="ghost"
                              size="sm"
                              className="hover:bg-red-100 px-1"
                            >
                              <Trash2 className="w-4 h-4 text-red-600" />
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent className="z-[80]"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <AlertDialogHeader>
                              <AlertDialogTitle>Delete Step</AlertDialogTitle>
                              <AlertDialogDescription>
                                Are you sure you want to delete "{step.name}"?
                                This action cannot be undone.
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>Cancel</AlertDialogCancel>
                              <AlertDialogAction
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveStep(index);
                                }}
                                className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
                              >
                                Delete
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                    </div>
                  </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {/* Right Column - Step Details */}
      <div className="flex flex-col flex-1 overflow-y-auto pt-0 pb-4">
        <div className="hidden lg:flex items-center p-2 lg:pt-0 bg-white mx-4">
          <div className="flex-1 hidden lg:flex flex-row items-end justify-between w-full">
            <div className="font-bold">{flowData.name}</div>
            <div className="flex items-center gap-2">
              {isEditMode && (
                <Button
                type="button"
                size="sm"
                disabled={isSaving || flowData.steps.length === 0}
                onClick={handleSaveClick}
                className="bg-black text-white hover:bg-green-500 hover:text-gray-800"
              >
                {isSaving ? savingText : saveButtonText}
              </Button>
              )}
              
              <Button
                type="submit"
                size="sm"
                disabled={isSubmitting || flowData.steps.length === 0}
                onClick={handleSubmit}
                className="bg-black text-white hover:bg-green-500 hover:text-gray-800"
              >
                {isSubmitting ? submittingText : submitButtonText}
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => navigate("/manage-flows")}
                className="bg-gray-500 text-white hover:bg-red-400"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="p-4 pt-0">
          {currentStepIndex !== null ? (
            <StepCard
              step={flowData.steps[currentStepIndex]}
              index={currentStepIndex}
              handleStepChange={handleStepChange}
              handleRemoveStep={handleRemoveStep}
              assistants={combinedChatbots}
              generatePreviousStepPlaceholders={
                generatePreviousStepPlaceholders
              }
              isFirstStep={currentStepIndex === 0}
            />
          ) : (
            <div className="h-full flex items-center justify-center">
              <p className="text-muted-foreground">
                Select a step to view details
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-[65] lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Dialogs */}
      <AlertDialog
        open={isMasterWritingStyleDialogOpen}
        onOpenChange={setIsMasterWritingStyleDialogOpen}
      >
        <AlertDialogContent className="z-[80]">
          <AlertDialogHeader>
            <AlertDialogTitle>Update Existing Steps?</AlertDialogTitle>
            <AlertDialogDescription>
              Do you want to update all existing AI Request steps with the new
              Writing Style?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setMasterWritingStyleId(pendingMasterWritingStyleId);
                setPendingMasterWritingStyleId(null);
              }}
            >
              New Steps Only
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setMasterWritingStyleId(pendingMasterWritingStyleId);
                setPendingMasterWritingStyleId(null);
                setFlowData((prevData) => {
                  const updatedSteps = prevData.steps.map((step) => {
                    if (step.type === "aiRequest") {
                      return {
                        ...step,
                        writingStyleId: pendingMasterWritingStyleId,
                      };
                    }
                    return step;
                  });
                  return { ...prevData, steps: updatedSteps };
                });
              }}
            >
              Update All Steps
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Dialog
        open={isValidationDialogOpen}
        onOpenChange={setIsValidationDialogOpen}
      >
        <DialogContent className="z-[80]">
          <DialogHeader>
            <DialogTitle>Form Validation Errors</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <ul className="list-disc pl-5">
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FlowForm;
