import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { Zap } from 'lucide-react';

const AddContextModal = ({ 
  isOpen, 
  onClose, 
  contextSteps, 
  onSubmit, 
  isSubmitting 
}) => {
  const [prompts, setPrompts] = useState({});
  const [errors, setErrors] = useState({});
  
  // Initialize prompts with existing values
  useEffect(() => {
    const initialPrompts = {};
    contextSteps.forEach(step => {
      initialPrompts[step._id] = step.prompt;
    });
    setPrompts(initialPrompts);
  }, [contextSteps]);

  const handleSubmit = () => {
    const newErrors = {};
    let hasErrors = false;
    
    Object.entries(prompts).forEach(([id, prompt]) => {
      if (prompt?.length > 5000) {
        newErrors[id] = 'Prompt must be 5000 characters or less';
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrors(newErrors);
      return;
    }

    onSubmit(prompts);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh] z-[60]">
        <DialogHeader>
          <DialogTitle className='flex items-center'><Zap className='h-5 w-5 mr-1 text-yellow-500' />Quick Customize & Run</DialogTitle>
          <DialogDescription>
          Quickly customize your flow's context before running, or leave as-is to proceed with current values. Click "Execute" when you're ready to run the flow.
          </DialogDescription>
        </DialogHeader>
        
        <ScrollArea className="h-[50vh] mt-4">
          {contextSteps.map((step) => (
            <div key={step._id} className="mb-6 mx-4">
              <h4 className="font-medium mb-2">{step.name}</h4>
              <p className="text-sm text-muted-foreground mb-2">{step.description}</p>
              <Textarea
                value={prompts[step._id] || ''}
                onChange={(e) => {
                  setPrompts(prev => ({
                    ...prev,
                    [step._id]: e.target.value
                  }));
                  if (errors[step._id]) {
                    setErrors(prev => ({
                      ...prev,
                      [step._id]: undefined
                    }));
                  }
                }}
                className="min-h-[100px]"
              />
              {errors[step._id] && (
                <Alert variant="destructive" className="mt-2">
                  <AlertDescription>{errors[step._id]}</AlertDescription>
                </Alert>
              )}
            </div>
          ))}
        </ScrollArea>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Execute"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddContextModal;