import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login, register } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
/*import {
  checkEmailWhitelist,
} from "../services/api";*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavigationDots from "./LoginComponents/NavigationDots";
import BenefitsSection from "./LoginComponents/BenefitsSection";
import PricingSection from "./LoginComponents/PricingSection";
import FAQSection from "./LoginComponents/FAQSection";
import FloatingQuote from "./LoginComponents/FloatingQuote";
import ConnectSection from "./LoginComponents/ConnectSection";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import InteractiveDemosAll from "./DocumentationComponents/InteractiveDemosAll";
import GoogleAuthButton from "../components/GoogleAuthButton";
import { openSurveyModal } from "../slices/surveyModalSlice";
import { Pointer } from "lucide-react";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const handleShowRegistration = () => {
      setIsLogin(false);
      setIsRegistering(true);
    };

    window.addEventListener("showRegistration", handleShowRegistration);

    return () => {
      window.removeEventListener("showRegistration", handleShowRegistration);
    };
  }, []);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await dispatch(login({ email, password })).unwrap();
      navigate("/dashboard");
    } catch (err) {
      alert("Invalid email or password");
      setError("Invalid email or password");
      setPassword("");
      //return; // Prevent the component from trying to navigate
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");

    if (registrationData.password !== registrationData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await dispatch(register(registrationData)).unwrap();
      navigate("/dashboard");
    } catch (err) {
      setError(err.message || "Registration failed");
    }
  };

  const sections = ["home", "pricing", "benefits", "faq", "connect"];

  return (
    <div className="relative">
      <div className="hidden lg:block">
        <NavigationDots sections={sections} />
      </div>

      {/* Main Content */}
      <div className="overflow-y-auto scroll-smooth h-screen">
        {/* Login Section */}
        <section data-section-index="0" className="min-h-screen relative">
          <div className="flex flex-col lg:flex-row min-h-screen w-full">
            {/* Left side - Dark section */}
            <div className="flex flex-col lg:w-1/2 bg-black text-white justify-between relative h-80 md:h-screen">
              <div className="absolute inset-0 bg-black p-0 pt-12 lg:p-0">
                <video
                  className="w-full h-full object-contain rounded lg:rounded-none"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Automate%20Your%20Content%20Creation%20with%20Flow%20Orchestra%202.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>

              <div className="p-4 lg:p-12 relative z-10">
                <div className="flex items-center space-x-2">
                  <img
                    src="/FlowOrchestraIcon5.png"
                    alt="Flow Orchestra Icon"
                    className="w-8 h-8 bg-white rounded-md"
                  />
                  <h1 className="text-xl font-semibold">
                    Flow Orchestra
                    <span className="text-xs ml-1 text-yellow-500">Beta</span>
                  </h1>
                </div>
              </div>
              <div className="flex flex-col text-white font-bold text-xl items-center justify-center text-center space-y-2 leading-none gap-2 relative bottom-2 lg:bottom-10">
                <div className="hidden lg:block bg-black/70 px-5 py-3 rounded">It only takes five clicks and 27 seconds to see how<br></br>Flow Orchestra can change YOUR life!</div>
                <Button
                  onClick={() => dispatch(openSurveyModal())}
                  className="px-2 lg:px-6 py-3 lg:text-lg bg-indigo-700 text-white rounded hover:bg-indigo-800 flex items-center justify-center gap-2"
                >
                  <Pointer className="h-5 w-5" />Show me how I can EXPLODE my productivity
                </Button>
              </div>
            </div>

            {/* Right side - Form container */}
            <div className="flex-1 flex flex-col justify-between px-4 py-4 lg:py-0 sm:px-6 lg:px-20 xl:px-24 bg-[#fafafa]">
              <div className="ml-auto mt-6 hidden lg:block">
                <a
                  href="https://www.producthunt.com/posts/flow-orchestra?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flow&#0045;orchestra"
                  target="_blank"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=825290&theme=light&t=1738605513944"
                    alt="Flow&#0032;Orchestra - AI&#0045;powered&#0032;orchestration&#0032;for&#0032;seamless&#0032;content&#0032;creation&#0046; | Product Hunt"
                    width="250"
                    height="54"
                  />
                </a>
              </div>
              <div className="flex-1 flex flex-col justify-center pt-2 lg:pt-28">
                {/* Waitlist Form or Login Form with Fade Effect */}
                <div
                  className={`w-full max-w-sm transition-opacity duration-1000`}
                >
                  {error && (
                    <div className="text-sm text-red-500 bg-red-50 border border-red-200 rounded-md p-3">
                      {error}
                    </div>
                  )}

                  {isLogin ? (
                    <>
                      <div className="flex justify-between items-center">
                        <h2 className="text-3xl font-bold text-inter">
                          Sign in
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                              setIsLogin(false);
                              setIsRegistering(true);
                            }}
                            className="text-sm text-gray-600 hover:text-gray-900 mr-4"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                      <p className="mt-2 text-sm text-gray-600">
                        Enter your credentials below to access your account
                      </p>
                      <form
                        onSubmit={handleLoginSubmit}
                        className="mt-8 space-y-6"
                      >
                        <div className="space-y-4">
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email address"
                            required
                            className="w-full"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            required
                            className="w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Button
                            type="submit"
                            className="w-full bg-black text-white hover:bg-gray-800"
                          >
                            Sign In
                          </Button>
                          <div className="relative my-6">
                            <div className="absolute inset-0 flex items-center">
                              <span className="w-full border-t" />
                            </div>
                            <div className="relative flex justify-center text-xs uppercase">
                              <span className="bg-[#fafafa] px-2 text-gray-500">
                                Or continue with
                              </span>
                            </div>
                          </div>
                          <GoogleAuthButton
                            onClick={() => {
                              window.location.href = `${
                                process.env.REACT_APP_SOCKET_URL ||
                                "http://localhost:5000"
                              }/auth/google`;
                            }}
                            text={
                              isLogin
                                ? "Sign in with Google"
                                : "Sign up with Google"
                            }
                          />
                          <div className="text-right mt-2">
                            <a
                              href="/forgot-password"
                              className="text-blue-500 hover:underline text-sm"
                            >
                              Forgot Password?
                            </a>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : isRegistering ? (
                    <>
                      <div className="flex justify-between items-center">
                        <h2 className="text-3xl font-bold text-inter">
                          Register
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                              setIsLogin(true);
                              setIsRegistering(false);
                            }}
                            className="text-sm text-gray-600 hover:text-gray-900 mr-4"
                          >
                            Sign in
                          </button>
                        </div>
                      </div>
                      <form
                        onSubmit={handleRegister}
                        className="mt-8 space-y-6"
                      >
                        <div className="space-y-4">
                          <div className="grid grid-cols-2 gap-4">
                            <Input
                              type="text"
                              placeholder="First Name"
                              value={registrationData.firstName}
                              onChange={(e) =>
                                setRegistrationData((prev) => ({
                                  ...prev,
                                  firstName: e.target.value,
                                }))
                              }
                              required
                            />
                            <Input
                              type="text"
                              placeholder="Last Name"
                              value={registrationData.lastName}
                              onChange={(e) =>
                                setRegistrationData((prev) => ({
                                  ...prev,
                                  lastName: e.target.value,
                                }))
                              }
                              required
                            />
                          </div>
                          <Input
                            type="email"
                            placeholder="Email address"
                            value={registrationData.email}
                            onChange={(e) => {
                              const email = e.target.value;
                              setRegistrationData((prev) => ({
                                ...prev,
                                email,
                              }));
                            }}
                            required
                          />
                          {registrationData.password &&
                            registrationData.password.length < 8 && (
                              <div className="text-sm text-red-500">
                                Passwords must contain at least 8 characters
                              </div>
                            )}
                          <Input
                            type="password"
                            placeholder="Password"
                            value={registrationData.password}
                            onChange={(e) =>
                              setRegistrationData((prev) => ({
                                ...prev,
                                password: e.target.value,
                              }))
                            }
                            required
                            className={
                              registrationData.password &&
                              registrationData.password.length < 8
                                ? "border-red-500 focus:border-red-500"
                                : ""
                            }
                          />
                          <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={registrationData.confirmPassword}
                            onChange={(e) =>
                              setRegistrationData((prev) => ({
                                ...prev,
                                confirmPassword: e.target.value,
                              }))
                            }
                            required
                          />
                          {registrationData.password &&
                            registrationData.confirmPassword &&
                            registrationData.password !==
                              registrationData.confirmPassword && (
                              <div className="text-sm text-red-500">
                                Passwords do not match
                              </div>
                            )}
                          <Button
                            type="submit"
                            className="w-full bg-black text-white hover:bg-gray-800"
                            disabled={
                              !registrationData.password ||
                              registrationData.password.length < 8 ||
                              !registrationData.confirmPassword ||
                              registrationData.password !==
                                registrationData.confirmPassword
                            }
                          >
                            Register
                          </Button>
                          <div className="relative my-6">
                            <div className="absolute inset-0 flex items-center">
                              <span className="w-full border-t" />
                            </div>
                            <div className="relative flex justify-center text-xs uppercase">
                              <span className="bg-[#fafafa] px-2 text-gray-500">
                                Or continue with
                              </span>
                            </div>
                          </div>
                          <GoogleAuthButton
                            onClick={() => {
                              window.location.href = `${
                                process.env.REACT_APP_SOCKET_URL ||
                                "http://localhost:5000"
                              }/auth/google`;
                            }}
                            text={
                              isLogin
                                ? "Sign in with Google"
                                : "Sign up with Google"
                            }
                          />
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-between items-center">
                        <div>
                          <button
                            onClick={() => setIsLogin(true)}
                            className="text-sm text-gray-600 hover:text-gray-900 mr-4"
                          >
                            Sign in
                          </button>
                          <button
                            onClick={() => setIsRegistering(true)}
                            className="text-sm text-gray-600 hover:text-gray-900"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {isLogin && (
                    <p className="mt-6 text-center text-sm text-gray-500">
                      By signing in, you agree to our{" "}
                      <a
                        href="/terms-of-service"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  )}
                  <div className="hidden inset-0 bg-black p-0 max-w-[40vw] lg:max-w-[200px] mx-auto mt-4 lg:mt-10">
                    <video
                      className="w-full h-full object-contain rounded lg:rounded-none"
                      src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/How%20to%20boost%20content%20production%20with%20Flow%20Orchestra.mp4"
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                  </div>
                  <div className="mx-auto mt-4 lg:hidden flex items-center justify-center">
                    <a
                      href="https://www.producthunt.com/posts/flow-orchestra?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-flow&#0045;orchestra"
                      target="_blank"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=825290&theme=light&t=1738605513944"
                        alt="Flow&#0032;Orchestra - AI&#0045;powered&#0032;orchestration&#0032;for&#0032;seamless&#0032;content&#0032;creation&#0046; | Product Hunt"
                        width="250"
                        height="54"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <FloatingQuote
                quote="Orchestrate. Automate. Innovate."
                author="Flow Orchestra"
                className="text-black text-right pr-4 lg:pr-12 pl-12 lg:pl-0"
              />
              <div className="lg:hidden flex flex-col items-center justify-center mt-0 space-x-2">
                More
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="scroll-chevron-glow w-6 h-6 text-primary hover:text-primary/80 transition-colors"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section data-section-index="1" className="min-h-screen relative pb-20">
          <PricingSection
            onStartFreePlan={() => {
              const firstSection = document.querySelector(
                '[data-section-index="0"]'
              );
              if (firstSection) {
                firstSection.scrollIntoView({ behavior: "smooth" });
                setIsLogin(false);
                setIsRegistering(true);
              }
            }}
          />
          <div className="max-w-7xl px-4 mx-auto mt-6">
            <InteractiveDemosAll />
          </div>
        </section>

        {/* Benefits Section */}
        <section
          data-section-index="2"
          className="min-h-screen relative pb-0 bg-black"
        >
          <BenefitsSection />
          <div className="text-center mt-6">
            <div className="text-gray-100 text-3xl max-w-3xl mx-auto">
              Your AI assistants are waiting to help you get started.
            </div>
            <Button
              className="bg-gray-100 text-black hover:bg-gray-200 hover:text-black mt-8 text-lg"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                  setIsLogin(false);
                  setIsRegistering(true);
                }
              }}
            >
              Get Started For Free
            </Button>
          </div>
          <img
            src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/Automate%20your%20content%20creation%20with%20Flow%20Orchestra.jpg"
            alt="Benefits"
            className="w-full lg:w-1/2 lg:mx-auto"
          />
        </section>

        {/* FAQ Section */}
        <section data-section-index="3" className="min-h-screen relative">
          <FAQSection />
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mt-12">
            <Button
              className="bg-gray-100 text-black hover:bg-gray-200 hover:text-black mt-8 text-lg"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                  setIsLogin(false);
                  setIsRegistering(true);
                }
              }}
            >
              Get Started For Free
            </Button>
          </div>
        </section>

        {/* Connect Section */}
        <section
          data-section-index="4"
          className="min-h-screen relative pb-12 lg:pb-0"
        >
          <ConnectSection />
          {/* Back to Top Button and Copyright can go here */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mt-12">
            <Button
              variant="outline"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="flex items-center gap-2 border-none shadow-md hover:shadow-xl bg-gray-100 hover:bg-gray-200 text-black hover:text-black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <path d="m18 15-6-6-6 6" />
              </svg>
              Back to Top
            </Button>
          </div>
        </section>
      </div>
      <div className="text-sm text-gray-500 text-center mb-2">
        © {new Date().getFullYear()} Flow Orchestra. All rights reserved.
      </div>
    </div>
  );
};

export default LoginPage;
